import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import { Tabs } from 'react-bootstrap';
import Content from '../components/Calendar/content';
import Wishes from '../components/Calendar/wishes';
import { useLocation } from 'react-router-dom';

const CalendarContent = () => {
    const { state } = useLocation();
    return (
        <main className='d-flex gap-3 flex-column my-4 mx-3'>
            <Card className='p-3 border border-dark'>
                <Tabs
                    defaultActiveKey="content"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                >
                    <Tab eventKey="content" title="Content" className='newclass'>
                        <Content eventid={state?.eventid} />
                    </Tab>
                    <Tab eventKey="wishes" title="Wishes">
                        <Wishes eventid={state?.eventid} />
                    </Tab>
                </Tabs>
            </Card>
        </main>
    )
}

export default CalendarContent