import { useEffect, useState ,useContext } from "react";
import { Button, Modal, Row ,Form ,Col} from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEdit, FaTrash,FaArrowLeft } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/banner_background.png";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import { setCookies } from '../utils/Helper';

const SidhaPurushGallery=()=> {
  document.title = ' Siddha Purush Gallery';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [showdt, setShowDt] = useState(false)
  const [allData , setAllData] = useState([])
  const [allDataCount , setAllDataCount] = useState([])
  const [data, setData] = useState();
  const [ selectedId ,setSelectedId] = useState()
  const { id: SDId } = useParams();
  const navigate = useNavigate();
  const  {refreshToken} = useContext(UserContext);


  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  useEffect(() => {
    fetchData();
  }, [offset]);

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`siddhaPurush/getDetailById/${SDId}/${offset}/${perPage}`, headers)
    let data = response?.response?.data
   
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false)
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  }


  useEffect(() => {
    if(allData && allData.length > 0){
      setLen(allDataCount);
      setPageCount(Math.ceil(allDataCount / perPage));
      setData(allData);
    }
  }, [allData])

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = (selectedPage * perPage) % len;
    setOffset(newOffset)
  }



  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = (id) => {
    setShowDt(true)
    setSelectedId(id)
  }
  const handledelete = async () => {
    let res = await APIServices.delete(`siddhaPurush/gallery/findByIdAndremove/${selectedId}`, headers)
    if (res?.status === 200) {
      setShowDt(false)
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
      }
    }
  }
  function copy(text){
  navigator.clipboard.writeText(text)
  }


  return (
    <>
      <div className="main aarti_section">
        <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" > 
            <FaArrowLeft
              className="hd-cr "
              style={{marginRight: "15px"}}
              size={25}
              onClick={() => navigate(-1)}/>
           
           Siddha Purush Gallery <small>({allDataCount})</small></h4>   
           </div>
        </div>
        <div className="aarti_inner pt-5">
           <div className="container">
              <div className="row">
                 <div className="col-md-12 col-lg-12"> 
                 {loading ? <div className="center"><Spinner animation="border" /></div> :
              <>
              
            <div className="row">
                 
              {
                data?.map((item) =>
                <div className="col-md-6 col-lg-4 col-xl-3">
                  <div className="card mb-3 Reels_video" key={item?._id} >
                    <div className="card-header">

                     {item?.type === "Image"?
                     <img
                       src={(item?.file) ? item?.file :item?.file_url?item?.file_url: bimg}
                       className="card-img-top"
                       alt="img"
                     />: item?.type === "Video"?
                <iframe
                title="astrowatch-content"
                width="560"
                height="242"
                className="w-100"
                src={(item?.file) ? item?.file :item?.file_url?item?.file_url: bimg}
                type="video/mp4"
                autoPlay
                controls
                ></iframe>
                   :''}
                      <div className="icon">
                        <FaTrash
                          className="dt-btn hd-cr "
                          size={20}
                          onClick={e => {
                            handleShowDt(item?._id)
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-body " >
                    <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2">
                        <span className="calicon">
                          <i className="ri-calendar-line"></i>
                        </span>
                        {new Date(item.createdAt).toDateString()}
                      </p>
                    
                    </div>
                   {/* <div className="card-footer">
                      <span style={{fontSize:"15px"}}>Click to copy</span>
                    <span type="button" className="link_copy_cs"  data-toggle="tooltip" title="Click to copy" onClick={() => copy(item?.file_url !== null ? item?.file_url : item?.file)}>
                         {item?.file_url !== null ? item?.file_url : item?.file}
                       </span>
                        </div> */}
                  </div>
                  </div>

                )
              }
           
            </div>
           
          </>
        }

<div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
                 </div>
              </div>
           </div>
        </div>
      </div>
     
 

      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt} 
        >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this element?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  )
}


export default SidhaPurushGallery;
