import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card, ListGroup } from 'react-bootstrap';

const FacebookPost = (props) => {

  const { fbPageAccessToken, id } = props;
  const [postDetails, setPostDetails] = useState({})
  const [postLikes, setPostLikes] = useState([])
  const [postComments, setPostComments] = useState([])
  const [postEngage, setPostEngage] = useState({})
  const [postImpression, setPostImpression] = useState([])

  //..................For Data............................

  const fetchData = () => {
    window.FB.api(`/${id}?fields=full_picture,message,created_time,permalink_url`, 'get', {
      access_token: fbPageAccessToken
    },
      function (response) {
        setPostDetails({ ...response });
      }
    );

  }

  useEffect(() => {
    if (fbPageAccessToken) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbPageAccessToken])

  //........................For Likes....................

  const fetchLikes = () => {
    window.FB.api(`/${id}/likes`, 'get', {
      access_token: fbPageAccessToken
    },
      function (response) {
        setPostLikes([...response.data]);
      }
    );

  }

  useEffect(() => {
    if (fbPageAccessToken) {
      fetchLikes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbPageAccessToken])

  //....................For Comments.........................

  const fetchComments = () => {
    window.FB.api(`/${id}/comments`, 'get', {
      access_token: fbPageAccessToken
    },
      function (response) {
        setPostComments({ ...response.data[0] });
      }
    );

  }

  useEffect(() => {
    if (fbPageAccessToken) {
      fetchComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbPageAccessToken])

  //.................For Engagements....................

  const fetchEngage = () => {
    window.FB.api(`/${id}/insights?metric=post_engaged_users`, 'get', {
      access_token: fbPageAccessToken
    },
      function (response) {
        setPostEngage({ ...response.data[0].values });
      }
    );

  }

  useEffect(() => {
    if (fbPageAccessToken) {
      fetchEngage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbPageAccessToken])

  //...................................For Impressions...................................................

  const fetchImpression = () => {
    window.FB.api(`/${id}/insights?metric=post_impressions`, 'get', {
      access_token: fbPageAccessToken
    },
      function (response) {
        setPostImpression({ ...response.data[0].values });
      }
    );

  }

  useEffect(() => {
    if (fbPageAccessToken) {
      fetchImpression();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbPageAccessToken])

  //..........................................................................

  return (
    <div className="cardd">
      <Card className="shadow">

        <a className="text-decoration-none" target="_blank" href={postDetails && postDetails.permalink_url}>
          {postDetails && postDetails.full_picture && <Card.Img className="imgclass" variant="top" src={postDetails.full_picture} />}
          <ListGroup variant="flush">
            <ListGroup.Item>Message: {postDetails && postDetails.message}</ListGroup.Item>
            <ListGroup.Item>Date: {postDetails && moment(postDetails.created_time).format("DD/MM/YYYY")}</ListGroup.Item>
            <ListGroup.Item>Likes: {postLikes.length}</ListGroup.Item>
            <ListGroup.Item>Comments: {postComments && postComments.message}</ListGroup.Item>
            <ListGroup.Item>Engagement: {postEngage && postEngage[0] && postEngage[0].value}</ListGroup.Item>
            <ListGroup.Item>Impressions: {postImpression && postImpression[0] && postImpression[0].value}</ListGroup.Item>
          </ListGroup>
        </a>
      </Card>

    </div>
  );
}

export default FacebookPost;