import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PAGE_ID = "102399459127472";

const FBimpressions = (props) => {

  const { fbPageAccessToken } = props;

  const [values, setValues] = useState([]);


  const fetchData = () => {
    window.FB.api(`/${PAGE_ID}/insights?metric=page_impressions&date_preset=last_7d&period=day`, 'get', {
      access_token: fbPageAccessToken
    }, function (response) {
      setValues([...response.data[0].values]);

      // console.log(response);

    }
    );

  }

  useEffect(() => {
    if (fbPageAccessToken) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbPageAccessToken])

  var data = {
    labels: values.map(x => moment(x.end_time).format("YYYY/MM/DD")),
    datasets: [{
      label: 'Page_Impressions',
      data: values.map(x => x.value),
      backgroundColor: [
        'rgba(0, 80, 255, 0.3)',
      ],
      borderColor: [
        'rgba(0, 0, 255, 1)',
      ],
      borderWidth: 1
    }]
  };

  var options = {
    maintainAspectRatio: false,
    scales: {
    },
    legend: {
      labels: {
        fontSize: 25,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Page_Impressions',

      },
    },
  }

  return (
    <>
      <div className="bar1">
        {values && values.length > 0 && <Bar
          data={data}
          height={400}
          options={options}
        />}
      </div>
    </>
  );
}

export default FBimpressions;