import React, { createRef, useEffect, useState } from "react";
import { Col, Container, Row, Modal, Button, InputGroup, FormControl, Spinner } from "react-bootstrap";
import Select from 'react-select';
import { useToasts } from 'react-toast-notifications';
import dateFormat from 'dateformat';
import APIServicenew from "../../utils/APIGeneralService";

const EditUsers = (props) => {
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

  const { addToast } = useToasts();
  let nameRef = createRef();
  let emailRef = createRef();
  let mobileRef = createRef();
  let sourceRef = createRef();
  let problemRef = createRef();
  let dobRef = createRef();
  let locationRef = createRef();
  let hourRef = createRef();
  let minuteRef = createRef();
  let secondRef = createRef();
  let statusRef = createRef();
  let detailsRef = createRef();


  const [sourceList, setSourceList] = useState([]);
  const [problemList, setProblemList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [errors, setErrors] = useState([]);


  const [name, setName] = useState(props.data.name);
  const [mobile, setMobile] = useState(props.data.contactNumber);
  const [email, setEmail] = useState(props.data.email);
  const [dob, setDOB] = useState(props.data.dob);
  const [hour, setHour] = useState(props.data.hour);
  const [minutes, setMinutes] = useState(props.data.minutes);
  const [seconds, setSecond] = useState(props.data.seconds);
  const [location, setLocation] = useState(props.data.location);
  const [utmsource, setSource] = useState(props.data.utmSource);
  const [status, setStatus] = useState(props.data.deal_status ? props.data.deal_status : "Pending");
  const [problem, setProblem] = useState(props.data.problem);
  const [details, setDetails] = useState(props.data.details);



  const source = [
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Linkedin', label: 'Linkedin' },
    { value: 'Call', label: 'Call' },
    { value: 'Whatsapp', label: 'Whatsapp' },
    { value: 'Youtube', label: 'Youtube' },
    { value: 'Other', label: 'Other' },

  ];

  const statusData = [
    { value: 'Pending', label: 'Pending' },
    { value: 'In-progress', label: 'In-progress' },
    { value: 'Completed', label: 'Completed' }
  ];

  const problems = [
    { value: 'Love Life', label: 'Love Life' },
    { value: 'Marriage Life', label: 'Marriage Life' },
    { value: 'Vastu', label: 'Vastu' },
    { value: 'Job', label: 'Job' },
    { value: 'Business', label: 'Business' },
  ];


  useEffect(() => {
    try {
      setSourceList(source);
      setProblemList(problems);
      setStatusList(statusData);
    }
    catch (err) {
      console.log('err: ', err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveDATA = async (data) => {
    console.log("data", data);
    var url = "admin/users/userUpdateByMarketing/" + data.id
    let response = await APIServices(url, data, "PUT");
    return response;
  }

  const UpdateUsersData = async () => {
    let error = [];
    let userdata = {}
    if (nameRef.current.value.trim().length == 0 || nameRef.current.value === '' || nameRef.current.value == null) {
      error.push({ message: 'Please enter name' })
    } else if (emailRef.current.value.trim().length == 0 || emailRef.current.value === '' || emailRef.current.value == null) {
      error.push({ message: 'Please enter email' })
    } else if (mobileRef.current.value.trim().length == 0 || mobileRef.current.value === '' || mobileRef.current.value == null) {
      error.push({ message: 'Please enter mobile number' })
    } else if (sourceRef.current.state.selectValue == '' || sourceRef.current.state.selectValue.length == 0) {
      error.push({ message: 'Please select source' })
    } else if (problemRef.current.state.selectValue == '' || problemRef.current.state.selectValue.length == 0) {
      error.push({ message: 'Please select problem' })
    } else if (locationRef.current.value.trim().length == 0 || locationRef.current.value === '' || locationRef.current.value == null) {
      error.push({ message: 'Please enter location' })
    }

    if (error.length === 0) {
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
      userdata.created = date;
      userdata.name = nameRef.current.value;
      userdata.email = emailRef.current.value;
      userdata.contactNumber = mobileRef.current.value;
      let problem = problemRef.current.state.selectValue.map(ct => ct.label);
      userdata.problem = problem[0];
      let source = sourceRef.current.state.selectValue.map(ct => ct.label);
      userdata.utmSource = source[0];
      let status = statusRef.current.state.selectValue.map(ct => ct.label);
      userdata.deal_status = status[0];
      userdata.details = detailsRef.current.value;
      userdata.dob = dobRef.current.value;
      userdata.hour = hourRef.current.value;
      userdata.minutes = minuteRef.current.value;
      userdata.seconds = secondRef.current.value;
      userdata.location = locationRef.current.value;
      userdata.id = props.data._id;
      let data = userdata;
      console.log("userdata", userdata);
      let resp = await saveDATA(data);
      let success_msg = "Data Added Successfully";
      if (resp.data.status == true) {
        addToast(success_msg, {
          appearance: 'success',
          autoDismiss: true
        })
        handleCancel()
        props.toggleFalse()
      } else {
        addToast(resp.data.message, {
          appearance: 'error',
          autoDismiss: true
        })
      }

    } else {
      error.map(e => {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true
        })
      })
    }
  }

  const handleCancel = () => {
    props.toggleFalse()
  }

  const renderName = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="name"
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Name"
            value={name}
            ref={nameRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderMobile = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="mobile"
            onChange={(e) => setMobile(e.target.value)}
            type="number"
            placeholder="Mobile"
            value={mobile}
            ref={mobileRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderEmail = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Email"
            value={email}
            ref={emailRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderDOB = () => {
    let dateofBirth = dateFormat(dob, "yyyy-d-M");
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="dob"
            onChange={(e) => setDOB(e.target.value)}
            placeholder="DOB"
            type="date"
            value={dob}
            ref={dobRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderHours = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="hour"
            onChange={(e) => setHour(e.target.value)}
            placeholder="Hour"
            type="number"
            value={hour}
            ref={hourRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderMinutes = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="minutes"
            onChange={(e) => setMinutes(e.target.value)}
            placeholder="Minutes"
            type="number"
            value={minutes}
            ref={minuteRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderSecond = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="second"
            onChange={(e) => setSecond(e.target.value)}
            placeholder="Second"
            type="number"
            value={seconds}
            ref={secondRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderLocation = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="location"
            onChange={(e) => setLocation(e.target.value)}
            placeholder="Location"
            value={location}
            ref={locationRef}
          />
        </InputGroup>
      </>
    )
  }

  const renderSource = () => {
    return (
      <>
        <Select
          closeMenuOnSelect={true}
          placeholder="Source"
          name="source"
          onChange={() => handleChange()}
          options={sourceList}
          defaultValue={{
            label: utmsource,
            value: utmsource,
          }}
          ref={sourceRef}
        />
      </>
    )
  }

  const renderStatus = () => {
    return (
      <>
        <Select
          closeMenuOnSelect={true}
          placeholder="Status"
          name="status"
          onChange={() => handleChange()}
          options={statusList}
          defaultValue={{
            label: status,
            value: status,
          }}
          ref={statusRef}
        />
      </>
    )
  }


  const renderDetails = () => {
    return (
      <>
        <InputGroup size="md" className="">
          <FormControl
            name="details"
            onChange={(e) => setDetails(e.target.value)}
            placeholder="Write details about discussion with user."
            value={details}
            ref={detailsRef}
          />
        </InputGroup>
      </>
    )
  }

  const handleChange = () => {
    let problem = problemRef.current.state.selectValue.map(ct => ct.label);
    let source = sourceRef.current.state.selectValue.map(ct => ct.label);
  }

  const renderProblem = () => {
    return (
      <>
        <Select
          closeMenuOnSelect={true}
          name="problem"
          onChange={() => handleChange()}
          placeholder="Problem"
          options={problemList}
          defaultValue={{
            label: problem,
            value: problem,
          }}
          ref={problemRef}
        />
      </>
    )
  }

  return (
    <Modal size="xl" centered={true} show={props.toggleFalse} onHide={handleCancel}>
      <Modal.Header>
        <Modal.Title>
          Edit User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="mt-3 mb-5">
          <Row>
            <Col>
              <h5>Add Users</h5>
            </Col>
          </Row>
          <Row>
            <Col className="my-2">
              {renderName()}
            </Col>
            <Col className="my-2">
              {renderMobile()}
            </Col>
            <Col className="my-2">
              {renderEmail()}
            </Col>
          </Row>
          <Row>

            <Col className="my-2" md={4}>
              {renderDOB()}
            </Col>
            <Col className="my-2" >
              {renderHours()}
            </Col>
            <Col className="my-2" >
              {renderMinutes()}
            </Col>
            <Col className="my-2" >
              {renderSecond()}
            </Col>

          </Row>
          <Row>
            <Col className="my-2">
              {renderProblem()}
            </Col>
            <Col className="my-2">
              {renderLocation()}
            </Col>
            <Col className="my-2">
              {renderSource()}
            </Col>
          </Row>
          <Row>
            <Col className="my-2" >
              {renderStatus()}
            </Col>
            <Col className="my-2">
              {renderDetails()}
            </Col>


          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="me-1"
          variant='secondary cancle_btn'
          size='sm'
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="me-1"
          variant='primary'
          size='sm'
          onClick={UpdateUsersData}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditUsers;