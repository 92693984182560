import { useEffect, useState,useRef, useCallback } from "react";
import axios from 'axios'
import { Editor } from "@tinymce/tinymce-react";

export default function TinymceEditor({
 content,
 setContent,
 handleChange,
 onChange,
 name
}) {

  return (
    <>
     <Editor
    // apiKey="limjfdlb66u3w96h1skw5m93kdvmf55zt4ohb3ol4jeb3q6m"
    apiKey={process.env.REACT_APP_TINY_EDITER_KEY}
    cloudChannel='5-stable'
    disabled={false}
    // initialValue=""
    value={content}
    inline={false} 
    onEditorChange={value => {onChange(value ,name);handleChange(name)}}
    plugins=''
    tagName='div'
    textareaName=''
    toolbar=''
    outputFormat='html'
    init={{
        height: 300, 
        // language: '',
        //menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
     ],
     toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help',

          image_title: true,
          automatic_uploads: true,
          selector: 'textarea', 
          file_picker_types: 'file image',
          // images_upload_url: `${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`,

          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*')

            input.onchange = async function () {
            var file = this.files[0];
            let formdata = new FormData();
            formdata.append("commonFile", file, file.name);
            const res = await axios.post(`${process.env.REACT_APP_OTHER_API_URL}commonUpload/editor`, formdata, { headers: { "Content-Type": "multipart/form-data" } })  
            if (res.status === 200) {
              if (res?.data?.status === false) {
                console.log('not 200', res?.data?.message);
              }
              else {
                cb(res.data.path)
              }
            } else {
              console.log('Failed in uploading BLOODY FILE')
            }
          };
          input.click();
   }} 
  }
  />
 
    </>
  )
}

