import React from 'react';

const Homepage = () => {
  document.title = 'Home';
  return (
    <div className='homepage' style={{
      backgroundImage: 'url("/images/banner_background.png")'
    }}>
      <img className='img-fluid' src='/images/Trilok_logo.png' />
    </div>
  );
};

export default Homepage;