import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import APIService from "../utils/APIGeneralService";
import { Trash3Fill, PencilSquare, PlusSquare } from "react-bootstrap-icons"
import { getCookies, setCookies, removeCookies } from "../utils/Helper"
import { Commonmodal } from "../components/Shared/modal";
import Carousel from 'react-bootstrap/Carousel';
import { useLocation } from "react-router-dom";


const Instagram = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [modalShow, setModalShow] = useState({
    id: null,
    modal: false,
    message: ""
  })
  const [posts, setPosts] = useState([])
  const APIServices = new APIService(process.env.REACT_APP_INSTA_API_URL)

  let insta_url = `https://api.instagram.com/oauth/authorize?client_id=${process.env.REACT_APP_INSTA_APP_ID}&redirect_uri=${process.env.REACT_APP_INSTA_REDIRECT_URI}&scope=user_profile,user_media&response_type=code`

  const deletePost = async (id) => {
    setLoading(true)
    const resp = await APIServices.delete(`/instagram/deletePost/${id}`)

    if (resp.data.code === 200) {
      let newPosts = [...posts]
      newPosts = newPosts.filter(post => post._id !== id)
      setPosts(newPosts)
      setLoading(false)
    }
  }

  const handleUpdate = async (tags, categories) => {
    const resp = await APIServices.put(`/instagram/updatePost/${modalShow.id}`, {
      tags, categories
    })
    if (resp.data.message === 'success') {
      setModalShow(prev => ({ ...prev, message: 'Successfully updated' }))
    } else {
      setModalShow(prev => ({ ...prev, message: 'An Error occurred!' }))
    }
  }

  useEffect(() => {
    // let validation = true
    let params = new URLSearchParams(window.location.search)
    let code = params.get('code')
    
    // if(code){
    //   const value = getCookies('prevention_strategy')
    //   if(value == 1){
    //     validation = true
    //   }else{
    //     validation = false
    //     setCookies('prevention_strategy',1)
    //   }
    // }

    // if(validation){
      removeCookies('prevention_strategy')
      const initialization = async () => {
        const ins_username = getCookies('ins_username')
  
        const response = await APIServices.post('/instagram', {
          code: code,
          username:ins_username
        })
        if (response.data.status === 1 && response.data.data.status === 'authorize') {
          if(response.data.data.response.username){
            setCookies('ins_username',response.data.data.response.username)
          }
          setPosts(response.data.data.response.data)
          setLoading(false)
        } else {
          window.location.href = insta_url
        }
      }
      initialization()
    // }
  }, [])

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "90vh" }}><h2>Loading...</h2></div>
  }

  return (
    <Row>
      <Col className="mx-4">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Instagram Posts</h2>
          <button className="text-success bg-transparent border-0"><PlusSquare size={20} /></button>
        </div>
        <div>
          {
            posts.map((post) => {
              const { _id, media_url, username, media_type } = post
              return (
                <div key={_id} className="my-2">
                  {
                    media_type == 'CAROUSEL_ALBUM' ? (
                      <Carousel className="w-25">
                        {
                          post.children.map((child, index) => {
                            return (
                              <Carousel.Item key={index}>
                                <img
                                  width="100%" height="25%"
                                  src={child.media_url}
                                  alt={`slide ${child.id}`}
                                />
                              </Carousel.Item>
                            )
                          })
                        }
                      </Carousel>
                    )
                      :
                      media_type == 'IMAGE' ?
                        <img src={media_url} alt="altsimae" width="25%" height="25%" className="rounded" />
                        :
                        <>
                          <video width="320" height="240" controls>
                            <source src={media_url} type="video/mp4" />
                          </video>
                        </>
                  }
                  <article className="d-flex justify-content-between w-25 my-2">
                    <div className="text-center fw-bold">{username}</div>
                    {/* <button>edit</button> */}
                    <div className="text-center">
                      <button className="bg-transparent border-0 text-info"
                        onClick={() => {
                          setModalShow({ id: _id, modal: true })
                        }}
                      ><PencilSquare /></button>
                      <button onClick={() => {
                        deletePost(_id)
                      }} className="bg-transparent border-0 text-danger"><Trash3Fill /></button>
                    </div>
                  </article>
                  <hr />
                </div>
              )
            })
          }
        </div>
      </Col>
      
      <Commonmodal
        show={modalShow.modal}
        onHide={() => setModalShow(pre => ({ ...pre, modal: false }))}
        handleUpdate={handleUpdate}
        message={modalShow.message}
      />
    </Row>
  );
};

export default Instagram;
