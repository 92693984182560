import React, { useEffect, useState } from 'react'
import { PencilSquare, Trash3Fill } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useToasts } from 'react-toast-notifications';
import APIServicenew from '../../utils/APIGeneralService';
import Thumbnail from './thumbnail';

const Wishes = (props) => {
    const[loader,setLoader]=useState(false)
    const [errors, setErrors] = useState({})
    const [wishes, setWishes] = useState([])
    const [editMode, setEditMode] = useState({
        status: false,
        id: ''
    })
    const [fields, setFields] = useState({
        text: '',
        file: null
    })

    const { addToast } = useToasts()

    const APIServices = new APIServicenew(process.env.REACT_APP_CALENDAR_API_URL)

    const setfields = (key, value) => {
        setFields(prev => ({ ...prev, [key]: value }))
    }

    const fetchingWishes = async () => {
        const response = await APIServices.get(`/calendar/event-wishes/getAllWishes/${props.eventid}`)
        if (response.data.code === 200) {
            setWishes(response.data.results)
        }
    }

    const reset = () => {
        setFields({
            text: '',
            file: null
        })
        setEditMode(false)
        fetchingWishes()
    }
    const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
        
      
          if ( !fields?.text ||fields?.text =="") 
          newErrors.text = "Please enter title .";
    
        //   if (newData.file === "") 
        //   newErrors.file = "Please upload image.";
    
    
          return newErrors;
      }; 


 


    const handleUpdate = async () => {

        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.put(`/calendar/event-wishes/updateWish/${editMode.id}`, {
            Text: fields.text
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                let formData = new FormData()
                formData.append('file', file)
                const fileupload = await APIServices.post(`/calendar/upload-file/wishes/${id}`, formData,
                    { 'content-type': 'multipart/form-data' }
                )
                if (fileupload?.data.code !== 200) {
                    addToast(`File Error - ${fileupload.data.message}`, {
                        appearance: 'error',
                        autoDismiss: true
                    })
                }
            }
            addToast('Successfully updated', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }
    }
}

    const handleEdit = async (id) => {
        const response = await APIServices.get(`/calendar/event-wishes/getWish/${id}`)
        if (response.data.code === 200) {
            const { Text } = response.data.results
            setFields({
                text: Text,
                file: null
            })
            setEditMode({ status: true, id })
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

    const handleDelete = async (id) => {
        const response = await APIServices.delete(`/calendar/event-wishes/deleteWish/${id}`)
        if (response.data.code === 200) {
            addToast('Deleted', {
                appearance: 'success',
                autoDismiss: true
            })
            fetchingWishes()
        }
    }

    const handleSubmit = async (e) => {

        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{

        const response = await APIServices.post('/calendar/event-wishes/addWish', {
            Event_id: props.eventid,
            Text: fields.text,
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                let formData = new FormData()
                formData.append('file', file)
                const fileupload = await APIServices.post(`/calendar/upload-file/wishes/${id}`, formData,
                    { 'content-type': 'multipart/form-data' }
                )
                if (fileupload?.data.code !== 200) {
                    addToast(`File Error - ${fileupload.data.message}`, {
                        appearance: 'error',
                        autoDismiss: true
                    })
                }
            }
            addToast('Successfully added', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }

    }
}

function handleChange(e) { 
    let key, value;
    // key=e
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

    useEffect(() => {
        fetchingWishes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <article className='border border-dark rounded d-flex gap-2' >
            <div className='w-75 flex-grow-1 p-3'>
                <Form.Group className="mb-3">
                    <Form.Label className='required'>Title</Form.Label>
                    <Form.Control type="text" name="text" placeholder="Add Text" isInvalid={!!errors?.text} value={fields.text} onChange={(e)=> {setfields('text', e.target.value); handleChange(e)}} />
                    <Form.Control.Feedback type="invalid">
                {errors?.text}
              </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Thumbnail id={"wish-content-image"} title='Wish Image' image={fields.file} handler={(val) => {
                        setfields('file', val.target.files[0])
                        val.target.value = null
                    }} />
                </Form.Group>

                <div className='w-100 text-center'>
                {loader? <Button type="submit" size="sm" className='as_btn'>
                    {editMode.status ? "Processing..." : "Processing..."}
                </Button>: <Button type="submit" size="sm" className='as_btn' onClick={(e) => {
                    e.preventDefault()
                    editMode.status ? handleUpdate() :
                        handleSubmit()
                }}>
                    {editMode.status ? "Update" : "Submit"}
                </Button>}
                   
                    <Button type="submit" size="sm" className='as_btn mx-2' onClick={reset}>
                        Clear
                    </Button>
                </div>
            </div>
            <div className='w-25 border rounded'>
                <h6 className='mx-2 p-2'>All Wishes</h6>
                {
                    wishes.map(wish => {
                        let imageurl = `${process.env.REACT_APP_CALENDAR_API_URL}/${wish.Image}`
                        return (
                            <article key={wish._id} className='d-flex justify-content-between align-items-center p-2 border overflow-hidden'>
                                <div>
                                    <img  src={wish.Image ? imageurl : '/images/default.jpg'} alt="newimagess" width={28} className="rounded-circle" />
                                    <span className='mx-2 text-capitalize'>{wish.Text}</span>
                                </div>
                                <div>
                                    <PencilSquare className='pointerUtilityClass text-success mx-2' size={15} onClick={() => handleEdit(wish._id)} />
                                    <Trash3Fill className='pointerUtilityClass text-danger mx-2' size={14.5} onClick={() => handleDelete(wish._id)} />
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </article>
    )
}

export default Wishes