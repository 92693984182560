import React, { useLayoutEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { TagsInput } from 'react-tag-input-component';
import APIServicenew from '../../utils/APIGeneralService';
import Thumbnail from './thumbnail';
import { v4 as uuid } from 'uuid';
import { useToasts } from 'react-toast-notifications';

const EventData = (props) => {
    const[loader,setLoader]=useState(false)
    const [errors, setErrors] = useState({});
    const [fields, setFields] = useState({
        titles: [],
        des: "",
        tags: [],
        categories: [],
        file: null
    })
    const APIServices = new APIServicenew(process.env.REACT_APP_CALENDAR_API_URL)
    const { addToast } = useToasts();

    const setfields = (key, value) => {
        setFields(prev => ({ ...prev, [key]: value }))
    }

    const reset = () => {
        setFields({
            titles: [],
            des: "",
            tags: [],
            categories: [],
            file: null
        })
    }

    const fetchedEventData = async () => {
        if (props.mode === 'edit') {
            let id = props.event.id
            const response = await APIServices.get(`/calendar/event/getEvent/${id}`)
            if (response?.data?.code === 200) {
                const { Categories, Description, Tags } = response.data.results
                setFields(prev => ({
                    ...prev,
                    des: Description,
                    tags: Tags,
                    categories: Categories,
                    file: null
                }))
            }
        }
    }

    const formNewEvents = () => {
        let finalObj = {}
        if (props.mode === 'add') {
            const { id, title, startDate, endDate } = props.event
            finalObj = {
                id,
                Title: [title],
                startDate,
                endDate,
                Description: fields.des,
                Tags: fields.tags,
                Categories: fields.categories,
            }
        }
        if (props.mode === 'new') {
            finalObj = {
                id: uuid(),
                Title: fields.titles,
                startDate: props.startDate,
                endDate: props.endDate,
                Description: fields.des,
                Tags: fields.tags,
                Categories: fields.categories,
                CreatedBy: 'custom'
            }
        }
        return finalObj
    }


    const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
        
      
          if ( fields?.titles =="") 
          newErrors.title = "Please enter title and press enter.";
    
          if ( fields.tags =="") 
          newErrors.tags = "Please enter tags and press enter.";
    
        //   if (newData.file === "") 
        //   newErrors.file = "Please upload image.";
    
          if (fields.categories == "")
          newErrors.catgories = "Please select categories and press enter.";

          if(fields.des === '')
          newErrors.des = "Please enter description.";
    
          return newErrors;
      }; 
      

    const handlesubmit = async (e) => {
        e.preventDefault()
        const finalObj = formNewEvents()
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.post('/calendar/event/addEvents', {
            ...finalObj
        })
        
        if (response.data.code === 200) {
            if (fields.file) {
                let formData = new FormData()
                console.log('id', response.data)
                let id = response.data.results._id
                formData.append('file', fields.file)
                let fileupload = await APIServices.post(`/calendar/upload-file/event/${id}`, formData,
                    { 'content-type': 'multipart/form-data' }
                )
                if (fileupload?.data.code !== 200) {
                    addToast(fileupload?.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    })
                    setLoader(false)
                }
            }
            addToast('successfully added', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }
    }
}

    const handleUpdate = async (e) => {
        e.preventDefault()
        let newevent = {
            Title: fields.titles,
            startDate: props.startDate,
            endDate: props.endDate,
            Description: fields.des,
            Tags: fields.tags,
            Categories: fields.categories,
        }
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoader(false)
          }else{
        const response = await APIServices.put(`/calendar/event/updateEvent/${props.event.id}`, {
            ...newevent
        })
        if (response.data.code === 200) {
            if (fields.file) {
                let formData = new FormData()
                let id = response.data.results._id
                formData.append('file', fields.file)
                let fileupload = await APIServices.post(`/calendar/upload-file/event/${id}`, formData,
                    { 'content-type': 'multipart/form-data' }
                )
                if (fileupload?.data.code !== 200) {
                    addToast(fileupload?.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    })
                    setLoader(false)
                }
            }
            addToast('successfully updated', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }
    }
    }

    const tagsValidation = (addedTags, existedTags) => {
        if (existedTags.length === 1) {
            return false
        }
        return true
    }

    useLayoutEffect(() => {
        if (props.event) {
            let newtitles = props.event.title
            setfields('titles', [newtitles])
        }
        fetchedEventData()
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleChange(e) { 
        let key, value;
        // key=e
        key = e?.target?.name;
        if (!!errors[key]){
          setErrors({
            ...errors,
            [key]: null,
            });
        }
      }

    return (
        <div>
            <Form>
                <Form.Group className="mb-1">
                    <Form.Label className='required'>Events</Form.Label>
                    <TagsInput
                        value={fields.titles}
                        onChange={(val) => {
                            if(fields.titles !== val) {setfields("titles", val)}
                        }}
                        onKeyUp={e=>handleChange(e)}
                        id="title"
                        name="title"
                        placeHolder="Add Titles (Press enter after adding text)"
                        beforeAddValidate={tagsValidation}
                    />
                    
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.title}</p>
               
                </Form.Group>

                <div className="form-group">
                    <label htmlFor='tags' className='my-2'>Tags</label>
                    <TagsInput
                        value={fields.tags}
                        onChange={(val) => setfields("tags", val)}
                        onKeyUp={e=>handleChange(e)}
                        id="tags"
                        name="tags"
                        placeHolder="Add tags (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.tags}</p>
                </div>
                <div className="form-group">
                    <label htmlFor='catgories' className='my-2'>Categories</label>
                    <TagsInput
                        value={fields.categories}
                        id="catgories"
                        onChange={(val) => setfields("categories", val)}
                        onKeyUp={e=>handleChange(e)}
                        name="catgories"
                        placeHolder="Add Categories (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.catgories}</p>
                </div>

                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={3} style={{ height: '100px' }} value={fields.des} onChange={e => {setfields('des', e.target.value); handleChange(e)}} />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.des}</p>
                    </Form.Group>

                <Form.Group className="mb-3" >
                    <Thumbnail title={"Upload Thumbnail"} id={"event-thumbnail"} image={fields.file} handler={val => {
                        setfields('file', val.target.files[0])
                        val.target.value = null
                    }} />
                </Form.Group>
{loader?<Button type="submit" size="sm" className='as_btn' >
{
    props.mode === 'edit' ? "Processing..." : "Processing..."
}
</Button>:<Button type="submit" size="sm" className='as_btn' onClick={(e) => props.mode === 'edit' ? handleUpdate(e) : handlesubmit(e)}>
{
    props.mode === 'edit' ? "Update" : "Submit"
}
</Button>}
                
            </Form>
        </div>
    )
}

export default EventData   
// fw-bold text-dark btnHover