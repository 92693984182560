import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, PointElement, Legend);

function Whatsupbroadcasting() {
  

  // line chart
  const jsonData1 = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    values1: [12, 19, 3, 5, 2, 3],
    values2: [10, 14, 1, 10, 12, 7],
  };

  const chartData1 = {
    labels: jsonData1.labels,
    datasets: [
      {
        label: 'Monthly Data',
        data: jsonData1.values1,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
        fill: true, // Fill under the line
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
      },
      {
        label: 'Monthly Data',
        data: jsonData1.values2,
        backgroundColor: 'purple',
        borderColor: 'red',
        borderWidth: 2,
        fill: true, // Fill under the line
        pointBackgroundColor: 'black',
        pointBorderColor: 'yellow',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };

  return (
    <div>
      <div className='gita_quote_heading'>
        <div className='container d-flex align-items-center justify-content-between'>
          <h4 className='hd-list-f left-q'>WhatsApp Analytics</h4>
          <div className='_btn'></div>
        </div>
      </div>
      {/* <div style={{ width: '800px', height: '600px' }}>
        <Bar data={chartData} />
      </div> */}
       <div className='container'>
        <div style={{ width: '1000px', height: '700px' }}>
            <Line data={chartData1} />
        </div>
       </div>
    </div>
  );
}

export default Whatsupbroadcasting;
