import { useState, useEffect, useRef } from 'react'
import { useToasts } from 'react-toast-notifications'
import APIServicenew from '../../utils/APIGeneralService'
import { Heart, HeartFill, Chat, Share, ThreeDots , Trash} from 'react-bootstrap-icons';
import { FaEdit, FaTrash } from "react-icons/fa";

import { Modal,Card, Row, Col, Container , Button} from 'react-bootstrap';
import { getCookies } from '../../utils/Helper';
import moment from 'moment';
import Comment from './comment';

const Post = (props) => {
    const { postData ,getPosts ,perPage,offset,setOffset} = props
    const [showcmt, setShowcmt] = useState(false)
    const [comments, setComments] = useState([])
    const [showaMark, setShowMark] = useState(false)
    const [showImage, setShowImage] = useState(true)
    const [postTypes, setPostTypes] = useState('')
    const [showdt, setShowDt] = useState(false)
    const [ postId , setPostId] = useState()
    const accessToken = getCookies('accessToken')
    const [commentCount, setCommentCount] = useState(postData?.commentCount)
    const ref = useRef()
    const cmtref = useRef()

    const { addToast } = useToasts()

    let id = props.postData._id

    const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)

    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + accessToken
    }

    const getPostDetails = async ({ postid, limit = offset, page = perPage }) => {
        const commentResponse = await APIServices.post('/admin/post/detail', {
            commentLimit: limit,
            commentPage: page,
            getAllReply: true,
            postId: postid
        }, headers)
        return commentResponse.data
    }

    const handleComments = async (toggle = false) => {
        let newpage = comments.length
        if (newpage && !toggle) {
            newpage = (newpage / 10) + 1
        } else {
            newpage = 1
        }
        const allcomments = await getPostDetails({ postid: id, page: newpage })
        if (allcomments.status === 1) {
            if (toggle) setComments(prev => [])
            setComments(prev => ([...prev, ...allcomments.data.comments]))
        } else {
            addToast(allcomments.message,
                {
                    appearance: 'error',
                    autoDismiss: true
                }
            )
        }
        if (toggle)
            setShowcmt(!showcmt)
    }

    const handleCmtDelete = async (cmtId) => {
        let data={
            postId:id,
            commentId:cmtId
        }
        const response = await APIServices.delete(`/admin/post/delete_comment`, headers,data)
        if (response.data.status === 1) {
            handleComments(true)
            addToast(response.data.message,
                {
                    appearance: 'success',
                    autoDismiss: true
                }
            )
        } else {
            addToast(response.data.message,
                {
                    appearance: 'error',
                    autoDismiss: true
                }
            )
        }
    }

    const handleMark = async () => {
        let post_type = postTypes === 'public' ? 'private' : 'public'
        const response = await APIServices.post('/admin/post/postType_change', {
            postId: id,
            postType: post_type
        }, headers)
        if (response.data.status === 1) {
            setPostTypes(() => response.data.data.postType)
            addToast(`Post is updated to ${response.data.data.postType}`, {
                appearance: 'success',
                autoDismiss: true
            })
            getPosts();
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }
    const handleCloseDt = () => setShowDt(false)

    const handleShowDt = (id) => {
        setShowDt(true)
        setPostId(id)
    }
    
    const handledelete = async () => {
      const data ={
        postId : postId
    }
        await APIServices.delete("admin/post/delete", headers , data).then(res => {
            if (res.data.status === 1) {
                setShowDt(false)
                setOffset(0)
                addToast(res.data.message, {
                    appearance: 'success',
                    autoDismiss: true
                })
            }

        }).then(async()=>{
            await getPosts()
        })
        .catch(err => {
                console.log(err);
            })
    }


    useEffect(() => {
        setPostTypes(postData.postType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowMark(false)
            }
            if (cmtref.current && !cmtref.current.contains(event.target)) {
                setShowcmt(false)
            }
        };
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    return (<>
        
                    <Card className="feed_box">
                        <div className="card-header">
                            <div className='feed_user_img'>
                                <img src={postData?.astrologerId?.profileImage || postData?.userId?.profileImage} className="img-fluid user_img" alt='userorastroimg' />

                                <div className='feed-user-info'>
                                    <h6 className="">{postData?.astrologerId?.name || postData?.userId?.name || 'Unknown User'}</h6>
                                    <p className="">{moment(postData?.createdAt).format('MMM Do YYYY, h:mm a')}</p>
                                </div>
                            </div>

                            
                            <div className="trash_top" ref={ref}>
                                <span className="icon">
                                <FaTrash
                                    className="dt-btn hd-cr"
                                    style={{marginRight:"6px"}}
                                    size={15}
                                    
                                    onClick={e => {
                                        handleShowDt(postData._id)
                                    }}
                                />
                                <ThreeDots className="pointerUtilityClass" onClick={() => setShowMark(!showaMark)} />
                                <button className={`${showaMark || 'd-none'} card py-2 px-3 mt-4 position-absolute text-nowrap end-0 h-70 btnHover pointerUtilityClass z_Index responsivePostSize`} onClick={handleMark}>
                                    Mark {postTypes === 'public' ? 'private' : 'public'}
                                </button>
                                </span>
                                {/* <div className='responsivePostSize serial_no'>Serial No. {props.index}</div> */}
                            </div>

                        </div>
                       
                        <div className="card-body pb-0">
                            {/* <div className="info">
                            <h6 className="">{postData?.astrologerId?.name || postData?.userId?.name || 'Unknown User'}</h6>
                            <p className="">{moment(postData?.createdAt).format('MMM Do YYYY, h:mm a')}</p>
                           </div>  */}
                            <div className="text_description">
                            <p>{postData?.description}</p>
                            {
                                showImage &&
                                <img onError={() => setShowImage(false)} src={postData?.mediaFile} className="img-fluid" width={400} />
                            }
                            </div>
                        </div>
                    
                        <div className="card-footer">
                            <div className="cursor-pointer ms-2">
                                <span>
                                    {
                                        postData?.likeCount >0 ? (
                                            <HeartFill style={{ color: '#B21414' }} />
                                        ) : (
                                            <Heart />
                                        )} </span>{' '}
                                Like ({postData?.likeCount})
                            </div>
                            <div className="cursor-pointer" ref={cmtref}>
                                <button className="bg-transparent border-0" onClick={() => {
                                    handleComments(true)
                                }}>
                                    <Chat />
                                    <span className="mx-1">
                                        Comments
                                        </span>
                                    ({commentCount > 0 ? commentCount : 0})
                                </button>
                            </div>
                            <div className="cursor-pointer">
                                <button className="bg-transparent border-0">
                                    <span className="mx-1" >Report</span>
                                    ({postData?.report})
                                </button>
                            </div>
                           {/* <div className="cursor-pointer">
                                <button className="bg-transparent border-0">
                                    <Share />
                                    <span className="mx-1" >Share</span>
                                    ({postData?.shareCount})
                                </button>
                            </div> */}
                        </div>
                        {
                            showcmt && comments.length ?
                                <article className="my-2 artical_scroll" ref={cmtref}>
                                    <hr />
                                    <ul className="p-0 m-0 comment-Scroll">
                                        {
                                            comments.map(comment => {
                                                return (
                                                    <div key={comment._id} className="bg-light rounded px-2">
                                                        <Comment comment={comment} handleDelete={handleCmtDelete} />
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            commentCount > comments.length &&
                                            <li className='d-flex justify-content-center pt-2' style={{ listStyle: 'none' }}>
                                                <button className="rounded py-1 px-2 border-0 btnHover" onClick={() => handleComments()}>
                                                    show more
                                                </button>
                                            </li>
                                        }
                                    </ul>
                                </article>
                                : null
                        }
                    </Card>
     

<Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
backdrop="static" onHide={handleCloseDt}>
<Modal.Header className="modal-header-hd">
    <Modal.Title>Delete</Modal.Title>
</Modal.Header>
<Modal.Body >
Are you sure you want to delete this element?
</Modal.Body>
<Modal.Footer>
    <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
        Cancel
    </Button>
    <Button variant="primary" className="success_btn" 
      onClick={handledelete}
    >
        Delete
    </Button>
</Modal.Footer>
</Modal></>
    )
}

export default Post;