import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
// import { SlugifyStringWithDash, setCookies } from '../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
// import { UserContext } from '../context/UserContextProvider';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

const BroadCastingHistory = () => {
  document.title = 'WhatsApp broadcasting';
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [flag, setFlag] = useState(false);
  const [pageCount, setPageCount] = useState(0);

  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(9);

  const handleStartDate = date => {
    const formattedDate = moment(date).format('MM-DD-YYYY');
    setStartDate(formattedDate);
  };

  const handleEndDate = date => {
    const formattedDate = moment(date).format('MM-DD-YYYY');
    setEndDate(formattedDate);
  };

  useEffect(() => {
    getCampaignList();
    fetchedResponder();
    fetchedLabels();
  }, []);

  // for compaign
  const [compaign, setCompaign] = useState([]);

  const getCampaignList = async () => {
    const responce = await APIServices.get(`campaign/getCampaign`, headers);
    if (responce?.status === 200) {
      let apiData = responce?.data;
      if (apiData?.status) {
        setCompaign(apiData?.data);
      }
    } else {
      setCompaign([]);
    }
  };

  const [selectedOption, setSelectedOption] = useState('');
  const options = compaign.map(item => ({
    value: item?._id || '',
    label: item?.name,
  }));

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    setOffset(0)
  };

  //api for all labels data
  const [labels, setLabels] = useState([]);
  const [selectedLabelId, setSelectedLabelId] = useState('');

  const handleLabelSelectChange = event => {
    setSelectedLabelId(event.target.value);
    setOffset(0)
  };

  const fetchedLabels = async () => {
    const res = await APIServices.get('chat/getChatFilters', headers);
    if (res && res.status === 200) {
      const labelData = res?.data?.data?.filter(item => item?.status === 1);
      setLabels(labelData);
    }
  };

  // for Responder
  const [responder, setResponder] = useState([]);
  const [selectedResponderId, setSelectedResponderId] = useState('');
  const fetchedResponder = async () => {
    const res = await APIServices.get('responders/list', headers);
    if (res && res.status === 200) {
      setResponder(res?.data?.data);
    }
  };
  const handleResponderSelectChange = event => {
    setSelectedResponderId(event.target.value);
    setOffset(0)
  };

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Campaign Name',
      Cell: e => {
        return <span>{e.row.original?.campaign_name ?? '_'}</span>;
      },
    },
    {
      Header: 'Responder Name',
      Cell: e => {
        return <span>{e.row.original?.responder_name ?? '_'}</span>;
      },
    },
    {
      Header: 'Label',
      Cell: e => {
        return <span>{e.row.original?.label_name ?? '_'}</span>;
      },
    },

    {
      Header: 'Template Name',
      Cell: e => {
        return <span>{e.row.original?.tempObj?.templateName.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()) ?? '_'}</span>;
      },
    },
    {
      Header: 'Message',
      Cell: e => {
        return <span>{e.row.original?.tempObj?.message ?? '_'}</span>;
      },
    },
    {
      Header: 'Message Count',
      Cell: e => {
        return <span>{e.row.original?.requestedCount ?? '_'}</span>;
      },
    },

    {
      Header: 'Success Count',
      Cell: e => {
        return <span>{e.row.original?.sentCount ?? '_'}</span>;
      },
    },
    {
      Header: 'Sender Name',
      Cell: e => {
        return <span>{e.row.original?.senderName ?? '_'}</span>;
      },
    },
    {
      Header: 'Date',
      Cell: e => {
        return <span>{moment(e.row.original.createdAt).format('lll')}</span>;
      },
    },
  ]);

  const [data, setData] = useState([]);
  const [allDataCount, setAllDataCount] = useState();

  useEffect(() => {
    fetchData(offset, perPage, pageIndex);
  }, [flag]);

  const fetchData = useCallback(
    async (offset, perPage, index) => {
      try {
        let response = await APIServices.get(
          `whatsAppApi/broadcastingList/${offset}/${perPage}?filterId=${selectedLabelId}&campaignId=${selectedOption?.value || ''}&responderId=${selectedResponderId}&startdate=${startDate}&enddate=${endDate}`,
          headers
        );
        if (response?.status === 200) {
          let apiData = response?.data;
          if (apiData?.status) {
            setData(apiData?.data);
            setAllDataCount(apiData?.total_count);
            setPageCount(Math.ceil(apiData?.total_count / perPage));
            setFlag(false);
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setOffset(offset);
      setPerPage(perPage);
      setPageIndex(index);
    },
    [pageIndex, offset, startDate, endDate, selectedOption?.value, selectedResponderId, selectedLabelId]
  );

  const resetData = () => {
    setSelectedOption('');
    setSelectedResponderId('');
    setStartDate('');
    setEndDate('');
    setPageIndex(0);
    setOffset(0);
    setFlag(true);
    setSelectedLabelId('')
  };

  const handleFilterClick = () => {
    if (startDate || endDate || perPage || offset || pageIndex || selectedOption?.value || selectedResponderId || selectedLabelId) {
      fetchData(offset, perPage, 0, startDate, endDate, selectedOption?.value, selectedResponderId, selectedLabelId);
    }
  };

  return (
    <div className=''>
      <div className='gita_quote_heading'>
        <div className='container d-flex align-items-center justify-content-between'>
          <h4 onClick={() => navigate(-1)}>
            <FaArrowLeft
              //  className="hd-cr"
              style={{ marginRight: '5px' }}
              size={25}
            />
            Go Back
          </h4>
          <h4 className='hd-list-f left-q'>
            {' '}
            Total BroadCasting <small>({allDataCount})</small>
          </h4>
        </div>
      </div>

      {/* <div className='container'> */}
        {/* <div className='notifiction_form'> */}
          {/* <div className='user-list-table'> */}
            <Container className='mb-2' fluid>
              <div class='feed_area box-shadow my-3 br-6 p-20'>
                <div class='chat_search_box user-search'>
                  <div className='chat-search-field'>
                    <Row>
                      <form>
                        <Row className='align-items-end'>
                          <Col md={6}>
                            <Form.Label>Select Campaign</Form.Label>
                            <Select
                            classNamePrefix="react-select"
                              autoComplete='new-password'
                              options={options}
                              onChange={handleChange}
                              value={selectedOption || ''}
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Label class='label-f-w-cd'>Start Date</Form.Label>

                            <DatePicker
                              placeholderText={'Select a starting date'}
                              className='form-control form-ch fil-date-pick mt-2'
                              name='date'
                              selected={startDate ? new Date(startDate) : null}
                              onChange={date => {
                                handleStartDate(date);
                                setOffset(0);
                              }}
                              dateFormat='yyyy-MM-dd'
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Label class='label-f-w-cd'>End Date</Form.Label>
                            <DatePicker
                              placeholderText={'Select a ending date'}
                              className='form-control form-ch fil-date-pick mt-2'
                              name='date'
                              selected={endDate ? new Date(endDate) : null}
                              onChange={date => {
                                handleEndDate(date);
                                setOffset(0);
                              }}
                              dateFormat='yyyy-MM-dd'
                            />
                          </Col>
                          <Col md={3} className='mt-2'>
                            <Form.Label>Labels</Form.Label>
                            <Form.Select
                              aria-label='label-selected-menu'
                              className='fw-bold label-selected-menu me-1 text-capitalize w-50'
                              onChange={handleLabelSelectChange}
                              value={selectedLabelId}
                            >
                              <option value='all' className='fw-bold'>
                                All Labels
                              </option>
                              {labels.map(label => {
                                return (
                                  <>
                                    <option
                                      className='text-capitalize'
                                      value={label._id}
                                      style={{ color: label?.colourCode === '' ? 'black' : label.colourCode }}
                                    >
                                      {label.name}
                                    </option>
                                  </>
                                );
                              })}
                            </Form.Select>
                          </Col>
                          <Col md={3} className='mt-2'>
                            <Form.Label>Responder</Form.Label>
                            <Form.Select
                              aria-label='label-filter-menu'
                              className='fw-bold label-selected-menu w-100 ms-1 text-capitalize'
                              onChange={handleResponderSelectChange}
                              value={selectedResponderId}
                            >
                              <option value='all' className='fw-bold'>
                                Responder
                              </option>
                              {responder.map(respon => {
                                return (
                                  <>
                                    <option className='text-capitalize' value={respon._id}>
                                      {respon.name}
                                    </option>
                                  </>
                                );
                              })}
                            </Form.Select>
                          </Col>
                          <Col md={3} style={{ marginTop: '34px' }}>
                            <Button
                              variant='secondary'
                              className='as_btn ms-0'
                              onClick={() => {
                                handleFilterClick();
                                setOffset(0);
                              }}
                            >
                              Filter
                            </Button>
                            <Button
                              variant='secondary'
                              className='as_btn reset-btn'
                              onClick={() => {
                                resetData();
                              }}
                            >
                              Reset
                            </Button>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Form.Group as={Row} className='mb-3' controlId=''></Form.Group>
                        </Row> */}
                      </form>
                    </Row>
                  </div>
                </div>
              </div>
            </Container>

            {data && data.length > 0 ? (
              <DynamicDataTableWithCount
                columns={columns}
                data={data}
                totalCount={pageCount}
                initialState={{
                  pageIndex: pageIndex,
                  pageSize: perPage,
                }}
                fetchData={fetchData}
                forcePage={offset / perPage}
              />
            ) : (
              <h2 className='no_records'>Records not Available</h2>
            )}
          {/* </div> */}
        {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default BroadCastingHistory;
