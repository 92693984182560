
const InstagramAnalytics = () => {

  return (
    <div>
      <h2>Instagram</h2>
    </div>
  );
}

export default InstagramAnalytics;