import React, { useEffect, useRef, useState ,useContext} from "react";
import { Card, Form, Button, Row, Col, Nav, Tab , Modal} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { useToasts } from "react-toast-notifications";
import { ToastContainer, toast } from "react-toastify";
import APIServicenew from "../../utils/APIGeneralService";
import { Link } from "react-router-dom";
import { PencilSquare, XOctagon } from "react-bootstrap-icons";
import { formateDate ,setCookies } from "../../utils/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

const AstroWatch = () => {
  document.title = 'AstroWatch';
  const { addToast } = useToasts();
  const cookies = new Cookies();
  const imageRef = useRef('')
  const linkColorRef = useRef(null)
  const accessToken = cookies.get('accessToken')
  const [astrowatch, setAstroWatch] = useState([])
  const [tabChange, setTabChange] = useState('all')
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState()
  const[loader,setLoader]=useState(false)
  const [watchType, setWatchType] = useState('temples')
  const [mode, setMode] = useState({
    status: 'Submit',
    id: null
  })
  const [allDataCount , setAllDataCount] = useState()
  const [inputFields, setInputFields] = useState({
    heading: '',
    content: '',
    watchType: '',
    videoLink: '',
    image: ''
  })
  const [status , setStatus] = useState("")
  const [_id, set_Id] = useState();
  const [showdt, setShowDt] = useState(false);
  // const APIServices = new APIServicenew(serverAddrs)
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)
  const [errors, setErrors] = useState({});

  const headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + accessToken
  }

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const fetchData = async () => {
    setLoading(true);
    const response = await APIServices.get(`astroWatch/astroWatchList/?watchType=${watchType}&status=${status}`, headers)
    let data = response?.response?.data
    
    if (response.data?.status) {
      const data = response?.data.resData.data
      setAstroWatch(data);
      setAllDataCount(response.data.resData.total_count)
    } else{
      if(data?.status == 4){
      logout()
     }
    }
    setLoading(false);
  }


  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (!inputFields.heading ||inputFields.heading === "") 
      newErrors.heading = "Please enter heading.";

      if (!inputFields.content ||inputFields.content === "") 
      newErrors.content = "Please enter content.";

      if (!inputFields.videoLink ||inputFields.videoLink === "") 
      newErrors.videoLink = "Please enter video Link.";

      // if (!inputFields.image || inputFields.image === "" ) 
      // newErrors.file ="Please add image.";

      if (!inputFields.watchType ||inputFields.watchType === "") 
      newErrors.watchType = "Please select watch Type."; 

      return newErrors;
  }; 

  const handleSubmit = async (e) => {
    e.preventDefault()
    const body = {
      heading: inputFields.heading,
      content: inputFields.content,
      watchType: inputFields.watchType,
      videoLink: inputFields.videoLink
    }

    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
    const response = await APIServices.post('astroWatch/addAstroWatch', JSON.stringify(body), headers)
    if (response.data.status) {
      reset()
      setLoader(false)
      // addToast(response.data.message, {
      //   appearance: 'success',
      //   autoDismiss: true
      // })
      toast.success(response?.data.message);
    } else {
      // addToast(response.data.message, {
      //   appearance: 'error',
      //   autoDismiss: true
      // })
      toast.error(response?.data.message);
      setLoader(false)

    }
    setLoader(false)
  }
  }

  const reset = () => {
    setInputFields({
      heading: '',
      content: '',
      watchType: '',
      videoLink: ''
    })
    linkColorRef.current.style.border = '1px solid #ced4da';
    // imageRef.current.value = null
    setMode({ status: 'Submit', id: null })
  }

  const handleCloseDt = () => setShowDt(false);
  const handleShowDt = (id) => {
    set_Id(id);
    setShowDt(true);
  };

  const handleDelete = async () => {
    // e.preventDefault()
    const response = await APIServices.delete(`astroWatch/deleteAstroWatch/${_id}`, headers)
    if (response.data.status) {
      let newwatches = astrowatch.filter(item => item._id !== _id)
      setAstroWatch(newwatches)
      // addToast(response.data.message, {
      //   appearance: 'success',
      //   autoDismiss: true
      // })
      toast.success(response?.data.message);
      setShowDt(false)
    } else {
      // addToast(response.data.message, {
      //   appearance: 'error',
      //   autoDismiss: true
      // })
      toast.error(response?.data.message);
    }
  }

  const changeToEdit = async (e, id) => {
    e.preventDefault()
    setLoading(true)
    const response = await APIServices.get(`astroWatch/getAstroWatchById/${id}`, headers)
    if (Array.isArray(response.data) && response.data.length) {
      const { heading, content, videoLink, watchType, videoThumbnail } = response.data[0]
      setInputFields({
        heading,
        content,
        videoLink,
        watchType,
        image: videoThumbnail
      })
      setMode({ status: 'Update', id })
    } else {
      addToast('Something went Wrong', {
        appearance: 'error',
        autoDismiss: true
      })
    }
    setTabChange('add')
    setLoading(false)
  }

  const handleUpdate = async (e) => {
    e.preventDefault()
    const body = {
      heading: inputFields.heading,
      content: inputFields.content,
      watchType: inputFields.watchType,
      videoLink: inputFields.videoLink
    }
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
    const response = await APIServices.put(`astroWatch/updateAstroWatch/${mode.id}`, JSON.stringify(body), headers)   
    if (response.data.status) {
      reset()
      setLoader(false)
      toast.success(response?.data.message);
    } else {
      toast.error(response?.data.message);
      setLoader(false)
    }
    setLoader(false)
  }
  }

  const tabChanged = (selectedKey) => {
    setTabChange(selectedKey)
    fetchData()
  }

  const handleclick = async (status, id) => {
    var datas = status === 1 ? 2 : 1;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`astroWatch/updateStatus/${id}`, formData, headers)
      .then((res) => {
        fetchData()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleYoutubelink = (e) => {
    let link = e.target.value
    const linkregex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
    if (!link.match(linkregex) && linkColorRef) {
      linkColorRef.current.style.border = '1px solid red';
      linkColorRef.current.style.color = 'black';
    } else if (linkColorRef) {
      linkColorRef.current.style.border = '1px solid #ced4da';
      linkColorRef.current.style.color = '#25658f';
    }
    setInputFields((prev) => ({ ...prev, videoLink: e.target.value }))
  }

  useEffect(() => {
    fetchData()
    setLoader(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchType ,status])

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  } 

  return (
    <div className="blog_area festival_area">
    <div className="gita_quote_heading">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
        <h4 className="hd-list-f left-q" >Astro Watch <small>({allDataCount})</small></h4>
       
        </div>
       
      </div>
     </div>
   <div className="container">
    <Tab.Container id="WatchTab"
      activeKey={tabChange}
      onSelect={(selectedKey) => tabChanged(selectedKey)}
    >
      <Row className="pt-5 m-0">
        <Col md={4} lg={3}>
          <Nav
            variant="pills"
            className="flex-column"
          >
            <Nav.Item>
              <Nav.Link className='tabs-color-class' eventKey="all">Astro Watch</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className='tabs-color-class' onClick={reset} eventKey="add">Add Watch</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col md={8} lg={9}>
          <Tab.Content className="m-0 p-0">
            <Tab.Pane eventKey="all" className="verticalScoll p-0 m-0 common-Scroll">
           

              {
                loading ? <div style={{ marginTop: "15%" }}><Spinner animation="border" /></div> :
                  <section className="festival_inner">
                    <div className="row gx-2 justify-content-end mb-3">
                            <Form.Select
                              aria-label="categories"
                              className="  astroWatch-select-hover hd-cr w-25 right-q"
                              value={status}
                              onChange={(e) => {setStatus(e.target.value)}}
                            >
                              <option value="">All</option>
                              <option value={1} >Active</option>
                              <option value= {2}>InActive</option>
                            </Form.Select>
                            <Form.Select aria-label="categories" className=" astroWatch-select-hover w-25 hd-cr" value={watchType} onChange={(e) => setWatchType(e.target.value)}>
                              <option value="temples">Temples</option>
                              <option value="dosha">Dosha</option>
                              <option value="gods">Gods</option>
                              <option value="festivals">Festivals</option>
                              <option value="religious_cities">Religious Cities</option>
                              <option value="live_darshan">Live Darshan</option>
                              <option value="completed_puja">Completed Puja</option>
                            </Form.Select>
                    </div>
                    <div className="row">
                    {
                      astrowatch?.length !== 0 ? astrowatch.map(data => {
                        let Date = formateDate(data.blogDate)
                        return (
                          <div class="col-md-6 col-lg-6 col-xl-4">
                          <Card key={data._id} className="shadow blog-cards">
                               <div className="card-header position-relative p-0">
                                 <Card.Img variant="top" src={data.videoThumbnail ? data.videoThumbnail : '/images/default.jpg'} />
                                 <div className="icon card-icons">
                                    <PencilSquare className="me-2 text-success h5 event-pointer mb-0" onClick={e => changeToEdit(e, data._id)} />
                                    <XOctagon className="me-2 text-danger h5 event-pointer mb-0"
                                    onClick={(e) => {
                                      handleShowDt(data._id);
                                    }}
                                     />
                                     {data.status === 1 ? (
                                      <input
                                        type="button"
                                        className="_active"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Active"
                                        onClick={() =>
                                          handleclick(data.status, data._id)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="button"
                                        className="_inactive"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Inactive"
                                        onClick={() =>
                                          handleclick(data.status, data._id)
                                        }
                                      />
                                    )}
                                  </div>
                               </div>
                            <Link to={'/astrowatch/watch'} state={{ data, Date }} className="default-link-style">
                              <Card.Body>
                                <h6 className="description font-500 mb-2 text-start">{data.watchType}</h6>
                                <h5 className="card-title heading_18 mb-2 text-start">{data.heading}</h5>
                                <p className="card-text-blog  description">{data.content}</p>
                              </Card.Body>
                            </Link>
                          </Card>
                          </div>
                        )
                      })
                        : <article className="w-100 h-100 d-flex justify-content-center align-items-center h4">
                          No record to show
                        </article>
                    }
                    </div>
                  </section>
              }
            </Tab.Pane>
            <Tab.Pane eventKey="add">
              <Form className="add_blog_form">
                <Form.Group className="mb-3  mx-2" controlId="formBasicEmail">
                  <Form.Label className="required">Heading</Form.Label>
                  <Form.Control type="text" placeholder="Heading..." 
                  // value={() => {
                  //   linkColorRef.current.color = '#25658f';
                  //   return inputFields.heading
                  // }} 
                  name='heading' isInvalid={!!errors?.heading}
                  value={inputFields.heading}
                  onChange={(e) => {setInputFields((prev) => ({ ...prev, heading: e.target.value })); handleChange(e)}} />
                  <Form.Control.Feedback type="invalid">
                  {errors?.heading}
             </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3 mx-2" controlId="formBasicEmail">
                  <Form.Label className="required">Watch Link</Form.Label>
                  <Form.Control type="text" ref={linkColorRef} placeholder="Youtube Link..." name='videoLink' isInvalid={!!errors?.videoLink}  value={inputFields.videoLink} onChange={(e) => {handleYoutubelink(e); handleChange(e)}} />
                  <Form.Control.Feedback type="invalid">
                  {errors?.videoLink}
             </Form.Control.Feedback>
                  </Form.Group>

                <Form.Group className="mb-3  mx-2" controlId="formBasicEmail">
                  <Form.Label className="required">Watch Type</Form.Label>
                  <Form.Select  name='watchType' isInvalid={!!errors?.watchType} value={inputFields.watchType} onChange={e => {setInputFields((prev) => ({ ...prev, watchType: e.target.value })); handleChange(e)}}>
                    <option value="">Select</option>
                    <option value="temples">Temples</option>
                    <option value="dosha">Dosha</option>
                    <option value="gods">Gods</option>
                    <option value="festivals">Festivals</option>
                    <option value="religious_cities">Religious Cities</option>
                    <option value="live_darshan">Live Darshan</option>
                    <option value="completed_puja">Completed Puja</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                  {errors?.watchType}
             </Form.Control.Feedback>
                  </Form.Group>

                <Form.Group className="mb-3  mx-2" controlId="watchcontent">
                  <Form.Label className="required">Content</Form.Label>
                  <Form.Control as="textarea" className="textAreaHeight" placeholder="Content..." rows={5} name='content' isInvalid={!!errors?.content} value={inputFields.content} onChange={(e) => {setInputFields((prev) => ({ ...prev, content: e.target.value })); handleChange(e)}} />
                  <Form.Control.Feedback type="invalid">
                  {errors?.content}
             </Form.Control.Feedback>
                  </Form.Group>

                {/* <Form.Group className="mb-3  mx-2">
                  <Form.Label className="required">Thumbnail</Form.Label>
                  <div>
                    <label htmlFor='upload-Thumbnail'>
                      <img src="/images/imageUpload.jpg" alt="animage" width={50} className="pointerEvent rounded" />
                    </label>
                    <input id="upload-Thumbnail" name="upload-Thumbnail" className="d-none" type="file" ref={imageRef} onChange={(e) => setInputFields((prev) => ({ ...prev, image: e.target.files[0] }))} />
                  </div>
                  {
                    inputFields.image && <div><FontAwesomeIcon icon="fa-solid fa-check" className="mx-2" />{inputFields.image.name}</div>
                  }
                </Form.Group> */}


                <div className="text-center">
                {loader?<Button variant="primary" type="submit" className=" as_btn" >
                {
                  "Processing..."
                }
              </Button>:<Button variant="primary" type="submit" className=" as_btn" onClick={(e) => {mode.status === 'Update' ? handleUpdate(e) : handleSubmit(e); }}>
              {
                mode.status 
              }
            </Button>}
            {mode.status === "Update" ? "":<Button variant="primary" type="submit" className=" as_btn mx-2" onClick={(e) => {
              e.preventDefault()
              reset()
            }} >
              Reset
            </Button>}
                  
                </div>
              </Form>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row >
    </Tab.Container >
    <Modal
        show={showdt}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancle_btn"
            onClick={handleCloseDt}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="success_btn"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    <ToastContainer />
    </div>
    </div>
  );
}


export default AstroWatch;
