import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';
import APIServicenew from '../../utils/APIGeneralService';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import bimg from '../../../src/imgs/banner_background.png';
import { Row, Form, Col } from 'react-bootstrap';


export function ImgGalleyModel({ show, onHide, handleSetImageUrl }) {
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);

  const [loading, setLoading] = useState();
  const [allData, setAllData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [dropDownList, setDropDownList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    fetchData();
    getGalleryFolder()
  }, [selectedValue]);

  useEffect(() => {
    getGalleryFolder()
  }, []);

  const getGalleryFolder=async()=>{
    let response = await APIServices.get(`mediaGallery/getGalleryFolder`, headers);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setDropDownList(apiData?.data)
      }
    }
  }

  async function fetchData() {
    setLoading(true);
    let response = await APIServices.get(`mediaGallery/getAll?folderName=${selectedValue}`, headers);
    let data = response?.response?.data;
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata);
        setLoading(false);
      }
    } else {
      if (data.status == 4) {
        //  logout()
      }
    }
  }

  // Function to handle card click
  const handleCardClick = fileUrl => {
    setSelectedItem(fileUrl);
    handleSetImageUrl(fileUrl);
  };

  // Function to clear the selected item
  const handleModalClose = () => {
    // setSelectedItem(null);
    onHide();
  };

  return (
    <Modal
      show={show}
      id='my-modal'
      onHide={handleModalClose}
      size='lg'
      backdrop='static'
      dialogClassName='custom-modal-width add-puja-gallery  whatsapp-modal'
      scrollable={true}
    >
      <Modal.Header closeButton>
                <Modal.Title> Image Gallery </Modal.Title>
                <Form.Group as={Row} className='ms-5' controlId='formTitleInput'>
                    <Form.Select
                      aria-label='label-selected-menu'
                      className='fw-bold label-selected-menu text-capitalize  w-100 hd-cr mt-2 form-ch '
                      value={selectedValue}
                      onChange={(e)=> setSelectedValue(e.target.value)}
                      name='folderName'
                    >
                      <option value=''>Filter by folder</option>
                      {dropDownList && dropDownList?.length>0 && dropDownList.map((item)=>{
                        return <option value={item}>{item}</option>
                      })}
                    </Form.Select>
                  
                </Form.Group>
      </Modal.Header>
      <Modal.Body>
        <div className='main aarti_section'>
          <div className='aarti_inner pt-4'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12 col-lg-12'>
                  {loading ? (
                    <div className='center'>
                      <Spinner animation='border' />
                    </div>
                  ) : (
                    <>
                      <div className='row'>
                        {allData?.map(item =>
                          item?.file ? (
                            <div className='col-md-6 col-lg-4 col-xl-3' key={item?._id}>
                              <div
                                className={`card mb-3 add-puja-modal Reels_video hd-cr ${
                                  selectedItem === item?.file ? 'imgselected' : ''
                                }`}
                                onClick={() => handleCardClick(item?.file)}
                              >
                                <div className='card-header'>
                                  <img src={item?.file ? item?.file : bimg} className='card-img-top' alt='img' />
                                </div>
                              <p className='card-file-name font_14'>..{item?.file.slice(-25)}</p>
                              </div>
                            </div>
                          ) : (
                            ''
                          )
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal.Footer className='py-2 px-0'></Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}
