
import { Button, Modal, Row ,Form ,Tab, Col, Nav} from "react-bootstrap";


export default function MandirAllTabs() {
    document.title = 'MandirAllTabs';
 
  return (
    <>
      <div className="main aarti_section mandir_tabs_section">
        <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Aarti</h4>
           <div className="_btn">
                <Button
                variant="primary"
                className="by-btn as_btn"
                // onClick={()=>updateFile(true)}
              >
                Update File
              </Button>
              <Button
               className="by-btn as_btn me-0"
                variant="primary"
                // onClick={() => {
                //   setNewData(initialData);
                //   setShowModal(true);
                //   setCropImg()
                // }}
              >
                Add Aarti
              </Button>
           </div>
           </div>
        </div>

        <div className="mandir_tabs_inner aarti_inner pt-5">
             <div className="container">
                <Tab.Container id="left-tabs-example" defaultActiveKey="tabs-1">
                    <Row>
                        <Col  md={4} lg={3}>
                        <div class="mandir_sidebar_tabs">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-1">Aarti</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-2">Astrology Advice</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-3">Banner</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-4">Bhajan</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-5">Books</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-6">Chalisa </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-7">DevLok</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-8">God Images</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-9">Guru</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-10">Inspiring Quotes</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-11">Mantra</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-12"> Mysterious Temple</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-13">Master God</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-14">Meditation Yoga And Exercise</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-15">Morning Quotes</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-16">Panchang</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-17">Puja</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-18">Puja From PanditJi</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-19">Questions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-20">Siddha Purush</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="tabs-21">Todays Thought</Nav.Link>
                                </Nav.Item>

                                
                            </Nav>
                        </div>
                        </Col>
                        <Col md={8} lg={9}>
                  
                        <Tab.Content>
                            <Tab.Pane eventKey="tabs-1">
                                <div className="mandir_tabs_content">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h4 className="hd-list-f left-q"></h4>
                                        <select aria-label="categories" className=" w-25 astroWatch-select-hover hd-cr form-select">
                                            <option value="english">English</option>
                                            <option value="hindi">Hindi</option>
                                            </select>
                                    </div>

                                    <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-10-05T06:53:48.237Z-aarti_durga_490554_1692266028433.png" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Thu Oct 05 2023</p>
                                                <h5 className="card-title heading_18 mb-2">testtt</h5>
                                                <p className="card-text description">testtt</p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-09-08T13:07:26.773Z-ganesh1.jpeg" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">English Ganpati  aarti </h5>
                                                <p className="card-text description">सुखकर्ता दुखहर्ता वार्ता विघ्नाची |
                                                नुरवी पूर्वी प्रेम कृपा जयाची |
                                                सर्वांगी सुंदर उटी शेंदुराची |
                                                कंठी झळके माळ मुक्ताफळाची || १ ||
                                                जय देव जय देव जय मंगलमूर्ती |
                                                दर्शनमात्र
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-09-08T07:38:03.893Z-ganesh2.jpeg" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 29 2023</p>
                                                <h5 className="card-title heading_18 mb-2">Hindi Ganpati Aarti</h5>
                                                <p className="card-text description">सुखकर्ता दुखहर्ता वार्ता विघ्नाची |
                                                नुरवी पूर्वी प्रेम कृपा जयाची |
                                                सर्वांगी सुंदर उटी शेंदुराची |
                                                कंठी झळके माळ मुक्ताफळाची || १ ||
                                                जय देव जय देव जय मंगलमूर्ती |
                                                दर्शनमात्र
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-09-08T06:37:19.957Z-hanuman-ji-30.jpg" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">shiv shankar</h5>
                                                <p className="card-text description">liululuil</p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/uploads/images/aarti-images/aarti_krishn_223243_1692483857803.png" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">Shri Krishna Ji Ki Arti</h5>
                                                <p className="card-text description">Aarti Kunj Bihari Ki
                                                Aarti Kunj Bihari Ki
                                                Shri Girdhar Krishna Murari Ki
                                                Gale Mein Baijanti Mala, Bajave Murali Madhur Bala।
                                                Shravan Mein Kundal Jhalakala, Nand Ke Anand Nandla
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/uploads/images/aarti-images/aarti_hanuman_411549_1692483494004.png" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">Shri Hanuman Ji Ki Arti </h5>
                                                <p className="card-text description">॥ Aarti Shri Hanumanji ॥
                                                Aarti Kije Hanuman Lala Ki।Dusht Dalan Ragunath Kala Ki॥
                                                Jake Bal Se Girivar Kaanpe।Rog Dosh Ja Ke Nikat Na Jhaanke॥
                                                Anjani Putra Maha Baldaaee।Sant
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs-2">
                            <div className="mandir_tabs_content">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h4 className="hd-list-f left-q"></h4>
                                        <select aria-label="categories" className=" w-25 astroWatch-select-hover hd-cr form-select">
                                            <option value="english">English</option>
                                            <option value="hindi">Hindi</option>
                                            </select>
                                    </div>

                                    <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-10-05T06:53:48.237Z-aarti_durga_490554_1692266028433.png" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Thu Oct 05 2023</p>
                                                <h5 className="card-title heading_18 mb-2">testtt</h5>
                                                <p className="card-text description">testtt</p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-09-08T13:07:26.773Z-ganesh1.jpeg" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">English Ganpati  aarti </h5>
                                                <p className="card-text description">सुखकर्ता दुखहर्ता वार्ता विघ्नाची |
                                                नुरवी पूर्वी प्रेम कृपा जयाची |
                                                सर्वांगी सुंदर उटी शेंदुराची |
                                                कंठी झळके माळ मुक्ताफळाची || १ ||
                                                जय देव जय देव जय मंगलमूर्ती |
                                                दर्शनमात्र
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-09-08T07:38:03.893Z-ganesh2.jpeg" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 29 2023</p>
                                                <h5 className="card-title heading_18 mb-2">Hindi Ganpati Aarti</h5>
                                                <p className="card-text description">सुखकर्ता दुखहर्ता वार्ता विघ्नाची |
                                                नुरवी पूर्वी प्रेम कृपा जयाची |
                                                सर्वांगी सुंदर उटी शेंदुराची |
                                                कंठी झळके माळ मुक्ताफळाची || १ ||
                                                जय देव जय देव जय मंगलमूर्ती |
                                                दर्शनमात्र
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/images/aarti-images/2023-09-08T06:37:19.957Z-hanuman-ji-30.jpg" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">shiv shankar</h5>
                                                <p className="card-text description">liululuil</p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/uploads/images/aarti-images/aarti_krishn_223243_1692483857803.png" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">Shri Krishna Ji Ki Arti</h5>
                                                <p className="card-text description">Aarti Kunj Bihari Ki
                                                Aarti Kunj Bihari Ki
                                                Shri Girdhar Krishna Murari Ki
                                                Gale Mein Baijanti Mala, Bajave Murali Madhur Bala।
                                                Shravan Mein Kundal Jhalakala, Nand Ke Anand Nandla
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <img src="https://d3i0p1mk3sd6q7.cloudfront.net/devcontent/uploads/images/aarti-images/aarti_hanuman_411549_1692483494004.png" className="card-img-top" alt="img"/>
                                                <div className="icon">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" className="me-2 hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path>
                                                </svg>
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" className="dt-btn hd-cr " height="20" width="20" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path>
                                                </svg>
                                                </div>
                                            </div>
                                            <div className="card-body height_box">
                                                <p className="description font-500 mb-2" ><span className="calicon"><i className="ri-calendar-line"></i></span>Fri Sep 08 2023</p>
                                                <h5 className="card-title heading_18 mb-2">Shri Hanuman Ji Ki Arti </h5>
                                                <p className="card-text description">॥ Aarti Shri Hanumanji ॥
                                                Aarti Kije Hanuman Lala Ki।Dusht Dalan Ragunath Kala Ki॥
                                                Jake Bal Se Girivar Kaanpe।Rog Dosh Ja Ke Nikat Na Jhaanke॥
                                                Anjani Putra Maha Baldaaee।Sant
                                                </p>
                                            </div>
                                            <div className="card-footer text-center"><button type="button" className="btn-more as_btn btn btn-primary">see more</button></div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tabs-3">3 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-4">4 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-5">5 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-6">6 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-7">7 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-8">8 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-9">9 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-10">10 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-11">11 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-12">12 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-13">13 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-14">14 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-15">15 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-16">16 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-17">17 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-18">18 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-19">19 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-20">20 tab content</Tab.Pane>
                            <Tab.Pane eventKey="tabs-21">21 tab content</Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                
             </div>
           
        </div>
    
      </div>
    </>
  )
}