import React, { useContext ,useState} from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Dropdown, DropdownButton ,Modal,Button} from "react-bootstrap";
import { PersonCircle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContextProvider";
import { getCookies, setCookies } from "../../utils/Helper";
import APIServicenew from "../../utils/APIGeneralService";
import { toast, ToastContainer } from "react-toastify";

const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)

const Header = () => {
  const navigate = useNavigate();
  const { token, refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const instagram_user_id = getCookies("instagram_user_id");

  const handleLogout = e => {
    e.preventDefault();
    refreshToken(null);
    setCookies("accessToken", null);
    navigate("/");
  };
  const handleClose  =() =>{
    setShow(false)
  }

  const handleConfirm = () =>{
    handleDailyHoroscope()
  }
  const handleDailyHoroscope = async (e) => {
    const res = await APIServices.post("cron/daily_horoscope", {}, {
      // "x-access-token": token
       Authorization: `Bearer ${token}`
    })
    if(res.status === 200 && res.data === "OK"){
      toast.success("Cron initiated successfully")
      setShow(false)
    }else{
      toast.error("Unable to run daily horoscope cron")
    }
  }
  const renderNavDropDownItem = ({ path, text, divider }, key) => {
    if (divider) {
      return (
        <>
          <NavDropdown.Item onClick={() => navigate(path)} key={key}>
            {text}
          </NavDropdown.Item>
          <NavDropdown.Divider />
        </>
      );
    } else {
      return (
        <NavDropdown.Item onClick={() => navigate(path)} key={key}>
          {text}
        </NavDropdown.Item>
      );
    }
  };
  let youtube_url = `https://accounts.google.com/o/oauth2/v2/auth?access_type=${'offline'}&scope=${'https://www.googleapis.com/auth/youtube.readonly'}&response_type=code&client_id=${process.env.REACT_APP_YOUTUBE_CLIENT_ID}&redirect_uri=${'https://localhost:3000/youtube_posts/'}`

  const renderMenuItems = () => {
    const UserMenuItems = [
      // { path: "/addusers", text: "Add Users", divider: true },
      // { path: "/userlist", text: "Users List", divider: true },
      { path: "/userListNew", text: "Customer List", divider: true },
      { path: "/addquestion", text: "Add Customer ", divider: true },
      { path: "/campaign" , text: "Manage Campaign" ,divider:true},
      { path: "/responders" , text: "Manage Responder" ,divider:true},
      { path: "/label" , text: "Manage Label" ,divider:true},
      // { path: "/export-users" , text: "Export Users"}


    ];
    const AnalyticsMenuItems = [
      { path: "/analytics/facebook", text: "Facebook", divider: true },
      { path: "/analytics/instagram", text: "Instagram", divider: true },
      { path: "/analytics/twitter", text: "Twitter", divider: true },
      { path: "/analytics/youtube", text: "Youtube"},
    ];
    const HoroscopeMenuItems = [
      { path: "/horoscope/daily", text: "Daily Horoscope", divider: true },
      { path: "/horoscope/weekly", text: "Weekly Horoscope", divider: true },
      { path: "/horoscope/monthly", text: "Monthly Horoscope", divider: true },
      { path: "/horoscope/yearly", text: "Yearly Horoscope"},
    ];
  

    const QuotesMenuItem = [
      { path: "/gitaquotes", text: "Gita Quotes", divider: true },
      { path: "/spiritualquotes", text: "Spiritual Quotes", divider: true },
      { path: "/chanakyasquotes", text: "Chanakyas Quotes", divider: true },
      { path: "/todaysThought", text: "Today's Thought", divider: true },
      { path: "/morningQuotes", text: "Morning Quotes", divider: true },
      { path: "/inspiringQuotes", text: "Inspiring Quotes", divider: true },
      

    ]

    const BulkNotification = [
      { path: "/bulknotificaton", text: "Add Bulk Notification", divider: true },
      { path: "/bulknotificatonlist", text: "Bulk Notification List",divider :true },
      { path: "/astroDailyNotification", text: "Astro Daily Notification"},
      { path: "/whatsupbroadcasting", text: "Whatsup Broadcasting",divider :true},
      // { path: "/whatsAppAnalytics", text: "WhatsApp Analytics",divider :true},
    ]


    const puja = [
      { path: "/puja", text: "Puja List", divider: true },
      { path: "/pujaallbooking", text: "Puja Bookings",divider :true },
      { path: "/pujaFeedback", text: "Puja Feedback",divider :true},
      { path: "/pujaSuggestionList", text: "Puja Recommendation",divider :true},
      { path: "/completePujaList", text: "Complete Puja List",divider :true},
      // { path: "/pujaPandit", text: "Puja Pandit",divider :true},
    ]

    const chadhawa = [
      { path: "/chadhwaList", text: "Chadhawa Booking", divider: true },
      // { path: "/templeList", text: "Temple List",divider :true },
      { path: "/manokamanaList", text: "Manokamana Booking",divider :true },
    ]


    return (
      <>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="content_nav">
          <Nav className="ms-auto">
            {/* <NavLink to="/" className="nav-link">
              Home
            </NavLink> */}
            <NavLink to="/reels" className="nav-link">
              Reels
            </NavLink>
            <NavLink to="/mediaGallery" className="nav-link">
             Gallery
            </NavLink>
            <NavLink to="/feeds" className="nav-link">
              Feeds
            </NavLink>
            <NavDropdown
              title="Quotes"
              id="responsive-navbar-nav"
              className="ms-auto"
            >
              {QuotesMenuItem.map((item, key) =>
                renderNavDropDownItem(item, key)
              )}
            </NavDropdown>
            <NavLink to="/mysteriousTemple" className="nav-link">
              CMS
            </NavLink>
            <NavDropdown
              title="Puja"
              id="responsive-navbar-nav"
              className="ms-auto"
            >
              {puja.map((item, key) =>
                renderNavDropDownItem(item, key)
              )}
            </NavDropdown>
            <NavDropdown
              title="Chadhawa"
              id="responsive-navbar-nav"
              className="ms-auto"
            >
              {chadhawa.map((item, key) =>
                renderNavDropDownItem(item, key)
              )}
            </NavDropdown>
            <NavLink to="/blogs" className="nav-link">
              Blogs
            </NavLink>
            <NavLink to="/festivals" className="nav-link">
              Festivals
            </NavLink>
            <NavLink to="/astrowatch" className="nav-link">
              Astro Watch
            </NavLink>
            <NavDropdown title="Notification" id="basic-nav-dropdown">
              {BulkNotification.map((item, key) =>
                renderNavDropDownItem(item, key)
              )}
            </NavDropdown>
            {/* <NavLink to="/chat" className="nav-link">
              Chat
            </NavLink> */}
            {/* <NavLink to="/whatsAppLeads" className="nav-link">
             WhatsApp Leads
            </NavLink> */}
            {/* <NavLink to="/calender-event" className="nav-link">
              Calender Event
            </NavLink> */}
            <NavDropdown
              id="responsive-navbar-nav"
              title={<span><PersonCircle className="me-1" size={15} /> {adminUser?.name}</span>}
              className="main-navbar"
            >
              {/* <DropdownButton id="dropdown-basic-button" title="Customer" className="dropselect">

              {UserMenuItems.map((item, key) =>
                renderNavDropDownItem(item, key)
              )}
            </DropdownButton>
            <hr/> */}
            {/* <NavDropdown.Item onClick={() => navigate("/pujaallbooking")}>
                Puja Bookings
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/pujaFeedback")}>
                Puja Feedback
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/pujaSuggestionList")}>
                Puja Recommendation 
              </NavDropdown.Item>
              <hr /> */}


              <NavDropdown.Item onClick={() => navigate("/whatsAppLeads")}>
                WhatsApp Leads
              </NavDropdown.Item>
              <hr />
               <NavDropdown.Item onClick={() => navigate("/userListNew")}>
                Manage Customers 
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/campaign")}>
               Manage Campaign
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/responders")}>
               Manage Responder
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/label")}>
               Manage Label
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/contactInquiries")}>
              Contact Inquiries
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/connectAstroUser")}>
              Connect Astro - Customer
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/whatsapptemplate")}>
              WhatsApp Template
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/whatsappinteractivereply")}>
              WhatsApp Interactive Reply
              </NavDropdown.Item>
              <hr />
              {/* <NavDropdown.Item onClick={() => navigate("/callhistory")}>
              WhatsApp Call History
              </NavDropdown.Item>
              <hr /> */}
              <NavDropdown.Item onClick={() => navigate("/responderCallhistory")}>
             Responder Call History
              </NavDropdown.Item>
              <hr />
            
            {/*  <NavDropdown.Item onClick={() => navigate("/diwaliuserslist")}>
                Diwali Users
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={() => navigate("/mankibatlist")}>
                Man Ki Bat
              </NavDropdown.Item>
              <hr />*/}
            <NavDropdown.Item onClick={() => navigate("/blockedusers")}>
              Block Customers
            </NavDropdown.Item>
            <hr/>
              {/* <DropdownButton id="dropdown-basic-button" title="Analytics" className="dropselect">
                {AnalyticsMenuItems.map((item, key) =>
                  renderNavDropDownItem(item, key)
                )}
              </DropdownButton> */}
            {/* <hr/>
              <DropdownButton id="dropdown-basic-button" title="Horoscopes" className="dropselect">
             
              {HoroscopeMenuItems.map((item, key) =>
                renderNavDropDownItem(item, key)
              )}
              </DropdownButton> */}
            {/* <hr/>
            <a href="/insta_posts" target = "_blank" style={{ marginLeft: "15px" }} >
            Sync Instagram
             </a><hr />
              <a href={youtube_url} target = "_blank"  style={{ marginLeft: "15px" }} >
                Sync Youtube
              </a><hr /> */}
              <NavDropdown.Item onClick={() => setShow(true)}>
                Update Daily Horoscope 
              </NavDropdown.Item>
              <hr />
              <NavDropdown.Item onClick={(e) => handleLogout(e)}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </>
    );
  };

  return (
    <div>
      <div className="row p-0 m-0">
        <div className="col-md-12 p-0 m-0">
          <Navbar expand="xl" className="header navbar-dark p-0">
           <NavLink className="navbar-brand logo-link" to="/">  <img src="images/logo_withtagline.png" class="img-fluid main-logo"/></NavLink>
            {token ? (
              renderMenuItems()
            ) : (
              <Nav className="ms-auto">
                <NavLink to="/login" className="nav-link">
                  Login
                </NavLink>
              </Nav>
            )}
          </Navbar>
        </div>
      </div>
      <ToastContainer />
      <Modal
        show={show}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure want to run job?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e)=>handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={handleClose}
          >
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
