import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import APIServicenew from '../../utils/APIGeneralService';
import { getCookies } from '../../utils/Helper';
import Papa from 'papaparse';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);

const ExportUsers = () => {
  const token = getCookies('accessToken');
  const [allDataCount , setAllDataCount] = useState()
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [inputState, setInputState] = useState({
    startdate: moment().format('YYYY-MM-DD'),
    enddate: moment().format('YYYY-MM-DD'),
  });

  const handleInputChange = e => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;

    setInputState(prevState => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const handleSearch = async () => {
    setLoading(true);
    let startdate = moment(inputState.startdate).format('MM-DD-YYYY');
    let enddate = moment(inputState.enddate).format('MM-DD-YYYY');

    const res = await APIServices.get(
      `admin/users/usersList/?name=&email=&contactNumber=&startDate=${startdate}&endDate=${enddate}&source=`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res.status === 200 && res?.data?.status) {
      const userInfo = res.data?.resData?.data;
      setUserData(
        userInfo?.map(info => ({
          id: info?.['_id'],
          Name: info?.name,
          ContactNumber: info?.contactNumber,
          Source: info?.utmSource,
          CreatedDate: moment(info?.createdAt).format("lll"),
        }))
      );
        setLoading(false);
        setAllDataCount(res.data?.resData?.total_count)
    } else {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    let csv = Papa.unparse(userData, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData, 'UserList.csv');
    else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.download = 'UserList.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 50,
    showTotal: true,
    sizePerPageList: [
      { text: '20', value: 20 },
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: 'All', value: userData && userData?.length },
    ],
  });

  const columns = [
    {
      sort: true,
      dataField: 'id',
      text: 'ID',
      headerStyle: { width: '60px' },
      type: 'string',
      shouldFilter: true,
    },
    {
      sort: true,
      dataField: 'Name',
      text: 'Name',
      headerStyle: { width: '100px' },
      type: 'string',
      shouldFilter: true,
    },
    {
      sort: true,
      dataField: 'ContactNumber',
      text: 'Contact Number',
      headerStyle: { width: '100px' },
      type: 'string',
      shouldFilter: true,
    },
    {
      sort: true,
      dataField: 'Source',
      text: 'Source',
      headerStyle: { width: '100px' },
      type: 'string',
      shouldFilter: true,
    },
    {
      sort: true,
      dataField: 'CreatedDate',
      text: 'Created Date',
      headerStyle: { width: '100px' },
      type: 'string',
      shouldFilter: true,
    },
  ];

  const renderTable = () => {
    return (
      <div className='p-4'>
          <BootstrapTable
            columns={columns}
            data={userData ?? []}
            bootstrap4
            condensed={true}
            striped={true}
            hover={true}
            noDataIndication={`No Users found ...`}
            defaultSortDirection='asc'
            filterPosition='top'
            keyField={'id'}
            pagination={pagination}
          />
      </div>
    );
  };

  return (
    <Container fluid style={{ marginTop: '22px' }}>
      <Row>
        <h5>
          <strong>Users {allDataCount?<small>({allDataCount})</small>:""}</strong>
        </h5>
        <hr />
        <Col md={1} className='d-flex justify-content-end pe-0 align-items-end'>
          <Form.Label>Start Date</Form.Label>
        </Col>
        <Col md={2}>
          <input
            className='form-control'
            name='startdate'
            type='date'
            onChange={e => handleInputChange(e)}
            value={inputState.startdate}
          />
        </Col>
        <Col md={1} className='d-flex justify-content-end pe-0 align-items-end'>
          <Form.Label>End Date</Form.Label>
        </Col>
        <Col md={2}>
          <input
            className='form-control'
            name='enddate'
            type='date'
            onChange={e => handleInputChange(e)}
            value={inputState.enddate}
          />
        </Col>
        <Col md={4} className='ms-1'>
          <Button
            className='as_btn'
            variant='primary'
            onClick={() => {
              handleSearch();
            }}
          >
            Get Users
          </Button>
          <Button
            className='as_btn ms-2'
            variant='primary'
            disabled={!userData || (userData && userData.length <= 0)}
            onClick={() => {
              handleDownload();
            }}
          >
            Export Users
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        {loading ? (
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <Spinner className='mt-3' animation='border' variant='danger'></Spinner>
            <span className='mt-4'>Loading users, Please wait...</span>
          </div>
        ) : (
          renderTable()
        )}
      </Row>
    </Container>
  );
};

export default ExportUsers;
