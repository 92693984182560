import React, { useEffect, useState ,useContext } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import { PlayFill } from "react-bootstrap-icons";
import { FaEdit, FaTrash, FaVideo } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../components/Shared/Sidebar";
import bimg from "../../src/imgs/banner_background.png";
import upimg from "../../src/imgs/default.jpg";
import Cookies from "universal-cookie";
import APIServicenew from "../utils/APIGeneralService";
import CropModal from "../components/Shared/CropModal";
import { getBaseURL ,setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

const AstroGuru = () => {
  document.title = 'Guru';
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [show, setShow] = useState(false);
  const [showup, setShowUp] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [showMore, setShowMore] = useState(false);
  const [img, setImg] = useState();
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [len, setLen] = useState();
  const [errors, setErrors] = useState({});
  const [loader,setLoader]=useState(false)
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()
  const [allDataCount , setAllDataCount] = useState()
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId");
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  const onImageChange = (event) => {
   
    setFile(event);
      setFile1(event)
    //  i have no idea so i am not removing this
    // if (event.target.files && event.target.files[0]) {
    //   setFile(URL.createObjectURL(event.target.files[0]));
    //   setFile1(event.target.files[0])
    // }
  }
  const handleClose_m = () => setShowMore(false);
  const handleClose = () => {
    setShow(false)
    setErrors("")
  };
  const handleCloseDt = () => setShowDt(false);

  const handleShowMore = (item) => {
    setShowMore(true);
    setTitle(item?.title);
    setContent(item?.contentHi);
  };
  const handleShow = async(item) => {
    
    setFile("");
    setFile1("");
    setShow(true);
    setSelectedId(item?._id);
    setTitle(item?.title);
    setContent(item?.contentHi);
    setVideo(item?.video ?item.video :"");
    setAudio(item?.audio ?item.audio :"");
    setImg(item?.file);

  };
  
  const handleShowDt = (id) => {
    setSelectedId(id);
    setShowDt(true);
  };

  function isValidURL(string) {
    var res = 
string.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
return (res !== null);
};
  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (!title ||title === "") 
      newErrors.title = "Please enter title.";

      if (!content ||content === "") 
      newErrors.content = "Please enter content.";
      if ( video !== "" && isValidURL(video) === false) 
      newErrors.video = "Please enter valid video url.";

      if (audio !== "" && isValidURL(audio) === false) 
      newErrors.audio = "Please enter valid audio url.";
    
      if (file1 === "" && (!img || img === "")) 
      newErrors.file = "Please add image.";

      return newErrors;
  }; 
  async function handleEdit() {
    const formData = new FormData();
    if (file1) {
      formData.append("file", file1);
    }
    formData.append("title", title);
    formData.append("contentHi", content);
    formData.append("video", video);
    formData.append("audio", audio);
    formData.append("userId", astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      let response = await APIServices.put(
        `astroGuru/editOne/${selectedId}`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShow(false);
          setLoader(false)
          toast.success(apiData?.message);
          setFile1(null);
          setCropImg()
          updateFile();
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }

  const fetchData = async () => {
    setLoading(true);
    let response = await APIServices.get(`astroGuru/getAll/${offset}/${perPage}`, headers);
    let data = response?.response?.data
   
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData?.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false);
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage);
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset);
  };

  async function updateFile(showToast) {
    let res = await APIServices.get(`astroGuru/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message);
        }
        fetchData();
      }
    }
  }

  const handledelete = async () => {
    let res = await APIServices.delete(
      `astroGuru/findByIdAndremove/${selectedId}`,
      headers
    );
    if (res?.status === 200) {
      setShowDt(false);
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  };



  async function handleCreate() {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("file", file1);
    formData.append("contentHi", content);
    formData.append("video", video);
    formData.append("audio", audio);
    formData.append("userId", astroUserId);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      let response = await APIServices.post(
        `astroGuru/upload`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShow(false);
          setLoader(false)
          toast.success(apiData?.message);
          setCropImg()
          updateFile();
        }else{
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }
  
  useEffect(() => {
    fetchData();
  }, [offset]);


  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

useEffect(()=>{
  if(caartifileimage){
    onImageChange(caartifileimage)
  }
},[cropimg,caartifileimage])

const renderFormModal = () => {

return(
  <Modal show={show} backdrop="static" onHide={()=>{handleClose(); setCropImg()}}>
  <Modal.Header closeButton>
    <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Guru`} </Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label className="required" column sm="3">
          Title
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            placeholder="Title..."
            name='title'
            defaultValue={title}
            isInvalid={!!errors?.title}
            className="form-control "
            onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
          />
          <Form.Control.Feedback type="invalid">
                {errors?.title}
           </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label className="required" column sm="3">
          Content
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            as="textarea"
            style={{ height: 150 }}
            name='content'
            isInvalid={!!errors?.content}
            defaultValue={content}
            placeholder="Content..."
            className="form-control "
            onChange={(e) => {setContent(e.target.value);handleChange(e)}}
          />
           <Form.Control.Feedback type="invalid">
                {errors?.content}
           </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label column sm="3">
          Video
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            name="video"
            placeholder="Video Url..."
            className="form-control "
            defaultValue={video}
            isInvalid={!!errors?.video}
            onChange={(e) => {setVideo(e.target.value);handleChange(e)}}
          />
           <Form.Control.Feedback type="invalid">
                {errors?.video}
           </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label column sm="3">
          Audio
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            name="audio"
            placeholder="Audio Url..."
            className="form-control "
            defaultValue={audio}
            isInvalid={!!errors?.audio}
            onChange={(e) => {setAudio(e.target.value);handleChange(e)}}
          />
             <Form.Control.Feedback type="invalid">
                {errors?.audio}
           </Form.Control.Feedback>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
        <Form.Label className="required" column sm="3">
          Image
        </Form.Label>
        <label id="labimg">
      {cropimg?<img 
        // crossOrigin="anonymous" 
      className="imgs" 
      src={cropimg}
      onClick={()=>setCropImg()}
      width="100%" alt="" />:<img 
      className="imgs" 
      // src={file ? file : upimg}
      src={selectedId ?img  :file1 ?file1 : upimg}

      width="100%" alt="" />}

      <Form.Control
        // type="file"
        name="file"
        isInvalid={!!errors?.file}
        className="form-control "
        onClick={(e)=>{setShowCrop(true);handleChange(e)}}
        size="40"
        accept="image/png, image/gif, image/jpeg"
        id="inputds"
      />
       <Form.Control.Feedback type="invalid">
            {errors?.file}
           </Form.Control.Feedback>
        </label>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
  {loader?<Button variant='primary' className='as_btn' >
        {`${selectedId ? 'Processing...' : 'Processing...'}`}
      </Button>:<Button variant='primary' className='as_btn' onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
      {`${selectedId ? 'Edit' : 'Add'}`}
    </Button>}
    <Button variant="secondary"
    className="as_btn" onClick={()=>{handleClose(); setCropImg()}}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

)
}
  return (
    <div>
      <>
        <div className="main aarti_section">
          <div className="gita_quote_heading">
            <div className="container d-flex align-items-center justify-content-between">
              <h4 className="hd-list-f left-q"> Guru <small>({allDataCount})</small></h4>
              <div className="_btn">
                <Button
                  variant="primary"
                  className="by-btn as_btn"
                  onClick={()=>updateFile(true)}
                >
                  Update File
                </Button>
                <Button
                  className="by-btn as_btn me-3"
                  variant="primary"
                  onClick={handleShow}
                >
                  Add Guru
                </Button>
              </div>
            </div>
          </div>

          <div className="aarti_inner pt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-3">
                  <Sidebar />
                </div>
                <div className="col-md-8 col-lg-9">
                  {loading ? (
                    <div className="center">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        {data?.map((item) => (
                          <div className="col-md-6 col-lg-4">
                            <div className="card mb-4" key={item?._id}>
                              <div className="card-header">
                                {item.video ? (
                                  <iframe
                                    title="astrowatch-content"
                                    width="560"
                                    height="240"
                                    className="w-100"
                                    src={item?.video}
                                    type="video/mp4"
                                    autoPlay
                                    controls
                                  ></iframe>
                                ) : (
                                  <img
                                    
                                    src={
                                      item?.file
                                        ? item?.file
                                        : bimg
                                    }
                                    className="card-img-top"
                                    alt="img"
                                  />
                                )}
                                <div className="icon">
                                  <FaEdit
                                    className="me-2 hd-cr"
                                    size={20}
                                    onClick={(e) => {
                                      handleShow(item);
                                    }}
                                  />
                                  <FaTrash
                                    className="dt-btn hd-cr"
                                    size={20}
                                    onClick={(e) => {
                                      handleShowDt(item._id);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="card-body">
                                <p className="cal-dat description font-500 mb-2">
                                  <span className="calicon">
                                    <i className="ri-calendar-line"></i>
                                  </span>
                                  {new Date(item.updatedAt).toDateString()}
                                </p>

                                <h5 className="card-title heading_18 mb-2">
                                  {item.title}
                                </h5>
                                <p className="card-text description">
                                  {item.contentHi}
                                </p>
                              </div>
                              <div class="card-footer text-center">
                                <Button
                                  className="btn-more as_btn"
                                  variant="primary"
                                  onClick={(e) => {
                                    handleShowMore(item);
                                  }}
                                >
                                  See more
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                    <div className="pagination mt-5 me-0">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel="Next>"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          // pageCount={pageCount}
                          pageCount={Math.ceil(allDataCount / perPage)}
                          previousLabel="< Previous"
                          renderOnZeroPageCount={null}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={offset/perPage}
                        />
                      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showMore} backdrop="static" onHide={handleClose_m}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{content}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" 
            className="as_btn"onClick={handleClose_m}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {renderFormModal()}
        <Modal
          show={showdt}
          size="sm"
          aria-labelledby="example-modal-sizes-title-sm"
          backdrop="static"
          onHide={handleCloseDt}
        >
          <Modal.Header className="modal-header-hd">
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="cancle_btn"
              onClick={handleCloseDt}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="success_btn"
              onClick={handledelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
        <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/>
      </>
    </div>
  );
};

export default AstroGuru;
