import React, { useEffect, useState, useRef } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import APIService from '../utils/APIGeneralService'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { PencilSquare, Trash3Fill, NodePlusFill, PlusCircleFill } from 'react-bootstrap-icons'
import { handleDuplicateEvents } from '../utils/Helper'
import { useNavigate } from 'react-router-dom'

function Calender() {
    const [feedEvents, setFeedEvents] = useState([])
    let navigate = useNavigate()
    const allevent = useRef([])
    const [indication, setIndication] = useState(false)

    const APIServices = new APIService(process.env.REACT_APP_CALENDAR_API_URL)

    //auth variables
    const calendarId = process.env.REACT_APP_CALENDAR_ID
    const api_key = process.env.REACT_APP_CALENDAR_API_KEY

    let todayStr = (date) => new Date(date).toISOString().replace(/T.*$/, '') // YYYY-MM-DD

    const fetchAllEvents = async () => {
        let response = await APIServices.get('/calendar/event/getAllEvents')
        if (response.data.code === 200) {
            response = response.data.results
            let finalObj = response.map(item => {
                const { Event_id, Title, endDate, startDate, Description, CreatedBy } = item
                return { id: Event_id, title: Title, color: '#6789f9', start: todayStr(startDate), endDate: todayStr(endDate), description: Description, flag: CreatedBy }
            })
            const newevents = handleDuplicateEvents(allevent.current, finalObj)
            setFeedEvents(newevents)
        }
    }

    const navigateToEvents = (event = null, mode = 'add', date = null) => {
        let newevent
        if (event != null) {
            newevent = { id: event.id, title: event.title, startDate: event.startStr, endDate: event.endStr }
        }
        navigate('/calender-event/event', { state: { event: newevent, startDate: date?.startDate, endDate: date?.endDate, mode } })
    }

    const navigateToContent = (event) => {
        navigate('/calender-event/content', { state: { eventid: event.id } })
    }

    const handleDelete = async (jsevent, eventInfo) => {
        jsevent.preventDefault()
        const response = await APIServices.delete(`/calendar/event/deleteEvent/${eventInfo.event.id}`)
        if (response.data.code === 200) {
            eventInfo.event.remove()
        }
    }

    const handleEdit = (jsevent, eventInfo) => {
        jsevent.preventDefault()
        navigateToEvents(eventInfo.event, "edit")
    }

    const handleContents = (jsevent, event) => {
        jsevent.preventDefault()
        navigateToContent(event)
    }



    const handleAdd = (jsevent, event) => {
        jsevent.preventDefault()
        navigateToEvents(event, 'add')
    }

    const onSelect = (event) => {
        const date = { startDate: event.startStr, endDate: event.endStr }
        navigateToEvents(null, 'new', date)
    }


    const renderEventContent = (eventInfo) => {
        const flag = eventInfo.event.extendedProps.flag
        return (
            <div className='w-100 h-100'>
                <b>{eventInfo.timeText}</b>
                <div className='text-white my-2 mx-1 d-flex justify-content-between'>
                    <span className='text-capitalize fw-bold w-75 text-wrap'>{eventInfo.event.title}</span>
                    <div className='d-flex justify-content-end gap-2 w-100 p-1'>
                        {
                            flag === 'custom' ? <>
                            <PencilSquare className='text-success bg-white pointerUtilityClass' title='Edit Event' size={15} onClick={(jsevent) => handleEdit(jsevent, eventInfo)} />
                            <NodePlusFill title='Content Management Tool' className='pointerUtilityClass text-warning' size={16} onClick={(jsevent) => handleContents(jsevent, eventInfo.event)} />
                            <Trash3Fill title='Delete Event' className='text-danger bg-white pointerUtilityClass' size={15} onClick={(jsevent) => handleDelete(jsevent, eventInfo)} />
                            </>
                                :
                                <PlusCircleFill title='Add' className='text-white pointerUtilityClass rounded' size={15} onClick={(jsevent) => handleAdd(jsevent, eventInfo.event)} />
                        }
                    </div>
                </div>
            </div>
        )
    }

    const eventFetched = (events) => {
        allevent.current = []
        allevent.current = events
        if (events.length)
            setIndication(true)
    }


    useEffect(() => {
        if (indication && allevent.current.length)
            fetchAllEvents()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indication])

    return (
        <div className='demo-app position-relative' >
            <div className='demo-app-main'>
                <ul className='mb-3 position-sticky top-0 left-0 bg-white z_Index'>
                    <li className='fw-bold'>Icon (<NodePlusFill className='pointerUtilityClass text-warning mx-1' size={20} />) is used for Content Management of an event</li>
                </ul>
                <FullCalendar
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin]}
                    googleCalendarApiKey={api_key}
                    eventSources={[
                        {
                            googleCalendarId: calendarId
                        }
                    ]}
                    eventClick={(event) => {
                        event.jsEvent.preventDefault();
                    }}
                    initialView='dayGridMonth'
                    editable={false}
                    eventSourceSuccess={eventFetched}
                    contentHeight={1000}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    select={onSelect}
                    events={feedEvents}
                    eventContent={renderEventContent}
                />
            </div>
        </div>
    )
}

export default Calender
