import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Card, Container, Form, Button, Row, Col, Tab, Nav, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies } from '../../utils/Helper';
import bimg from '../../imgs/default.jpg';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

const ChadhawaList = () => {
  document.title = 'Chadhawa List';
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();
  
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount , setAllDataCount] = useState()
  const [flag, setFlag] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [sort, setSort] = useState('');

  const [editData, setEditData] = useState('')
  const [selectedId, setSelectedId] = useState("")
  const [ showdt,setShowdt ] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCloseDt = () => setShowdt(false);
  const handleShowDt = id => {
    setSelectedId(id?._id);
    setShowdt(true);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  useEffect(() => {
    fetchChadhawaList(skip, limit, pageIndex);
  }, [flag]);

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Image English',
      Cell: e => {
        return (
          <img
            src={`${e.row.original.image ? e.row.original.image : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Image Hindi',
      Cell: e => {
        return (
          <img
            src={`${e.row.original.imageHi ? e.row.original.imageHi : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Title English',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },
    {
      Header: 'Title Hindi',
      Cell: e => {
        return <span>{e.row.original.titleHi ?? '_'}</span>;
      },
    },
    {
      Header: 'Sort Order',
      Cell: e => {
        return <span>{e.row.original.sortOrder ?? '_'}</span>;
      },
    },
  
    {
      Header: 'Status',
      Cell: e => {
        if (e.row.original.status === 1) {
          return (
            <h5>
              <input type='button' className='_active' value='Active' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        } else {
          return (
            <h5>
              <input type='button' className='_inactive' value='Inactive' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        }
      },
    },
    {
      Header: 'Created At',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('lll') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: 'Action',
      Cell: e => {
        if (e.row.original._id) {
          
          return (
            <>
            <div className='d-flex'>
                <Button type="button" className='Et-btn edit-btn1' onClick={()=>{getEditaData(e.row.original)}}>Edit</Button>
                <Button type="button" className='ms-2 Dt-btn' onClick={() => { handleShowDt(e.row.original)}}>Delete</Button>
            </div>
            </>
          );
        }
      },
    },
    
   
   
  ]);

  const fetchChadhawaList = useCallback(
    async (skip, limit, index) => {
      setLoading(true)
      try {
           const response = await APIServices.get(`chadhawaSamgri/getAll/${skip}/${limit}?status=${status}&title=${title}&sortDirection=${sort}`,headers);
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setLoading(false)
          setAllDataCount(response.data?.totalCount)
          setTableData(response.data?.Listdata);
          setFlag(false)
          window.scrollTo({ top: 0 });
        }else{
          if(response?.response?.data?.status == 4){
            logout()
           }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, title, status, sort]
  );

  const getEditaData = async (data) => {
    navigate(`/chadhawaAddEdit?editDataId=${data?._id}`);
  };

  const handleDelete = async () => {
    const response = await APIServices.delete(`chadhawaSamgri/findByIdAndremove/${selectedId}`, headers);
    if (response?.data?.status === true) {
      let newData = tableData.filter(item => item._id !== selectedId);
      setTableData(newData);
      toast.success(response?.data.message);
      setShowdt(false);
      handleCloseDt()
      fetchChadhawaList()
      
    } else {
      console.log("Something went Wrong")
    }
  };

  const handleStatusUpdate = async item => {
    const formData = new FormData();
    var datas = item?.status === 1 ? 2 : 1;
    let id = item?._id;
    formData.append('status', datas);

   const res = await APIServices.put(`chadhawaSamgri/updateStatus/${id}`, formData, headers)
   if(res?.data?.status === true){
     fetchChadhawaList();
   }
   else{
     console.log("error..")
   }
  };

  const handleFilterClick = () => {
    if (title || status || sort) {
      fetchChadhawaList(skip, limit, 0, title, status, sort);
    }
  };

  const handleReset = () => {
    setTitle('');
    setStatus('');
    setSort('');
    setFlag(true)
  };

  return (
    <div>
      <div className='App'>
        <div className='gita_quote_heading'>
          <div className='container'>
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q '>
                {' '}
                Chadhawa List <small>({allDataCount})</small>
              </h4>
            </div>
          </div>
        </div>

        <Container className='' fluid>
          <div class='feed_area box-shadow my-3 p-20 br-6'>
            <div class='chat_search_box py-0'>
              <div className='chat-search-field'>
                <Form>
                  <Form.Group as={Row} className='mb-2 align-items-end' controlId=''>
                    <Col md={3}>
                      <Form.Control
                        className='form-ch'
                        name='name'
                        type='search'
                        placeholder='Title'
                        value={title}
                        onChange={e => {
                          setTitle(e.target.value.toLowerCase());
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Select
                        aria-label='categories'
                        className='astroWatch-select-hover hd-cr form-ch'
                        value={status}
                        onChange={e => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option>Select Status</option>
                        <option value='1'>Active</option>
                        <option value='2'>Inactive</option>
                      </Form.Select>
                    </Col>

                    <Col md={3}>
                      <Form.Select
                        aria-label='categories'
                        className='  astroWatch-select-hover hd-cr form-ch'
                        value={sort}
                        onChange={e => {
                          setSort(e.target.value);
                        }}
                      >
                        <option>Sort</option>
                        <option value='asc'>Order by Ascending</option>
                        <option value='desc'>Order by Descending</option>
                      </Form.Select>
                    </Col>

                    <Col md={3} className=''>
                      <Button
                        variant='secondary'
                        className='as_btn'
                        onClick={() => {
                          handleFilterClick();
                        }}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='secondary'
                        className='as_btn'
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        Reset
                      </Button>
                      <Button variant='primary' className='as_btn' onClick={() => navigate('/chadhawaAddEdit')}>
                        Add +
                      </Button>
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
          {/* {loading ? "Loading..." : 
            <> */}
                {tableData && tableData.length > 0 ? (
                  <DynamicDataTableWithCount
                    columns={columns}
                    data={tableData}
                    totalCount={pageCount}
                    initialState={{
                      pageIndex: pageIndex,
                      pageSize: limit,
                    }}
                    fetchData={fetchChadhawaList}
                    forcePage={skip / limit}
                  />
                ) : (
                  <h2 className='no_records'>Records not Available</h2>
                )}
              {/* </>
          } */}
        </Container>
        <Modal
          show={showdt}
          size='sm'
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={handleCloseDt}
        >
          <Modal.Header className='modal-header-hd'>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
              Cancel
            </Button>
            <Button variant='primary' className='success_btn' onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ChadhawaList;
