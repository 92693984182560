import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { capitalizeFirstLetter, fetchImageAsFile } from '../../utils/Helper';
import DefaultImage from '../../imgs/default.jpg';
import Imagecrop from './imagecrop';

const initialData = {
  godInfo: null,
  godImage: null,
};

const GodImagesForm = ({ show, selectedItem, handleClose, onSubmit, godList,loader }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    trigger,
    setValue,
    clearErrors,
  } = useForm();

  const [godImagePreview, setGodImagePreview] = useState(DefaultImage);

  // const watchGodImage = watch('godImage');

  useEffect(() => {
    if (selectedItem) {
      setFormData(selectedItem);
    }
  }, [selectedItem, setValue]);

  // useEffect(() => {
  //   if (watchGodImage) {
  //     const file = watchGodImage?.[0];
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onload = () => setGodImagePreview(reader.result);
  //       reader.readAsDataURL(file);
  //     }
  //   }
  // }, [watchGodImage]);

  const setFormData =  selectedData => {
    const formData = {
      godInfo: { value: selectedData?.godId, label: capitalizeFirstLetter(selectedData?.godName) },
    };
    reset(formData); // Set default form values based on selectedData
    const gImage = selectedData?.image;
    setValue('godImage', gImage);
    setGodImagePreview(selectedData?.image );
  };

  const handleModalClose = () => {
    reset(initialData);
    handleClose();
    setGodImagePreview(null);
    clearErrors();
  };

// /////////////////////////////////////////////////////////////////


  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [cfileimage,setFileCimage]=useState()


useEffect((e)=>{
  if(cfileimage){
    setValue('godImage', cfileimage )
    trigger()
  }
},[cropimg,cfileimage])


// /////////////////////////////////////////////////////////////////

  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='example-modal-sizes-title-sm'
        onHide={handleModalClose}
        className='god_image_modal'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className='modal-header' closeButton>
            <Modal.Title>{selectedItem ? 'Edit' : 'Add'} Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='login_form'>
              <div className='row'>
              {selectedItem?.godName?"":<div className='col-lg-6'>
              <div className='form-group'>
                <label className='required'>Select God</label>
                <Controller
                  name='godInfo'
                  control={control}
                  rules={{ required: { value: true, message: 'God Selection is required' } }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={godList.map(d => ({
                        value: d?._id,
                        label: d?.godName ? capitalizeFirstLetter(d?.godName) : 'No Selection',
                      }))}
                    />
                  )}
                />
                {errors.godInfo && <p className='text-danger'>{errors.godInfo?.message}</p>}
              </div>
            </div>}
            {selectedItem?.godName?"": 
                <hr />}
                <div className='col-lg-12 pb-3'>
                  <div className='form-group'>
                    <label className='preview-image-input'>
                      God Image <span className='redstar'>*</span>
                      <div className='as_input_feild mt-2'>
                        <div className='image-preview'>
                         {cropimg?<img src={cropimg} onClick={(e)=> setShowCrop(true) } alt='' />:<img src={godImagePreview} alt='' />} 
                        </div>
                        <Controller
                          name='godImage'
                          control={control}
                          rules={{ required: { value: true, message: 'God Image is required' } }}
                          // defaultValue={godImagePreview}
                          render={({ field }) => (
                            <input
                              // type='file'
                              accept="image/png, image/gif, image/jpeg"
                              id="inputds"
                              onClick={(e)=>setShowCrop(true)}
                            />
                          )}
                        />
                      </div>
                    </label>
                    {errors.godImage && <p className='text-danger'>{errors.godImage?.message}</p>}
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
            {loader?<Button  variant='primary' className='as_btn '>
            {selectedItem ? 'Processing...' : 'Processing...'} 
          </Button>:<Button type='submit' variant='primary' className='as_btn '>
          {selectedItem ? 'Edit' : 'Add'} 
        </Button>}
              
              <Button variant='secondary' className='as_btn' onClick={handleModalClose}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <Imagecrop 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    setFileCimage={setFileCimage}
    cfileimage={cfileimage}/>
    </>
  );
};

export default GodImagesForm;
