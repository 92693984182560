import { useEffect, useState ,useContext } from "react";
import { Button, Modal, Row ,Form } from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/banner_background.png";
import ModalComponent from "../components/Shared/ModalComponent";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import { getBaseURL ,setCookies} from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

export default function AstrologyAdvice() {
  document.title = 'Astrology Advice';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false)
  const [ type , setType] = useState("english")
  const [allData , setAllData] = useState([])
  const [data, setData] = useState();
  const[loader,setLoader]=useState(false)
  const initialData = {
    title: "",
    content:"",
    file: "",
    imageType:"",
    audio:""
  }
  const [newData, setNewData] = useState(initialData);
  const [allDataCount , setAllDataCount] = useState()
  // ////////  crop modal/////////
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()
  // //////////////


  useEffect(() => {
    fetchData();
  }, [offset ,type]);

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`astrologyAdvice/getAll/${type}/${offset}/${perPage}`, headers)
    let data = response?.response?.data
    
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
       
        setData(apiData.Listdata)
        setAllDataCount(apiData.totalCount)
      
        setLoading(false)
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  }


  async function updateFile(showToast) {
    let res = await APIServices.get(`astrologyAdvice/updateFile`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
        toast.success(apiData?.message);
        }
        fetchData();
      }
    }
  }
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage)
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset)
  }
  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (newData.title === "") 
      newErrors.title = "Please enter title.";

      if (newData.content === "") 
      newErrors.content = "Please enter content.";

      if (newData.file === "") 
      newErrors.file = "Please add image.";

      if (newData?.imageType === "")
      newErrors.imageType = "Please select content type.";

      // if(newData?.songUrl === '')
      // newErrors.songUrl = "Please add Song URL.";

      // if(newData?.description === '')
      // newErrors.description = "Please enter description.";

      return newErrors;
  };  
  async function addAstrologyAdvice() {
    let payload = new FormData();
    payload.append('title', newData?.title);
    payload.append('file', newData.file);
    payload.append('description', newData?.content);

    if (newData.imageType === "english") {
      payload.append("contentEn", true);
    } else if (newData.imageType === "hindi") {
      payload.append("contentHi", true);
    }
    payload.append('audio', newData.audio);

    payload.append("user_id", astroUserId)
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
    let response = await APIServices.post(`astrologyAdvice/upload`, payload, { ...headers, "Content-Type": "multipart/form-data" })
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        setShowModal(false);
        toast.success(apiData?.message);
        setLoader(false)
        updateFile();
      }else if(apiData?.status === false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }

  async function updateAstrologyAdvice() {
    let payload = new FormData();

    if (typeof newData?.file === 'object') {
      payload.append('file', newData?.file);
    }
    if (typeof newData?.audio === 'object') {
      payload.append('audio', newData?.audio);
    }

    payload.append('description', newData?.content);
    payload.append('title', newData?.title);
    if (newData.imageType === "english") {
      payload.append("contentEn", true);
      payload.append("contentHi", false);
    

    } else if (newData.imageType === "hindi") {
      payload.append("contentHi", true);
    payload.append("contentEn", false);
   
    }
    payload.append("user_id", astroUserId)
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
    let response = await APIServices.put(`astrologyAdvice/editOne/${newData?._id}`, payload, { ...headers, "Content-Type": "multipart/form-data" })
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        setShowModal(false);
        toast.success(apiData?.message);
        setLoader(false)
        updateFile();
      }else if(apiData?.status === false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }
  

  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }
  const handledelete = async () => {
    let res = await APIServices.delete(`astrologyAdvice/findByIdAndremove/${newData?._id}`, headers)
    if (res?.status === 200) {
      setShowDt(false)
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        updateFile()
      }
    }
  }

  function handleChange(e, field = "text") {
    let key, value;
    key = e?.target?.name;
    if (field === "text") {
      value = e?.target?.value;
    }
    else if (field === "file") {
      value = e?.target?.files?.[0];
    }else if (field === "audio"){
      value = e?.target?.files?.[0];
    }
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
    setNewData(prev => ({ ...prev, [key]: value }))
  }

  function handleImage(e){
    setNewData(prev => ({...prev, file: e}))
  }
  function handleRt (e){
    let key = e?.target?.name;
    if (!!errors[key])
        setErrors({
          ...errors,
          [key]: null,
        });
    }

  return (
    <>
      <div className="main aarti_section">
        <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Astrology Advice <small>({allDataCount})</small></h4>
           <div className="_btn">
           <Button
                variant="primary"
                className="by-btn as_btn"
                onClick={()=>updateFile(true)}
              >
                Update File
              </Button>
              <Button
               className="by-btn as_btn me-0"
                variant="primary"
                onClick={() => {
                  setNewData(initialData);
                  setShowModal(true);
                  setCropImg()
                }}
              >
                Add Advice
              </Button>
           </div>
           </div>
        </div>
        <div className="aarti_inner pt-5">
           <div className="container">
              <div className="row">
              <div className="col-md-4 col-lg-3"> 
                   <Sidebar />
                 </div>
                 <div className="col-md-8 col-lg-9"> 
                 {loading ? <div className="center"><Spinner animation="border" /></div> :
          <>
          <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                            value={type}
                            onChange={(e) => {setType(e.target.value); setOffset(0)}}
                          >
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>

                          </Form.Select>
                        </div>
                      </div>
                    </div>

            
            <div className="row mt-3">
            
              {
                data?.map((item) =>
                <div className="col-md-6 col-lg-4">
                  <div className="card mb-4" key={item?._id} >
                    <div className="card-header">
                    <img
                        
                        src={(item?.file) ? item?.file : bimg}
                        className="card-img-top"
                        alt="img"
                      />
                      <div className="icon">
                        <FaEdit
                          className="me-2 hd-cr "
                          size={20}
                          onClick={() => {
                            setShowModal(true);
                            setCropImg()
                            setNewData({
                              _id : item._id ,
                              title: item.title ?? "",
                              content:item.description,
                              file: item.file ?? "",
                              imageType: item?.contentHi ? "hindi" :item?.contentEn ? "english":"" ,
                              audio: item.audio ?? ""
                            });
                          }}
                        />
                        <FaTrash
                          className="dt-btn hd-cr "
                          size={20}
                          onClick={e => {
                            setNewData(item);
                            handleShowDt()
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-body height_box" >
                    <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2">
                        <span className="calicon">
                          <i className="ri-calendar-line"></i>
                        </span>
                        {new Date(item.updatedAt).toDateString()}
                      </p>
                      <h5 className="card-title heading_18 mb-2">{item.title}</h5>
                      <p className="card-text description">
                      {item.description}
                      </p>
                    </div>
                    {/* <div class="card-footer text-center">
                        <Button
                          className="btn-more as_btn"
                          variant="primary"
                          onClick={() => {
                            setNewData(item);
                            setShow(true);
                          }}
                        >
                          see more
                        </Button>
                    </div> */}
                  </div>
                  </div>

                )
              }
           
            </div>
           
          </>
        }

<div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
                 </div>
              </div>
           </div>
        </div>
      </div>
      
      <ModalComponent
        id="updateAarti"
        show={showModal}
        onHide={() => {setShowModal(false);setErrors("")}}
        modalHeading={(newData?._id) ? "Edit Advice" : "Add Advice"}
        size="lg"
        localData={newData}
        errors = {errors}
        loader={loader}
        onButtonClick={(newData?._id) ? updateAstrologyAdvice : addAstrologyAdvice}
        handleChange={handleChange}
        handleImage={handleImage}
        handleRt={handleRt}
        setShowCrop={setShowCrop}
        caartifileimage={caartifileimage}
        setFileCaartiimage={setFileCaartiimage}
        cropimg={cropimg}
         setCropImg={setCropImg}
         showcrop={showcrop}
      />

      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt} 
        >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this element?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  )
}