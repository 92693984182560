import { useEffect, useState,useContext} from "react";
import { Button, Modal , Form } from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
//import SweetAlert from "react-bootstrap-sweetalert";
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/banner_background.png";
import ModalComponent from "../components/Shared/ModalComponent";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import { getBaseURL,setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

export default function Bhajan() {
  document.title = 'Bhajan';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(6);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showdt, setShowDt] = useState(false)
  const [ type , setType] = useState("english")
  const [allData , setAllData] = useState([])
  const [data, setData] = useState();
  const [progress ,setProgress] = useState(0)
  const [loader,setLoader]=useState(false)
  const initialContent = { subTitle: "", subContent: "" };
  const initialData = {
    title: "",
    content: [initialContent],
    file: "",
    imageType:"",
    description:"",
    audio:"",
    songUrl:"",
  }
  const [newData, setNewData] = useState(initialData);
  const [allDataCount , setAllDataCount] = useState()

  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()
  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  useEffect(() => {
    fetchData();
  }, [offset ,type]);

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`astroBhajan/getAll/${type}/${offset}/${perPage}`, headers)
    let data = response?.response?.data
  
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData?.Listdata)
        setAllDataCount(apiData.totalCount)
        setLoading(false)
      }
    }else{
      if(data?.status == 4){
        logout()
       }
    }
  }



  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage)
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset)
  }

  async function updateFile(showToast) {
    let res = await APIServices.get(`astroBhajan/updateFile`, headers)
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        if (showToast) {
          toast.success(apiData?.message);
        }
        fetchData();
      }
    }
  }
  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};

    const inputFieldsErrors = [];

    let isLocalCantentValid=false;
    newData.content.forEach((field, index) => {
      let errorMsgs = [];
      let defaultObj={
        subTitle:"",
        subContent:""
      }

      if(field["subTitle"] === "") {
        isLocalCantentValid=true;
        let errMsg = `Please add sub title.`;
        defaultObj.subTitle=errMsg
      }

      if(field["subContent"] === "") {
        isLocalCantentValid=true;
        let errMsg = `Please add sub content.`;
        defaultObj.subContent=errMsg
      }
      inputFieldsErrors.push(defaultObj)
         });

    if (newData.title === "") 
      newErrors.title = "Please enter title.";
    if (
      isLocalCantentValid
      ) 
      newErrors.content = inputFieldsErrors
     if (newData.file === "") 
      newErrors.file = "Please add image.";

     if (newData?.imageType === "") 
      newErrors.imageType = "Please select content type.";

     if(newData?.songUrl === '')
      newErrors.songUrl = "Please add Song URL.";

     if(newData?.description === '')
      newErrors.description = "Please enter description.";

    return newErrors;
  };
  async function addBhajan() {
    let payload = new FormData();
    payload.append('title', newData?.title);
    payload.append('file', newData.file);
    if (typeof newData?.audio === 'object') {
      payload.append('audio', newData?.audio);
    }
    if (newData.imageType === "english") {
      payload.append("contentLangEn", true);
      payload.append('contentEn', JSON.stringify(newData?.content));

    } else if (newData.imageType === "hindi") {
      payload.append("contentLangHi", true);
     payload.append('contentHi', JSON.stringify(newData?.content));

    } 
    payload.append('description', newData.description);
    payload.append('songUrl', newData.songUrl);
    payload.append("user_id", astroUserId)
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else{
      let response = await APIServices.post(`astroBhajan/upload`, payload, { ...headers, "Content-Type": "multipart/form-data" })
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowModal(false);
          setLoader(false)
          toast.success(apiData?.message);
        updateFile();
        setCropImg();
      }else if(apiData?.status ===  false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }

  async function updateBhajan() {
    let payload = new FormData();
    if (typeof newData?.file === 'object') {
      payload.append('file', newData?.file);
    }
    if (typeof newData?.audio === 'object') {
      payload.append('audio', newData?.audio);
    }
    payload.append('title', newData?.title);
    if (newData.imageType === "english") {
      payload.append("contentLangEn", true);
      payload.append('contentEn', JSON.stringify(newData?.content));
      payload.append("contentLangHi", false);
      payload.append('contentHi', null);
    } else if (newData.imageType === "hindi") {
      payload.append("contentLangHi", true);
      payload.append('contentHi', JSON.stringify(newData?.content));
      payload.append("contentLangEn", false);
      payload.append('contentEn',null);
    }
    payload.append('description', newData.description);
    payload.append('songUrl', newData.songUrl);
    payload.append("user_id", astroUserId)

    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    }else {
    let response = await APIServices.put(`astroBhajan/editOne/${newData?._id}`, payload, { ...headers, "Content-Type": "multipart/form-data" },(progressEvent) => {
       const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
       console.log("Upload progress:", percentCompleted + "%");
       setProgress(percentCompleted)
   })
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        toast.success(apiData?.message);
        setShowModal(false);
        setLoader(false)
        updateFile();
        setCropImg();
      }else if(apiData?.status ===  false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
    setLoader(false)
  }
  }

  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }
  const handledelete = async () => {
    let res = await APIServices.delete(`astroBhajan/findByIdAndremove/${newData?._id}`, headers)
    if (res?.status === 200) {
      let apiData = res?.data;
      setShowDt(false)
      setOffset(0)
      if (apiData?.status) {
        toast.success(apiData?.message);
        updateFile()
      }
    }
  }
  function handleChange(e, field = "text", i = null) {
    let key, value;
    key = e?.target?.name;
    if (field === "text") {
      value = e?.target?.value;
    }
    else if (field === "file") {
      value = e?.target?.files?.[0];
    }
    else if (field === "audio"){
      value = e?.target?.files?.[0];
    }
  
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
    if (key === "subTitle" || key === "subContent") {
      errors?.content?.forEach((field, index) => {
          if (errors.content[index][key]!=null || errors.content[index][key]!="") {
            errors.content[index][key] = null
          }
      })
    }

    if (i === null) {
      setNewData(prev => ({ ...prev, [key]: value }))
    }
    else {
      setNewData(prev => {
        let newContent = [...prev?.content];
        newContent[i] = { ...newContent[i], [key]: value }
        return ({ ...prev, content: newContent })
      });
    }
  }

  function handleImage(e){
    setNewData(prev => ({...prev, file: e}))  
  }


function handleRt (e){
let key = e?.target?.name;
if (!!errors[key])
    setErrors({
      ...errors,
      [key]: null,
    });
}


  function addSection() {
    setNewData(pre => {
      let newArr = [...pre?.content];
      newArr.push({ ...initialContent});
      return ({ ...pre, content: newArr });
    })
  }

  function removeSection(index) {
    setNewData(pre => {
      let newArr = pre?.content.filter((e, i) => (i !== index) && e);
      return ({ ...pre, content: newArr });
    })
  }

  function onButtonClick(buttonType, i = 0) {
    if (buttonType === "Add") {
      addBhajan();
      

    }
    else if (buttonType === "Edit") {
      updateBhajan();
     

    }
    else if (buttonType === "addSection") {
      addSection();
    }
    else if (buttonType === "removeSection") {
      removeSection(i);
    }
  }

  return (
    <>
      <div className="main aarti_section">

      <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Bhajan <small>({allDataCount})</small></h4>
           <div className="_btn">
                  <Button
                  variant="primary"
                  className="by-btn as_btn"
                  onClick={()=>updateFile(true)}
                >
                  Update File
                </Button>
                <Button
                className="by-btn as_btn me-0"
                  variant="primary"
                  onClick={() => {
                    setNewData(initialData);
                    setShowModal(true);
                    setCropImg()
                  }}
                >
                  Add Bhajan
                </Button>
           </div>
           </div>
        </div>
      

        <div className="aarti_inner pt-5">
           <div className="container">
              <div className="row">
              <div className="col-md-4 col-lg-3"> 
                 <Sidebar />
              </div>
              <div className="col-md-8 col-lg-9"> 
                {loading ? <div className="center"><Spinner animation="border" /></div> :
                <>
                  <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                            value={type}
                            onChange={(e) => {setType(e.target.value); setOffset(0)}}
                          >
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>

                          </Form.Select>
                        </div>
                      </div>
                    </div>

                
                  <div className="row mt-3">
                    {
                      data?.map((item) =>
                      <div className="col-md-6 col-lg-4">
                        <div className="card  mb-4" key={item?._id} >

                          <div className="card-header">
                          <img
                              
                              src={(item?.file) ? item?.file : bimg}
                              className="card-img-top"
                              alt="img"
                            />
                             <div className="icon" >
                              <FaEdit
                                className="me-2 hd-cr"
                                size={20}
                                onClick={() => {
                                  setShowModal(true);
                                  setCropImg()
                                  setNewData({
                                    _id: item._id,
                                    title: item.title ?? "",
                                    content:item.contentLangEn === true?  item.contentEn : item.contentHi ?? initialData,
                                    file: item.file ?? "",
                                    imageType: item?.contentLangHi ? "hindi" :item?.contentLangEn ? "english":"" ,
                                    description:item?.description ?? "",
                                    audio: item.audio ?? "",
                                    songUrl: item.songUrl ?? "",
                                  })
                                }}
                              />
                              <FaTrash
                                className="dt-btn hd-cr"
                                size={20}
                                onClick={e => {
                                  setNewData(item);
                                  handleShowDt()
                                }}
                              />
                            </div>
                          </div>
                          <div className="card-body height_box" >

                          <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2" >
                              <span className="calicon">
                                <i className="ri-calendar-line"></i>
                              </span>
                              {new Date(item.updatedAt).toDateString()}
                            </p>
                           
                            <h5 className="card-title heading_18 mb-2">{item.title}</h5>
                            <p className="card-text description" >
                              { item.contentLangEn === true ?<>
                              <p>{item.contentEn?.[0]?.subTitle}</p>
                              <p>{item.contentEn?.[0]?.subContent?.slice(0, 180)}</p></>
                            :<>
                            <p>{item.contentHi?.[0]?.subTitle}</p>
                            <p>{item.contentHi?.[0]?.subContent?.slice(0, 180)}</p></>
                            
                            }
                            </p>
                         

                          </div>
                          <div class="card-footer text-center">
                             <Button
                                className="btn-more as_btn"
                                variant="primary"
                                onClick={() => {
                                  setNewData(item);
                                  setShow(true);
                                }}
                              >
                                see more
                              </Button>
                          </div>
                        </div>
                        </div>
                      )
                    }
                  </div>
                 
                  </>
                }
                 <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="Next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={Math.ceil(allDataCount / perPage)}
                      // pageCount={pageCount}
                      previousLabel="< Previous"
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                      forcePage={offset/perPage}

                    />
                  </div>
                </div>
        </div>
        </div>
        </div>
      </div>

      <ModalComponent
        id="chalisaReadMore"
        show={show}
        onHide={() => setShow(false)}
        modalHeading={newData?.title}
        size="lg"
        localData={ newData.contentLangEn === true? newData?.contentEn : newData?.contentHi}
      />

      <ModalComponent
        id="updateChalisa"
        show={showModal}
        onHide={() => {setShowModal(false);setErrors("")}}
        modalHeading={(newData?._id) ? "Edit Bhajan" : "Add Bhajan"}
        size="lg"
        localData={newData}
        errors = {errors}
        loader={loader}
        onButtonClick={onButtonClick}
        handleChange={handleChange}
        handleImage={handleImage}
        progress ={progress}
        handleRt={handleRt}
        setShowCrop={setShowCrop}
        caartifileimage={caartifileimage}
        setFileCaartiimage={setFileCaartiimage}
        cropimg={cropimg}
         setCropImg={setCropImg}
         showcrop={showcrop}
      />
      {/* 
      {showSweetAlert &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Delete, delete it!"
          confirmBtnBsStyle="danger"
          title="Are you sure?"
          onConfirm={e => deleteMantra(true)}
          onCancel={e => setShowSweetAlert(false)}
          focusCancelBtn
        >
          You will not be able to recover this data!
        </SweetAlert>
      } */}

      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt}>
         <Modal.Header className="modal-header-hd" >
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this element?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  )
}