import React from "react";
import { Row, Col, Card, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const Blogs = () => {
    const navigate = useNavigate()
    const { state: { data = {}, Date = '' } } = useLocation();
    return (
        <div class="blog_details">
            <div className="gita_quote_heading">
                <div className="container">
                <FaArrowLeft
                 className="hd-cr "
                 style={{marginRight: "15px"}}
                 size={25}
                 onClick={() => navigate(-1)}/>
                </div>
            </div>
           <div className="container">
                <Row className="pt-3 m-0 d-flex flex-column">
                  
                    <Col md={10} className="mx-auto">
                        <Card className="p-2 m-2">
                            <div className="card-header p-0">
                              <img src={data.blogImage ? data.blogImage : '/images/default.jpg'}  className="w-100 blog_height"/>
                            </div>
                            <p className="description font-500 mb-2 text-start mt-2">{Date && Date}</p>
                            <h4 className="card-title heading_18 mb-2 text-start">{data?.title}</h4>
                            <p className=" description " dangerouslySetInnerHTML={{ __html: data?.blogContent}}></p>
                        </Card>
                    </Col>
                </Row>
           </div>
        </div>
        
    );
}


export default Blogs;
