
import React, { useEffect, useState ,useContext} from "react";
import { Button, Modal , Form , Col} from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import APIServicenew from "../../utils/APIGeneralService";
import CropModal from "../../components/Shared/CropModal";
import upimg from "../../../src/imgs/default.jpg"
import { setCookies } from "../../utils/Helper";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

const BulkNotificaton = () => {
  document.title = 'Notificaton';
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')
    const [ dataType,setDataType] = useState("")
    const [image, setimage] = useState("");
    const [imageUrl, setImageUrl] = useState('')
    const [validated, setValidated] = useState(false);
    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const APIServices = new APIServicenew(process.env.REACT_APP_BULKNOT_API_URL);
    const APIServices_sec = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
    const headers = { Authorization: `Bearer ${token}` };
    let astroUserId = cookies.get("astroUserId")
    const[loader,setLoader]=useState(false)
    const  {refreshToken} = useContext(UserContext);
    const navigate = useNavigate();
    const logout =() =>{
      refreshToken(null)
      setCookies("accessToken", null);
      navigate("/");
    }

  const reset = () =>{
   setTitle('')
   setMessage('')
   setType('')
   setImageUrl('')
   setimage('')
   setDataType('')
   setLoader(false)
  }


    async function handleCreate(event) {
        const form = event.currentTarget;
        event.preventDefault();
        const formData = new FormData();
        formData.append("title", title)
        formData.append("message", message)
        formData.append("type", type)
        formData.append('typeofcontent', dataType);
        formData.append("id", "")
        if ( image && typeof image === 'object') {
            formData.append('file', image);
          } else {
            formData.append("img_url", imageUrl? imageUrl :"")   
        }

        if (form.checkValidity() === false) {
            event.stopPropagation();
           setValidated(true);
           
        }else{
       
        const newHeaders = {
            ...headers,
            "Content-Type": "multipart/form-data"
           };
           setLoader(true)
            let response = await APIServices_sec.post(`bulkNotification/create` ,formData ,newHeaders)
           let data = response?.data
          
            if (response?.status === 200) {
                let apiData = response?.data;
                if (apiData?.status === true) {
                    // console.log(apiData?.data);
                    toast.success(apiData?.message);
                    reset()
                    setLoader(false)
                    setValidated(false)
                    setCropImg()
                }else{
                  toast.error(apiData?.message);
                  setLoader(false)
                 
                }
            }else{
              if(data?.status == 4){
                logout()
               }
            }
            setLoader(false)
    }
    }

    const [showcrop, setShowCrop] = useState(false);
    const [cropimg, setCropImg] = useState("");
    const [caartifileimage,setFileCaartiimage]=useState()

    useEffect(()=>{
      if(cropimg && caartifileimage){
        setimage(caartifileimage);
      } 
    },[cropimg,caartifileimage])


    return (
        <>
    <div className="notifiction_area" style={{
      backgroundImage: 'url("/images/why_choose_us.jpg")'
        }}>
         <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Add Bulk Notifiction</h4>
           </div>
        </div>
           
           <div className="container">
            <div className="notifiction_form box-shadow br-6 p-20 bg-white mb-3">
            <Form noValidate validated={validated} onSubmit={handleCreate}>

            <Form.Group as={Col} size="md" controlId="validationCustom01" className="mb-3">
            <Form.Label class="required">
                Type Of Content
              </Form.Label>
              
              <Form.Select
               required
              aria-label="categories"
              className=" astroWatch-select-hover"
               value={dataType}
              onChange={(e) => setDataType(e.target.value)}
            >
             <option value="">Choose</option>
              <option value="temple">Temple</option>
              <option value="bhajan_chalisa">Bhajan & Chalisa</option>
              <option value="morning_quotes">Morning Quotes</option>
              <option value="daily_horoscope">Daily Horoscope</option>
              <option value="chankya_quotes">Chankya quotes</option>
              <option value="motivational_quotes">Motivational Quotes</option>
              <option value="live_katha">Live katha</option>
              <option value="puja">Puja</option>
              <option value="bhajan">Bhajan</option>
              <option value="watch_live">Watch Live</option>
              <option value="optional">optional</option>
              <option value="talk_to_astrologer">Talk to Astrologer</option>

            </Form.Select>
            <Form.Control.Feedback type='invalid'>Please select Type Of Content.</Form.Control.Feedback>

            </Form.Group>
                <Form.Group as={Col} size="md" controlId="validationCustom01" className="mb-3">
                    <Form.Label class="required">
                            Title</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            placeholder="Title..."
                            className="form-control "
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    <Form.Control.Feedback type='invalid'>Please enter title.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} size="md" controlId="validationCustom01" className="mb-3">
                    <Form.Label class="required">
                            Message</Form.Label>
                            <Form.Control
                            required
                            type="text"
                            placeholder="Message..."
                            className="form-control "
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    <Form.Control.Feedback type='invalid'>Please enter message.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} size="md" controlId="validationCustom01" className="mb-3">
                    <Form.Label class="required">
                            Type</Form.Label>
                        <Form.Select name="slct" required value={type} onChange={(e) => setType(e.target.value)}>
                            <option value=''>Select Type</option>
                            <option value="all_user_and_astrologers">All_user_and_astrologers</option>
                            <option value="all_users">All_users</option>
                            <option value="all_astrologers">All_astrologers</option>
                            <option value="android_users">Android_users</option>
                            <option value="android_astrologers">Android_astrologers</option>
                            <option value="female_users">Female_users</option>
                            <option value="ios_users">Ios_users</option>
                            <option value="ios_astrologers">Ios_astrologers</option>
                            <option value="male_users">Male_users</option>
                            <option value="transgender_users">Transgender_users</option>
                            <option value="web_users">Web_users</option>
                            <option value="web_astrologers">Web_astrologers</option>
                        </Form.Select>
                        <Form.Control.Feedback type='invalid'>Please select type.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} size="md" controlId="validationCustom01" className="mb-3">
                    <Form.Label class="" >Image Url</Form.Label>
                    <Form.Control
                        
                            type="text"
                            placeholder="Image Url..."
                            className="form-control "
                            value={imageUrl}
                            onChange={(e) => setImageUrl(e.target.value)}
                        />
                        {/* <Form.Control.Feedback type='invalid'>Please add image url.</Form.Control.Feedback> */}

                    </Form.Group>
                    <div className='text-center mb-2 fw-bold text-danger'>OR</div>
                    <Form.Group as={Col} size="md" controlId="validationCustom01" className='mb-3'>
              <Form.Label className="">
                Upload Image
              </Form.Label>
              <label id="labimg">
              <input
              // type="file"
              className="form-control "
              onClick={(e)=>setShowCrop(true)}
             
              size="40"
              accept="image/png, image/gif, image/jpeg"
              id="inputds"
            />
            {cropimg?<img 
              // crossOrigin="anonymous" 
            className="imgs" 
            src={cropimg}
            onClick={()=>setCropImg()}
            width="50%" alt="" />:<img 
            className="imgs" 
            src={image ? image : upimg}
            width="50%" alt="" />}
            </label>
            </Form.Group>
                    <div className="text-center">
                    {loader?<Button variant="secondary" className="as_btn ms-0">
                    Processing...
                </Button>:<Button variant="secondary" type="submit" className="as_btn ms-0" >
                Create
            </Button>}</div>
                </Form>
            </div>
            <CropModal 
            showcrop={showcrop}
            setShowCrop={setShowCrop}
            setCropImg={setCropImg}
            cropimg={cropimg}
            caartifileimage={caartifileimage}
            setFileCaartiimage={setFileCaartiimage}/>

            <ToastContainer />

            </div>
          </div>
        </>

    )

}


export default BulkNotificaton;