import React, { useState,useContext, useEffect } from 'react';
import { Card, Form, Button, Row, Col, Modal } from 'react-bootstrap';
import defaultImage from '../../imgs/default.jpg';
import { PujaImgGalleyModel } from '../../components/Shared/pujaImgGalleryModal';
import upimg from '../../imgs/default.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies } from '../../utils/Helper';
import { ToastContainer, toast } from 'react-toastify';


const ChadhawaAddEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDataId = searchParams.get('editDataId');
  const [selectedId, setSelectedId] = useState("")
  const [loading, setLoading] = useState(false)
  const [inputFields, setInputFields] = useState({
    title: '',
    sortOrder: '',
    imageEn: '',
  });

  const [inputFieldsHi, setInputFieldsHi] = useState({
    titleHi: '',
    imageHi: '',
  });

  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const [errors, setErrors] = useState({});
  const [errorsHi, setErrorsHi] = useState({});


  // Image gallery
  const [modalFieldKey, setModalFieldKey] = useState(null);
  const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
  const [clearSel, setClearSel] = useState(false);
  const [rindex, setRindex] = useState();
  const [imageEn, setImageEn] = useState('');
  const [imageHi, setImageHi] = useState('');
 

  const handleOpenModal = (fieldKey, index) => {
    setClearSel(true);
    setModalFieldKey(fieldKey);
    setRindex(index);
    setShowImageGalleyModel(true);
  };

  const extractPaths = url => {
    const extractPath = singleUrl => {
      const parts = singleUrl.split('cloudfront.net/');
      if (parts.length > 1) {
        return parts[1];
      }
      return singleUrl; 
    };

    if (Array.isArray(url)) {
      return url.map(singleUrl => extractPath(singleUrl));
    } else if (typeof url === 'string') {
      return extractPath(url);
    }
    return url;
  };

  const handleSetImageUrl = (urls, fieldKey) => {
    let extractedPath = extractPaths(urls);
    setClearSel(false);
    if (fieldKey === 'ChadhawaListImageEn') {
      setInputFields(prev => ({ ...prev, imageEn: urls }));
      setImageEn(extractedPath);
      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'ChadhawaListImageHi') {
      setInputFieldsHi(prev => ({ ...prev, imageHi: urls }));
      setImageHi(extractedPath);
      setShowImageGalleyModel(false);
    }
  };


  const handleChange = e => {
    const { name, value } = e.target;

    setInputFields(prev => ({ ...prev, [name]: value }));

    if (!!errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };
  
  const handleChangeHi = e => {
    const { name, value } = e.target;

    setInputFieldsHi(prev => ({ ...prev, [name]: value }));

    if (!!errorsHi[name]) {
      setErrorsHi(prev => ({ ...prev, [name]: null }));
    }
  };

  const validateInputs = () => {
    const newErrors = {};

    if (!inputFields.title || inputFields.title.trim() === '') {
      newErrors.title = 'Please enter Title.';
    }
    if(selectedId){

      if (!inputFields.sortOrder || inputFields.sortOrder.trim() === '') {
        newErrors.sortOrder = 'Please enter Sort Order.';
      }
    }

    if (!inputFields.imageEn) {
      newErrors.imageEn = 'Please add an Image.';
    }

    return newErrors;
  };

  const validateInputsHi = () => {
    const newErrorsHi = {};

    if (!inputFieldsHi.titleHi || inputFieldsHi.titleHi.trim() === '') {
      newErrorsHi.titleHi = 'Please enter Title.';
    }

    if (!inputFieldsHi.imageHi) {
      newErrorsHi.imageHi = 'Please add an Image.';
    }

    return newErrorsHi;
  };

  const handleReset=()=>{

     setInputFields({
      title: '',
      sortOrder: '',
      imageEn: '',
    });
  
    setInputFieldsHi({
      titleHi: '',
      imageHi: '',
    });
    setErrors({})
    setErrorsHi({})
  }

  const handleSubmit = async(e) => {
    e.preventDefault();

    const newErrors = validateInputs();
    const newErrorsHi = validateInputsHi();
    if (Object.keys(newErrors).length > 0 || Object.keys(newErrorsHi).length > 0) {
      setErrors(newErrors);
      setErrorsHi(newErrorsHi)
      setLoading(false)
      return
    }

    setLoading(true)
    const formData = new FormData();
    formData.append('title', inputFields.title);
    formData.append('image', imageEn);

    formData.append('titleHi', inputFieldsHi.titleHi);
    formData.append('imageHi', imageHi);

   
    try{
      const response = await APIServices.post('chadhawaSamgri/add', formData);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false)
          handleReset()
        // alert("successfull")
        
      } else {
        toast.error(response?.data?.message);
      }

    }
    catch (error) {
      toast.error('Something went wrong!');
    }
     finally {
      setLoading(false); // Reset loading state after the request is completed
    }
    
   
  };

  useEffect(()=>{
    if(editDataId){
      showEditData()
    }
  },[])

  const showEditData =(item) => {
   
    setSelectedId(item?._id)
   setInputFields({
    title: item?.title,
    imageEn: item?.image,
    sortOrder: item?.sortOrder,
    
    });
    setInputFieldsHi({
      titleHi: item?.titleHi,
    imageHi: item?.imageHi,
    });
  }
  


  useEffect(()=>{
    getEditData()
  },[editDataId])

  const getEditData =async()=>{
    if(editDataId){

      let chadhawaDetailsRes = await APIServices.get(`chadhawaSamgri/getDetailById/${editDataId}`, headers);
      if (chadhawaDetailsRes?.status === 200) {
        let apiData = chadhawaDetailsRes?.data;
        if (apiData?.status) {
          showEditData(apiData?.data[0])
        }
      }
    }
  }

  const handleUpdate = async(e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('title', inputFields?.title);
    formData.append('sortOrder', inputFields?.sortOrder);
    if(imageEn !== ''){
      formData.append('image', imageEn);
    }

    formData.append('titleHi', inputFieldsHi?.titleHi);
    if(imageHi !== ''){
    formData.append('imageHi', imageHi);
    }

    const newErrors = validateInputs();
    const newErrorsHi = validateInputsHi();
    setLoading(true)
    if (Object.keys(newErrors).length > 0 || Object.keys(newErrorsHi).length > 0) {
      setErrors(newErrors);
      setErrorsHi(newErrorsHi)
    }
    else {
      const response = await APIServices.put(`chadhawaSamgri/editOne/${editDataId}`, formData);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        setLoading(false)
        navigate("/chadhawaList")
        
      } else {
        toast.error(response?.data?.message);
      }
    } 
   
  };

  return (
    <div className='container mt-5'>
      <Form className='add_blog_form box-shadow p-20 br-6' onSubmit={(e)=> `${selectedId ? handleUpdate(e) : handleSubmit(e)}`}>
        <Row>
          <Col sm={6}>
             <h5 className='text-center mb-4'>English Content</h5>
              <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicTitle'>
                <Form.Label className='required' column sm='3'>
                  Title
                </Form.Label>
                <Col sm='9'>
                  <Form.Control
                    type='text'
                    placeholder='Title...'
                    name='title'
                    isInvalid={!!errors.title}
                    value={inputFields.title}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>{errors.title}</Form.Control.Feedback>
                </Col>
              </Form.Group>
             { selectedId ?
                  <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicSortOrder'>
                    <Form.Label className='required' column sm='3'>
                      Sort Order
                    </Form.Label>
                    <Col sm='9'>
                      <Form.Control
                        type='number'
                        placeholder='Sort Order...'
                        name='sortOrder'
                        isInvalid={!!errors?.sortOrder}
                        value={inputFields?.sortOrder}
                        onChange={handleChange}
                        // readOnly
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.sortOrder}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                 : ""}

              <Form.Group as={Row} className='mb-3 mx-2' controlId='formImageInput'>
                <Form.Label className='required' column sm='3'> Image</Form.Label>
                <Col sm='9'>
                    <Form.Control
                      id='upload-photo-1'
                      name='imageEn'
                      className='d-none'
                      isInvalid={!!errors?.imageEn}
                      onClick={e => {
                        handleOpenModal('ChadhawaListImageEn');
                        handleChange(e)
                      }}
                    />

                    <img
                      name='imageEn'
                      style={{ width: '180px', height: '160px'}}
                      alt='img'
                      src={inputFields?.imageEn ? inputFields?.imageEn : upimg}
                      onClick={e => {
                        handleOpenModal('ChadhawaListImageEn');
                        handleChange(e)
                      }}
                    />
                  <Form.Control.Feedback type='invalid'>{errors?.imageEn}</Form.Control.Feedback>

                </Col>
              </Form.Group>
          
          </Col>

          <Col sm={6}>
             <h5 className='text-center mb-4'>Hindi Content</h5>
              <Form.Group as={Row} className='mb-3 mx-2' controlId='formBasicTitleHi'>
                <Form.Label className='required' column sm='3'>
                  Title
                </Form.Label>
                <Col sm='9'>
                  <Form.Control
                    type='text'
                    placeholder='Title...'
                    name='titleHi'
                    isInvalid={!!errorsHi.titleHi}
                    value={inputFieldsHi.titleHi}
                    onChange={handleChangeHi}
                  />
                  <Form.Control.Feedback type='invalid'>{errorsHi.titleHi}</Form.Control.Feedback>
                </Col>
              </Form.Group>


              <Form.Group as={Row} className='mb-3 mx-2' controlId='formImageInputHi'>
                <Form.Label className='required' column sm='3'> Image </Form.Label>
                <Col sm='9'>
                    <Form.Control
                      id='upload-photo-1'
                      name='imageHi'
                      className='d-none'
                      isInvalid={!!errorsHi?.imageHi}
                      onClick={e => {
                        handleOpenModal('ChadhawaListImageHi');
                        handleChangeHi(e)
                      }}
                    />

                    <img
                      name='imageHi'
                      style={{ width: '180px', height: '160px'}}
                      alt='img'
                      src={inputFieldsHi?.imageHi ? inputFieldsHi?.imageHi : upimg}
                      onClick={e => {
                        handleOpenModal('ChadhawaListImageHi');
                        handleChangeHi(e)
                      }}
                    />
                  <Form.Control.Feedback type='invalid'>{errorsHi?.imageHi}</Form.Control.Feedback>

                </Col>
              </Form.Group>
          
          </Col>
        </Row>


        <div className='text-center'>
          <Button variant='primary' type='submit' className='as_btn'>
            {loading ? "Loading..." : `${ selectedId ? "Edit" : "Add"}`}
          </Button>
          <Button variant='primary' type='button' className='as_btn' onClick={() => navigate(-1)}>
            Back
          </Button>
          {/* <Button variant='primary' type='button' className='as_btn' onClick={handleReset}>
            Reset
          </Button> */}
        </div>
      </Form>
      <PujaImgGalleyModel
          id='ImgGalleyModel'
          show={showImageGalleyModel}
          onHide={() => setShowImageGalleyModel(false)}
          modalHeading={'ImgGalley'}
          size='lg'
          handleSetImageUrl={handleSetImageUrl}
          fieldKey={modalFieldKey}
          setClearSel={setClearSel}
          clearSel={clearSel}
        />
        <ToastContainer />
    </div>
  );
};

export default ChadhawaAddEdit;
