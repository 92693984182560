

import React, { useEffect, useState, useMemo, useCallback,useContext } from "react";
import { Button, Modal } from 'react-bootstrap';
import DynamicDataTableWithCount from "../DynamicDataTableWithCount";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import APIServicenew from "../../utils/APIGeneralService";
import { setCookies } from "../../utils/Helper";
import moment from "moment";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";

const BulkNotificaton = () => {
  document.title = 'Notificaton';
    const [loading, setLoading] = useState()
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const cookies = new Cookies();
    const token = cookies.get("accessToken");
    const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
    const  {refreshToken} = useContext(UserContext);
    const navigate = useNavigate();
    const headers = { Authorization: `Bearer ${token}` };
    const [allDataCount , setAllDataCount] = useState()
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedId(null);
      };
    
      const handleShowDeleteModal = id => {
        setShowDeleteModal(true);
        setSelectedId(id);
      };

      const logout =() =>{
        refreshToken(null)
        setCookies("accessToken", null);
        navigate("/");
      }
      async function updateFile() {
        let res = await APIServices.get(`bulkNotification/updateFile`, headers);
        if (res?.status === 200) {
          let apiData = res?.data;
          if (apiData?.status) {
            toast.success(apiData?.message);
            fetchProfilesByPagination(skip, limit, pageIndex);
          }
        }
      }
      const handleDelete = async () => {
        console.log("deleted");
        await APIServices.delete(`bulkNotification/findByIdAndremove/${selectedId}`, headers)
          .then(res => {
            if (res.data.status === true) {
              fetchProfilesByPagination(skip, limit, pageIndex);
              updateFile()
              setShowDeleteModal(false);
              setTimeout(() => {
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }, 1000);
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            setSelectedId(null);
      
          
          });
      };
     
    const columns = useMemo(
        () => [
            {
                Header: 'NO',
                Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
              },
            {
                Header: " Title",
                accessor: "title",
                
            },
            {
                Header: "Message",
                accessor: "message"
            },
            {
                Header: "Type",
                accessor: "type"
            },
            {
                Header: "Image",
              Cell: (e) => {
                    if (e.row.original.file !== null) {
                        return (<>
                      <img className="imglist" alt="img" src={e.row.original.file} />
                        </>
                        )
                      }else if (e.row.original.img_url !== null && e.row.original.img_url !== '') {
                        return (<>
                          <img className="imglist" alt="img" src={e.row.original.img_url} />
                           </>
                         )
                    }else {
                        return (<>
                            
                             </>
                           )
                      
                  }
                }
            },
            {
                Header: 'Delete',
                Cell: e => {
                  if (e.row.original._id) {
                    return (
                      <h5>
                        <input
                          type='button'
                          className='Dt-btn'
                          value='Delete'
                         onClick={() => handleShowDeleteModal(e.row.original._id)}
                        />
                      </h5>
                    );
                  }
                },
              },
            {
                Header: 'Date',
                Cell: e => {
                  return <span>{moment(e.row.original.updatedAt).format("MMM Do YY")}</span>;
                },
              },
        ],
        []
    );


    const fetchProfilesByPagination = useCallback(async (skip, limit, index) => {

        try {
            const response = await APIServices.get(`bulkNotification/getAll/${skip}/${limit}`, headers)
            let data = response?.response?.data

            if (response.data?.status === true) {
              setAllDataCount(response.data?.totalCount)
                setPageCount(Math.ceil(response.data?.totalCount / limit));
                setTableData(response.data?.Listdata)
              } else{

                if(data.status == 4){
                logout()
               }
              }
        } catch (e) {
            throw new Error(`API error:${e?.message}`);
        }
        setSkip(skip);
        setLimit(limit);
        setPageIndex(index);

    }, [pageIndex]);

    return (
        <>
         <div className="notifiction_area" style={{
                backgroundImage: 'url("/images/why_choose_us.jpg")'
                    }}>
            <div className="gita_quote_heading">
                <div className="container d-flex align-items-center justify-content-between">
                  <h4 className="hd-list-f left-q" > Bulk Notification List <small>({allDataCount})</small></h4>
                  <div className="_btn">
              <Button
                variant="primary"
                className="by-btn as_btn"
                onClick={updateFile}
              >
                Update File
              </Button>
            </div>
                </div>
            </div>
         <div className="my-3">
            <DynamicDataTableWithCount
                columns={columns}
                data={tableData}
                totalCount={pageCount}
                initialState={{
                    pageIndex: pageIndex,
                    pageSize: limit,
                }}
                fetchData={fetchProfilesByPagination}
            />
            </div>
          
            <Modal
        show={showDeleteModal}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
            <ToastContainer />
        </div>
        
        </>

    )

}


export default BulkNotificaton;