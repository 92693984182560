import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';
import { useForm } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
// import {Button }from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const StatusButtonText = {
  0: 'Start Puja',
  1: 'End Puja',
  2: 'Share Puja Video',
  3: 'Dispatch Prasad',
  4: 'Complete Puja',
};

const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
const APIServicesSec = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL);

const cookies = new Cookies();
const token = cookies.get('accessToken');
const headers = { Authorization: `Bearer ${token}` };
const astroUserId = cookies.get('astroUserId');

const PujaBookingStatus = ({ pujaId, pujaData,paidCount, refetchPujaDetails ,pujaBookings,setAddBookingHide}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [showShareLink, setShowShareLink] = useState(false);
  const [showStartLink, setShowStartLink] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    trigger,
    reset,
  } = useForm();

  useEffect(() => {
    setActiveStep(pujaData?.pujaBookingStatus);
  }, [pujaData?.pujaBookingStatus]);


  let filterPujaBookingPaid= pujaBookings?.filter(el=>el.paymentStatus=="Paid") // for filter only paid users

  const updateBookingStatus = async payload => {
    setUpdatingStatus(true);
    try {
      const updateRes = await APIServices.post(`astroPooja/updateBookingStatus/${pujaId}`, payload, headers);

      if (updateRes && updateRes.status === 200) {
        if(payload.pujaBookingStatus === 0){
         toast.success(updateRes?.data?.message);
        }
        let status = updateRes?.data?.data?.pujaBookingStatus
        if (status === 1) {
           handlePujaStartTemp()
        }else if(status === 2){
           handlePujaEndTemp()
        } else if(status === 3){
            handlePujaVideoTemp()
        }else if(status === 4){
          handlePujaDispatched()
       }
        await refetchPujaDetails();
      } else {
        toast.error(updateRes?.data?.message ? updateRes?.data?.message : 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message ? error?.message : 'Something went wrong');
    } finally {
      setUpdatingStatus(false);
      
    }
  };

  const handleStatusUpdate = async () => {
    if(activeStep==0){
      setShowStartLink(true);
      handleClose()
    }
    else if (activeStep === 2) {
      setShowShareLink(true);
      handleClose()
    
    } else {
      const newStatus = activeStep + 1;
      const payload = {
        pujaBookingStatus: newStatus,
      };
      await updateBookingStatus(payload);
      handleClose()
    }
  };

  const handleSharePujaVideo = async data => {
    const videoLink = data?.videoLink?.trim();
    if (videoLink === '') return;
    const newStatus = activeStep + 1;
    const payload = {
      pujaBookingStatus: newStatus,
      pujaVideo: videoLink,
    };
    await updateBookingStatus(payload);
    setShowShareLink(false);
  };

  const sharePujaVideoClick = async () => {
    await trigger(); // Trigger form validation

    if (Object.keys(errors).length === 0) {
      const formData = getValues(); // Get form data using getValues()

      await handleSharePujaVideo(formData);
    }
  };


  const handleStartPujaVideo = async data => {
    const videoLink = data?.videoLink?.trim();
    // if (videoLink === '') return;
    const newStatus = activeStep + 1;
    const payload = {
      pujaBookingStatus: newStatus,
      pujaVideo: videoLink,
    };
    await updateBookingStatus(payload);
    setShowStartLink(false);
    reset()
  };


  const startPujaVideoClick = async () => {
    
      const formData = getValues(); // Get form data using getValues()

      await handleStartPujaVideo(formData);
 
  };

  

  const handlePujaStartTemp = async () => {
    const formData = getValues();
    const payload = {type: "puja_start_temp", 
    videoLink : formData?.videoLink,
    userArr:filterPujaBookingPaid?.map((x)=>({
      name:x?.name,
      contactNumber:x?.contactNumber,
      pujaName:pujaData?.hindiTitle,
      date:pujaData?.date,
    	place :pujaData?.address,
    }))
   };
    let response = await APIServicesSec.post('whatsAppApi/sendPoojaBookingNoti', payload, headers);
    if (response && response.status === 200) {
      // toast.success(response?.data?.message);
      toast.success(`Status updated successfully. Customer notified ${paidCount}`);

    }else{
      toast.error(response?.data?.message ? response?.data?.message : 'Something went wrong');
    }
    }


 const handlePujaEndTemp = async () => {
  const payload = {type: "puja_end_temp", 
  userArr:filterPujaBookingPaid?.map((x)=>({
    name:x?.name,
    contactNumber:x?.contactNumber,
    pujaName:pujaData?.hindiTitle
  }))
 };
  let response = await APIServicesSec.post('whatsAppApi/sendPoojaBookingNoti', payload, headers);
  if (response && response.status === 200) {
    // toast.success(response?.data?.message);
    toast.success(`Status updated successfully. Customer notified ${paidCount}`);

  }else{
    toast.error(response?.data?.message ? response?.data?.message : 'Something went wrong');
  }
  }


  const handlePujaVideoTemp = async () => {
    const formData = getValues();
    const payload = {type: "puja_video_temp", 
    videoLink : formData?.videoLink,
    userArr:filterPujaBookingPaid?.map((x)=>({
      name:x?.name,
      contactNumber:x?.contactNumber,
      date:pujaData?.date,
    	place :pujaData?.address,
      pujaName:pujaData?.hindiTitle
    }))
   };
    let response = await APIServicesSec.post('whatsAppApi/sendPoojaBookingNoti', payload, headers);
    if (response && response.status === 200) {
      // toast.success(response?.data?.message);
       toast.success(`Status updated successfully. Customer notified ${paidCount}`);

    }else{
      toast.error(response?.data?.message ? response?.data?.message : 'Something went wrong');
    }
    }
    
   const handlePujaDispatched=async()=>{
    const payload = {type : "prasad_dispatched", 
    userArr:filterPujaBookingPaid?.map((x)=>({
      name:x?.name,
      contactNumber:x?.contactNumber,
      pujaName:pujaData?.hindiTitle
    }))
   };
    let response = await APIServicesSec.post('whatsAppApi/sendPoojaBookingNoti', payload, headers);
    if (response && response.status === 200) {
      // toast.success(response?.data?.message);
    toast.success(`Status updated successfully. Customer notified ${paidCount}`);

    }else{
      toast.error(response?.data?.message ? response?.data?.message : 'Something went wrong');
    }
   }

useEffect(()=>{
  if(activeStep==5){
    setAddBookingHide(true)
  }
},[activeStep])
    

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        styleConfig={{
          activeBgColor: '#a10308',
        }}
      >
        <Step label='Scheduled'>{activeStep >= 0 ? <FaCheck /> : ' '}</Step>
        <Step label='Puja Started'>{activeStep >= 1 ? <FaCheck /> : ' '}</Step>
        <Step label='Puja Ended'>{activeStep >= 2 ? <FaCheck /> : ' '}</Step>
        <Step label='Shared Puja Video'>{activeStep >= 3 ? <FaCheck /> : ' '}</Step>
        <Step label='Prasad Dispatched'>{activeStep >= 4 ? <FaCheck /> : ' '}</Step>
        <Step label='Completed'>{activeStep >= 5 ? <FaCheck /> : ' '}</Step>
      </Stepper>
      {showStartLink && (
        <div className='d-flex align-items-center justify-content-center'>
          <Form onSubmit={handleSubmit(handleStartPujaVideo)}>
            <div className='d-flex align-items-center'>
              <div className='form-group'>
                <Form.Control
                  placeholder='Start Puja Video Link ...'
                  {...register('videoLink', {
                    // required: { value: true, message: 'Puja Start Video Link is required' },
                  })}
                />
              </div>
              <Button
                className='as_btn mt-0'
                variant='primary'
                onClick={async () => {
                  startPujaVideoClick();
                }}
              >
                Start Puja Video
              </Button>
            </div>
            {errors.videoLink && <p className='text-danger'>{errors.videoLink?.message}</p>}
          </Form>
        </div>
      )}
      {showShareLink && (
        <div className='d-flex align-items-center justify-content-center'>
          <Form onSubmit={handleSubmit(handleSharePujaVideo)}>
            <div className='d-flex align-items-center'>
              <div className='form-group'>
                <Form.Control
                  placeholder='Puja Video Link ...'
                  {...register('videoLink', {
                    required: { value: true, message: 'Puja Video Link is required' },
                  })}
                />
              </div>
              <Button
                className='as_btn mt-0'
                variant='primary'
                onClick={async () => {
                  sharePujaVideoClick();
                }}
              >
                Share Puja Video
              </Button>
            </div>
            {errors.videoLink && <p className='text-danger'>{errors.videoLink?.message}</p>}
          </Form>
        </div>
      )}
      { paidCount !== 0 &&
      <div className='d-flex align-items-center justify-content-center mt-2'>
        {StatusButtonText?.[activeStep] && !showShareLink && !showStartLink &&(
          <Button
            className='as_btn'
            variant='primary'
            onClick={() => {
              handleShow();
            }}
            disabled={updatingStatus}
          >
            {updatingStatus ? 'Updating Status' : StatusButtonText?.[activeStep]}
          </Button>
        )}
       {activeStep !==5? <Button
          className='as_btn reset-btn'
          variant='primary'
          onClick={async () => {
            const payload = {
              pujaBookingStatus: 0,
            };
            await updateBookingStatus(payload);
            await refetchPujaDetails();
            setShowShareLink(false);
            setShowStartLink(false);
            setAddBookingHide(false);
            reset()
          }}
        >
          Reset Status
        </Button>:""}
      </div>
     }
      <Modal show={show}  size='sm' onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header> */}
        <Modal.Body style={{background:"#a21a2e",color:"#fff"}} >Are You Sure You Want To {StatusButtonText?.[activeStep]}</Modal.Body>
        <Modal.Footer >
        <Button variant="primary"  className='as_btn' onClick={handleStatusUpdate}>
            Yes
          </Button>
          <Button variant="secondary"  className='as_btn cancle_btn' onClick={handleClose}>
            Cancel
          </Button>
          
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PujaBookingStatus;
