import moment from 'moment';
import React, { useState } from 'react'
import { ThreeDots } from 'react-bootstrap-icons';
import { useToasts } from 'react-toast-notifications'
import APIServicenew from '../../utils/APIGeneralService'
import { getCookies } from '../../utils/Helper';

const Replies = ({commentpost,postid, reply }) => {
    
    const [showoption, setShowOption] = useState(false)
    const { addToast } = useToasts()
    const accessToken = getCookies('accessToken')
    const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + accessToken
    }

    const handleDelete = async () => {
        let data={
            postId:postid,
            commentId:commentpost,
            replyId:reply._id
        }
        
        
        const response = await APIServices.delete(`/admin/post/delete_reply`, headers,data)
        if (response.data.status === 1) {
            addToast(response.data.message,
                {
                    appearance: 'success',
                    autoDismiss: true
                }
            )
        } else {
            addToast(response.data.message,
                {
                    appearance: 'error',
                    autoDismiss: true
                }
            )
        }
    }

    return (
        <section key={reply._id} className="my-2 custom-margin border-top py-2">
            <article className="d-flex justify-content-between w-100">
                <div className="d-flex align-items-center">
                    <img src={reply.replyUserId?.profileImage || reply.replyAstrologerId?.profileImage} className="img-fluid user_img" alt='astroOrUserImg' />
                    <div className="mx-2 d-flex justify-content-center flex-column">
                        <h6 className="text-capitalize m-0 p-0" style={{ fontSize: '13px' }}>{reply.replyUserId?.name || reply.replyAstrologerId?.name || 'Unknown User'}</h6>
                        <p className="m-0 p-0 responsiveSize" style={{ fontSize: '13px' }}>{moment(reply.replyDate).format('MMM Do YYYY, h:mm a')}</p>
                    </div>
                </div>
                <div className="position-relative responsiveSize">
                    <ThreeDots className="pointerUtilityClass" onClick={() => setShowOption(!showoption)} />
                    <button className={`${showoption || 'd-none'} card py-2 px-3 position-absolute text-nowrap end-0 btnHover pointerUtilityClass z_Index`} onClick={() => handleDelete()}>
                        Delete
                    </button>
                </div>
            </article>
            <article className="mb-2 custom-margin my-2">
                <p className="ms-4 responsiveSize" style={{ fontSize: '14px' }}>{reply.replyDescription}</p>
            </article>
        </section>
    )
}

export default Replies;