import React, { createRef, useEffect, useState } from "react";
import { Col, Container, Row, Button, InputGroup, FormControl, Spinner, Tab, Nav, Table } from "react-bootstrap";
import dateFormat from 'dateformat';
import { useToasts } from 'react-toast-notifications';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Trash2, PencilFill, TrashFill, Pencil } from 'react-bootstrap-icons';
import EditUserModel from "../../components/UserManagment/EditUsers";
import EditInqueryModel from "../../components/UserManagment/EditInquery";
import { faL } from "@fortawesome/free-solid-svg-icons";
import APIServicenew from "../../utils/APIGeneralService";
import Cookies from "universal-cookie";
import moment from "moment";

const UserList = () => {
  const { addToast } = useToasts();
  const [tabKey, setTabKey] = useState('users');
  const [userList, setUserList] = useState([]);
  const [kundliList, setKundliList] = useState([]);
  const [lifeReportList, setLifeReportList] = useState([]);
  const [inquiryList, setInquiryList] = useState([]);
  const cookies = new Cookies();
  let token = cookies.get("accessToken");
  const headers = { Authorization: `Bearer ${token}` };
  const [toggle, setToggle] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [inquiryToggle, setInquiryToggle] = useState(false);
  const [editInquiry, setEditInquiry] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingKundli, setIsLoadingKundli] = useState(true);
  const [isLoadingInquery, setIsLoadingInquery] = useState(true);


  const [isLoadingLifeReports, setisLoadingLifeReports] = useState(true);
  const handleSelect = async (tabKey) => {
    setTabKey(tabKey)
  }

  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL)


  const editRecord = (row) => {
    setEditUser(row);
    setToggle(true);
  }

  const editRecordInquiry = (row) => {
    setEditInquiry(row);
    setInquiryToggle(true);
  }

  let pagination = paginationFactory({
    page: 1,
    sizePerPage: 20,
    hideSizePerPage: true,
    showTotal: true
  });

  const columns = [
    {
      sort: true, dataField: '_id', text: '#', headerStyle: { width: '10%' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            {rowIndex + 1}
          </div>
        );
      }
    },
    { sort: true, dataField: 'name', text: 'Name', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'email', text: 'Email', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'contactNumber', text: 'Contact Number', align: (cell, row, rowIndex, colIndex) => { return 'center' }, headerStyle: { width: '10%' } },
    { sort: true, dataField: 'utmSource', text: 'UTM Source', align: (cell, row, rowIndex, colIndex) => { return 'center' }, headerStyle: { width: '10%' } },
    {
      sort: true, dataField: 'dob', text: 'DOB(Y-M-D H:M:S)', headerStyle: { width: '10%' },
      formatter: (cell, row) => {
        return (
          <div> {row.dob ? dateFormat(row?.dob, "d-m-yyyy") : null}-
            {row.hour ? row.hour : null}-
            {row.minutes ? row.minutes : null}-
            {row.seconds ? row.seconds : null} </div>
        )
      }
    },
    { sort: true, dataField: 'problem', text: 'Problem', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'location', text: 'Location', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'createdAt', text: 'Date', headerStyle: { width: '20%' } ,
    formatter: (cell, row) => {
      return (
        <div> {row.createdAt ? moment(row?.createdAt).format("lll") : null} </div>
      )
    }},
    { sort: true, dataField: 'deal_status', text: 'Status' },
    {
      sort: true, dataField: '_id', text: 'Action', headerStyle: { width: '20%' },
      formatter: (cell, row) => {
        return (
          <div>
            <Pencil
              onClick={() => editRecord(row)}
              color={'black'}
              size={18} />
          </div>
        )
      }
    },
  ];


  const columnsInquiry = [
    {
      sort: true, dataField: '_id', text: '#', headerStyle: { width: '10%' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            {rowIndex + 1}
          </div>
        );
      }
    },
    { sort: true, dataField: 'name', text: 'Name', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'email', text: 'Email', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'contactNumber', text: 'Contact Number', align: (cell, row, rowIndex, colIndex) => { return 'center' }, headerStyle: { width: '10%' } },
    { sort: true, dataField: 'utmSource', text: 'UTM Source', align: (cell, row, rowIndex, colIndex) => { return 'center' }, headerStyle: { width: '10%' } },
    { sort: true, dataField: 'problem', text: 'Problem Area', align: (cell, row, rowIndex, colIndex) => { return 'center' }, headerStyle: { width: '10%' } },
    { sort: true, dataField: 'createdAt', text: 'Date', headerStyle: { width: '20%' } },
    { sort: true, dataField: 'deal_status', text: 'Status', headerStyle: { width: '20%' } },
    {
      sort: true, dataField: '_id', text: 'Action', headerStyle: { width: '20%' },
      formatter: (cell, row) => {
        return (
          <div>
            <Pencil
              onClick={() => editRecordInquiry(row)}
              color={'black'}
              size={18} />
          </div>
        )
      }
    },

  ];

  const columnsKundli = [
    {
      sort: true, dataField: '_id', text: '#', headerStyle: { width: '10%' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            {rowIndex + 1}
          </div>
        );
      }
    },
    { sort: true, dataField: 'name', text: 'Name', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'contactNumber', text: 'Contact Number', headerStyle: { width: '10%' } },
    {
      sort: true, dataField: 'dob', text: 'DOB(Y-M-D H:M:S)', align: (cell, row, rowIndex, colIndex) => { return 'center' }, headerStyle: { width: '10%' },
      formatter: (cell, row) => {
        return (
          <div>
            {row.day}-{row.month}-{row.year} {row.hour}:{row.min}
          </div>
        )
      }
    },
    {
      sort: true, dataField: 'createdAt', text: 'Date', headerStyle: { width: '20%' },
      formatter: (cell, row) => {
        return (
          <div>
            {row.createdAt ? dateFormat(row?.createdAt, "d-m-yyyy") : null}
          </div>
        )
      }
    },
  ];

  const columnsReports = [
    {
      sort: true, dataField: '_id', text: '#', headerStyle: { width: '10%' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <div>
            {rowIndex + 1}
          </div>
        );
      }
    },
    { sort: true, dataField: 'name', text: 'Name', headerStyle: { width: '10%' } },
    { sort: true, dataField: 'contactNumber', text: 'Contact Number', headerStyle: { width: '10%' } },
    {
      sort: true, dataField: 'dob', text: 'DOB(Y-M-D H:M:S)', align: (cell, row, rowIndex, colIndex) => { return 'center' }, headerStyle: { width: '10%' },
      formatter: (cell, row) => {
        return (
          <div>
            {row.day}-{row.month}-{row.year} {row.hour}:{row.min}
          </div>
        )
      }
    },
    {
      sort: true, dataField: 'createdAt', text: 'Date', headerStyle: { width: '20%' },
      formatter: (cell, row) => {
        return (
          <div>
            {row.createdAt ? dateFormat(row?.createdAt, "d-m-yyyy") : null}
          </div>
        )
      }
    },
  ];

  useEffect((e) => {
    getUsersData();
    getKundliData();
    getLifeReportData();
    getInquiryData();

  }, [updated])

  const getUsersData = async (e) => {
    let resp = await APIServices.get('admin/users/usersList', headers)
    if (resp.data.status == true) {
      setIsLoading(false);
      setUserList(resp.data.resData.data);
    }
  }

  const getInquiryData = async (e) => {
    let resp = await APIServices.get('admin/enquiry/enquiryList', headers);
    if (resp.data.status == true) {
      setIsLoadingInquery(false);
      setInquiryList(resp.data.data);
    }
  }

  const getKundliData = async (e) => {
    // let resp = await APIServices.get('astrology/getKundliList', headers);
    // if (resp.data.status == true) {
    //   setIsLoadingKundli(false);
    //   setKundliList(resp.data.data);
    // }
  }

  const getLifeReportData = async (e) => {
    // let resp = await APIServices.get('astrology/getLifeReportsList', headers);
    // if (resp.data.status == true) {
    //   setisLoadingLifeReports(false);
    //   setLifeReportList(resp.data.data);
    // }
  }

  const renderSpinner = () => {
    return (
      <>
        <div className="text-center">
          <Spinner className="mt-3" animation="border" variant="danger" />
        </div>
      </>
    )
  }

  const renderUserTables = () => {

    return <BootstrapTable
      striped
      hover
      bordered={true}
      bootstrap4
      keyField="_id"
      defaultSortDirection="asc"
      data={userList}
      columns={columns}
      pagination={pagination}
    />
  }

  const renderInquiryTables = () => {

    return <BootstrapTable
      striped
      hover
      bordered={true}
      bootstrap4
      keyField="_id"
      defaultSortDirection="asc"
      data={inquiryList}
      columns={columnsInquiry}
      pagination={pagination}
    />
  }

  const renderKundliTables = () => {

    return <BootstrapTable
      striped
      hover
      bordered={true}
      bootstrap4
      keyField="_id"

      defaultSortDirection="asc"
      data={kundliList}
      columns={columnsKundli}
      pagination={pagination}
    />
  }

  const renderLifeReportTables = () => {

    return <BootstrapTable
      striped
      hover
      bordered={true}
      bootstrap4
      keyField="_id"

      defaultSortDirection="asc"
      data={lifeReportList}
      columns={columnsReports}
      pagination={pagination}
    />
  }

  const renderUserEditModal = () => {
    return (
      <EditUserModel
        addToast={addToast}
        data={editUser}
        // show={toggle}
        toggleFalse={() => setToggle(!toggle)}
        setUpdated={() => setUpdated(!updated)}
      />
    );
  }

  const renderInqueryEditModal = () => {

    return (
      <EditInqueryModel
        addToast={addToast}
        data={editInquiry}
        toggleFalse={() => setInquiryToggle(!inquiryToggle)}
        setUpdated={() => setUpdated(!updated)}
      />
    );
  }

  return (
    <>
    <div className="gita_quote_heading">
    <div className="container d-flex align-items-center justify-content-between">
    <h4 className="hd-list-f left-q" >Users </h4>
    </div>
 </div>
      <section className="as_zodiac_single_wrapper _pad73">
        <Tab.Container id="left-tabs-example" defaultActiveKey="users" activeKey={tabKey}
          onSelect={(k) => handleSelect(k)} >
          <div className="astrologer_heading mb-0">
            <div className="container">
            </div>
          </div>
          <div className="container my-3">
            <div className="row align-items-center border_bottom">
              <div className="col-md-12 col-lg-12">
                <div className="astrologer_type">
                  <Nav>
                    <Nav.Item>
                      <Nav.Link eventKey="users">Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="inquiry">Inquiries</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="kundli">Kundli</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="life_reports">Life Report</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="as_zodiac_single_inner">
              <div className="as_tab_wrapper">
                <Tab.Content  >
                  <Tab.Pane eventKey="users">
                    {isLoading ? renderSpinner() : renderUserTables()}
                    {toggle ? renderUserEditModal() : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="inquiry">
                    {isLoading ? renderSpinner() : renderInquiryTables()}
                    {inquiryToggle ? renderInqueryEditModal() : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="kundli">
                    {isLoadingKundli ? renderSpinner() : renderKundliTables()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="life_reports">
                    {isLoadingLifeReports ? renderSpinner() : renderLifeReportTables()}
                  </Tab.Pane>

                </Tab.Content>
              </div>
            </div>
          </div>
        </Tab.Container>
      </section >

    </>
  );
}

export default UserList;