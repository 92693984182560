import { useEffect, useState,useContext } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import bimg from '../../../src/imgs/default.jpg';
import APIServicenew from '../../utils/APIGeneralService';
import CropModal from '../../components/Shared/CropModal';
import { setCookies } from "../../utils/Helper";
import { UserContext } from "../../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
const FestivalWish = ({setAllDataCountadd}) => {
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);

  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get('astroUserId');
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(6);
  const [pageCount, setPageCount] = useState(0);
  const [len, setLen] = useState();
  const [loading, setLoading] = useState();
  const [imageType, setImageType] = useState('hindi');
  const [status , setStatus] = useState("all")
  const [allData, setAllData] = useState([]);
  const [contentType, setContentType] = useState('');
  const [title, setTitle] = useState('');
  const [file, setFile] = useState('');
  const [image, setImage] = useState('');
  const [imgShow, setImgShow] = useState();
  const [show, setShow] = useState(false);
  const [showup, setShowUp] = useState(false);
  const [showdt, setShowDt] = useState(false);
  const [data, setData] = useState();
  const [selectedId, setSelectedId] = useState()
  const [allDataCount , setAllDataCount] = useState()
  const[loader,setLoader]=useState(false)
  const [errors, setErrors] = useState({});
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [caartifileimage,setFileCaartiimage]=useState()
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();


  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }

  const handleClose = () => {
    setErrors("")
    setShow(false);}

  const handleCloseDt = () => setShowDt(false);

  const handleShow =  async item => {
    setShow(true);
    setImage('');
    setFile('');
    setSelectedId(item?._id);
    setContentType(item?.contentLangEn === true ? 'english' : item?.contentLangHi === true ? 'hindi' : '');
    setImgShow(item?.image);
    setTitle(item?.title);
  };

  const handleShowDt = id => {
  setSelectedId(id);
    setShowDt(true);
  };


  const fetchData = async () => {
    setLoading(true);

    let response = await APIServices.get(`festivalWishes/getFestivalWishList/${imageType}/${offset}/${perPage}?status=${status}`, headers);
    let data = response?.response?.data
   
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData?.Listdata);
        setAllDataCountadd(apiData.totalCount)
        setAllDataCount(apiData.totalCount)

        setLoading(false);
      } 
    }else{
      if(data?.status == 4){
      logout()
     }
  }
  };


  useEffect(() => {
    fetchData();
  }, [offset,imageType ,status]);


  const handlePageClick = e => {
    const selectedPage = e.selected;
    console.log(selectedPage * perPage);
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset);
  };

  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (!title ||title === "") 
      newErrors.title = "Please enter title.";

       if (file === "" && (!imgShow || (imgShow && imgShow.length === 0))) 
       newErrors.file = "Please add single or multi images.";
       let maxLength = 10

       if (image && (image.length > maxLength && !selectedId)) 
       newErrors.file = "Images can't be more then 10";

      if (!contentType ||contentType === "") 
      newErrors.contentType = "Please enter language type."; 

      return newErrors;
  }; 


  async function handleEdit() {
    const formData = new FormData();
    formData.append('title', title);
    if (file) {
      formData.append('image', file[0]);
    }
    if (contentType === 'english') {
      formData.append('contentLangEn', true);
      formData.append('contentLangHi', false);
    } else if (contentType === 'hindi') {
      formData.append('contentLangHi', true);
      formData.append('contentLangEn', false);
    }
    // formData.append("userId", astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      setLoader(true)
      let response = await APIServices.post(`festivalWishes/updateFestivalWish/${selectedId}`, formData, headers);
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setFile('');
          setShow(false);
          setLoader(false)
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }

  async function handleCreate() {
    const newErrors = validateInputs();
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
   let i = 0;
     for (i;i< file.length;i++) {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', file[i]);
    if (contentType === 'english') {
      formData.append('contentLangEn', true);
      formData.append('contentLangHi', false);
    } else if (contentType === 'hindi') {
      formData.append('contentLangHi', true);
      formData.append('contentLangEn', false);
    }
    formData.append('userId', astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };
  
      setLoader(true)
      let response = await APIServices.post(`festivalWishes/addFestivalWish`, formData, headers);
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          
          if(i === file.length -1){
            setShow(false);
            toast.success(apiData?.message);
            setLoader(false)
            fetchData();
          }
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }
  }

  const handleclick = async (status, id) => {
    var datas = status === 1 ? 2 : 1;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`festivalWishes/updateStatus/${id}`, formData, headers)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handledelete = async () => {
    let res = await APIServices.delete(`festivalWishes/findByIdAndremove/${selectedId}`, headers);
    if (res?.status === 200) {
      let apiData = res?.data;
      setShowDt(false);
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [offset]);

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }


var fileObj = [];
  var arrobj = [];
const onImageChange = (event) => {
  if (event.target.files && event.target?.files) {
    setFile(event.target.files);
    fileObj.push(event.target.files);
    for (let i = 0; i < fileObj[0]?.length; i++) {
      arrobj?.push(URL.createObjectURL(fileObj[0][i]));
      setImage(arrobj);
    }
  }
  let key, value;
  key = event?.target?.name;
  if (!!errors[key]){
    setErrors({
      ...errors,
      [key]: null,
      });
  }
};

  return (
    <>
      <div className='blog_area festival_area'>
        <div className='aarti_inner pt-5'>
          <div className='container'>
            <div className='row'>
           
              <div className='col-md-12 col-lg-12'>
                {loading ? (
                  <div className='center'>
                    <Spinner animation='border' />
                  </div>
                ) : (
                  <>
                    <div className='row'>
                      <div className='container'>
                        <div className='d-flex align-items-center' style={{justifyContent:"flex-end"}}>
                          <h4 className='hd-list-f left-q'></h4>
                          <Button variant='primary' onClick={handleShow} className='by-btn as_btn me-2 mt-0'>
                            Add Festival Wish 
                          </Button>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr right-q"
                            value={status}
                            onChange={(e) => {setStatus(e.target.value);setOffset(0)}}
                          >
                            <option value="all">All</option>
                            <option value="active" >Active</option>
                            <option value= "inActive">InActive</option>
                          </Form.Select>
                          <Form.Select
                            aria-label='categories'
                            className=' w-25 astroWatch-select-hover hd-cr'
                            value={imageType}
                            onChange={e => {
                              setImageType(e.target.value);
                              setOffset(0);
                            }}
                          >
                            <option value='hindi'>Hindi</option>
                            <option value='english'>English</option>
                          </Form.Select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className='row'>
                      {data?.map(item => (
                        <div className='col-md-6 col-lg-4'>
                          <div className='card mb-4' key={item?._id}>
                            <div class='card-header'>
                              <img src={item?.image ? `${item?.image}` : bimg} className='card-img-top' alt='img' />
                              <div className='icon card-icons'>
                                <FaEdit
                                  className='me-2 hd-cr'
                                  size={20}
                                  onClick={e => {
                                    handleShow(item);
                                  }}
                                />
                                <FaTrash
                                  className='dt-btn hd-cr'
                                  size={20}
                                  onClick={e => {
                                    handleShowDt(item?._id);
                                  }}
                                />
                                 {item.status === 1 ? (
                                      <input
                                        type="button"
                                        className="_active"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Active"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="button"
                                        className="_inactive"
                                        style={{fontSize:"8px", borderRadius:"15px"}}
                                        value="Inactive"
                                        onClick={() =>
                                          handleclick(item.status, item._id)
                                        }
                                      />
                                    )}
                              </div>
                            </div>
                            <h3 className="card-text-wish description_wish">{item.title}</h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <div className='pagination me-0'>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='Next>'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    // pageCount={pageCount}
                    pageCount={Math.ceil(allDataCount / perPage)}
                    previousLabel='< Previous'
                    renderOnZeroPageCount={null}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    containerClassName={'pagination me-0'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                    forcePage={offset / perPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} backdrop='static' onHide={()=>{handleClose();setCropImg()}}>
        <Modal.Header closeButton>
          <Modal.Title> {`${selectedId ? 'Edit' : 'Add'} Festival Wish`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
                Title
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  className='form-control '
                  name='title'
                  defaultValue={title}
                  isInvalid={!!errors?.title}
                  onChange={e => {setTitle(e.target.value); handleChange(e)}}
                />
                <Form.Control.Feedback type="invalid">
                {errors?.title}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {imgShow 
                    ? 
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="" column sm="3">
                Previous
              </Form.Label>
              <Col sm="9">
                        <img
                          src={imgShow}
                          width="20%"
                          alt=""
                        />
                    
              </Col>
            </Form.Group>
                    : ""}
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className={`${selectedId?"": "required"}`} column sm='3'>
                Image
              </Form.Label>
              <Col sm='9'>
              {selectedId ?
              <Form.Control
                type="file"
                name="file"
                isInvalid={!!errors?.file}
                className="form-control "
                onChange={onImageChange}
                size="40"
                accept="image/png, image/gif, image/jpeg"
               />:<Form.Control
               type="file"
               name="file"
               isInvalid={!!errors?.file}
               className="form-control "
               onChange={onImageChange}
               size="40"
               accept="image/png, image/gif, image/jpeg"
               multiple
            />}
             <Form.Control.Feedback type="invalid">
             {errors?.file}
             </Form.Control.Feedback>
             {selectedId ?  <img
                          src={image[0]}
                          width="20%"
                          alt=""
                        />:
             image?.length === 0 ?"" :
             <div class="gallery">
                {image
                   ? image?.map((item, key) => (
                       <img width="60px" height="60px" src={item} alt="" />
                     ))
                   : ""}
                 </div>}
                 { selectedId ?"":<small >Maximum Limit 10</small>}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
                Language Type
              </Form.Label>
              <Col sm='9'>
                <Form.Select
                  aria-label='Default select example'
                  required
                  className='hd-cr'
                  name='contentType' isInvalid={!!errors?.contentType}
                  defaultValue={contentType}
                  onChange={e => {setContentType(e.target.value); handleChange(e)}}
                >
                  <option value=''>Choose</option>
                  <option value='english'>English</option>
                  <option value='hindi'>Hindi</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                {errors?.contentType}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        {loader?<Button variant='primary' className='as_btn' >
        Processing...
      </Button>:<Button variant='primary' className='as_btn' onClick={e =>  `${selectedId?handleEdit():handleCreate(e)}`}>
      {`${selectedId ? 'Edit' : 'Add'}`}
    </Button>}
          
          <Button variant='secondary' className='as_btn' onClick={()=>{handleClose();setCropImg()}}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showdt}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDt}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
            Cancel
          </Button>

          <Button variant='primary' className='success_btn' onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <ToastContainer /> */}
      <CropModal 
    showcrop={showcrop}
    setShowCrop={setShowCrop}
    setCropImg={setCropImg}
    cropimg={cropimg}
    caartifileimage={caartifileimage}
    setFileCaartiimage={setFileCaartiimage}/>
      
    </>
  );
};

export default FestivalWish;
