import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner, Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../utils/APIGeneralService';
import { formateDateAndTime, SlugifyStringWithDash, capitalizeFirstLetter, setCookies } from '../utils/Helper';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../context/UserContextProvider';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const PujaPandit = () => {
  const Navigate = useNavigate();

   const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Pandit Image',
      Cell: e => {
        return (
          <img
            // src={`${e.row.original.posterImage ? e.row.original.posterImage : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Pandit Name',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },
    {
      Header: 'Number',
      Cell: e => {
        //   return <span>{e.row.original.address ?? '_'}</span>;
      },
    },
    {
      Header: 'Puja Date',
      Cell: e => {
        return (
          <>{/* <span>{e.row.original.pujaDate ? moment(e.row.original.pujaDate).format('lll') : '_'}</span> */}</>
        );
      },
    },
  ]);

  return (
    <div>
      <div className='main aarti_section'>
        <>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>Pandit</h4>
              <div className='_btn'>
                <Button
                  style={{ backgroundColor: 'green' }}
                  className='by-btn as_btn me-0'
                  variant='primary'
                  onClick={() => {
                    Navigate('/addEditPandit');
                  }}
                >
                  <FaPlus style={{ marginRight: '5px' }} size={13} />
                  Pandit
                </Button>
              </div>
            </div>
          </div>
        </>
        <div className='user-list-table'>
          <Container className='mb-2 '>
            <div class='feed_area'>
              <div class='chat_search_box user-search'>
                <div className='chat-search-field'>
                  <Row>
                    <Form>
                      <Row>
                        <Form.Group as={Row} className='mb-3' controlId=''>
                          <Col md={3}>
                            <Form.Label class='label-f-w-cd  mt-2 '>Pandit Name</Form.Label>
                            <Form.Control
                              className=' mt-2 form-ch'
                              name='name'
                              type='search'
                              placeholder='Search By Pandit Name'
                              value={name}
                              onChange={e => {
                                setName(e.target.value);
                                // setOffset(0);
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Label class='label-f-w-cd  mt-2 '>Address</Form.Label>

                            <Form.Control
                              className=' mt-2 form-ch'
                              name='address'
                              type='search'
                              placeholder='Search By Address'
                              value={address}
                              onChange={e => {
                                setAddress(e.target.value);
                                // setOffset(0);
                              }}
                            />
                          </Col>
                          <Col md={3}>
                            <Form.Label class='label-f-w-cd  mt-2 '>Mobile Number</Form.Label>

                            <Form.Control
                              className=' mt-2 form-ch'
                              name='number'
                              type='search'
                              placeholder='Search By Mobile No.'
                              value={mobileNumber}
                              onChange={e => {
                                setMobileNumber(e.target.value);
                                // setOffset(0);
                              }}
                            />
                          </Col>
                          
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group as={Row} className='mb-3' controlId=''>

                          <Col md={3} style={{ marginTop: '38px' }}>
                            <Button
                              variant='secondary'
                              className='as_btn'
                              onClick={() => {
                                // handleFilterClick();
                                // setOffset(0);
                              }}
                            >
                              Filter
                            </Button>
                            <Button
                              variant='secondary'
                              className='as_btn'
                              onClick={() => {
                                // resetData();
                              }}
                            >
                              Reset
                            </Button>
                          </Col>
                        </Form.Group>
                      </Row>
                    </Form>
                  </Row>
                </div>
              </div>
            </div>
          </Container>

          {/* {data && data.length > 0 ? ( */}
          {/* <DynamicDataTableWithCount
                      columns={columns}
                      data={data}
                      totalCount={pageCount}
                      initialState={{
                        pageIndex: pageIndex,
                        pageSize: perPage,
                      }}
                      fetchData={fetchData}
                      forcePage={offset / perPage}
                    /> */}
          {/* ) : (
                    <h2 className='no_records'>Records not Available</h2>
                  )} */}
        </div>
      </div>
    </div>
  );
};

export default PujaPandit;
