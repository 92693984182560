import React from "react";
import { Row, Col, Card, Button } from 'react-bootstrap';
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment';

const Watch = () => {
    const navigate = useNavigate()
    const { state: { data = {}, Date = '' } } = useLocation();
    return (

        <div class="blog_details">
        <div className="gita_quote_heading">
            <div className="container">
              {/* <Col className="mt-2 m-2"><Button className="as_btn" onClick={() => navigate(-1)}>Back</Button></Col> */}
              <FaArrowLeft
                 className="hd-cr "
                 style={{marginRight: "15px"}}
                 size={25}
                 onClick={() => navigate(-1)}/>
            </div>
        </div>
       <div className="container">
        <Row className="p-0 m-0 d-flex flex-column">
         
            <Col md={10} className="mx-auto">
                <Card className="p-2 m-2">

                <div className="card-header p-0">
                <iframe
                        title="astrowatch-content"
                        width="560"
                        height="500"
                        className="w-100"
                        src={data?.videoLink}
                        type="video/mp4"
                        autoPlay
                        controls
                    ></iframe>
                </div>
                    <p className="description font-500 mb-2 text-start mt-2">{Date && moment(Date).format('MMM Do YYYY')}</p>
                    <h4 className="card-title heading_18 mb-2 text-start">{data?.heading}</h4>
                    <p className="card-text-blog  description ">{data?.content}</p>
                </Card>
            </Col>
        </Row>
        </div>
    </div>
    );
}


export default Watch;
