import React, { useEffect, useState } from "react";

const YoutubeAnalytics = () => {

  const [statistics, setStatistics] = useState({});

  const fetchData = async () => {
    const response = await fetch("/json/youtube_data.json")
    const rawdata = await response.json()
    setStatistics({ ...rawdata.items[0].statistics });
    console.log(rawdata);
  }

  useEffect(() => {
    fetchData()
  }, [])


  return (
    <div>
      <div className="p1">
        <p> <i className="fa-solid fa-eye"></i> {statistics.viewCount} <br /> View Count</p>
      </div>
      <div className="p2">
        <p> <i className="fa-solid fa-user-group"></i> {statistics.subscriberCount} <br /> Subscriber Count </p>
      </div>
      {/* <p>Hidden Subscriber Count{statistics.hiddenSubscriberCount}</p> */}
      <div className="p3">
        <p> <i className="fa-solid fa-video"></i> {statistics.videoCount} <br /> Video Count</p>
      </div>
    </div>
  );
}

export default YoutubeAnalytics;