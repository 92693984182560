import { useState, useCallback, useMemo, useEffect,useContext } from 'react';
import DynamicDataTableWithCount from './DynamicDataTableWithCount';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Button, Modal, Form, Col, Row, Container } from 'react-bootstrap';
import APIServicenew from '../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies, formateDate } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import Papa from 'papaparse';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

function UserListNew() {
  document.title = 'Customers';
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  let astroUserId = cookies.get('astroUserId');
  const  {refreshToken} = useContext(UserContext);
  const navigate = useNavigate();
  const { user: adminUser } = useContext(UserContext);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount , setAllDataCount] = useState()
  const [show, setShow ] = useState(false);
  const [source , setSource] = useState('')
  const [signUpUtmSource , setSignUpUtmSource] = useState('')
  const [gender ,setGender] = useState('')
  const [name ,setName] = useState('')
  const [email ,setEmail] = useState('')
  const [number ,setNumber] = useState('')
  const [ country ,setCountry] = useState('')
  const [ isVerified ,setIsVerified] = useState('')
  const [isNotes ,setIsNotes] = useState('')
  const [ flag , setFlag] = useState(false)
  const [notesData ,setNotesData] = useState([])
  const [userForNote ,setUserForNote] = useState()
  const [error ,setError] = useState(false) ; 
  const [note ,setNote] = useState('')
  const [loader, setLoader] = useState(false)
  const [countData,setCountData]=useState()
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [countryOption ,setCountryOption] = useState([
    {name :"India",id:"65effd4325ca275efc0d2f67"},
    {name :"USA",id:"65effee925ca275efc0d3651"}
  ])

  const [startDate , setStartDate] = useState('')
  const [endDate,setEndDate] = useState('')
  const [selectedRange, setSelectedRange] = useState("");
  
  const handleDateRange = (range) => {
   const now = new Date();
   let start, end;

   if (range === "disabled") {
     setStartDate(null);
     setEndDate(null);
     setSelectedRange("");
     return;
   }

   switch (range) {
     case "today":
       start = end = now;
       break;
     case "yesterday":
       start = end = new Date(now.setDate(now.getDate() - 1));
       break;
     case "thisWeek":
       start = new Date(now.setDate(now.getDate() - now.getDay()));
       end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
       break;
     case "last7Days":
       start = new Date(now.setDate(now.getDate() - 7));
       end = new Date();
       break;
     case "thisMonth":
       start = new Date(now.getFullYear(), now.getMonth(), 1);
       end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
       break;
     case "lastMonth":
       start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
       end = new Date(now.getFullYear(), now.getMonth(), 0);
       break;
     case "customDate":
       start = end = null;
       break;
     default:
       break;
   }

   setStartDate(start);
   setEndDate(end);
   setSelectedRange(range);
 };

  const logout =() =>{
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }


  const statusMap = {
    true: { text: 'VERIFIED', testClass: 'text-success fw-bold' },
     false: { text: 'UNVERIFIED', testClass: 'text-danger fw-bold' },
    
   };

  const columns = useMemo(
    () => [
      {
        Header: 'NO.',
        Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
      },
      {
        Header: 'Name',
        Cell: (e) => {
          return  <span>{e.row.original.name??"_"}</span>
        }
      },
      {
        Header: 'Country',
        Cell: (e) => {
          return  <span>{e.row.original.country??"_"}</span>
        }
      },
      {
        Header: 'Email / Mobile Number',
        Cell: (e) => {
          return  <>
          <span>{e.row.original.email??"_"}</span><br/>
          <span>{e.row.original.contactNumber ??"_"}</span>

          </>
        }
      },
      {
        Header: 'Gender',
        Cell: (e) => {
          return  <span>{e.row.original.gender??"_"}</span>
        }
      },
      {
        Header: 'Source',
        Cell: (e) => {
          return  <span>{e.row.original.utmSource??"_"}</span>
        }
      },	
      {
        Header: 'Signup Source',
        Cell: (e) => {
          return  <span>{e.row.original.signUpUtmSource??"_"}</span>
        }
      },
      {
        Header: 'Verified',
        Cell: (e) => {
          return   <span className={statusMap[e.row.original.isVerified]?.testClass} >
          {statusMap[e.row.original.isVerified]?.text}
        </span>;
        }
      },
      {
        Header: 'Registration Date',
        Cell: e => {
          return <span>{moment(e.row.original.createdAt).format('lll')}</span>;
        },
      },
      {
        Header: 'Last Note Date',
        Cell: e => {
          return <span>{e.row.original?.lastNote?moment(e.row.original?.lastNote?.updatedAt).format('lll'):"_"}</span>;
        },
      },
      {
        Header: 'Last Note',
        Cell: (e) => {
          const lastMessage = e.row.original?.lastNote?.last_message ?? "_";
          const truncatedMessage = lastMessage.length > 20 ? lastMessage.substring(0, 10) + '...' : lastMessage; 
          return <>
           <h6 className='text-primary'>{ e.row.original?.lastNote?.last_adminName?e.row.original?.lastNote?.last_adminName:e.row.original?.lastNote?.last_responderName }</h6>
            <span title={lastMessage}>{truncatedMessage}</span>

          </>
          
        }
      },      
      {
        Header: 'Notes',
        Cell: e => {
          if (e.row.original._id) {
            return (
              <h5>
                <input
                  type='button'
                  className='Et-btn notes-btn'
                  value='Notes'
                  onClick={() =>{ setUserForNote(e.row.original) ;handleUserNotes(e.row.original._id)}}
                />
              </h5>
            );
          }
        },
      },
     
    ],
    
  );


  const handleCountData = async (id) => {
    const res = await APIServices.get(`userNotes/getAdminUserCount`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };

  const fetchContactList = useCallback(
    async (skip, limit, index) => {
      try {
let page = skip/limit+1
        const response = await APIServices.get(`userNotes/usersList/${page}/${limit}?name=${name}&email=${email}&contactNumber=${number}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&countryId=${country}&isVerified=${isVerified}&source=${source}&signUpUtmSource=${signUpUtmSource}&gender=${gender}&isNotes=${isNotes}`,headers);
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.resData?.total_count / limit));
          setAllDataCount(response.data?.resData?.total_count) 
          setTableData(response.data?.resData?.data);
          setFlag(false)
          window.scrollTo({ top: 0 });
        }else{
          if(response?.response?.data?.status == 4){
            logout()
           }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      // setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex,skip ,name ,email,number,startDate ,endDate,country ,isVerified ,source ,signUpUtmSource,gender ,isNotes]
  );


  const handleFilterClick =() =>{
    if (name||email||number|| startDate ||endDate|| skip ||country || isVerified || source || signUpUtmSource || gender || isNotes) {
        fetchContactList(skip ,limit,0 ,name,email,number,startDate ,endDate,country,isVerified ,source ,signUpUtmSource ,gender ,isNotes)
   }
   }

   const handleStartDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setStartDate(formattedDate);
  };

  const handleEndDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setEndDate(formattedDate);
  };


   const handleNotesAdd = async () => {

    if (!note || note === "") {
      setError(true)
  }else{
    let payload = {
      userId: userForNote?._id,
      source:"Marketing-Customer",
      type:adminUser?.adminType,
      adminName:adminUser?.name,
      adminId: adminUser?._id,
      message:note
    };
    setLoader(true)
    const res = await APIServices.post(`userNotes/userNoteMessage`, payload, headers);

    if (res && res?.data?.status === true) {
      toast.success("Note added successfully")
      setFlag(true)
      setShow(false)
      // getUserNotes(userForNote?.userId)
      setNote('')
      setLoader(false)

    }
    else {

      toast.error(res?.data?.message)
    
      setLoader(false)

    }
    setLoader(false)
  }
  }
  const handleChange = () =>{
    setError(false)
}
  const handleCloseModal = ()=>{setShow(false)
    setError(false)

  }
  
  const handleUserNotes = async(id) => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data)
        setShow(true)

      }else{
        console.log("error");
      }
    
    }
  }
   const resetData = () =>{
    setNumber('')
     setEmail('')
     setName('')
     setStartDate('');
     setEndDate('')
     setSelectedRange('')
     setCountry('')
     setIsVerified('')
     setIsNotes('')
     setSource('')
     setSignUpUtmSource('')
     setGender('')
     setFlag(true)
    }
 
    useEffect(()=>{
        fetchContactList(skip ,limit,pageIndex)
        handleCountData()
   },[flag])

   const handleDownload = () => {
    for (let item of tableData) {
      if (item.name) item['Name'] = item.name ?? "_"
      if (item.contactNumber) item['Mobile Phone'] = item.contactNumber;
      if (item.email) item['Email'] = item.email?? "_" ;
      if (item.country) item['Country'] = item.country; 
      if (item.utmSource) item["Source" ] = item.utmSource;
      if (item.signUpUtmSource) item["signUp Source"] = item.signUpUtmSource
      if (item.isVerified) item["Verified"] = item.isVerified === true? "Verified":"UnVerified"
      if (item.gender)item ["Gender"] = item.gender 
      item['Created Date ']=moment(item?.createdAt).format('lll')
      item['Updated Date ']=moment(item?.updatedAt).format('lll')
      // Add additional fields as needed
  
      // Delete unwanted fields
      delete item.name;
      delete item.contactNumber;
      delete item.email;
      delete item.countryId;
      delete item.country;
      delete item._id;
      delete item.countryCode;
      delete item.currency;
      delete item.phoneCode;
      delete item.profileImage ;
      delete item.utmSource;
      delete item.signUpUtmSource;
      delete item.gender;
      delete item.isVerified;
      delete item.status;
      delete item.wallet;
      delete item.isDeleted;
      delete item.createdAt;
      delete item.updatedAt;
      delete item.__v;
      // Delete additional unwanted fields

    }
  
    let csv = Papa.unparse(tableData, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(csvData);
    } else {
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
      a.setAttribute('download', 'userList_data.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  
  

  return (
    <div className='App'>
      <div className='gita_quote_heading'>
        <div className='container'>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='hd-list-f left-q counter-top'> Customers <small>{allDataCount}</small></h4>
            <h4 className="hd-list-f font_20 left-q counter-top">
             Today Customers <small>{countData?.usersAddedToday}</small>
            </h4>
            <h4 className="hd-list-f font_20 left-q counter-top">
              This Week Customers <small>{countData?.usersAddedThisWeek}</small>
            </h4>
            <h4 className="hd-list-f font_20 left-q counter-top">
              This Month Customers <small>{countData?.usersAddedThisMonth}</small>
            </h4>
            <div class='_btn'>
            <Button
                    className="by-btn as_btn"
                    variant="primary"
                    onClick={(e) => {
                      navigate(`/addquestion`);
                    }}
                  >
                   Add Customer
                  </Button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <div className='user-list-table  box-shadow br-6 p-20 my-3'>  
        <div class="feed_area pt-0">
          <div class="chat_search_box user-search">  
            <div className="chat-search-field">
               <Row>
               <Form>

                <Form.Group as={Row} className="mb-3"  controlId="">

                <Col md={3}>
                <Form.Label class="label-f-w-cd  mt-2 ">Name</Form.Label>
                
                 <Form.Control
                   className="hd-cr mt-2 form-ch"
                   name="name"
                   type="search"
                   placeholder="Search By Name"
                   value={name}
                   onChange={(e) => {setName(e.target.value);setSkip(0)}}
                 />
                 </Col>


                <Col md={3}>
                <Form.Label class="label-f-w-cd  mt-2 ">Email</Form.Label>
                
                 <Form.Control
                   className="hd-cr mt-2 form-ch"
                   name="email"
                   type="search"
                   placeholder="Search By email"
                   value={email}
                   onChange={(e) => {setEmail(e.target.value);setSkip(0)}}
                 />
                 </Col>
               
                
                 
<                 Col md={3}>
               <Form.Label class="label-f-w-cd  mt-2 ">Mobile Number</Form.Label>
                 
                  <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="number"
                    type="search"
                    placeholder="Search By Mobile Number"
                    value={number}
                    onChange={(e) => {setNumber(e.target.value);setSkip(0)}}
                  />
                  </Col>


                <Col md={3}>
                <Form.Label class="label-f-w-cd  mt-2 ">Country</Form.Label>
                
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                  onChange={(e)=>{setCountry(e.target.value);setSkip(0)}}
                  value={country}
                >
                  <option className='fw-bold'>
                    All
                  </option>
                 {countryOption && Array.isArray(countryOption) && countryOption.map((item, index) => (
                 <option className='text-capitalize' value={item.id} key={index}>
                   {item.name}
                 </option>
                ))}
                
                </Form.Select>
                </Col>
                  </Form.Group>
                 
                  <Form.Group as={Row} className="mb-3"  controlId="">
                  <Col md={3}>
                    <Form.Label class="label-f-w-cd">Verified</Form.Label>
                
                      <Form.Select
                      aria-label='label-selected-menu'
                      className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                      onChange={(e)=>{setIsVerified(e.target.value);setSkip(0)}}
                      value={isVerified}
                    >
                      <option className='fw-bold'>
                        All
                      </option>
                      <option className='text-capitalize' value='yes' >
                    Yes
                    </option>
                    <option className='text-capitalize' value='no'>
                      No
                    </option>
                    </Form.Select>
                 </Col>
                <Col md={3}>
                <Form.Label class="label-f-w-cd">Source</Form.Label>
                
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                  onChange={(e)=>{setSource(e.target.value);setSkip(0)}}
                  value={source}
                >
                  <option className='fw-bold'>  All </option>
                  <option className='text-capitalize' value='web' > Web </option>
                  <option className='text-capitalize' value='whatsApp'> WhatsApp</option>
                  <option className='text-capitalize' value='mobile'> Mobile</option>
                  <option className='text-capitalize' value='instagram'> Instagram</option>
                  <option className='text-capitalize' value='facebook'>Facebook</option>
                  <option className='text-capitalize' value='global'>Global</option>
                </Form.Select>
                </Col>
                <Col md={3}>
                <Form.Label class="label-f-w-cd"> SignUp Source</Form.Label>
                
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                  onChange={(e)=>{setSignUpUtmSource(e.target.value);setSkip(0)}}
                  value={signUpUtmSource}
                >
                 <option className='fw-bold'>  All </option>
                  <option className='text-capitalize' value='web' > Web </option>
                  <option className='text-capitalize' value='whatsApp'> WhatsApp</option>
                  <option className='text-capitalize' value='mobile'> Mobile</option>
                  <option className='text-capitalize' value='puja'> Puja</option>
                  <option className='text-capitalize' value='instagram'> Instagram</option>
                  <option className='text-capitalize' value='facebook'>Facebook</option>
                  <option className='text-capitalize' value='global'>Global</option>
                </Form.Select>
                </Col>
                <Col md={3}>
                <Form.Label class="label-f-w-cd"> Gender</Form.Label>
                
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                  onChange={(e)=>{setGender(e.target.value);setSkip(0)}}
                  value={gender}
                >
                  <option className='fw-bold'> All</option>
                  <option className='text-capitalize' value='male' >Male </option>
                  <option className='text-capitalize' value='female'> Female </option>
                  <option className='text-capitalize' value='other'> Other </option>

                </Form.Select>
                </Col>
              
              </Form.Group>
                  
        
                  <Form.Group as={Row} className="mb-3 userlist-calender align-items-end"  controlId="">
                  <Col md={3}>
                <Form.Label class="label-f-w-cd"> Notes</Form.Label>
                
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch'
                  onChange={(e)=>{setIsNotes(e.target.value);setSkip(0)}}
                  value={isNotes}
                >
                  <option className='fw-bold'> All</option>
                  <option className='text-capitalize' value='yes' >Yes </option>
                  {/* <option className='text-capitalize' value='no'> No </option> */}

                </Form.Select>
                </Col>
                    <Col md={3}>
                      <Form.Label class="label-f-w-cd">Search By Registration Date</Form.Label>

                        {/* <Col md={2}>

                          <Form.Label class="label-f-w-cd  my-1 ">
                                      Select by Start Date
                                    </Form.Label>
                            <DatePicker
                                  placeholderText={"Select by Start Date"}
                                  className="form-control form-ch fil-date-pick"
                                  name="startdate"
                                  selected={startDate ? new Date(startDate) : null}
                                  showIcon
                                  toggleCalendarOnIconClick
                                  onChange={(date) => {
                                    handleStartDate(date);
                                    setSkip(0);
                                  }}
                                  dateFormat="yyyy-MM-dd"
                            />
                          </Col>

                          <Col md={2}>

                          <Form.Label class="label-f-w-cd  my-1 ">
                                      Select by End Date
                          </Form.Label>
                          <DatePicker
                                placeholderText={"Select by End Date"}
                                className="form-control form-ch fil-date-pick"
                                name="enddate"
                                showIcon
                                toggleCalendarOnIconClick
                                selected={endDate ? new Date(endDate) : null}
                                onChange={(date) => {
                                  handleEndDate(date);
                                  setSkip(0);
                                }}
                                dateFormat="yyyy-MM-dd"
                          />
                        </Col> */}

                      <div className="date-range-wrapper mt-2"> 
                          <div className='select-icon-wrapper'>  
                            <select
                            className='select-range'
                              value={selectedRange}
                              onChange={(e) => handleDateRange(e.target.value)}
                            >
                              <option value="disabled">Select Date Range</option>
                              <option value="today">Today</option>
                              <option value="yesterday">Yesterday</option>
                              <option value="thisWeek">This Week</option>
                              <option value="last7Days">Last 7 Days</option>
                              <option value="thisMonth">This Month</option>
                              <option value="lastMonth">Last Month</option>
                              <option value="customDate">Custom Date</option>
                            </select>
                            <img src='/images/down-arrow.png' className='select-icons'/>
                          </div>

                            {selectedRange === "customDate" && (
                              <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={new Date()}
                                onChange={(update) => {
                                  const [start, end] = update;
                                  setStartDate(start);
                                  setEndDate(end);
                                }}
                                // isClearable={true}
                                placeholderText="Select custom date range"
                                dateFormat="yyyy-MM-dd"
                              />
                            )}
                      </div>
  
                      </Col>
                      <Col md={3} className='mt-3'>
                              <Button variant="secondary"
                                  className="as_btn ms-0"
                                  onClick={()=> {handleFilterClick();setSkip(0)}}
                              >
                                Filter
                              </Button>
                              <Button variant="secondary"
                                className="as_btn reset-btn"
                                onClick={()=> {resetData()}}
                                >
                                Reset
                              </Button>
                            </Col>
                {/* <Button
                className='as_btn ms-2'
                variant='primary'
                disabled={!tableData || (tableData && tableData.length <= 0)}
                onClick={() => {
                  // handleDownload();
                }}
              >
                Download File
              </Button> */}
                  </Form.Group>
         
          </Form>
                </Row>
            </div>
          </div> 
        </div> 
        {/* </div> */}
    </div>

  
          { tableData && tableData.length > 0 ?
            (
              <DynamicDataTableWithCount
                columns={columns}
                data={tableData}
                totalCount={pageCount}
                initialState={{
                  pageIndex: pageIndex,
                  pageSize: limit,
                }}
                fetchData={fetchContactList}
                forcePage={skip/limit}
              />
            ) 
            :
            (<h2 className="no_records">Records not Available</h2>)
          } 
   


      <Modal
      centered
        show={show}
        size='md'
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true} 
        backdrop='static'
        onHide={handleCloseModal}
      >
        <Modal.Header className='modal-header-hd' closeButton>
          <Modal.Title> Add Note</Modal.Title>
          </Modal.Header>
        <Modal.Body>
        <div className="aa d-flex">
            <span className="me-3">  Name :- {userForNote?.name} </span>
           <span> Contact Number :- {userForNote?.contactNumber} </span> 
          
          </div>
         
          <Row>
            <Col>
        <Form.Group as={Row} className="my-3" controlId="formAartiInput">
        
          <Col sm="12">
            <Form.Control
              as="textarea"
              name="content"
              placeholder="Type here..."
              value={note}
              style={{ height: 100 }}
              onChange={(e) => {handleChange();setNote(e.target.value)}}
            />
            {error?
             <p className='text-danger'>Please enter your note</p>:''}
          </Col>

          </Form.Group>
              
              </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
          {
            loader ? <Button variant="primary" className="as_btn" >
              Processing...
            </Button> : <Button variant="primary" className="as_btn" onClick={handleNotesAdd}>
              Add
            </Button>
          }
          <Button variant="secondary" className="as_btn" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>
       

     {notesData && notesData?.notes?.length > 0?<>
      {notesData?.notes && notesData?.notes?.length>0 && notesData?.notes?.map((item ,index) =><>
        <Row>
                      { item?.source && item?.source != "" ?  
                          <Col xs={5}>
                              <span className="aa">Source :- </span>
                                <span className="item-msg">{item.source ? item.source : "" } </span>
                          </Col> : ""
                      }

                      {item?.sourcename && item?.sourcename != "" ? 
                        <Col xs={7}>
                            <span className="aa">Action :- </span>
                              <span className="item-msg">
                                {/* {item?.sourcename} */}
                                {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                                </span>
                        </Col> : ""
                      }
                </Row>
        <Row className="notes-item">
              <Col xs={9}>
              <span className="item-name aa">
                              Note :- 
                  </span>
              <span className="item-msg">{item?.message}</span>
              </Col>
              <Col xs={3}>
                <div className="text-end d-flex flex-column">
                <span className="text-primary item-name aa">{item?.responderName || item?.adminName}</span> 
                <span className="item-text">{ item?.createdAt? moment(item?.createdAt).format('lll') :''}</span>
                </div>
              </Col>
            </Row>
        </>)}</>:<><h4 className='text-center text-secondary'>Not Available</h4></>}


     
     </Modal.Body>
      </Modal>

      <ToastContainer />

     
    </div>
  );
}

export default UserListNew;
