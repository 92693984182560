import React from 'react'
import Card from 'react-bootstrap/Card';
import { useLocation } from 'react-router-dom';
import EventData from '../components/Calendar/event';

const CalendarEvent = () => {
    const { state } = useLocation();
    return (
        <main className='d-flex gap-3 flex-column my-4 mx-3'>
            <h4 className='w-100 text-center'>Calendar Event</h4>
            <Card className='p-3 border border-dark'>
                <EventData event={state?.event} startDate={state && state.event ? state.event.startDate : state.startDate} endDate={state && state.event ? state.event.endDate : state.endDate} mode={state?.mode} />
            </Card>
        </main>
    )
}

export default CalendarEvent