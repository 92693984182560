import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { FaTrash } from 'react-icons/fa';

const initialData = {
  name: '',
  gotra: '',
  address: '',
  contactNumber: '',
  maskcontactNumber: '',
  paymentStatus: '',
  remark: '',
  joiningLink: '',
  pujaPlan: null,
  prasad_add:'',
  dakshina_add:'',
  prasad_price:'',
  dakshina_price:'',
  city : '',
  state: '',
  country: 'India',
  postalCode: '',
  address2: '',
  selectedPrice:''
};

const BookingModal = ({ show, handleClose, pujaData, onSubmit, selectedBooking,totalAmount, setTotalAmount }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    trigger,
    reset,
    watch,
    setValue,
  } = useForm();

  const pujaPlanSelection = watch('pujaPlan');
  const memberNameValue = watch('memberName');
  const memberGotraValue = watch('memberGotra');
  const paymentStatusValue = watch('paymentStatus');
  const prasad_add_StatusValue = watch('prasad_add');
  const dakshina_add_StatusValue = watch('dakshina_add');
  const prasad_Price_StatusValue = watch('prasad_price');
  const dakshina_Price_StatusValue = watch('dakshina_price');
  const total_price_value = watch('total_price')
  const phoneCode = watch('phoneCode')
  const chadawa_items_value=watch('chadawa_items');
  const currencySymbol = phoneCode === "+1" ? "$" : "₹" 
  
  const [members, setMembers] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState("");


  useEffect(() => {
    if (selectedBooking) {
      const formData = {
        name: selectedBooking?.name,
        gotra: selectedBooking?.gotra,
        address: selectedBooking?.address,
        maskcontactNumber: selectedBooking?.maskcontactNumber,
        contactNumber: selectedBooking?.contactNumber,
        joiningLink: selectedBooking?.joiningLink,
        paymentStatus: { label: selectedBooking?.paymentStatus, value: selectedBooking?.paymentStatus },
        pujaPlan: {
          value: selectedBooking?.pujaPlan,
          label: `${selectedBooking?.pujaPlan?.title} -- ${selectedBooking?.pujaPlan?.price}`,
        },
        prasad_add: { label: selectedBooking?.prasad_add === "yes" ? "Yes" : "No", value: selectedBooking?.prasad_add },
        dakshina_add: { label: selectedBooking?.dakshina_add === "yes" ? "Yes" : "No", value: selectedBooking?.dakshina_add },
        prasad_price: selectedBooking?.prasad_add === "yes" ? selectedBooking?.prasad_price : null,
        dakshina_price: selectedBooking?.dakshina_add === "yes" ? selectedBooking?.dakshina_price : null,
        remark : selectedBooking?.remark,
        city : selectedBooking?.city,
        state : selectedBooking?.state,
        country : selectedBooking?.country,
        postalCode : selectedBooking?.postalCode,
        address2 : selectedBooking?.address2,
        total_price : selectedBooking?.total_price
      };

      setSelectedPrice(selectedBooking?.pujaPlan?.price)
      if (selectedBooking?.members && selectedBooking?.members?.length > 0) {
        formData['memberName'] = selectedBooking?.members?.map(mem => mem?.name);
        formData['memberGotra'] = selectedBooking?.members?.map(mem => mem?.gotra);
      }
      reset(formData); // Set default form values based on selectedBooking
      setMembers(selectedBooking?.members ?? []);
    } else {
      reset(initialData);
    }
  }, [selectedBooking]);

  const getPujaPlanOptions = () => {
    const options = [];
    const { poojaSelectionOptions: selectionOptions } = pujaData;
    selectionOptions.forEach(option => {
      options.push({
        label: `${option.price} -- ${option.title} `,
        value: { title: option.title, price: option.price },
      });
    });
    return options;
  };



const handlePujaPlanChange = (selectedOption) => {
  setSelectedPrice(selectedOption?.value?.price); 
};

useEffect(()=>{
  getTotalAmount()
},[prasad_add_StatusValue,dakshina_add_StatusValue,prasad_Price_StatusValue,selectedPrice,dakshina_Price_StatusValue,chadawa_items_value])

const getTotalAmount=()=>{

  // const totalAmountNew = Number(selectedPrice) +(prasad_add_StatusValue?.value === "yes" ? Number(prasad_Price_StatusValue) : 0) +
  //                        (dakshina_add_StatusValue?.value === "yes" ? Number(dakshina_Price_StatusValue) : 0);

  // setTotalAmount(totalAmountNew)

  const chadhawaItems = selectedBooking?.chadawa_items
  const chadhawatotalPrice = chadhawaItems?.reduce((sum, item) => sum + Number(item?.price), 0);

  const totalAmountNew = Number(selectedPrice) +(prasad_add_StatusValue?.value === "yes" ? Number(prasad_Price_StatusValue) : 0) +
   (dakshina_add_StatusValue?.value === "yes" ? Number(dakshina_Price_StatusValue) : 0)
   + (chadhawaItems?.length > 0 ? chadhawatotalPrice : 0);
   setTotalAmount(totalAmountNew)
}


  const getPaymentStatusOptions = () => {
    const options = [
      { label: 'Paid', value: 'Paid' },
      // { label: 'Failed', value: 'Failed' },
    ];
    return options;
  };

  const getPrasadStatus = () => {
    const options = [
      // { label: 'Select Status', value: '' },
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ];
    return options;
  };

  const getDakshinaStatus = () => {
    const options = [
      // { label: 'Select Status', value: ''},
      { label: 'Yes', value: 'yes' },
      { label: 'No', value: 'no' },
    ];
    return options;
  };


  const isAddMemberVisible = () => {
    if (!pujaPlanSelection) return false;
    const currentSelection = pujaPlanSelection?.value?.title;
    const { poojaSelectionOptions: selectionOptions } = pujaData;
    const optionIndex = selectionOptions.findIndex(opt => opt.title === currentSelection);
    if (optionIndex >= 1) return true;

    return false;
  };

  const handleAddMemberClick = () => {
    const copyCurrentMemebers = [...members];
    copyCurrentMemebers.push({
      name: '',
      gotra: '',
    });

    setMembers(copyCurrentMemebers);
  };

  const handleMemberDelete = index => {
    const copyCurrentMemebers = [...members];
    copyCurrentMemebers.splice(index, 1);

    setMembers(copyCurrentMemebers);
    let copyMemNames = [...memberNameValue];
    copyMemNames.splice(index, 1);
    setValue('memberName', copyMemNames);
    let copyMemGotra = [...memberGotraValue];
    copyMemGotra.splice(index, 1);
    setValue('memberGotra', copyMemGotra);
  };

  const handleBookingSubmit = async () => {
    await trigger(); // Trigger form validation

    if (Object.keys(errors).length === 0) {
      const formData = getValues(); // Get form data using getValues()

      if (members && members.length > 0) {
        const formattedMembers = members.map((_, index) => {
          return {
            name: formData?.memberName?.[index],
            gotra: formData?.memberGotra?.[index],
          };
        });
        formData.members = formattedMembers;

        delete formData?.memberName;
        delete formData?.memberGotra;
      }
      onSubmit(formData); // Manually call onSubmit with the form data
    }
  };

  const handleModalClose = () => {
    reset(initialData);
    setSelectedPrice('')
    setMembers([]);
    handleClose();
  };

  // const phoneCode = () => {
  //   return (
  //     <>
  //       <Form.Group className='phn-code' size='md' controlId='validationCustom01'>
  //         {/* <Form.Label class='required'></Form.Label> */}
  //         <Form.Select
  //           aria-label='Default select example'
  //           required
  //           className='hd-cr'
  //           style={{ height: '42px', border: '1px solid #b3b3b3' }}
  //           isInvalid={!!errors?.phoneCode}
  //           name='phoneCode'
  //           value={state?.phoneCode}
  //           onChange={e => {
  //             setState({ ...state, phoneCode: e.target.value });
  //             handleChange(e);
  //           }}
  //         >
  //           {/* <option value=''>Choose</option> */}
  //           <option value='+91'>+91</option>
  //           <option value='+1'>+1</option>
  //         </Form.Select>
  //         <Form.Control.Feedback type='invalid'>{errors?.phoneCode}</Form.Control.Feedback>
  //       </Form.Group>
  //     </>
  //   );
  // };
  // const renderUserMobile = () => {
  //   return (
  //     <>
  //       <Form.Group className='phn-num' size='md' controlId='validationCustom01'>
  //         {/* <Form.Label class='required'> User Contact Number</Form.Label> */}
  //         <Form.Control
  //           required
  //           name='userContactNumber'
  //           type='text'
  //           placeholder='Number'
  //           isInvalid={!!errors?.userContactNumber}
  //           value={state.userContactNumber}
  //           onChange={e => {
  //             setState({ ...state, userContactNumber: e.target.value });
  //             handleChange(e);
  //           }}
  //           onKeyPress={(evnt) => {
  //             // Allow only numeric characters
  //             if (!/^\d$/.test(evnt.key)) {
  //               evnt.preventDefault();
  //             }
  //           }}
  //         />
  //         <Form.Control.Feedback type='invalid'>{errors?.userContactNumber}</Form.Control.Feedback>
  //       </Form.Group>
  //     </>
  //   );
  // };

  return (
    <>
      <Modal
        show={show}
        size='xl'
        centered
        aria-labelledby='example-modal-sizes-title-sm'
        onHide={handleModalClose}
        className='puja_booking_modal'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className='modal-header' closeButton>
            <Modal.Title>{selectedBooking ? 'Edit' : 'Add'} Puja Booking </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='login_form'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>Name</label>
                    <input
                      {...register('name', {
                        required: { value: true, message: 'Name is required' },
                      })}
                      className='form-control'
                      placeholder='Enter name'
                    />
                    {errors.name && <p className='text-danger'>{errors.name?.message}</p>}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>Gotra</label>
                    <input
                      {...register('gotra', {
                        required: { value: true, message: 'Gotra is required' },
                      })}
                      className='form-control'
                      placeholder='Enter Gotra'
                    />
                    {errors.gotra && <p className='text-danger'>{errors.gotra?.message}</p>}
                  </div>
                </div>
                <div className='col-lg-6'>
                  {!selectedBooking ? (
                    <>

                      <label className='required mb-0 mt-2'>WhatsApp Number</label>
                      <div className='form-group phn-code'>
                        <select
                          {...register('phoneCode', {})}
                          aria-label='Default select example'
                          required
                          className='hd-cr'
                          style={{ height: '40px', border: '1px solid #b3b3b3' }}
                          // isInvalid={!!errors?.phoneCode}
                          name='phoneCode'
                          // value={state?.phoneCode}
                          // onChange={e => {
                          //   setState({ ...state, phoneCode: e.target.value });
                          //   handleChange(e);
                          // }}
                        >
                          {/* <option value=''>Choose</option> */}
                          <option value='+91'>+91</option>
                          <option value='+1'>+1</option>
                        </select>
                      </div>
                      <div className='form-group phn-num'>
                        {/* <label className='required'></label> */}
                        <div className='as_input_feild'>
                          <input
                            {...register('contactNumber', {
                              required: { value: true, message: 'WhatsApp Number is required' },
                              // pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Invalid Whatsa2pp Mobile Number' },
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message: 'WhatsApp Number cannot be less then 10 digits and more then 10 digits',
                              },
                            })}
                            // type='tel'
                            type='text'
                            maxLength={10}
                            minLength={10}
                            className='form-control'
                            placeholder='Enter WhatsApp Number'
                            onKeyPress={evnt => {
                              // Allow only numeric characters
                              if (!/^\d$/.test(evnt.key)) {
                                evnt.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      {errors.contactNumber && <p className='text-danger'>{errors.contactNumber?.message}</p>}
                    </>
                  ) : (
                    <div className='form-group '>
                      <label className='required'>WhatsApp number</label>
                      <div className='as_input_feild'>
                        <input
                          {...register('maskcontactNumber', {})}
                          type='text'
                          className='form-control'
                          placeholder='Enter WhatsApp Number'
                          onKeyPress={evnt => {
                            // Allow only numeric characters
                            if (!/^\d$/.test(evnt.key)) {
                              evnt.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                </div>
                {selectedBooking?.paymentStatus != 'Paid' ? (
                  <>
                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className=''>Payment Status</label>
                        <Controller
                          name='paymentStatus'
                          control={control}
                          render={({ field }) => <Select classNamePrefix="react-select"  {...field} options={getPaymentStatusOptions()} isDisabled={selectedBooking?.paymentStatus ? true : false}/>}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>Address 1</label>
                    <textarea
                      {...register('address', {
                        required: { value: true, message: 'Address 1 is required' },
                      })}
                      className='form-control'
                      placeholder='Enter Address'
                      rows={3}
                    />
                    {errors.address && <p className='text-danger'>{errors.address?.message}</p>}
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className=''>Address 2</label>
                    <textarea
                      {...register('address2', {
                        // required: { value: true, message: 'Address 2 is required' },
                      })}
                      className='form-control'
                      placeholder='Enter Address'
                      rows={3}
                    />
                    {errors.address2 && <p className='text-danger'>{errors.address2?.message}</p>}
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>City</label>
                    <input
                      {...register('city', {
                        required: { value: true, message: 'City is required' },
                      })}
                      className='form-control'
                      placeholder='Enter City'
                    />
                    {errors.city && <p className='text-danger'>{errors.city?.message}</p>}
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>State</label>
                    <input
                      {...register('state', {
                        required: { value: true, message: 'State is required' },
                      })}
                      className='form-control'
                      placeholder='Enter State'
                    />
                    {errors.state && <p className='text-danger'>{errors.state?.message}</p>}
                  </div>
                </div>

                

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>Country</label>
                    <input
                      {...register('country', {
                        required: { value: true, message: 'Country is required' },
                      })}
                      className='form-control'
                      placeholder='Enter Country'
                    />
                    {errors.country && <p className='text-danger'>{errors.country?.message}</p>}
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>Postal Code</label>
                    <input
                      {...register('postalCode', {
                        required: { value: true, message: 'Postal Code is required' },
                      })}
                      className='form-control'
                      placeholder='Enter Postal Code'
                    />
                    {errors.postalCode && <p className='text-danger'>{errors.postalCode?.message}</p>}
                  </div>
                </div>

                <hr className='mt-3' />
               
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>Puja Plan</label>
                      <Controller
                        name='pujaPlan'
                        control={control}
                        rules={{ required: { value: true, message: 'Puja Plan is required' } }}
                        render={({ field }) => 
                        <Select classNamePrefix="react-select" 
                       {...field}
                        options={getPujaPlanOptions()}
                        onChange={(selectedOption) => {
                        field.onChange(selectedOption); // Update form value
                        handlePujaPlanChange(selectedOption); // Update price state
                        }}
                        />}
                      />
                    {errors.pujaPlan && <p className='text-danger'>{errors.pujaPlan?.message}</p>}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label>Price</label>
                    <input
                      type="text"
                      className="form-control"
                      value={ pujaPlanSelection?.value?.price} 
                      readOnly
                    />
                 </div>
                 </div>

                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='required'>Prasad</label>
                        <Controller
                          name='prasad_add'
                          control={control}
                          rules={{ required: { value: true, message: 'Prasad status is required' } }}
                          render={({ field }) => <Select classNamePrefix="react-select"  {...field} options={getPrasadStatus()}/>}
                        />
                        {errors.prasad_add && <p className='text-danger'>{errors.prasad_add?.message}</p>}
                      </div>
                    </div>
                  { prasad_add_StatusValue?.value === "yes" ? 
                    ( <>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='required'>Prasad Price</label>
                                <div className='as_input_feild'>
                                  <input
                                    {...register('prasad_price', {
                                      required: { value: true, message: 'Prasad Price is required' },
                                      validate: value => parseFloat(value) > 0 || 'Prasad Price must be greater than 0',
                                    })}
                                    type='text'
                                    className='form-control'
                                    placeholder='Prasad Price'
                                    onKeyPress={evnt => {
                                      // Allow only numeric characters
                                      if (!/^\d$/.test(evnt.key)) {
                                        evnt.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                            </div>
                            {errors.prasad_price && <p className='text-danger'>{errors.prasad_price?.message}</p>}
                        </div>
                    </>

                    ) : 
                    (
                      <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className=''>Prasad Price</label>
                            <div className='as_input_feild'>
                              <input
                                type='text'
                                className='form-control'
                                readOnly
                                placeholder='Prasad Price'
                              />
                            </div>
                        </div>
                     </div>
                    )
                }

                    <div className='col-lg-6'>
                      <div className='form-group'>
                        <label className='required'>Dakshina</label>
                        <Controller
                          name='dakshina_add'
                          control={control}
                          rules={{ required: { value: true, message: 'Dakshina status is required' } }}
                          render={({ field }) => <Select classNamePrefix="react-select"  {...field} options={getDakshinaStatus()}/>}
                        />
                        {errors.dakshina_add && <p className='text-danger'>{errors.dakshina_add?.message}</p>}
                      </div>
                    </div>

                    { dakshina_add_StatusValue?.value === "yes" ? 
                    ( <>
                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className='required'>Dakshina Price</label>
                            <div className='as_input_feild'>
                              <input
                                {...register('dakshina_price', {
                                  required: { value: true, message: 'Dakshina Price is required' },
                                  validate: value => parseFloat(value) > 0 || 'Dakshina Price must be greater than 0',
                                })}
                                type='text'
                                className='form-control'
                                placeholder='Dakshina Price'
                                onKeyPress={evnt => {
                                  // Allow only numeric characters
                                  if (!/^\d$/.test(evnt.key)) {
                                    evnt.preventDefault();
                                  }
                                }}
                              />
                            </div>
                        </div>
                          {errors.dakshina_price && <p className='text-danger'>{errors.dakshina_price?.message}</p>}
                    </div>
                    </>

                    ) :  
                    (
                      <div className='col-lg-6'>
                        <div className='form-group'>
                            <label className=''>Dakshina Price</label>
                            <div className='as_input_feild'>
                              <input
                                type='text'
                                className='form-control'
                                readOnly
                                placeholder='Dakshina Price'
                              />
                            </div>
                        </div>
                     </div>
                    )
                }
              
                


              <div className='col-lg-12 d-flex justify-content-between mt-3'>
                <h5 className='fw-bold'>Total Amount</h5>
                <h5 className='fw-bold'>
                  
                  { `${selectedBooking?.currencySymbol ??currencySymbol} ${totalAmount}` || `${selectedBooking?.currencySymbol??currencySymbol} ${selectedBooking?.total_price}`}


                  </h5>
              </div>
            

                {/* <div className='col-lg-6'>
                  <div className='form-group'>
                    <label>Puja Joining Link</label>
                    <input
                      {...register('joiningLink', {
                      })}
                      type='url'
                      className='form-control'
                      placeholder='Enter Puja Joining Link'
                    />
                  </div>
                </div> */}
                
                {paymentStatusValue?.value != 'Pending' ? (
                      <div className='col-lg-12'>
                        <div className='form-group'>
                          <label className='required'>Remark</label>
                          <input
                            {...register('remark', {
                              required: { value: true, message: 'Remark is required' },
                            })}
                            className='form-control'
                            placeholder='Enter Remark'
                          />
                          {errors.remark && <p className='text-danger'>{errors.remark?.message}</p>}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
              </div>
              {isAddMemberVisible() && (
                <div>
                  <hr />
                  <h5>Puja Members</h5>
                  {members &&
                    members?.length > 0 &&
                    members?.map((member, i) => {
                      return (
                        <div className='col-lg-12 row align-items-center' key={i}>
                          <div className='form-group col-lg-3'>
                            <label className='required'>Member Name</label>
                            <input
                              {...register(`memberName[${i}]`, {
                                required: { value: true, message: 'Member Name is required' },
                              })}
                              className='form-control'
                              placeholder='Name'
                            />
                            {errors[`memberName`]?.[i] && (
                              <p className='text-danger'>{errors?.[`memberName`]?.[i]?.message}</p>
                            )}
                          </div>
                          <div className='form-group col-lg-3'>
                            <label className='required'>Member Gotra</label>
                            <input
                              {...register(`memberGotra[${i}]`, {
                                required: { value: true, message: 'Member Gotra is required' },
                              })}
                              className='form-control'
                              placeholder='Gotra'
                            />
                            {errors[`memberGotra`]?.[i] && (
                              <p className='text-danger'>{errors?.[`memberGotra`]?.[i]?.message}</p>
                            )}
                          </div>
                          <FaTrash
                            className='col-lg-1 text-danger mt-4'
                            style={{ cursor: 'pointer' }}
                            title='Delete Puja Member'
                            onClick={() => {
                              handleMemberDelete(i);
                            }}
                          />
                        </div>
                      );
                    })}
                  <Button className='as_btn ms-0' onClick={handleAddMemberClick}>
                    Add Puja member +
                  </Button>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className={`${!selectedBooking && 'justify-content-between'}`}>
            {!selectedBooking && (
              <span className='text-danger'>
                *Confirming the booking will send notification to the user. Make sure all the information is correct
              </span>
            )}
            <div>
              <Button
                variant='primary'
                className='as_btn'
                type='submit'
                //  onClick={handleBookingSubmit}
              >
                {selectedBooking ? 'Edit' : 'Confirm'} Puja Booking
              </Button>
              <Button variant='secondary' className='as_btn cancle_btn' onClick={handleModalClose}>
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default BookingModal;
