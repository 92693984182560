import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TagsInput } from 'react-tag-input-component'

export function Commonmodal(props) {
    const [tags, setTags] = useState([])
    const [categories, setCategories] = useState([])

    const reset = () => {
        setCategories([])
        setTags([])
    }

    const Showinputs = (prop) => {
        switch (prop.show) {
            case "categories":
                return (<div className="form-group">
                    <label htmlFor='catgories' className='my-2'>Categories</label>
                    <TagsInput
                        value={categories}
                        onChange={setCategories}
                        name="catgories"
                        placeHolder="Enter Categories"
                    />
                </div>)

            case "tags":
                return (<div className="form-group">
                    <label htmlFor='tags' className='my-2'>Tags</label>
                    <TagsInput
                        value={tags}
                        onChange={setTags}
                        name="tags"
                        placeHolder="Enter tags"
                    />
                </div>)

            default:
                return (<>
                    <div className="form-group">
                        <label htmlFor='tags' className='my-2'>Tags</label>
                        <TagsInput
                            value={tags}
                            onChange={setTags}
                            name="tags"
                            placeHolder="Enter tags"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor='catgories' className='my-2'>Categories</label>
                        <TagsInput
                            value={categories}
                            onChange={setCategories}
                            name="catgories"
                            placeHolder="Enter Categories"
                        />
                    </div>
                </>)
        }
    }

    return (
        <Modal
            show={props.show} onHide={props.onHide} animation
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <Showinputs show={props.field} />
                <p className={props.message === 'Successfully updated' ? 'text-success' : 'text-danger'}>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    props.handleUpdate(tags, categories)
                    setTimeout(() => {
                        props.onHide()
                        reset()
                    }, 4000)
                }} className="modalButton" >Update</Button>
                <Button onClick={() => {
                    props.onHide()
                    reset()
                }} className="modalButton">Close</Button>
            </Modal.Footer>
        </Modal >
    );
}
