import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import APIService from "../utils/APIGeneralService";
import { Trash3Fill, PencilSquare, PlusSquare } from "react-bootstrap-icons"
import { useLocation } from "react-router-dom";
import { Commonmodal } from "../components/Shared/modal";


const Youtube = () => {
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState([])
  const [modalShow, setModalShow] = useState({
    id: null,
    modal: false,
    message: ""
  })
  const location = useLocation()
  const APIServices = new APIService(process.env.REACT_APP_INSTA_API_URL)

  const deletePost = async (id) => {
    setLoading(true)
    const resp = await APIServices.delete(`/youtube/deletePost/${id}`)
    if (resp.data.code === 200) {
      let newPosts = [...posts]
      newPosts = newPosts.filter(post => post._id !== id)
      setPosts(newPosts)
      setLoading(false)
    }
  }

  const handleUpdate = async (...inputs) => {
    let categories = inputs[1]
    const resp = await APIServices.put(`/youtube/updatePost/${modalShow.id}`, {
      categories
    })
    if (resp.data.message === 'success') {
      setModalShow(prev => ({ ...prev, message: 'Successfully updated' }))
    } else {
      setModalShow(prev => ({ ...prev, message: 'An Error occurred!' }))
    }
  }
  const getAllPost = async () => {
    const resp = await APIService.get('/youtube/getAllPosts')
    if (resp.data.data.length) {
      setPosts(resp.data.data)
      setLoading(false)
    }
  }

  const fetchInstDetails = async (code) => {
    const response = await APIServices.post('/youtube', { code })
    if (response.data.code === 200) {
      setPosts(response.data.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    const search = location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const code = params.get('code');
    if (code && !posts.length) {
      fetchInstDetails(code)
    } else {
      getAllPost()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center w-100" style={{ height: "90vh" }}><h2>Loading...</h2></div>
  }

  return (
    <Row>
      <Col className="mx-4">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Youtube Posts</h2>
          <button className="text-success bg-transparent border-0"><PlusSquare size={20} /></button>
        </div>
        <div>
          {
            posts.map((post) => {
              const { _id, videoUrl, categories, channelTitle, description, liveBroadcastContent, title, thumbnail } = post
              // const { url, width, height } = thumbnail
              return (
                <div key={_id} className="my-2">
                  <iframe
                    src={`${videoUrl}`}>
                  </iframe>
                  <article className="w-25 my-2">
                    <div className="fw-bold">{title}</div>
                    <p className="fw-normal my-0">Channel Title <span className="fw-light">{channelTitle}</span></p>
                    <p className="">Live Broadcast - <span className="fw-light">{liveBroadcastContent}</span></p>
                    <p className="">{description}</p>
                  </article>
                  <article className="d-flex justify-content-between w-25 my-2">
                    <div className="text-center">
                      <button className="bg-transparent border-0 text-info" onClick={() => setModalShow({ id: _id, modal: true })}><PencilSquare /></button>
                      <button onClick={() => deletePost(_id)} className="bg-transparent border-0 text-danger"><Trash3Fill /></button>
                    </div>
                  </article>
                  <hr />
                </div>
              )
            })
          }
        </div>
      </Col>
      <Commonmodal
        show={modalShow.modal}
        onHide={() => setModalShow(pre => ({ ...pre, modal: false }))}
        handleUpdate={handleUpdate}
        message={modalShow.message}
        field="categories"
      />
    </Row>
  );
};

export default Youtube;
