import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const WeeklyHoroscope = () => {
  const [weekly, setWeekly] = useState([])
  const [loading, setLoading] = useState()

  const fetchData = async () => {
    setLoading(true);
    const response = await fetch("https://www.astrobeans.com/wp-json/wp/v2/weekly_horoscope")
    const data = await response.json()
    setWeekly(data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      {loading ? <div style={{marginTop:"15%"}}><Spinner animation="border"/></div> :
        <>
          <h3 className="headerdd">Weekly Horoscope</h3>
          {weekly && weekly.map(x => {
            var contentdata = x.content.rendered;
            var titledata = x.title.rendered;
            return (
              <>
                <div className="dailycardd">
                  <Card className="shadow">
                    <Card.Body className="weeklycardbody">
                      <Card.Title><span dangerouslySetInnerHTML={{ __html: titledata }} /></Card.Title>
                      <Card.Text><span dangerouslySetInnerHTML={{ __html: contentdata }} /></Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )
          }
          )}
        </>
      }
    </div>
  );
}

export default WeeklyHoroscope;