import React, { createRef, useEffect, useRef, useState ,useCallback } from 'react';
import { Modal,Col, Container, Row, Button, InputGroup, FormControl, Spinner, Table, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Pencil, XOctagon, XLg, Question } from 'react-bootstrap-icons';
import { Fa } from 'react-icons/fa';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { ToastContainer, toast } from 'react-toastify';
import { useToasts } from 'react-toast-notifications';
import APIServicenew from '../utils/APIGeneralService';
import { getCookies } from '../utils/Helper';
import ReactPaginate from 'react-paginate';

export const PujaFeedbackQuest = () => {
  document.title = 'Puja Feedback Question';

  const cookies = new Cookies();

  let token = cookies.get("accessToken");
  const headers = { Authorization: `Bearer ${token}`}
  const [newData, setNewData] = useState([]);
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [offset, setOffset] = useState(0);
  const [perPage ,setPerPage] = useState(20);
  const [allDataCount, setAllDataCount] = useState();
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const defaultState={
    question : '',
    questionHi : '',
    
  };
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    getQuestList(offset ,perPage);
    setLoader(false);
  }, [offset ,perPage]);



  const getQuestList = async ({ offset = 0,perPage = '10'  }) => {
    const res = await APIServices.get(`pujaFeedbackQuest/getAll/${offset}/${perPage}`);
    if (res && res.status === 200) {
      let resData = res.data;

      if (resData && resData.status && resData.Listdata) {
        setList(resData.Listdata);
        setAllDataCount(resData.totalCount);
      }
    }
    setOffset(offset);
    setPerPage(perPage);
  }


  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    let payload =  {} ;
    payload.question = state.question;
    payload.questionHi = state.questionHi;

    if (form.checkValidity() === false) {
      event.stopPropagation();
     setValidated(true);

  }else{
    setLoader(true)
    if( newData?._id){
      const res = await APIServices.put(`pujaFeedbackQuest/update/${newData._id}`,  payload, headers );
      if (res && res.status === 200) {
        let apiData = res?.data;
        if (apiData?.status === true) {
            toast.success(apiData?.message);
            await getQuestList(offset ,perPage)
            setLoader(false)
            setNewData("")
            setState(defaultState)

        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }

      }
      
    }else{
    const res = await APIServices.post("pujaFeedbackQuest/add",  payload, headers );
    if (res && res.status === 200) {
      let apiData = res?.data;
      if (apiData?.status === true) {
          toast.success(apiData?.message);
          await getQuestList(offset ,perPage)
          setLoader(false)
          setNewData("")
          setState(defaultState)
      }else if(apiData?.status === false){
        toast.error(apiData?.message);
        setLoader(false)
      }
    }
  }
  }
}

  const handleActiveClick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append('status', datas);
    await APIServices.put(`pujaFeedbackQuest/updateStatus/${id}`, formData, headers)
      .then(res => {
       getQuestList(offset ,perPage)
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleShowDeleteModal = id => {
    setShowDeleteModal(true);
    setSelectedId(id);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedId(null);
  };
  const handleDelete = async () => {
    await APIServices.delete(`pujaFeedbackQuest/findByIdAndremove/${selectedId}`, headers)
      .then(res => {
        if (res.data.status === true) {
            getQuestList(offset ,perPage)
          
          setShowDeleteModal(false);
          setTimeout(() => {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1000);
        }
      })
      .catch(err => {
        console.log(err);
      })
     
  };

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const newOffset = selectedPage * perPage;
    setOffset(newOffset);
  };

  const question = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label  class="required">Question (English)</Form.Label>
          <Form.Control
            required
            name="question"
            as="textarea"
            style={{ height: 60 }}
            value={state.question}
            placeholder="Write here.. "
            onChange={(e) => setState({...state, question: e.target.value})}

          />
        <Form.Control.Feedback type='invalid'>Please enter question in english.</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }
  const questionHi = () => {
    return (
      <>
        <Form.Group as={Col} size="md" controlId="validationCustom01">
          <Form.Label  class="required">Question (Hindi)</Form.Label>
          <Form.Control
            required
            name="questionHi"
            as="textarea"
            style={{ height: 60 }}
            value={state.questionHi}
            placeholder="Write here.."
            onChange={(e) => setState({...state, questionHi: e.target.value})}

          />
        <Form.Control.Feedback type='invalid'>Please enter question in hindi.</Form.Control.Feedback>
        </Form.Group>
      </>
    )
  }
  return (
    <>
      {' '}
      <div
        className='notifiction_area'
        style={{
          backgroundImage: 'url("/images/why_choose_us.jpg")',
        }}
      >
        <div className='gita_quote_heading'>
          <div className='container d-flex align-items-center justify-content-between'>
          <h4 className="hd-list-f left-q" >  <Row>
        {newData?._id?<Col>
            <h5>Edit question
              {/* <span style={{marginLeft:"6px"}}>
            <XLg className="me-2"
            size={20}
            onClick={(e) => {
              setNewData("")
              setState(defaultState)
            }}
            />

            </span> */}
            </h5>
            
          </Col> :

          <Col>
            <h5> Manage Puja Feedback Question <small>({allDataCount})</small></h5>
          </Col>}
        </Row></h4>
          </div>
        </div>
        <div className='container-fluid'>
          <div className="notifiction_form box-shadow br-6 pr-20 my-3">
        
        
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col className="my-2">
            {question()}
          </Col>
          
          <Col className="my-2">
            {questionHi()}
          </Col>
        </Row>
        <Row>
        {newData?._id?<Col className="">{loader?<Button variant="primary" className="as_btn"  >Processing...</Button>:<>
        <Button variant="primary" className="as_btn ms-0"  type="submit" >Submit </Button>
        <Button variant="primary" onClick={(e) => {
          setNewData("")
          setState(defaultState)
        }} className="as_btn cancle_btn"  type="submit" >Cancel </Button></>
        }
          
          </Col> :
          <Col className="">{
            loader?<Button variant="primary" className="as_btn ms-0" >Processing...</Button>:<Button variant="primary" className="as_btn ms-0"  type="submit">Submit</Button>
          }
          
          </Col>}
        </Row>
        </Form>
          </div>
        </div>
          
            <div className='table-responsive admin_grid_table responders'>
              <Table bordered hover className='w-1920'>
                <thead>
                  <tr>
                    <th> No. </th>
                    <th> Question (English)</th>
                    <th> Question (Hindi)</th>
                    <th> Status </th>
                     <th>Action</th>
                     <th>Delete</th>
                    <th>Created Date</th>
                    <th>Updated Date</th>

                  </tr>
                </thead>
                {list && list?.map((item, i) => (
                  <tbody>
                    <tr key={item?._id}>
                      <td>{i + 1}</td>
                      <td>{item?.question}</td>
                      <td>{item?.questionHi}</td>
                            <td>{item?.status === true ?
                            <input
                            type='button'
                            className='_active'
                            value='Active'
                            onClick={() => handleActiveClick(item.status,item._id )
                            }
                            />:<input
                            type='button'
                            className='_inactive'
                            value='Inactive'
                            onClick={() => handleActiveClick(item.status,item._id )
                            }
                          />
                          }</td>
                            <td><Pencil
                                  className="me-2"
                                  size={20}
                                  onClick={(e) =>{
                                    setNewData(item)
                                    setState(item)}
                                  }
                            /></td>
                      <td> <input
                  type='button'
                  className='Dt-btn'
                  value='Delete'
                  onClick={() => handleShowDeleteModal(item._id)}
                /></td>
                      <td>{moment(item.createdAt).format("lll")}</td>
                      <td>{moment(item.updatedAt).format("lll")}</td>

                    </tr>
                  </tbody>
                ))}
              </Table>
            </div>
            <div className='pagination'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='Next'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel='< Previous'
                renderOnZeroPageCount={null}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
                forcePage={offset / perPage}
              />
            </div>
         
       
      </div>
      <ToastContainer />

      <Modal
        show={showDeleteModal}
        size='sm'
        aria-labelledby='example-modal-sizes-title-sm'
        backdrop='static'
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header className='modal-header-hd'>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body> Are you sure you want to delete this element?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' className='cancle_btn' onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant='primary' className='success_btn' onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PujaFeedbackQuest;
