
const TwitterAnalytics = () => {

  return (
    <div>
      <h2>Twitter</h2>
    </div>
  );
}

export default TwitterAnalytics;