
import React, { useEffect, useState } from "react";
import FacebookPost from "./FacebookPost";


const PAGE_ID = "102399459127472";

const FBposts = (props) => {

  const { fbPageAccessToken } = props;

  const [postvalues, setPostvalues] = useState([]);

  const fetchData = () => {
    window.FB.api(`/${PAGE_ID}/posts`, 'get', {
      access_token: fbPageAccessToken
    },
      function (response) {
        setPostvalues([...response.data]);
      }
    );
  }

  useEffect(() => {
    if (fbPageAccessToken) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbPageAccessToken])



  return (
    <>
      {postvalues.map(post => <FacebookPost id={post.id} fbPageAccessToken={fbPageAccessToken} />)}

    </>
  );
}

export default FBposts;