import React, { useEffect, useState, useContext } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaArrowLeft, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../../components/Shared/Sidebar';
import bimg from '../../imgs/banner_background.png';
import upimg from '../../imgs/default.jpg';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import { formateDateAndTime, SlugifyStringWithDash, capitalizeFirstLetter, setCookies } from '../../utils/Helper';
import PosterCrop from './PosterCrop';
import BannerCrop from './bannerCrop';
import Mandirdetailcrop from './Mandirdetailcrop';
import Whatsappcrop from './Whatsappcrop';
import Pujacrop from './Pujacrop';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { UserContext } from '../../context/UserContextProvider';
import { PujaImgGalleyModel } from '../../components/Shared/pujaImgGalleryModal';

const AddEditPandit = () => {
  const Navigate = useNavigate();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [errors, setErrors] = useState({});
  
  return (
    <div>
      <>
        <div className='main aarti_section'>
          <>
            <div className='gita_quote_heading'>
              <div className='container d-flex align-items-center justify-content-between'>
                <h4 className='hd-list-f left-q'>
                  <FaArrowLeft
                    className='hd-cr '
                    style={{ marginRight: '15px' }}
                    size={25}
                    onClick={() => Navigate(-1)}
                  />
                  {/* {`${selectedId ? `Edit/ ${capitalizeFirstLetter(language)}` : 'Add/English'} Puja`} */}
                </h4>

                <div className='_btn'></div>
              </div>
            </div>

            <div className='aarti_inner pt-5'>
              <div className='container'>
                <div className='row'></div>
              </div>
            </div>
          </>
        </div>
      </>

      <div className='container'>
        <div className='notifiction_form'>
          <Form>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='4'>
                Name
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  defaultValue={name}
                  //   isInvalid={!!errors?.name}
                  className='form-control '
                  onChange={e => {
                    setName(e.target.value);
                    // handleErrors(e);
                  }}
                />
                {/* <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId=''>
              <Form.Label className='required' column sm='4'>
                Address
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  name='address'
                  defaultValue={address}
                  //   isInvalid={!!errors?.address}
                  placeholder='Address...'
                  className='form-control '
                  onChange={e => {
                    setAddress(e.target.value);
                    // handleErrors(e);
                  }}
                />
                {/* <Form.Control.Feedback type='invalid'>{errors?.address}</Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='mobileNumber'>
              <Form.Label className='required' column sm='4'>
                Mobile Number
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  name='mobileNumber'
                  defaultValue={mobileNumber}
                  // isInvalid={!!errors?.mobileNumber}
                  placeholder='Mobile Number...'
                  className='form-control'
                  onChange={e => {
                    setMobileNumber(e.target.value);
                    //   handleErrors(e);
                  }}
                />
                {/* <Form.Control.Feedback type='invalid'>{errors?.mobileNumber}</Form.Control.Feedback> */}
              </Col>
            </Form.Group>

            <Row className='justify-content-center d-flex'>
              <Button
                variant='primary'
                className='as_btn'
                // onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}
              >
                {/* {`${selectedId ? 'Edit Puja' : 'Add puja'}`} */}
                Add Pandit
              </Button>
              <Button
                variant='secondary'
                className='as_btn'
                //   onClick={handleClose}
              >
                Reset
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditPandit;
