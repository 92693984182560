import { useEffect, useState } from "react";
import { Button, Modal, Row ,Form ,Col} from "react-bootstrap";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEdit, FaTrash,FaArrowLeft } from "react-icons/fa";
import upimg from "../../src/imgs/default.jpg";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/banner_background.png";
import APIServicenew from "../utils/APIGeneralService";
import FestivalCrop from "./festivals/festivalCrop";
import { formateDate } from "../utils/Helper";
import DatePicker from "react-datepicker";
import CropModal from "../components/Shared/CropModal";
import ProfileCrop from "../pages/aspoojas/profileCrop"
const PujaTestimonial=()=> {
  document.title = 'Puja Testimonial';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const[loader,setLoader]=useState(false)
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [showdt, setShowDt] = useState(false)
  const [allData , setAllData] = useState([])
  const [allDataCount , setAllDataCount] = useState([])
  const [data, setData] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [address ,setAddress] = useState();
  const [date, setDate] = useState(new Date());
  const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [img, setImg] = useState();
  const [profileImage ,setProfileImage] = useState();
  const [profileImagePreview , setProfileImagePreview] = useState();
  const [contentType, setContentType] = useState();
  const [errors, setErrors] = useState({});
  const [ type , setType] = useState("english")
  const [show, setShow] = useState(false);
  const [ selectedId ,setSelectedId] = useState()
  const navigate = useNavigate();

  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [cfileimage,setFileCimage]=useState()

  const onImageChange = (event) => {
    setFile(event);
    setFile1(event);

  }

  const onImageChangeProfile = (event) => {
    setProfileImage(event);
  }

  useEffect(() => {
    fetchData();
  }, [offset ,type]);

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`pujaTestimonial/getAll/${type}/${offset}/${perPage}`, headers)
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setData(apiData.Listdata)
     
        setAllDataCount(apiData.totalCount)
        setLoading(false)
      }
    }
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = (selectedPage * perPage);
    setOffset(newOffset)
  }

  const handleClose = () => {
    setShow(false)
    setErrors({})
    setContentType("")
    setCropImg("")
  };

  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = (id) => {
    setShowDt(true)
    setSelectedId(id)
  }
  const handleShow =async (item) => {
    setFile("");
    setFile1("");
    setProfileImage("")
    setShow(true);
    setSelectedId(item?._id);
    setTitle(item?.title);
    setDate( item?.date ? new Date(item?.date ):null)
    setAddress(item?.address)
    setDescription(item?.description)
    setImg(item?.file ? item?.file :"");
    setProfileImagePreview(item?.profileImage)
    
    if (item?.contentEn === true) {
      setContentType("english")
      }else if( item?.contentHi === true){
      setContentType("hindi")
      }
    

  };

  const validateInputs = () => {
    let isValid = false;
   
    const newErrors = {};
  
      if (!title ||title === "") 
      newErrors.title = "Please enter title.";

      if ((!description || description === "")&& (file1 === "" && (!img || img === ""))) 
      newErrors.file = 'Please enter description or upload image.';
    
      if (!date ||date === "") 
      newErrors.date = "Please add date.";
    
      if (!address ||address === "") 
      newErrors.address = "Please enter address.";

      if (!contentType ||contentType === "") 
      newErrors.contentType = "Please select content Type.";
  
      if (profileImage === "" && (!profileImagePreview || profileImagePreview === "")) 
      newErrors.profileImage = "Please add profileImage.";

    
      return newErrors;
  }; 

  async function handleEdit() {
    const formData = new FormData();
    if (typeof profileImage === 'object') {
      formData.append("profileImage", profileImage);
    }
    description && formData.append('description', description);
    if (file1 && typeof file1 === 'object') {
      formData.append('file', file1);
    }
    formData.append("title", title);
    formData.append("address", address);
    const ds = formateDate(date)
    formData.append("date", ds);
    // formData.append("description", description);

    if (contentType === "english") {
      formData.append("contentEn", true);
      formData.append("contentHi", false);

    } else if (contentType === "hindi") {
      formData.append("contentHi", true);
      formData.append("contentEn", false);
    }
    formData.append("userId", astroUserId);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
   } else {
      let response = await APIServices.put(
        `pujaTestimonial/editOne/${selectedId}`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          toast.success(apiData?.message);
          setFile1(null);
          setShow(false);
          setCropImg();
          setProfileCropImg()
          fetchData()
          setLoader(false)
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }else if(response?.status === 400){
        let errData = response?.data;
        if(errData && errData.message){
          toast.error(errData?.message);
        }
      }
      setLoader(false)
    }
  }
  const handleclick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`pujaTestimonial/updateStatus/${id}`, formData, headers)
      .then((res) => {
        fetchData()
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handledelete = async () => {
    let res = await APIServices.delete(`pujaTestimonial/findByIdAndremove/${selectedId}`, headers)
    if (res?.status === 200) {
      setShowDt(false)
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
      }
    }
  }
 
  async function handleCreate(e) {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("address", address);
    const ds = formateDate(date)
    formData.append("date", ds);
    if (contentType === "english") {
      formData.append("contentEn", true);
    } else if (contentType === "hindi") {
      formData.append("contentHi", true);
    }
    formData.append("profileImage", profileImage);

    description && formData.append('description', description);
    if (file1 && typeof file1 === 'object') {
      formData.append('file', file1);
    }


    formData.append("userId", astroUserId);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    const newErrors = validateInputs();
    setLoader(true)
    if(Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      let response = await APIServices.post(
        `pujaTestimonial/upload`,
        formData,
        headers
      );
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShow(false)
          toast.success(apiData?.message);
          setCropImg()
          setProfileCropImg()
          fetchData()
          setLoader(false)
        }else if(apiData?.status === false){
          toast.error(apiData?.message);
          setLoader(false)
        }
      }else if(response?.status === 400){
        let errData = response?.data;
        if(errData && errData.message){
          toast.error(errData?.message);
        }
      }
      setLoader(false)
    }
  }

  function handleChange(e) {
    let key, value;
    key = e?.target?.name;
    if( key === "description") key = "file"
    if( e === "date") key = e
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

  useEffect(()=>{
    if(cfileimage){
      onImageChange(cfileimage)
    }
  },[cropimg,cfileimage])


  const[showcropProfile,setShowCropProfile]=useState(false)
  const[profilecropimg,setProfileCropImg]=useState()
  const[cprofilefileimage,setFileCProfileimage]=useState()

  useEffect(()=>{
    if(cprofilefileimage){
    onImageChangeProfile(cprofilefileimage)
    }
  },[profilecropimg,cprofilefileimage])

  const renderFormModal = () => {

    return(
      <Modal centered scrollable show={show} backdrop="static" onHide={()=>{handleClose(); setCropImg(); setProfileCropImg(); setErrors({})}} enforceFocus={false}>
      <Modal.Header closeButton>
        <Modal.Title>{`${selectedId ? 'Edit' : 'Add'} Puja Testimonial`} </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
            <Form.Label className="required" column sm="3">
              Title
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="Title..."
                name='title'
                defaultValue={title}
                isInvalid={!!errors?.title}
                className="form-control "
                onChange={(e) => {setTitle(e.target.value);handleChange(e)}}
              />
              <Form.Control.Feedback type="invalid">
                  {errors?.title}
             </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="required" column sm="3">
             Profile Image
            </Form.Label>
            <label id="labimg">
              
                {profilecropimg?<img 
                className="imgs" 
                src={profilecropimg}
                onClick={()=>setProfileCropImg()}
                width="100%" alt="" />:<img 
                className="imgs" 
                src={selectedId? profileImagePreview: profileImage ? profileImage : upimg}
                width="100%" alt="" />}
  
                <Form.Control
                //  type="file"
                 className="form-control "
                 isInvalid={!!errors?.profileImage}
                 name="profileImage"
                 onClick={(e)=>{setShowCropProfile(true);handleChange(e)}}
                
                 size="40"
                 accept="image/png, image/gif, image/jpeg"
                 id="inputds"
               />
          <Form.Control.Feedback type="invalid">
              {errors?.profileImage}
             </Form.Control.Feedback>
            </label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label className="" column sm="3">
          Description</Form.Label>
              <Col sm="9">
              <Form.Control
              as='textarea'
              style={{ height: 100 }}
              type="text"
              name='description'
              defaultValue={description}
              placeholder="Description..."
              className="form-control "
              onChange={(e) => {setDescription(e.target.value);handleChange(e)}}
            />
            </Col>
          </Form.Group>
          <div className='text-center mb-2 fw-bold text-danger'>OR</div>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="" column sm="3">
              Image
            </Form.Label>
            <label id="labimg">
              
              {cropimg?<img 
                // crossOrigin="anonymous" 
              className="imgs" 
              src={cropimg}
              onClick={()=>setCropImg()}
              width="100%" alt="" />:<img 
              className="imgs" 
              src={selectedId? img?img :upimg: file ? file : upimg}
              width="100%" alt="" />}
  
          <Form.Control
               // type="file"
               className="form-control "
               isInvalid={!!errors?.file}
               name="file"
               onClick={(e)=>{setShowCrop(true);handleChange(e)}}
              
               size="40"
               accept="image/png, image/gif, image/jpeg"
               id="inputds"
              />
          <Form.Control.Feedback type="invalid">
              {errors?.file}
             </Form.Control.Feedback>
            </label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
              <Form.Label className="required" column sm="3">
                Address
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="textarea"
                  name='address'
                  defaultValue={address}
                  isInvalid={!!errors?.address}
                  style={{ height: 50 }}
                  placeholder="Address..."
                  className="form-control "
                  onChange={(e) =>{ setAddress(e.target.value);handleChange(e)}}
                />
                   <Form.Control.Feedback type="invalid">
                {errors?.address}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
          <Form.Group as={Row} className="mb-3"  controlId="formTitleInput">
                  <Form.Label className="required" column sm="3">Date</Form.Label>
              <Col sm="9">
              <DatePicker
               placeholderText={'Please select a date'} 
               className='form-control react-datepicker__input-container-1'
               selected={date}
               onChange={(date) => {setDate(date);handleChange("date")}}
       
      />
               <p className="errorVal">{errors?.date}</p>
                  </Col>
                </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
            <Form.Label className="required" column sm="3">
              Content Type
            </Form.Label>
            <Col sm="9">
              <Form.Select
                aria-label="Default select example"
                required
                className=" form-control hd-cr"
                name="contentType"
                isInvalid={!!errors?.contentType}
                defaultValue={contentType}
                onChange={(e) => {setContentType(e.target.value);handleChange(e)}}
              >
                <option value="">Choose</option>
                <option value="english">English</option>
                <option value="hindi">Hindi</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                  {errors?.contentType}
             </Form.Control.Feedback>
            </Col>
          </Form.Group>
          
        </Form>
      </Modal.Body>
      <Modal.Footer>
      {loader?<Button variant="primary"
      className="as_btn"
    >
      {`${selectedId ? 'Processing...' : 'Processing...'}`}
      </Button>:<Button variant="primary"
      className="as_btn"
      onClick={(e) => `${selectedId?handleEdit():handleCreate(e)}`}>
      {`${selectedId ? 'Edit' : 'Add'}`}
      </Button>}
        
        <Button variant="secondary"
        className="as_btn"
        onClick={()=>{handleClose(); setCropImg();setProfileCropImg()}}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  
    )
  }

  return (
    <>
      <div className="main aarti_section">
        <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" > 
            <FaArrowLeft
              className="hd-cr "
              style={{marginRight: "15px"}}
              size={25}
              onClick={() => navigate(-1)}/>
           
           Testimonials <small>({allDataCount})</small></h4>   
           <div className="_btn">
                 
              <Button
              className="by-btn as_btn me-0"
                variant="primary"
                onClick={handleShow}
              >
                Add Puja Testimonial</Button>
              </div>
           </div>
        </div>
        <div className="aarti_inner pt-5">
           <div className="container">
              <div className="row">
                 <div className="col-md-12 col-lg-12"> 
                 {loading ? <div className="center"><Spinner animation="border" /></div> :
              <>
                <div className="row">
                      <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                          <h4 className="hd-list-f left-q"></h4>
                          <Form.Select
                            aria-label="categories"
                            className=" w-25 astroWatch-select-hover hd-cr"
                            value={type}
                            onChange={(e) => {setType(e.target.value); setOffset(0)}}
                          >
                            <option value="english">English</option>
                            <option value="hindi">Hindi</option>

                          </Form.Select>
                        </div>
                      </div>
                    </div>

                    <br />
            <div className="row">
                 
          {
            data?.map((item) =>
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="card mb-3 Reels_video" key={item?._id} >
                <div className="card-header">
    
                 <img
                   src={(item?.profileImage) ? item?.profileImage : bimg}
                   className="card-img-top"
                   alt="img"
                 />
                 
                  <div className="icon card-icons">
                    <FaEdit
                    className="me-2 hd-cr"
                    size={20}
                    onClick={(e) => {
                      handleShow(item)
                  }}
                />
                    <FaTrash
                      className="dt-btn hd-cr "
                      size={20}
                      onClick={e => {
                        handleShowDt(item?._id)
                      }}
                    />
                     {item.status === true ? (
                     <input
                       type="button"
                       className="_active"
                       style={{fontSize:"8px", borderRadius:"15px"}}
                       value="Active"
                       onClick={() =>
                         handleclick(item.status, item._id)
                       }
                     />
                   ) : (
                     <input
                       type="button"
                       className="_inactive"
                       style={{fontSize:"8px", borderRadius:"15px"}}
                       value="Inactive"
                       onClick={() =>
                         handleclick(item.status, item._id)
                       }
                     />
                   )}
                  </div>
                </div>
                <div className="card-body" >
                <h5 className="card-title  heading_18 mb-2" style={{ textAlign: "center"}}>{item.title}</h5>
                      
                <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2">
                    <span className="calicon">
                      <i className="ri-calendar-line"></i>
                    </span>
                    {new Date(item.createdAt).toDateString()}
                  </p>
                 
                              
                </div>
              </div>
              </div>
            )
          }
       
        </div>
       
      </>
        }

<div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next>"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                // pageCount={pageCount}
                pageCount={Math.ceil(allDataCount / perPage)}
                previousLabel="< Previous"
                renderOnZeroPageCount={null}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                containerClassName={"pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                forcePage={offset/perPage}
              />
            </div>
                 </div>
              </div>
           </div>
        </div>
      </div>
     
      {renderFormModal()}

      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt} 
        >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this element?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn as_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn as_btn" onClick={handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
      <CropModal 
      showcrop={showcrop}
      setShowCrop={setShowCrop}
      setCropImg={setCropImg}
      cropimg={cropimg}
      
      setFileCaartiimage={setFileCimage}/>

      <ProfileCrop 
      showcropProfile={showcropProfile}
      setShowCropProfile={setShowCropProfile}
      setProfileCropImg={setProfileCropImg}
      profilecropimg={profilecropimg}
      
      setFileCProfileimage={setFileCProfileimage}/>
    </>
  )
}


export default PujaTestimonial;
