import React from 'react'
import { CloudPlusFill } from 'react-bootstrap-icons'

const Thumbnail = ({ id, image, handler, title }) => {
    return (
        <div>
            <label htmlFor={id} className='thumbnail-custom-image'>
                {
                    image ?
                        <img src={URL.createObjectURL(image)} alt="thmimage" width={40} />
                        :
                        <CloudPlusFill size={30} className="mx-2" />
                }
                <span className='mx-2'>{title}</span>
            </label>
            <input id={id} type="file" style={{ display: "none" }} onChange={e => handler(e)} />
        </div>
    )
}

export default Thumbnail