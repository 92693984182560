import React, { useState, useEffect } from 'react';
import { Button, Modal,Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { capitalizeFirstLetter, fetchImageAsFile } from '../../utils/Helper';
import DefaultImage from '../../imgs/default.jpg';
import Imagecrop from '../GodImages/imagecrop';
import MasterImagecrop from "./MasterImagecrop"

const allDays = ["", 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const initialData = {
  godName: null,
  day: null,
  mandirGodImage: null,
  ABCGodImage: null,
};

const MasterGodForm = ({ show, selectedItem,aartiList, handleClose, onSubmit,loader ,progress }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
    setValue,
    trigger,
    clearErrors,
  } = useForm();

  const [mandirImagePreview, setMandirImagePreview] = useState(DefaultImage);
  const [abcImagePreview, setabcImagePreview] = useState(DefaultImage);
  
  // const watchMandirImage = watch('mandirGodImage');
  // const watchabcImage = watch('ABCGodImage');

const [ showDrop , setShowDrop] = useState(selectedItem?.aarti === 'yes'?'yes' :'no')
const [pwVideo ,setPwVideo] = useState("")
  useEffect(() => {
    if (selectedItem) {
      setFormData(selectedItem);
    }
  }, [selectedItem, setValue]);
  
  // useEffect(() => {
    //   if (watchMandirImage) {
      //     const file = watchMandirImage?.[0];
      //     console.log(file)
      //     if (file) {
        //       const reader = new FileReader();
        //       reader.onload = () => setMandirImagePreview(reader.result);
        //       reader.readAsDataURL(file);
        //     }
        //   }
        //   if (watchabcImage) {
          //     const file = watchabcImage?.[0];
  //     if (file) {
    //       const reader = new FileReader();
    //       reader.onload = () => setabcImagePreview(reader.result);
    //       reader.readAsDataURL(file);
    //     }
    //   }
    // }, [watchMandirImage, watchabcImage]);
    
    // console.log(selectedData?.ABCGodImage)

    
  const previewVideo = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setPwVideo(url)
  }
    const setFormData = async selectedData => {
    let data = aartiList?.find((x) =>x._id == selectedData?.aartiId)
      const formData = {
        godName: selectedData?.godName,
        day: { value: selectedData?.day, label:  capitalizeFirstLetter(selectedData?.day) },
        aarti:selectedData?.aarti,
        aartiId:{ value: selectedData?.aartiId, label:  capitalizeFirstLetter(data?.title) },
      };
     
    reset(formData); // Set default form values based on selectedData
    const mImage = (selectedData?.mandirGodImage);
    const aImage = (selectedData?.ABCGodImage);
    setValue('mandirGodImage', mImage);
    setValue('ABCGodImage', aImage);
    setMandirImagePreview(selectedData?.mandirGodImage || DefaultImage);
    setabcImagePreview(selectedData?.ABCGodImage || DefaultImage);
    setPwVideo(selectedData?.aartiVideo)
  };

  const handleModalClose = () => {
    reset(initialData);
    handleClose();
    setMandirImagePreview(null);
    setabcImagePreview(null);
    clearErrors();
  };

//  /////////////////////////////////////////
  const [showcrop, setShowCrop] = useState(false);
  const [cropimg, setCropImg] = useState("");
  const [cfileimage,setFileCimage]=useState()
  const [mshowcrop, setMShowCrop] = useState(false);
  const [mcropimg, setMCropImg] = useState("");
  const [mcfileimage,setFileMCimage]=useState()


useEffect((e)=>{
  if(cfileimage){
    setValue('mandirGodImage', cfileimage )
    trigger()

  }
  if(mcfileimage){
    setValue('ABCGodImage', mcfileimage);
    trigger()

  }
},[cropimg,cfileimage,mcfileimage,mcropimg])

//  /////////////////////////////////////////////////

  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='example-modal-sizes-title-sm'
        onHide={handleModalClose}
        className='master_god_modal'
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header className='modal-header' closeButton>
            <Modal.Title>{selectedItem ? 'Edit' : 'Add'} God</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='login_form'>
              <div className='row'>   
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='required'>God Name</label>
                    <input
                      {...register('godName', {
                        required: { value: true, message: 'God Name is required' },
                      })}
                      className='form-control '
                      placeholder='Enter God name'
                      style={{height:"37px"}}
                    />
                    {errors.godName && <p className='text-danger'>{errors.godName?.message}</p>}
                  </div>
                </div>
                <div className='col-lg-6'>
                <div className='form-group'>
                  <label className=''>Day</label>
                  <Controller
                    name='day'
                    control={control}
                    render={({ field }) => (
                      <Select
                      {...field}
                      options={allDays.map(d => ({
                        value: d,
                        label: d ? capitalizeFirstLetter(d) : 'Other',
                      }))}
                      />
                      )}
                      />
                     {/* rules={{ required: { value: true, message: 'Day is required' } }}*/}
                  {/*{errors.day && <p className='text-danger'>{errors.day?.message}</p>}*/}
                </div>
                </div>
                
                <hr />
                
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='preview-image-input'>
                      Mandir God Image <span className='redstar'>*</span>
                      <div className='as_input_feild mt-2'>
                        <div className='image-preview'>
                          {/* {mandirImagePreview && mandirImagePreview !== DefaultImage && (
                            <FaTrash
                              className='remove-button'
                              title='Remove Image'
                              onClick={e => {
                                e.preventDefault();
                                handleRemoveImage('mandirGodImage', setMandirImagePreview);
                              }}
                            ></FaTrash>
                          )} */}
                          {cropimg?<img src={cropimg} width="100%" alt='' />:<img src={mandirImagePreview}width="100%" alt='' />}
                        </div>
                        <Controller
                          name='mandirGodImage'
                          control={control}
                          rules={{ required: { value: true, message: 'Mandir God Image is required' } }}
                          // defaultValue={mandirImagePreview}
                          render={({ field }) => (
                            <input
                              // type='file'
                              accept="image/png, image/gif, image/jpeg"
                              id="inputds"
                              onClick={(e)=>setShowCrop(true)}
                            />
                          )}
                        />
                      </div>
                    </label>
                    {errors.mandirGodImage && <p className='text-danger'>{errors.mandirGodImage?.message}</p>}
                  </div>
                </div>
                
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='preview-image-input'>
                      Aarti, Bhajan, Chalisa God Image <span className='redstar'>*</span>
                      <div className='as_input_feild mt-2'>
                        <div className='image-preview'>
                          {/* {abcImagePreview && abcImagePreview !== DefaultImage && (
                            <FaTrash
                              className='remove-button'
                              title='Remove Image'
                              onClick={e => {
                                e.preventDefault();
                                handleRemoveImage('ABCGodImage', setabcImagePreview);
                              }}
                            ></FaTrash>
                          )} */}
                         {mcropimg?<img src={mcropimg}width="100%" alt='' />:<img src={abcImagePreview}width="100%" alt='' />} 
                        </div>
                        <Controller
                          name='ABCGodImage'
                          control={control}
                          rules={{ required: { value: true, message: 'Aarti, Bhajan, Chalisa God Image is required' } }}
                          // defaultValue={abcImagePreview}
                          render={({ field }) => (
                            <input
                              // type='file'
                              accept="image/png, image/gif, image/jpeg"
                             id="inputds"
                              onClick={(e)=>setMShowCrop(true)}
                            />
                          )}
                        />
                      </div>
                    </label>
                    {errors.ABCGodImage && <p className='text-danger'>{errors.ABCGodImage?.message}</p>}
                  </div>
                </div>
              </div>
              <div className='row'>  
              <div className='col-lg-6'>
              <div className='form-group'>
              <label className=''>Aarti</label>
              
                 <Form.Select
                 {...register('aarti', {
                  required: { value: false },
                })}
                            aria-label='categories'
                            className=' astroWatch-select-hover hd-cr'
                            onChange={(e)=>{setShowDrop(e.target.value)}}
                            
                          >
                            <option value='no'>No</option>
                            <option value='yes'>Yes</option>
                          </Form.Select>
                                 </div>
                                 </div>
                                 {showDrop === 'yes'
                 ?  <div className='col-lg-6'>
                 <div className='form-group'>
                   <label className='required'>Select Aarti</label>
                   <Controller
                     name='aartiId'
                     control={control}
                     rules={{ required: { value: true, message: 'Aarti Selection is required' } }}
                     render={({ field }) => (
                       <Select
                         {...field}
                         options={aartiList.map(d => ({
                           value: d?._id,
                           label: d?.title ? capitalizeFirstLetter(d?.title) : 'No Selection',
                         }))}
                       />
                     )}
                   />
                   {errors.aartiId && <p className='text-danger'>{errors.aartiId?.message}</p>}
                 </div>
                 </div>
                 : selectedItem?.aarti === 'yes' &&showDrop === 'yes'
                 ?  <div className='col-lg-6'>
                 <div className='form-group'>
                   <label className='required'>Select Aarti</label>
                   <Controller
                     name='aartiId'
                     control={control}
                     rules={{ required: { value: true, message: 'Aarti Selection is required' } }}
                     render={({ field }) => (
                       <Select
                         {...field}
                         options={aartiList.map(d => ({
                           value: d?._id,
                           label: d?.title ? capitalizeFirstLetter(d?.title) : 'No Selection',
                         }))}
                       />
                     )}
                   />
                   {errors.aartiId && <p className='text-danger'>{errors.aartiId?.message}</p>}
                 </div>
                 </div>
                 : ""}
                              
                </div>
                <div className='col-lg-6'>
                  <div className='form-group'>
                  <label className=''>Aarti Video</label>
                    <input
                      {...register('aartiVideo', {
                        required: { value: false },
                      })}
                      className='form-control '
                      type="file"
                      name="aartiVideo"
                      accept="video/*"
                      // defaultValue={video}
                      onChange={previewVideo}

                    />
                    
                     {pwVideo && (
                  <video
                  className="mt-4"
                  width="200" height="150"
                  controls
                  src={pwVideo}
                />
              )}
                  </div>
                </div>
                </div>
              </Modal.Body>
          <Modal.Footer>
            <div>
            {loader?<Button  variant='primary' className='as_btn '>
            {  progress === 100 ?'Processing...':`Uploading(${progress}%)...`}
          </Button>:<Button type='submit' variant='primary' className='as_btn '>
          {selectedItem ? 'Edit' : 'Add'} 
        </Button>}
              
              <Button variant='secondary' className='as_btn' onClick={handleModalClose}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      <Imagecrop 
      showcrop={showcrop}
      setShowCrop={setShowCrop}
      setCropImg={setCropImg}
      cropimg={cropimg}
      setFileCimage={setFileCimage}
      cfileimage={cfileimage}/>
      
      <MasterImagecrop
      mshowcrop={mshowcrop}
      setMShowCrop={setMShowCrop}
      mcropimg={mcropimg} setMCropImg={setMCropImg}
      mcfileimage={mcfileimage} setFileMCimage={setFileMCimage}
      />
    </>
  );
};

export default MasterGodForm;
