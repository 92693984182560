import React, { useState, useEffect } from 'react'
import { TagsInput } from 'react-tag-input-component';
import Button from 'react-bootstrap/Button';
import { Tab, Col, Row, Nav } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Thumbnail from './thumbnail';
import APIServicenew from '../../utils/APIGeneralService';
import { useToasts } from 'react-toast-notifications';
import { PencilSquare, Trash3Fill } from 'react-bootstrap-icons';

const APIServices = new APIServicenew(process.env.REACT_APP_CALENDAR_API_URL)

const VideoComponent = (props) => {
    const[loader,setLoader]=useState(false)
    const [errors, setErrors] = useState({})
    const [videos, setVideos] = useState([])
    const [editMode, setEditMode] = useState({
        status: false,
        id: null
    })
    const [fields, setFields] = useState({
        title: '',
        url: '',
        tags: [],
        categories: [],
        file: null
    })

    const { addToast } = useToasts();

    const fetchingVideos = async () => {
        const response = await APIServices.get(`/calendar/event-content/getAllVideos/${props.event_id}`)
        if (response.data.code === 200) {
            setVideos(response.data.results)
        }
    }

    const reset = () => {
        setFields({
            title: '',
            url: '',
            tags: [],
            categories: [],
            file: null
        })
        setEditMode({
            status: false,
            id: null
        })
        fetchingVideos()
    }

    const setfields = (key, value) => {
        setFields(prev => ({ ...prev, [key]: value }))
    }

    const handleEdit = async (id) => {
        setEditMode({ status: true, id })
        const response = await APIServices.get(`/calendar/event-content/getVideo/${id}`)
        if (response.data.code === 200) {
            const { Title, Url, Tags, Categories } = response.data.results
            setFields({
                title: Title,
                url: Url,
                tags: Tags,
                categories: Categories,
                file: null
            })
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

    const handleDelete = async (id) => {
        const response = await APIServices.delete(`/calendar/event-content/deleteVideo/${id}`)
        if (response.data.code === 200) {
            addToast('Deleted', {
                appearance: 'success',
                autoDismiss: true
            })
            fetchingVideos()
        }
    }
    const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
        
      
          if ( fields?.title =="") 
          newErrors.title = "Please enter title and press enter.";

          if(fields.url === '')
          newErrors.url = "Please enter url.";
    
          if ( fields.tags =="") 
          newErrors.tags = "Please enter tags and press enter.";
    
        //   if (newData.file === "") 
        //   newErrors.file = "Please upload image.";
    
          if (fields.categories == "")
          newErrors.catgories = "Please select categories and press enter.";

          return newErrors;
      }; 


    const fileupload = async (type, id, file) => {
        let formData = new FormData()
        formData.append('file', file)
        const fileupload = await APIServices.post(`/calendar/upload-file/${type}/${id}`, formData,
            { 'content-type': 'multipart/form-data' }
        )
        if (fileupload?.data.code !== 200) {
            addToast(`File uploading - ${fileupload.data.message}`, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

    const handleSubmit = async () => {
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.post('/calendar/event-content/addVideo', {
            Event_id: props.event_id,
            Title: fields.title,
            Url: fields.url,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('video', id, file)
            }
            addToast('Successfully added', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }
    }
}

    const handleUpdate = async () => {
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.put(`/calendar/event-content/updateVideo/${editMode.id}`, {
            Title: fields.title,
            Url: fields.url,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('video', id, file)
            }
            addToast('Successfully updated', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }
    }
}

    function handleChange(e) { 
        let key, value;
        // key=e
        key = e?.target?.name;
        if (!!errors[key]){
          setErrors({
            ...errors,
            [key]: null,
            });
        }
      }

    

    useEffect(() => {
        fetchingVideos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <article className='border border-dark rounded d-flex gap-2'>
            <div className='w-75 flex-grow-1 p-3'>
                <h4 className='text-center w-100 my-2'>
                    <Badge bg="light" text="dark">VIDEOS</Badge>
                </h4>
                <Form.Group className="mb-1">
                    <Form.Label>Add Url</Form.Label>
                    <Form.Control size="sm" type="text" placeholder='Add url' name="url" isInvalid={!!errors?.url} value={fields.url} onChange={e => {setfields('url', e.target.value); handleChange(e)}} />
                    <Form.Control.Feedback type="invalid">
                    {errors?.url}
                  </Form.Control.Feedback>
                    </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" placeholder="Add title" name="title" isInvalid={!!errors?.title} value={fields.title} onChange={e => {setfields('title', e.target.value); handleChange(e)}} />
                    <Form.Control.Feedback type="invalid">
                    {errors?.title}
                  </Form.Control.Feedback>
                    </Form.Group>

                <div className="form-group">
                    <label htmlFor='tags' className='my-2'>Tags</label>
                    <TagsInput
                        value={fields.tags}
                        onChange={(val) => setfields("tags", val)}
                        onKeyUp={e=>handleChange(e)}
                        id="tags"
                        name="tags"
                        placeHolder="Add tags (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.tags}</p>
                </div>
                <div className="form-group">
                    <label htmlFor='catgories' className='my-2'>Categories</label>
                    <TagsInput
                        value={fields.categories}
                        id="catgories"
                        onChange={(val) => setfields("categories", val)}
                        onKeyUp={e=>handleChange(e)}
                        name="catgories"
                        placeHolder="Add Categories (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.catgories}</p>
                </div>

                <Form.Group className="mb-3" >
                    <Thumbnail title={"Upload Thumbnail"} id={"videos-thumbnail"} image={fields.file} handler={val => {
                        setfields('file', val.target.files[0])
                        val.target.value = null
                    }} />
                </Form.Group>

                <div className='w-100 text-center'>
                {loader?<Button type="submit" size="sm" className='as_btn'>
                    {editMode.status ? "Processing..." : "Processing..."}
                </Button>:<Button type="submit" size="sm" className='as_btn' onClick={(e) => {
                    e.preventDefault()
                    editMode.status ? handleUpdate() :
                    handleSubmit()
                }}>
                    {editMode.status ? "Update" : "Submit"}
                </Button>}
                    
                    <Button type="submit" size="sm" className='as_btn mx-2' onClick={reset}>
                        Clear
                    </Button>
                </div>
            </div>
            <div className='w-25 border rounded'>
                <h6 className='mx-2 p-2'>ALL VIDEOS</h6>
                {
                    videos.map(vdo => {
                        let imageurl = `${process.env.REACT_APP_CALENDAR_API_URL}/${vdo.Thumbnail}`
                        return (
                            <article key={vdo._id} className='d-flex justify-content-between align-items-center p-2 border overflow-hidden'>
                                <div>
                                    <img  src={vdo.Image ? imageurl : '/images/default.jpg'} alt="newimagess" width={28} className="rounded-circle" />
                                    <span className='mx-2 text-capitalize'>{vdo.Title}</span>
                                </div>
                                <div>
                                    <PencilSquare className='pointerUtilityClass text-success mx-2' size={15} onClick={() => handleEdit(vdo._id)} />
                                    <Trash3Fill className='pointerUtilityClass text-danger mx-2' size={14.5} onClick={() => handleDelete(vdo._id)} />
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </article>
    )
}

const SongComponent = (props) => {
    const[loader,setLoader]=useState(false)
    const [errors, setErrors] = useState({})
    const [songs, setSongs] = useState([])
    const [editMode, setEditMode] = useState({
        status: false,
        id: null
    })
    const [fields, setFields] = useState({
        title: '',
        url: '',
        tags: [],
        categories: [],
        file: null
    })
    const { addToast } = useToasts();

    const fetchingSongs = async () => {
        const response = await APIServices.get(`/calendar/event-content/getAllSongs/${props.event_id}`)
        if (response.data.code === 200) {
            setSongs(response.data.results)
        }
    }

    const reset = () => {
        setFields({
            title: '',
            url: '',
            tags: [],
            categories: [],
            file: null
        })
        setEditMode({
            status: false,
            id: null
        })
        fetchingSongs()
    }

    const handleEdit = async (id) => {
        setEditMode({ status: true, id })
        const response = await APIServices.get(`/calendar/event-content/getSong/${id}`)
        if (response.data.code === 200) {
            const { Title, Url, Tags, Categories } = response.data.results
            setFields({
                title: Title,
                url: Url,
                tags: Tags,
                categories: Categories,
                file: null
            })
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

    const setfields = (key, value) => {
        setFields(prev => ({ ...prev, [key]: value }))
    }

    const handleDelete = async (id) => {
        const response = await APIServices.delete(`/calendar/event-content/deleteSong/${id}`)
        if (response.data.code === 200) {
            addToast('Deleted', {
                appearance: 'success',
                autoDismiss: true
            })
            fetchingSongs()
        }
    }

    const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
        
      
          if ( fields?.title =="") 
          newErrors.title = "Please enter title and press enter.";

          if(fields.url === '')
          newErrors.url = "Please enter url.";
    
          if ( fields.tags =="") 
          newErrors.tags = "Please enter tags and press enter.";
    
        //   if (newData.file === "") 
        //   newErrors.file = "Please upload image.";
    
          if (fields.categories == "")
          newErrors.catgories = "Please select categories and press enter.";

          return newErrors;
      }; 

    const fileupload = async (type, id, file) => {
        let formData = new FormData()
        formData.append('file', file)
        const fileupload = await APIServices.post(`/calendar/upload-file/${type}/${id}`, formData,
            { 'content-type': 'multipart/form-data' }
        )
        if (fileupload?.data.code !== 200) {
            addToast(`File uploading - ${fileupload.data.message}`, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

    const handleSubmit = async () => {
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.post('/calendar/event-content/addSong', {
            Event_id: props.event_id,
            Title: fields.title,
            Url: fields.url,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('song', id, file)
            }
            addToast('Successfully added', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }
    }
}


    const handleUpdate = async () => {
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.put(`/calendar/event-content/updateSong/${editMode.id}`, {
            Title: fields.title,
            Url: fields.url,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('song', id, file)
            }
            addToast('Successfully updated', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
            setLoader(false)
        }
    }
}
    function handleChange(e) { 
        let key, value;
        // key=e
        key = e?.target?.name;
        if (!!errors[key]){
          setErrors({
            ...errors,
            [key]: null,
            });
        }
      }

    useEffect(() => {
        fetchingSongs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <article className='border border-dark rounded d-flex gap-2' >
            <div className='w-75 flex-grow-1 p-3'>
                <h4 className='text-center w-100 my-2'>
                    <Badge bg="light" text="dark">SONGS</Badge>
                </h4>
                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" name="title" isInvalid={!!errors?.title} placeholder="Add title" value={fields.title} onChange={e => {setfields('title', e.target.value); handleChange(e)}} />
                    <Form.Control.Feedback type="invalid">
                {errors?.title}
              </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-1">
                    <Form.Label>Add Url</Form.Label>
                    <Form.Control size="sm" type="text" name="url" isInvalid={!!errors?.url} placeholder='Add url' value={fields.url} onChange={e => {setfields('url', e.target.value); handleChange(e)}} />
                    <Form.Control.Feedback type="invalid">
                {errors?.url}
              </Form.Control.Feedback>
                </Form.Group>

                <div className="form-group">
                    <label htmlFor='tags' className='my-2'>Tags</label>
                    <TagsInput
                        value={fields.tags}
                        onChange={(val) => setfields("tags", val)}
                        onKeyUp={e=>handleChange(e)}
                        id="tags"
                        name="tags"
                        placeHolder="Add tags (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.tags}</p>
                </div>
                <div className="form-group">
                    <label htmlFor='catgories' className='my-2'>Categories</label>
                    <TagsInput
                        value={fields.categories}
                        onChange={(val) => setfields("categories", val)}
                        onKeyUp={e=>handleChange(e)}
                        id="catgories"
                        name="catgories"
                        placeHolder="Add Categories (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.catgories}</p>
                </div>

                <Form.Group className="mb-3" >
                    <Thumbnail title={"Upload Thumbnail"} id={"songs-thumbnail"} image={fields.file} handler={val => {
                        setfields('file', val.target.files[0])
                        val.target.value = null
                    }} />
                </Form.Group>

                <div className='w-100 text-center'>
                {loader?<Button type="submit" size="sm" className='as_btn' >
                    {editMode.status ? "Processing..." : "Processing..."}
                </Button>:<Button type="submit" size="sm" className='as_btn' onClick={(e) => {
                    e.preventDefault()
                    editMode.status ? handleUpdate( ) :
                        handleSubmit()
                }}>
                    {editMode.status ? "Update" : "Submit"}
                </Button>}
                    
                    <Button type="submit" size="sm" className='as_btn mx-2' onClick={reset}>
                        Clear
                    </Button>
                </div>
            </div>
            <div className='w-25 border rounded'>
                <h6 className='mx-2 p-2'>ALL SONGS</h6>
                {
                    songs.map(sdo => {
                        let imageurl = `${process.env.REACT_APP_CALENDAR_API_URL}/${sdo.Thumbnail}`
                        return (
                            <article key={sdo._id} className='d-flex justify-content-between align-items-center p-2 border overflow-hidden'>
                                <div>
                                    <img  src={sdo.Image ? imageurl : '/images/default.jpg'} alt="newimagess" width={28} className="rounded-circle" />
                                    <span className='mx-2 text-capitalize'>{sdo.Title}</span>
                                </div>
                                <div>
                                    <PencilSquare className='pointerUtilityClass text-success mx-2' size={15} onClick={() => handleEdit(sdo._id)} />
                                    <Trash3Fill className='pointerUtilityClass text-danger mx-2' size={14.5} onClick={() => handleDelete(sdo._id)} />
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </article >
    )
}

const StoriesComponent = (props) => {
    const[loader,setLoader]=useState(false)
    const [errors, setErrors] = useState({})
    const [stories, setStories] = useState([])
    const [editMode, setEditMode] = useState({
        status: false,
        id: null
    })
    const [fields, setFields] = useState({
        title: '',
        content: '',
        tags: [],
        categories: [],
        file: null
    })

    const { addToast } = useToasts();

    const fetchingStories = async () => {
        const response = await APIServices.get(`/calendar/event-content/getAllStories/${props.event_id}`)
        if (response?.data.code === 200) {
            setStories(response.data.results)
        }
    }

    const reset = () => {
        setFields({
            title: '',
            content: '',
            tags: [],
            categories: [],
            file: null
        })
        setEditMode({
            status: false,
            id: null
        })
        fetchingStories()
    }
    const validateInputs = () => {
        let isValid = false;
       
        const newErrors = {};
        
      
          if ( fields?.title =="") 
          newErrors.title = "Please enter title and press enter.";

          if(fields.content === '')
          newErrors.content = "Please enter content.";
    
          if ( fields.tags =="") 
          newErrors.tags = "Please enter tags and press enter.";
    
        //   if (newData.file === "") 
        //   newErrors.file = "Please upload image.";
    
          if (fields.categories == "")
          newErrors.catgories = "Please select categories and press enter.";

          return newErrors;
      }; 

    const handleEdit = async (id) => {
        setEditMode({ status: true, id })
        const response = await APIServices.get(`/calendar/event-content/getStory/${id}`)
        if (response.data.code === 200) {
            const { Title, Text, Tags, Categories } = response.data.results
            setFields({
                title: Title,
                content: Text,
                tags: Tags,
                categories: Categories,
                file: null
            })
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

    const setfields = (key, value) => {
        setFields(prev => ({ ...prev, [key]: value }))
    }

    const handleDelete = async (id) => {
        const response = await APIServices.delete(`/calendar/event-content/deleteStory/${id}`)
        if (response.data.code === 200) {
            addToast('Deleted', {
                appearance: 'success',
                autoDismiss: true
            })
            fetchingStories()
        }
    }

    const fileupload = async (type, id, file) => {
        let formData = new FormData()
        formData.append('file', file)
        const fileupload = await APIServices.post(`/calendar/upload-file/${type}/${id}`, formData,
            { 'content-type': 'multipart/form-data' }
        )
        if (fileupload?.data.code !== 200) {
            addToast(`File uploading - ${fileupload.data.message}`, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }


    const handleSubmit = async () => {
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.post('/calendar/event-content/addStory', {
            Event_id: props.event_id,
            Title: fields.title,
            Text: fields.content,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('story', id, file)
            }
            addToast('Successfully added', {
                appearance: 'success',
                autoDismiss: true
            })
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }
}

    const handleUpdate = async () => {
        const newErrors = validateInputs();
        setLoader(true)
        if(Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
           setLoader(false)
          }else{
        const response = await APIServices.put(`/calendar/event-content/updateStory/${editMode.id}`, {
            Title: fields.title,
            Text: fields.content,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('story', id, file)
            }
            addToast('Successfully updated', {
                appearance: 'success',
                autoDismiss: true
            })
            setLoader(false)
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }
}

function handleChange(e) { 
    let key, value;
    // key=e
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }


    useEffect(() => {
        fetchingStories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <article className='border border-dark rounded d-flex gap-2' >
            <div className='w-75 flex-grow-1 p-3'>
                <h4 className='text-center w-100 my-2'>
                    <Badge bg="light" text="dark">Stories</Badge>
                </h4>

                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" name="title" isInvalid={!!errors?.title}  placeholder="Add title" value={fields.title} onChange={e => {setfields('title', e.target.value);handleChange(e)}} />
                    <Form.Control.Feedback type="invalid">
                    {errors?.title}
                  </Form.Control.Feedback>
                    </Form.Group>

                <Form.Group className="mb-3" >
                    <Form.Label>Content</Form.Label>
                    <Form.Control as="textarea" rows={4} name="content" isInvalid={!!errors?.content} style={{ height: '100px', padding: "5px" }} placeholder="Add contents" value={fields.content} onChange={e => {setfields('content', e.target.value); handleChange(e)}} />
                    <Form.Control.Feedback type="invalid">
                    {errors?.content}
                  </Form.Control.Feedback>
                    </Form.Group>

                <div className="form-group">
                    <label htmlFor='tags' className='my-2'>Tags</label>
                    <TagsInput
                        value={fields.tags}
                        onChange={(val) => setfields("tags", val)}
                        id="tags"
                        onKeyUp={e=>handleChange(e)}
                        name="tags"
                        placeHolder="Add tags (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.tags}</p>
                </div>
                <div className="form-group">
                    <label htmlFor='catgories' className='my-2'>Categories</label>
                    <TagsInput
                        value={fields.categories}
                        id="catgories"
                        onChange={(val) => setfields("categories", val)}
                        onKeyUp={e=>handleChange(e)}
                        name="catgories"
                        placeHolder="Add Categories (Press enter after adding text)"
                    />
                    <p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors?.catgories}</p>
                </div>

                <Form.Group className="mb-3" >
                    <Thumbnail title={"Image"} id={"stories-image"} image={fields.file} handler={val => {
                        setfields('file', val.target.files[0])
                        val.target.value = null
                    }} />
                </Form.Group>

                <div className='w-100 text-center'>
                {loader?<Button type="submit" size="sm" className='as_btn'>
                {editMode.status ? "Processing..." : "Processing..."}
                </Button>:<Button type="submit" size="sm" className='as_btn' onClick={(e) => {
                    e.preventDefault()
                    editMode.status ? handleUpdate() :
                        handleSubmit()
                }}>
                {editMode.status ? "Update" : "Submit"}
                </Button>}
                    
                        
                    <Button type="submit" size="sm" className='as_btn mx-2' onClick={reset}>
                        Clear
                    </Button>
                </div>
            </div>
            <div className='w-25 border rounded'>
                <h6 className='mx-2 p-2'>ALL Stories</h6>
                {
                    stories.map(sdo => {
                        let imageurl = `${process.env.REACT_APP_CALENDAR_API_URL}/${sdo.Image}`
                        return (
                            <article key={sdo._id} className='d-flex justify-content-between align-items-center p-2 border overflow-hidden'>
                                <div>
                                    <img  src={sdo.Image ? imageurl : '/images/default.jpg'} alt="newimagess" width={28} className="rounded-circle" />
                                    <span className='mx-2 text-capitalize'>{sdo.Title}</span>
                                </div>
                                <div>
                                    <PencilSquare className='pointerUtilityClass text-success mx-2' size={15} onClick={() => handleEdit(sdo._id)} />
                                    <Trash3Fill className='pointerUtilityClass text-danger mx-2' size={14.5} onClick={() => handleDelete(sdo._id)} />
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </article>
    )
}

const Content = (props) => {

    const { addToast } = useToasts();

    const fileupload = async (type, id, file) => {
        let formData = new FormData()
        formData.append('file', file)
        const fileupload = await APIServices.post(`/calendar/upload-file/${type}/${id}`, formData,
            { 'content-type': 'multipart/form-data' }
        )
        if (fileupload?.data.code !== 200) {
            addToast(`File uploading - ${fileupload.data.message}`, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

const videoAPI = async (fields, reset) => {
        const response = await APIServices.post('/calendar/event-content/addVideo', {
            Event_id: props.eventid,
            Title: fields.title,
            Url: fields.url,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('video', id, file)
            }
            addToast('Successfully added', {
                appearance: 'success',
                autoDismiss: true
            })
            reset()
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

    

   

    const videoUpdateAPI = async (id, fields) => {
        const response = await APIServices.put(`/calendar/event-content/updateVideo/${id}`, {
            Title: fields.title,
            Url: fields.url,
            Categories: fields.categories,
            Tags: fields.tags
        })
        if (response?.data.code === 200) {
            if (fields.file) {
                let id = response.data.results._id
                let file = fields.file
                await fileupload('video', id, file)
            }
            addToast('Successfully updated', {
                appearance: 'success',
                autoDismiss: true
            })
        } else {
            addToast(response.data.message, {
                appearance: 'error',
                autoDismiss: true
            })
        }
    }

   

    

    const handleSubmit = (tag, fields, reset) => {
        switch (tag) {
            case 'videos':
                videoAPI(fields, reset)
                break;
            case 'stories':
                // storiesAPI(fields, reset)
                break;
            case 'songs':
                // songsAPI(fields, reset)
                break;
            default:
                break;
        }
    }

    const handleEdit = (tag, fields, id) => {
        switch (tag) {
            case 'videos':
                // videoUpdateAPI(id, fields)
                break;
            case 'stories':
                // storyUpdateAPI(id, fields)
                break;
            case 'songs':
                // songUpdateAPI(id, fields)
                break;
            default: break;
        }
    }

    return (
        <div>
            <Tab.Container defaultActiveKey="video">
                <Row>
                    <Col sm={1}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link className='tabs-color-class' eventKey="video">Videos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='tabs-color-class' eventKey="song">Songs</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className='tabs-color-class' eventKey="story">Stories</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey="video">
                                <VideoComponent handleSubmit={handleSubmit} tag={'videos'} event_id={props.eventid} handleEdit={handleEdit} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="song">
                                <SongComponent event_id={props.eventid} handleEdit={handleEdit} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="story">
                                <StoriesComponent  event_id={props.eventid} handleEdit={handleEdit} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    )
}


export default Content
// fw-bold text-dark btnHover 