import React, { useEffect, useState } from "react";
import { useInitFbSDK } from '../../hooks/injectFbSDKScript';
import FBengagement from "../../components/Facebook/FBengagement";
import FBviews from "../../components/Facebook/FBviews";
import FBlikes from "../../components/Facebook/FBlikes";
import FBimpressions from "../../components/Facebook/FBimpressions";
import FBposts from "../../components/Facebook/FBposts";
// import Loader from 'rsuite/Loader';

const PAGE_ID = "102399459127472";

const FacebookAnalytics = () => {

  const isFbSDKInitialized = useInitFbSDK();

  // App state
  const [fbUserAccessToken, setFbUserAccessToken] = React.useState();
  const [fbPageAccessToken, setFbPageAccessToken] = React.useState();

  // Logs in a Facebook user
  const logInToFB = React.useCallback(() => {
    window.FB.login((response) => {
      setFbUserAccessToken(response.authResponse.accessToken);
    });
  }, []);

  // Logs out the current Facebook user
  const logOutOfFB = React.useCallback(() => {
    window.FB.logout(() => {
      setFbUserAccessToken(null);
      setFbPageAccessToken(null);
    });
  }, []);

  // Checks if the user is logged in to Facebook
  React.useEffect(() => {
    if (isFbSDKInitialized) {
      window.FB.getLoginStatus((response) => {
        if (response.authResponse?.accessToken) {

          setFbUserAccessToken(response.authResponse?.accessToken);
        }
        else {
          logInToFB();
        }
      });
    }
  }, [isFbSDKInitialized]);

  // Fetches an access token for the page
  React.useEffect(() => {
    if (fbUserAccessToken) {
      window.FB.api(
        `/${PAGE_ID}?fields=access_token&access_token=${fbUserAccessToken}`,
        ({ access_token }) => setFbPageAccessToken(access_token)
      );
    }
  }, [fbUserAccessToken]);

  return (
    <>
      {fbPageAccessToken &&
        <div>
          <FBengagement fbPageAccessToken={fbPageAccessToken} />
          <FBviews fbPageAccessToken={fbPageAccessToken} />
          <FBlikes fbPageAccessToken={fbPageAccessToken} />
          <FBimpressions fbPageAccessToken={fbPageAccessToken} />
          <FBposts fbPageAccessToken={fbPageAccessToken} />
        </div>
      }
    </>
  );
}

export default FacebookAnalytics;