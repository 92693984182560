import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { Row, Col, Dropdown, Form, ToggleButton, ButtonGroup, Spinner, Button, Modal } from "react-bootstrap";
import { ArrowClockwise, XLg, PersonCircle, BookmarksFill, BookmarkFill, Upload, FileExcel } from 'react-bootstrap-icons';
import moment from "moment";
// import Spinner from 'react-bootstrap/Spinner';
import { UserContext } from "../context/UserContextProvider";
import APIServicenew from "../utils/APIGeneralService";
// import { socket } from "../socket";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import CloseButton from 'react-bootstrap/CloseButton';
import ReactPaginate from 'react-paginate';
import { getCookies, setCookies } from "../utils/Helper";
import chatBack from "../imgs/chat_back.png"
import { FiMessageSquare, FiBell, FiTwitch, FiPhone } from "react-icons/fi";
import Tooltip from 'react-bootstrap/Tooltip';
import { ToastContainer, toast } from "react-toastify";
import { useToasts } from 'react-toast-notifications';
import { set } from "react-hook-form";
import { formateDate } from "../utils/Helper";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ImgGalleyModel } from "../components/Shared/ImgGalleryModel";
import { FaSistrix } from "react-icons/fa";

setCookies("skip", 0)
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    // href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </span>
));

const saveOrgetLocalStorage = (val, data, id) => {
  switch (val) {
    case "get":
      const values = JSON.parse(localStorage.getItem("doshFormData"))
      return values

    case "set":
      localStorage.setItem("doshFormData", JSON.stringify({
        id: id,
        name: data.name,
        dob: data.dob,
        poc: data.poc,
        lat: data.lat,
        lng: data.lng,
        hours: data.hours,
        minutes: data.minutes,
        seconds: data.seconds,
      }))
      break;
  }
}

const MangalForms = (props) => {
  const today = new Date()
  const [validation, setValidation] = useState({
    mode: false,
    message: "",
    effect: ""
  })
  const inputRef = useRef()
  const [data, setData] = useState({
    name: "",
    dob: "",
    poc: "",
    lat: "",
    lng: "",
    hours: "",
    minutes: '',
    seconds: "",
    type: "",
  })
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBY6JZhuRymDmtxBlKJuSHLMmYJxDwF_4Y"

  const validatioCheck = () => {
    let finalError = { check: true, value: "" }
    console.log("data", data)
    if (data.name === "") {
      finalError = { check: false, value: "Name field is empty" }
    } else if (data.hours == "") {
      finalError = { check: false, value: "Birth hours is empty" }
    } else if (data.minutes == "") {
      finalError = { check: false, value: "Birth minutes is empty" }
    }
    else if (data.seconds == "") {
      finalError = { check: false, value: "Birth seconds is empty" }
    } else if (data.hours < 0 || data.minutes < 0 && data.seconds < 0) {
      finalError = { check: false, value: "Birth time can't be less than zero" }
    } else if (data.lat == "" || data.lng == "") {
      finalError = { check: false, value: "Location is empty" }
    } else if (data.dob === "") {
      finalError = { check: false, value: "Date of birth is empty" }
    }
    return finalError
  }

  const handlingEffects = () => {
    if (props?.doshRespose?.response) {
      if (props?.doshRespose?.effect === "LESS_EFFECTIVE") {
        return { color: "yellow" }
      }
      if (props?.doshRespose?.effect === "EFFECTIVE") {
        return { color: "red" }
      }
    }
    return { color: "#ADFF2F" }
  }

  const handleSubmition = (e, data, type) => {
    e.preventDefault()
    setValidation({
      mode: false,
      message: ""
    })
    const { check, value } = validatioCheck()
    if (!check) {
      setValidation({
        mode: true,
        message: value,
      })
      return
    }
    saveOrgetLocalStorage("set", data, props.currentUser._id)
    // setData({
    //   name:"",
    //   dob:"",
    //   poc:"",
    //   lat:"",
    //   lng:"",
    //   type:"",
    //   hours:"",
    //   minutes:"",
    //   seconds:"",
    // })
    props.handleMangalDoshForm(data, type)
  }

  const doshButton = (newarray) => {

    return (
      <>
        {
          newarray.map((element, index) => {
            return (
              <ToggleButton
                key={index}
                id={index}
                type="radio"
                name={element.name}
                value={element.type}
                className="radio-btn-dosh-form"
                checked={element.type === data.type}
                onClick={(e) => handleSubmition(e, data, element.type)}
                onChange={(e) => {
                  setData(prev => ({ ...prev, type: e?.currentTarget?.value }))
                }}
              >
                {element.name}
              </ToggleButton>
            )
          })
        }
      </>
    )
  }
  useEffect(() => {

    if (props.localData?.name) {
      setData(props.localData)
    } else {
      setData({
        name: "",
        dob: "",
        poc: "",
        lat: "",
        lng: "",
        hours: "",
        minutes: "",
        seconds: "",
      })
    }

  }, [props.localData])
  return (
    <Form className="managaldosh-form-style">
      <div className="text-end">
        <CloseButton onClick={props.handleCloseDoshForm} className="bg-transparent" />
      </div>
      <Form.Group className="mb-3" controlId="namefield">
        <Form.Label className='required'>Full Name</Form.Label>
        <Form.Control type="text" placeholder="Your Name" value={data.name} onChange={e => setData({ ...data, name: e.target.value })} />
      </Form.Group>
      <Form.Group className="mb-3 d-flex row" controlId="birthTime">
        <div className="col-md-4">
          <Form.Label className='required'>Birth Hour</Form.Label>
          {/*<Form.Control  type="number" min={0} max={12} placeholder="Enter a hours" value={data.hours} onChange={e=>setData({...data,hours:e.target.value})}/>*/}
          <Form.Select
            aria-label="Default select example"
            required
            className="hd-cr"
            value={data.hours} onChange={e => setData({ ...data, hours: e.target.value })}

          >
            <option value="">Choose</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>

          </Form.Select>
        </div>
        <div className="col-md-4">
          <Form.Label className='required'>Birth Minute</Form.Label>
          {/* <Form.Control  type="number" min={0} max={60} placeholder="Enter a minutes" value={data.minutes} onChange={e=>setData({...data,minutes:e.target.value})}/>*/}
          <Form.Select
            aria-label="Default select example"
            required
            className="hd-cr"
            value={data.minutes} onChange={e => setData({ ...data, minutes: e.target.value })}
          >
            <option value="">Choose</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
            <option value="32">32</option>
            <option value="33">33</option>
            <option value="34">34</option>
            <option value="35">35</option>
            <option value="36">36</option>
            <option value="37">37</option>
            <option value="38">38</option>
            <option value="39">39</option>
            <option value="40">40</option>
            <option value="41">41</option>
            <option value="42">42</option>
            <option value="43">43</option>
            <option value="43">43</option>
            <option value="44">44</option>
            <option value="45">45</option>
            <option value="46">46</option>
            <option value="47">47</option>
            <option value="48">48</option>
            <option value="49">49</option>
            <option value="50">50</option>
            <option value="51">51</option>
            <option value="52">52</option>
            <option value="53">53</option>
            <option value="53">53</option>
            <option value="54">54</option>
            <option value="55">55</option>
            <option value="56">56</option>
            <option value="57">57</option>
            <option value="58">58</option>
            <option value="59">59</option>

          </Form.Select>
        </div>
        <div className="col-md-4">
          <Form.Label className='required'>Birth Seconds</Form.Label>
          {/*<Form.Control  type="number" min="0" max="60" placeholder="Enter a seconds" value={data.seconds} onChange={e=>setData({...data,seconds:e.target.value})}/>*/}
          <Form.Select
            aria-label="Default select example"
            required
            className="hd-cr"
            value={data.seconds} onChange={e => setData({ ...data, seconds: e.target.value })}
          >
            <option value="">Choose</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
            <option value="23">23</option>
            <option value="23">23</option>
            <option value="24">24</option>
            <option value="25">25</option>
            <option value="26">26</option>
            <option value="27">27</option>
            <option value="28">28</option>
            <option value="29">29</option>
            <option value="30">30</option>
            <option value="31">31</option>
            <option value="32">32</option>
            <option value="33">33</option>
            <option value="34">34</option>
            <option value="35">35</option>
            <option value="36">36</option>
            <option value="37">37</option>
            <option value="38">38</option>
            <option value="39">39</option>
            <option value="40">40</option>
            <option value="41">41</option>
            <option value="42">42</option>
            <option value="43">43</option>
            <option value="43">43</option>
            <option value="44">44</option>
            <option value="45">45</option>
            <option value="46">46</option>
            <option value="47">47</option>
            <option value="48">48</option>
            <option value="49">49</option>
            <option value="50">50</option>
            <option value="51">51</option>
            <option value="52">52</option>
            <option value="53">53</option>
            <option value="53">53</option>
            <option value="54">54</option>
            <option value="55">55</option>
            <option value="56">56</option>
            <option value="57">57</option>
            <option value="58">58</option>
            <option value="59">59</option>

          </Form.Select>
        </div>
      </Form.Group>
      <Form.Group className="mb-3" controlId="birthplace">
        <Form.Label className='required'>Birth Place</Form.Label>
        <ReactGoogleAutocomplete
          className="form-control "
          ref={inputRef}
          // value={data.poc}
          placeholder="Enter a location"
          onChange={(e) => setData(prev => ({ ...prev, poc: e.target.value }))}
          apiKey={YOUR_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place) => {
            setData(prev => ({ ...prev, lat: place?.geometry?.location?.lat(), lng: place?.geometry?.location?.lng() }))
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="dobfield">
        <Form.Label className='required'>Date of birth</Form.Label>
        <Form.Control type="date" max={formateDate(today)} onChange={e => setData({ ...data, dob: e.target.value })} value={data.dob} />
      </Form.Group>
      {props.doshloader ? "" : <ButtonGroup className="d-flex gap-2 dosh-btns as_btn">
        {doshButton([
          { name: "Mangal Dosh", type: "manglik_dosh" },
          { name: "Kaalsarp Dosh", type: "kaalsarp_dosh" },
          { name: "Sadhe Sati", type: "sadhesati" },
          { name: "Pitra Dosh", type: "pitra_dosh" },
        ])}
      </ButtonGroup>}

      <p className="text-danger">{validation.mode && validation.message}</p>
      <article>
        {props.doshloader ? <><Spinner animation="border" variant="dark" className="spinner" /></> :

          props?.doshRespose?.response && (
            <>
              <p>Response:-</p>
              <div className={`dosh-response-area`} style={handlingEffects()} dangerouslySetInnerHTML={{ __html: props?.doshRespose?.message }}></div>
            </>
          )
        }


      </article>
    </Form>
  )
}

const Chatv2 = () => {
  document.title = 'WhatsApp Leads';
  const listInnerRef = useRef();
  const chatBodyRef = useRef();
  const { user: adminUser } = useContext(UserContext);
  // const [btnd,setBtnd] = useState(false)
  const [isloading, setLoading] = useState(false)
  const { refreshToken } = useContext(UserContext);
  const [texts, setTexts] = useState("");
  const [Instanttexts, setInstantTexts] = useState("");
  const [spinner, setSpinner] = useState(false)
  const [allUsers, setAllUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [botChatHistory, setBotChatHistory] = useState([]);
  const [shortUser, setShortUser] = useState([]);
  const [totalChats, setTotalChats] = useState('');
  const [labels, setLabels] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [responder, setResponder] = useState([])
  const [localData, setLocalData] = useState({})
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ top: 0, left: 0 });
  const contextMenuRef = useRef("")
  const selectedLableValue = useRef('');
  const [imgData, setImgData] = useState("")
  const [imgLeadup ,setImagLeadup] = useState("")
  const [reloadText, setReloadText] = useState(false)
  const imgDataRef = useRef(null)
  const selectedLableUserId = useRef(null)
  const [showDoshForm, setShowDoshForm] = useState(false)
  const [oldUser, setOldUser] = useState(null)
  const [forcePage, setForcePage] = useState(0)
  const searchKeyword = useRef(null)
  const selectedCampaign = useRef(null)
  const selectedRead = useRef(null)
  const selectedNeedReply = useRef(null)
  const selectedResponder = useRef(null)
  const dateStored = useRef({
    startDate: moment().format('MM-DD-YYYY'),
    endDate: moment().format('MM-DD-YYYY')
  })
  const { addToast } = useToasts();
  const [loader, setLoader] = useState(false)
  const [refreshchatloader, setRefreshchatLoader] = useState(false)
  const [showaa, setShowaa] = useState(false);
  const [campaignsaa, setCampaignsaa] = useState([])
  const [responderaa, setResponderaa] = useState([])
  const [aauser, setAAuser] = useState([])
  const [selectcampaa, setSelectcampaa] = useState()
  const [selectrespaa, setSelectrespaa] = useState()
  const [btnTxt, setBtnTxt] = useState("Send");
  const [userForNote ,setUserForNote] = useState()
  const [showNoteModel ,setShowNoteModel] = useState(false)  
  const [showWTempModel ,setShowWTempModel] = useState(false)  
  const [showImageGalleyModel,setShowImageGalleyModel] = useState(false)  
  const [activeInputField, setActiveInputField] = useState(null);
  const [alltemplate, setAllTemplate] = useState([]);
  const [error ,setError] = useState(false) ; 
  const [note ,setNote] = useState('')
  const [userNoteDetails,setUserNoteDetails] = useState([])

  const handleCloseaa = () => {
    setShowaa(false);
    setSelectcampaa()
    setSelectrespaa()
    setResponderaa([])
  }
  const handleShowaa = () => {
    setShowaa(true);
    setSelectcampaa(aauser?.campaignId);
    setSelectrespaa(aauser?.responderId)
  }

  const handleShowNote = () =>{
    setShowNoteModel(true)
  }

  const handleCloseNote = () => {
    setShowNoteModel(false);
    setError(false)
  
  }

  const handleOpenModal = (itemId) => {
    setActiveInputField(itemId);
    setShowImageGalleyModel(true);
  };

  const handleSetImageUrl = (url) => {
    handleUrlTextChange({ target: { name: 'image_link', value: url } }, activeInputField);
    setShowImageGalleyModel(false);
  };

  const [loaderForTemp ,setLoaderForTemp] = useState(false)
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const [selectedMessagedata, setSelectedMessagedata] = useState([]);
  const [messagedata, setMessagedata] = useState([]);

  const handleCloseWTemp = () =>{
    setShowWTempModel(false)
    setMessagedata([])
    setSelectedMessageIds([])
    setSelectedMessagedata([])
    setErrors({
      image_links: {},
      body_text: {}
    });
  
    setInputValues({
      image_links: {},
      body_text: {}
    })
   }

  const [inputValues, setInputValues] = useState({
    image_links: {},
    body_text: {}
  });
  const [errors, setErrors] = useState({
    image_links: {},
    body_text: {}
  });


  const handleUrlTextChange = (e, id) => {
    const { value } = e.target;
  
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      image_links: {
        ...prevInputValues.image_links,
        [id]: value
      }
    }));
  
  };
  
  
  const handleBodyTextChange = (e, index, id) => {
    const { value } = e.target;
  
    setInputValues((prevInputValues) => {
      const updatedBodyText = {
        ...prevInputValues.body_text,
        [id]: [...(prevInputValues.body_text[id] || [])]
      };
      updatedBodyText[id][index] = value;
      return {
        ...prevInputValues,
        body_text: updatedBodyText
      };
    });
  };

  

  const toggleSelection = (item) => {
    if(selectedMessageIds.includes(item.id)){
      setSelectedMessageIds([])
      setSelectedMessagedata([])
      setMessagedata([])
      setInputValues({
        image_links: {},
        body_text: {}
      })
    }
    else if(selectedMessageIds.length === 0){
    setSelectedMessageIds((prevSelected) =>
      prevSelected.includes(item?.id)
        ? prevSelected.filter((selectedId) => selectedId !== item?.id)
        : [...prevSelected, item?.id]
    );


    setMessagedata((prevSelected) =>
      prevSelected.some((selected) => selected.id === item.id)
        ? prevSelected.filter((selected) => selected.id !== item.id)
        : [
            ...prevSelected,
            {
              ...item,
            },
          ]
    );
  }else{
      addToast("You can select only one template", {
        appearance: 'warning',
        autoDismiss: true
      })
    }
  };
  
  const handleShowWTemp = () =>{
    setShowWTempModel(true)
    fetchedAllTemplate()
  }
  const fetchedAllTemplate = async () => {
    const res = await APIServices.get(`/whatsAppApi/getTemplate`, headers);
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData.data];

        setAllTemplate(newData)
    
      }
    }
  };



  const compareBodyTextIdWithItemId = (text, itemId) => {
    return text.hasOwnProperty(itemId);
  };

  const handleWTMessage = async(e) =>{
  e.preventDefault();
     if(messagedata?.length === 0){
      addToast("Please select templete ", {
        appearance: 'warning',
        autoDismiss: true
      })
    }else{      
      let validationPassed = true;
        if ( messagedata[0].components) {
          messagedata[0].components.forEach(component => {
        if (component.type === "HEADER" && component.format === "IMAGE") {
          if (!compareBodyTextIdWithItemId(inputValues.image_links, messagedata[0]?.id)||!inputValues.image_links[ messagedata[0]?.id]) {
            addToast('Image URL is required.', {
              appearance: 'error',
              autoDismiss: true
            })

            validationPassed = false;
          }
      
        }
        if (component.type === "BODY" && component.example?.body_text?.[0]?.length > 0) {
          if (!compareBodyTextIdWithItemId(inputValues.body_text, messagedata[0]?.id)||!inputValues.body_text[ messagedata[0]?.id]) {
            addToast('Body text is required.', {
              appearance: 'error',
              autoDismiss: true
            })
            validationPassed = false;
          }
        }
      });
    }
    if (!validationPassed) {
      return;
    }
    const imageLink = inputValues.image_links[ messagedata[0].id] ||"";
    const bodyText = inputValues.body_text[ messagedata[0].id] || [];
    setLoaderForTemp(true)
    const payload = {
      userId: currentUser.userId,
      currentChatter: "admin",
      currentChatterId: adminUser._id,
      // tempArr: selectedMessagedata      
      tempArr: [
        ...selectedMessagedata,
        {
          ...messagedata[0],
          body_text: bodyText,
          image_link: imageLink,
        },
      ]                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   ,
    }
    const res = await APIServices.post(`/whatsAppApi/sendTemp`,payload, headers);
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status == true) {
      setLoaderForTemp(false)  
      addToast(resData.message, {
        appearance: 'success',
        autoDismiss: true
      })
      handleCloseWTemp()
      fetchBotChatHistory()
    }else if( resData.status == false){
      addToast(resData.message, {
        appearance: 'error',
        autoDismiss: true
      })
    setLoaderForTemp(false)  

    }

  }else{
    addToast(res?.data?.message, {
      appearance: 'error',
      autoDismiss: true
    })
    setLoaderForTemp(false)  
  }
 }
  }
  const handleChange = () =>{
    setError(false)
}
  const InstantdateStored = useRef({
    startDate: moment().format('MM-DD-YYYY'),
    endDate: moment().format('MM-DD-YYYY'),
    time: 24
  })
  const [showLeadMessage, setShowLeadMessage] = useState(false);
  const [showLeadMessagecampignid, setShowLeadMessageCampignId] = useState([]);
  const [showLeadMessageResponderid, setShowLeadMessageResponderId] = useState([]);
  const [selectleadcampaa, setSelectLeadcampaa] = useState('')
  const [selectleadResp, setSelectLeadResp] = useState('')

    const handleCloseLeadMessage = () => {
    setShowLeadMessage(false)
    setInstantTexts("")
    setSelectLeadcampaa('')
    setSelectLeadResp('')
    // setImagLeadup('')
    InstantdateStored.current.startDate = moment().format('MM-DD-YYYY')
    InstantdateStored.current.endDate = moment().format('MM-DD-YYYY')
  }
  const navigate = useNavigate()

  useEffect(() => {
    setSelectcampaa(aauser?.campaignId);
    callRespnder();
    setSelectrespaa(aauser?.responderId)
  }, [aauser])

  const callRespnder = async () => {

    let mycampId = aauser.campaignId ? aauser.campaignId : "";
    const res = await APIServices.get(`whatsAppApi/getResponderBycampaign/${aauser?.campaignId}`, headers);
    if (res && res?.data?.status === true) {
      setResponderaa(res?.data?.responderDataList)
    }
  }



  const [doshRespose, setDoshResponse] = useState({
    response: false,
    message: "",
    effect: ""
  })
  const [doshloader, setDoshloader] = useState(false)
  const [userloader, setUserloader] = useState(false)
  const [sendchatloader, setSendchatloader] = useState(false)
  const bottomRef = useRef(null);
  const socketRef = useRef(false);
  const allusersref = useRef([]);
  const [skip, setSkip] = useState(0)
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL)
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL)
  const allChatterType = ["admin", "bot"]
  const currentUserIdref = useRef(undefined)

  const token = getCookies("accessToken")

  const headers = {
    "Authorization": `Bearer ${token}`
  }

  const searchUsersList = async ({ skips = 0, searchKey = "", filterId = "", campaignId = "", responderId = "", startDate = moment().format('MM-DD-YYYY'), endDate = moment().format('MM-DD-YYYY'), checkRead, waitingReply }) => {
    let mySkip = Number(getCookies("skip"));

    let forcePage = mySkip / 50;
    setForcePage(forcePage)

    const res = await APIServices.post("whatsAppApi/chatList", {
      searchKey: searchKey,
      limit: 50,
      filterId,
      skip: mySkip,
      isRead: checkRead,
      waitingUser: waitingReply,
      startDate,
      endDate,
      campaignId,
      responderId
    }, headers);
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData.data]
        setAllUsers(newData);
        allusersref.current = newData;
        setTotalChats(resData.total_count);
        setReloadText(false)
        setLoader(false)
        setUserloader(false)
      }else if(resData.status == false){
        refreshToken(null);
        setCookies("accessToken", null);
        navigate("/");
  
      }
    }
    setLoader(false)
    setUserloader(false)
  }

  const handleLabelSelectChange = (e) => {
    // console.log("label",selectedLableValue.current);
    setCookies("skip", 0)
    selectedLableValue.current = e.target.value

    handlingCombineFiltering({
      selectedFilterId: e.target.value,
      selectedCampaignId: selectedCampaign.current,
      selectedResponderId: selectedResponder.current,
      startDates: dateStored.current.startDate,
      endDates: dateStored.current.endDate,
      searchKeyword: searchKeyword.current,
      selectedReadValue: selectedRead.current,
      needsReplyUser: selectedNeedReply.current,
    })
  }

  const handleLabelCampaignChange = (e) => {
    // console.log("selectedCampaign.current",selectedCampaign.current);
    selectedCampaign.current = e.target.value
    setCookies("skip", 0)
    handlingCombineFiltering({
      selectedFilterId: selectedLableValue.current,
      selectedCampaignId: e.target.value,
      selectedResponderId: selectedResponder.current,
      startDates: dateStored.current.startDate,
      endDates: dateStored.current.endDate,
      searchKeyword: searchKeyword.current,
      selectedReadValue: selectedRead.current,
      needsReplyUser: selectedNeedReply.current,
    })
  }

  const handleResponderChange = (e) => {
    // console.log("selectedResponder.current",selectedResponder.current);
    selectedResponder.current = e.target.value
    setCookies("skip", 0)
    handlingCombineFiltering({
      selectedFilterId: selectedLableValue.current,
      selectedCampaignId: selectedCampaign.current,
      selectedResponderId: e.target.value,
      startDates: dateStored.current.startDate,
      endDates: dateStored.current.endDate,
      searchKeyword: searchKeyword.current,
      selectedReadValue: selectedRead.current,
      needsReplyUser: selectedNeedReply.current,
    })
  }

  const handleSearch = (e) => {
    searchKeyword.current = e.target.value
    if (searchKeyword.current.length >= 3) {
      setCookies("skip", 0)
      handlingCombineFiltering({
        selectedFilterId: selectedLableValue.current,
        selectedCampaignId: selectedCampaign.current,
        startDates: dateStored.current.startDate,
        endDates: dateStored.current.endDate,
        selectedResponderId: selectedResponder.current,
        searchKeyword: e.target.value,
        selectedReadValue: selectedRead.current,
        needsReplyUser: selectedNeedReply.current,
      })

    }

    //searchUsersList({skips:skip,searchKey:e.target.value})
  }



  const fetchBotChatHistory = async () => {
    // console.log("fetchBotChatHistory",adminUser)
    //  setRefreshchatLoader(true)
    const res = await APIServices.post("whatsAppApi/getChatHistory", {
      userId: currentUser?.userId,
      currentChatterId: adminUser?._id,
      currentChatter: "all",
      lastCount: 0
    }, headers);
    if (res && res.status === 200) {
      // setBotChatHistory([])

      if (res.data && res.data?.status) {
        setBotChatHistory(res.data?.result);
      }
      setRefreshchatLoader(false)
      // bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }
    setRefreshchatLoader(false)
  }

  const fetchBotChatHistoryAgain = async () => {
    // console.log("sjhdjfk",adminUser)
    const res = await APIServices.post("whatsAppApi/getChatHistory", {
      userId: currentUser?.userId,
      currentChatterId: adminUser?._id,
      currentChatter: "all",
      lastCount: 0
    }, headers);
    if (res && res.status === 200) {
      // setBotChatHistory([])
      if (res.data && res.data?.status) {
        setBotChatHistory(res.data?.result);
      }
    }
  }

  const handleCloseDoshForm = () => {
    setShowDoshForm(false)
    localStorage.clear()
    setLocalData()
    setDoshResponse({
      response: false,
      message: "",
      effect: ""
    })
  }

  // const fetchAdminHistory = async () => {
  //   const res = await APIServices.post("whatsAppApi/getChatHistory", {
  //     userId: currentUser.userId,
  //     currentChatter: "admin",
  //     currentChatterId: adminUser._id,
  //     lastCount: 0
  //   });
  //   if (res && res.status === 200) {
  //     console.log("Admin chat history", res.data)
  //   }
  // }

  const renderSpinner = () => {
    return (
      <>
        <Spinner animation="border" variant="dark" />
      </>
    );
  }

  const splitDate = (date = null, splitter = null) => {
    const d = new Date(date),
      month = d.getMonth() + 1,
      day = d.getDate(),
      year = d.getFullYear();
    let result = false;

    switch (splitter) {
      case "api_object":
        result = {
          day,
          month,
          year,
        };
        break;
      default:
        break;
    }
    return result;
  };

  const handleMangalDoshForm = async (data, type) => {
    setDoshResponse({
      response: false,
      message: "",
      effect: ""
    })
    const birthDate = splitDate(data.dob, "api_object");
    let payload = {
      ...birthDate,
      name: data.name,
      contactNumber: currentUser.contactNumber,
      hour: data.hours,
      min: data.minutes,
      lat: data.lat,
      lon: data.lng,
      tzone: 5.5,
    };

    payload.type = type;
    setDoshloader(true)
    let res = await APIServices.post(`astrology/createReport/${payload.type}/`, payload, headers);
    let res_status = res.status;
    res = res.data;
    if (res_status === 200 && res.status === true) {
      setDoshloader(false)
      let resData = {
        response: true,
        message: "",
        effect: ""
      }

      if (res.data[0].manglikDoshDetail?.manglik?.manglik_report) {
        resData.message = res.data[0].manglikDoshDetail.manglik.manglik_report
        resData.effect = res.data[0].manglikDoshDetail.manglik.manglik_status
      } else if (res.data[0].kaalsarpDoshDetail?.kalsarpa_details?.one_line) {
        resData.message = res.data[0].kaalsarpDoshDetail?.kalsarpa_details?.one_line
        resData.effect = res.data[0].kaalsarpDoshDetail.kalsarpa_details.present ? "EFFECTIVE" : "NOT_EFFECTIVE"
      } else if (res.data[0].sadhesatiDetail?.sadhesati_current_status?.is_undergoing_sadhesati) {
        resData.message = res.data[0].sadhesatiDetail?.sadhesati_current_status?.is_undergoing_sadhesati
        resData.effect = res.data[0].sadhesatiDetail?.sadhesati_current_status?.sadhesati_status ? "EFFECTIVE" : "NO_EFFECTIVE"
      } else if (res.data[0].pitraDoshDetail?.pitra_dosha_report?.conclusion) {
        resData.message = res.data[0].pitraDoshDetail?.pitra_dosha_report?.conclusion
        resData.effect = res.data[0].pitraDoshDetail?.pitra_dosha_report?.is_pitri_dosha_present ? "EFFECTIVE" : "NO_EFFECTIVE"
      }
      setDoshResponse(resData)
    }
    setDoshloader(false)
  }

  const handleUserListClick = async (user) => {
    // console.log("user",currentUser)
    setOldUser(currentUser)
    handleDoshClose()

    setImgData("")
    setShortUser(prev => {
      let newPrev = prev;
      let newval = newPrev.filter(val => val !== user._id);
      return newval;
    });
    if (currentUser && currentUser.userId === user.userId) {
      return;
    }
    setCurrentUser(user);
    currentUserIdref.current = user
    //Update Chatter to admin
    // const res = await APIServices.put("whatsAppApi/updateChatter", {
    //   userId: user.userId,
    //   currentChatter: "admin",
    //   currentChatterId: adminUser._id,
    // });
    // console.log("Chatter updated to admin")
  };

  const closeChat = async () => {
    //Update Chatter to bot
    // const res = await APIServices.put("whatsAppApi/updateChatter", {
    //   userId: currentUser.userId,
    //   currentChatter: "bot",
    // });
    currentUserIdref.current = null
    setCurrentUser(null);
    // console.log("Chatter updated to bot")
  };

  const handleDoshClose = () => {
    setLocalData("")
    // if(localStorage){
    const data = saveOrgetLocalStorage("get")
    if (String(currentUser?._id) === String(data?.id)) {
      setLocalData(data)
    }
    // }
    setDoshResponse({
      response: false,
      message: "",
      effect: ""
    })
  }

  const handlingCombineFiltering = ({ selectedResponderId, selectedFilterId, selectedCampaignId, startDates, endDates, searchKeyword, selectedReadValue, needsReplyUser }) => {
    setUserloader(true)
    setMaauser([])
    let filterId = selectedFilterId ? selectedFilterId === "all" ? "" : selectedFilterId : ""
    let campaignId = selectedCampaignId ? selectedCampaignId === "all" ? "" : selectedCampaignId : ""
    let responderId = selectedResponderId ? selectedResponderId === "all" ? "" : selectedResponderId : ""
    let startDate = startDates ? startDates : ""
    let endDate = endDates ? endDates : ""
    let searchkeys = searchKeyword ? searchKeyword : ""
    let checkRead = selectedReadValue ? selectedReadValue : ""
    let waitingReply = needsReplyUser ? needsReplyUser : 0

    searchUsersList({ skips: skip, filterId, searchKey: searchkeys, campaignId, startDate, endDate, responderId, checkRead, waitingReply });
  }


  const handleClickLable = async (fid) => {
    const res = await APIServices.put("whatsAppApi/updateChatFilter", {
      chatId: selectedLableUserId.current,
      filterId: fid
    }, headers);
    if (res && res.status === 200) {
      setIsContextMenuVisible(false)
      let newallUsers = allUsers
      let lableItem
      if (fid) {
        lableItem = labels.find(item => String(item._id) === String(fid))
      }
      let newusers = newallUsers.map(object => {
        let newobject
        if (String(object._id) == String(selectedLableUserId.current)) {
          newobject = { ...object, filterColourCode: fid ? lableItem.colourCode : "white" }
          return newobject
        }
        return object
      })
      allusersref.current = newusers;
      setAllUsers(newusers)
    }
  }

  const handleDateFilter = async (e) => {
    let startDate, endDate
    switch (e.target.value) {
      case "Today":
        startDate = moment().format('MM-DD-YYYY');
        endDate = moment().format('MM-DD-YYYY');
        break;
      case "Last24Hours":
        startDate = moment().subtract(24, 'hours').format('MM-DD-YYYY HH:mm:ss');
        endDate = moment().format('MM-DD-YYYY HH:mm:ss');
        break;
      case "Yesterday":
          startDate = moment().subtract(1, 'day').format('MM-DD-YYYY');
          endDate = moment().subtract(1, 'day').format('MM-DD-YYYY');
          break;
      case "This Week":
        startDate = moment().startOf('week').format('MM-DD-YYYY');
        endDate = moment().endOf('week').format('MM-DD-YYYY');
        break;
      case "Last Week":
        startDate = moment().subtract(1, 'week').startOf('week').format('MM-DD-YYYY');
        endDate = moment().subtract(1, 'week').endOf('week').format('MM-DD-YYYY');
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, 'day').startOf('day').format('MM-DD-YYYY');
        endDate = moment().format('MM-DD-YYYY');
        break;
      case "This Month":
        startDate = moment().startOf('month').format('MM-DD-YYYY');
        endDate = moment().endOf('month').format('MM-DD-YYYY');
        break;
      case "Last Month":
        startDate = moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY');
        endDate = moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY');
        break;
    }
    let filterId = selectedLableValue.current ? selectedLableValue.current === "all" ? "" : selectedLableValue.current : ""
    let campaignId = selectedCampaign.current ? selectedCampaign.current === "all" ? "" : selectedCampaign.current : ""
    dateStored.current = { startDate, endDate }
    setCookies("skip", 0)
    searchUsersList({ filterId, campaignId, startDate, endDate, skips: skip })
  }

  const updateChatter = async (item) => {
    let currentChatterId = adminUser?._id || null;

    if (item === "bot") currentChatterId = "";

    const res = await APIServices.put("whatsAppApi/updateChatter", {
      userId: currentUser.userId,
      currentChatter: item,
      currentChatterId,
    }, headers);
    if (res?.data?.status) {
      let allUsersCopy = [...allUsers];
      allUsersCopy = allUsersCopy.map((user) => (user._id === currentUser._id ? { ...user, currentChatter: item } : user));
      setAllUsers(allUsersCopy);
      setCurrentUser((prev) => ({ ...prev, currentChatter: item }));

    }
  };

  const refreshChat = async () => {
    await fetchBotChatHistory();
  };

  const refreshchatuser = () => {
    setRefreshchatLoader(true)
  }

  useEffect(() => {
    if (botChatHistory.length) {
      chatBodyRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [botChatHistory])

  useEffect(() => {
    // const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
      if (currentUser) {
        fetchBotChatHistoryAgain();
      }
    // }, 10000)
    // return () => clearInterval(intervalId); //This is important
  }, [currentUser?.userId]);


  useEffect(() => {
    if (currentUser) {
      fetchBotChatHistory();
      // fetchAdminHistory();
    }
  }, [currentUser?.userId]);

  useEffect(() => {
    if (oldUser?.userId) {
      updateReadStatus()
    }
  }, [oldUser])

  useEffect(() => {
    fetchedLabels()
    fetchedCampaigns()
    fetchedResponder()
    document.addEventListener('click', handleClickOutside);
  }, []);

  // useEffect(() => {

  //     const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
  //       handlingCombineFiltering({
  //         selectedFilterId:selectedLableValue.current,
  //         selectedCampaignId:selectedCampaign.current,
  //         startDates:dateStored.current.startDate,
  //         endDates:dateStored.current.endDate,
  //         selectedResponderId:selectedResponder.current  ,
  //         searchKeyword:searchKeyword.current,       
  //       })
  //     }, 10000)
  //     return () => clearInterval(intervalId) //This is important
  // },[currentUser?.userId]);

  useEffect(() => {
    handlingCombineFiltering({
      selectedFilterId: selectedLableValue.current,
      selectedCampaignId: selectedCampaign.current,
      startDates: dateStored.current.startDate,
      endDates: dateStored.current.endDate,
      selectedResponderId: selectedResponder.current,
      searchKeyword: searchKeyword.current,
      selectedReadValue: selectedRead.current,
      needsReplyUser: selectedNeedReply.current,
    })
  }, [totalChats]);

  useEffect(() => {
    searchUsersList({ skips: skip });
  }, [])

  const sendMessageToWhatsup = async (e) => {
    // setLoading(true)
    // setBtnd(true)
    e.preventDefault();

    const formdata = new FormData()
    formdata.append('userId', currentUser?.userId);
    formdata.append('currentChatter', currentUser?.currentChatter || "admin");
    formdata.append('currentChatterId', adminUser?._id || null);
    if (imgData !== "") {
      formdata.append('commonFile', imgData);
      formdata.append('message', "");
      setImgData("")
    } else {
      formdata.append('commonFile', "");
      formdata.append('message', texts);
    }

    //Update Chatter to bot
    setSendchatloader(true)
    const res = await APIServices.post("whatsAppApi/sendMessage", formdata, { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` });
    if (res && res.status === 200 && res.data?.status) {
      // console.log("text",texts)
      setTexts("");
      fetchBotChatHistory()

      // Commenting refresh chat because it was duplicating messages
      // refreshChat();

      // let userindex = allUsers.findIndex(user => user.userId === currentUser.userId)
      // if (userindex !== -1) {
      // Create a copy of the all user
      // let allUsersCopy=[...allUsers],
      // user = allUsersCopy[userindex];

      // Insert active user to top
      // allUsersCopy.splice(userindex, 1);
      // allUsersCopy.unshift(user);
      // setAllUsers(allUsersCopy);
      // }
      imgDataRef.current.value = ""
      // setBtnd(false)
      setSendchatloader(false)
    }
    setTimeout(() => {
      setSendchatloader(false)
      // setBtnd(false)
    }, 500)
    // setLoading(false)
  }

  const handleRightClick = (event, id) => {
    selectedLableUserId.current = id
    event.preventDefault(); // Prevent the default context menu
    const { pageX, pageY, view } = event;
    setIsContextMenuVisible(true);
    if (pageY + 250 > view.innerHeight) {
      setContextMenuPosition({ top: pageY - 240, left: pageX });
    } else {
      setContextMenuPosition({ top: pageY, left: pageX });
    }
  };


  const fetchedLabels = async () => {
    const res = await APIServices.get("chat/getChatFilters", headers);
    if (res && res.status === 200) {
      const labelData = res?.data?.data?.filter(item => item?.status === 1);
      setLabels(labelData)
    }
  }

  const handlePageChange = (selectedPage) => {
    let skipValue = (selectedPage.selected * 50)
    setUserloader(true)
    setMaauser([])
    console.log("skipValue", skipValue)
    setCookies("skip", skipValue)
    setSkip(skipValue)
    let filterId = selectedLableValue.current ? selectedLableValue.current === "all" ? "" : selectedLableValue.current : ""
    let campaignId = selectedCampaign.current ? selectedCampaign.current === "all" ? "" : selectedCampaign.current : ""
    let startDate = dateStored.current.startDate
    let endDate = dateStored.current.endDate
    let searchKey = searchKeyword.current ? searchKeyword.current : ""
    let responderId = selectedResponder.current ? selectedResponder.current === "all" ? "" : selectedResponder.current : ""
    let checkRead = selectedRead.current ? selectedRead.current : ""
    let checkReply = selectedNeedReply.current ? selectedNeedReply.current : ""
    searchUsersList({ skips: skipValue, filterId, searchKey, campaignId, startDate, endDate, responderId, checkRead, checkReply });
  }

  const handleReload = () => {
    setCookies("skip", 0)
    // console.log("reloadText", reloadText)
    setReloadText(true)
    setLoader(true)
    setUserloader(true)
    setMaauser([])
    let skipValue = getCookies('skip')
    let startDate = dateStored.current.startDate;
    let endDate = dateStored.current.endDate;
    selectedNeedReply.current = 0;

    let filterId = selectedLableValue.current ? selectedLableValue.current === "all" ? "" : selectedLableValue.current : "";
    let campaignId = selectedCampaign.current ? selectedCampaign.current === "all" ? "" : selectedCampaign.current : "";
    let searchKey = searchKeyword.current;
    let responderId = selectedResponder.current ? selectedResponder.current === "all" ? "" : selectedResponder.current : "";
    selectedRead.current = "";
    let checkRead = "";
    let needsReplyUser = 0;
    // console.log("reloadText", reloadText)
    searchUsersList({ skips: skipValue, filterId, searchKey, campaignId, startDate, endDate, responderId, checkRead });
  }

  const needsReply = () => {
    setCookies("skip", 0)
    selectedNeedReply.current = 1

    handlingCombineFiltering({
      selectedFilterId: selectedLableValue.current,
      selectedCampaignId: selectedCampaign.current,
      startDates: dateStored.current.startDate,
      endDates: dateStored.current.endDate,
      selectedResponderId: selectedResponder.current,
      searchKeyword: searchKeyword.current,
      selectedReadValue: selectedRead.current,
      needsReplyUser: 1
    })
  }

  const handleUnreadMessage = (e) => {
    setCookies("skip", 0)
    selectedRead.current = e.target.value
    handlingCombineFiltering({
      selectedFilterId: selectedLableValue.current,
      selectedCampaignId: selectedCampaign.current ? selectedCampaign.current : "",
      startDates: dateStored.current.startDate,
      endDates: dateStored.current.endDate,
      searchKeyword: searchKeyword.current,
      searchKeyword: searchKeyword.current,
      selectedReadValue: e.target.value,
      needsReplyUser: selectedNeedReply.current,
    })
  }

  const fetchedCampaigns = async () => {
    const res = await APIServices.get("campaign/getCampaign", headers);
    if (res && res.status === 200) {
      setCampaigns(res?.data?.data)
      setCampaignsaa(res?.data?.data)
      setShowLeadMessageCampignId(res?.data?.data)
    }
  }

  const fetchedResponder = async () => {
    const res = await APIServices.get("responders/list", headers);
    if (res && res.status === 200) {
      setResponder(res?.data?.data)
      setShowLeadMessageResponderId(res?.data?.data)
    }
  }

  const handleAaCampChange = async (e) => {
    setSelectcampaa(e.target.value)
    const res = await APIServices.get(`whatsAppApi/getResponderBycampaign/${e.target.value}`, headers);
    if (res && res?.data?.status === true) {
      setResponderaa(res?.data?.responderDataList)
    }
  }

  const handleAaRespChange = (e) => {
    setSelectrespaa(e.target.value)
  }

  const handleAssign = async () => {
    let payload = {

      userId: aauser?.userId,
      campaignId: selectcampaa,
      responderId: selectrespaa
    };
    setLoader(true)
    const res = await APIServices.post(`whatsAppApi/updateUserResponder`, payload, headers);
    if (res && res?.data?.status === true) {
      addToast("Update successfully", {
        appearance: 'success',
        autoDismiss: true
      })
      handleCloseaa()
      setSelectcampaa()
      setSelectrespaa()
      setLoader(false)
      handleReload()

    }
    else {
      addToast(res?.data?.message, {
        appearance: 'error',
        autoDismiss: true
      })
      setLoader(false)

    }
    setLoader(false)
  }

  const handleNotesAdd = async () => {

    if (!note || note === "") {
      setError(true)
  }else{
    let payload = {
      userId: userForNote?.userId,
      source:"Marketing-Chat",
      type:adminUser?.adminType,
      adminName:adminUser?.name,
      adminId: adminUser?._id,
      message:note
    };
    setLoader(true)
    const res = await APIServicesSec.post(`userNotes/userNoteMessage`, payload, headers);

    if (res && res?.data?.status === true) {
      addToast("Note added successfully", {
        appearance: 'success',
        autoDismiss: true
      })
      setShowNoteModel(false)
      // getUserNotes(userForNote?.userId)
      setNote('')
      setLoader(false)

    }
    else {
      addToast(res?.data?.message, {
        appearance: 'error',
        autoDismiss: true
      })
      setLoader(false)

    }
    setLoader(false)
  }
  }

  const getUserNotes = async (id) => {
    const res = await APIServicesSec.get(`userNotes/getDetailById/${id}`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setUserNoteDetails(res.data.data)
      }else{
        console.log("error");
      }
    
    }
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseMulti = () => setShowMessage(false);
  const handleShow = () => setShow(true);


  // const handleMessage = obj => {
  //   if(skip > 800){
  //     return 
  //   }
  //   //handling users priority
  //   if(String(currentUserIdref.current?.userId) != String(obj.userId)){
  //     setShortUser(prev => [...prev, obj._id]);
  //   }
  //   let newAllusers = allusersref.current;
  //   let element = newAllusers.find(elem => String(elem._id) == String(obj._id));
  //   let index = newAllusers.findIndex(objs => String(objs._id) == String(element?._id));

  //   //campaign selection
  //   if(obj.campaignId && selectedCampaign.current && String(selectedCampaign.current) !== String(obj.campaignId)){
  //     return 
  //   }

  //   //filter selection
  //   if(obj.filterId && selectedLableUserId.current && String(selectedLableUserId.current) !== String(obj.filterId)){
  //     return 
  //   }

  //   if (index !== -1 && (String(currentUserIdref.current?.userId) != String(obj.userId))) {
  //     newAllusers.splice(index, 1);
  //     newAllusers.unshift(element);
  //     allusersref.current = newAllusers;
  //     setAllUsers(newAllusers);
  //   }else{
  //     const newobj = {
  //       contactNumber: obj.contactNumber,
  //       createdAt: "",
  //       currentChatter: obj.currentChatter,
  //       currentChatterId: obj.currentChatterId,
  //       name:obj.name,
  //       updatedAt: "",
  //       userId:obj.userId, 
  //       _id:obj._id
  //     }
  //     if(String(currentUserIdref.current?.userId) !== String(obj.userId)){
  //       newAllusers.unshift(newobj)
  //       allusersref.current = newAllusers;
  //       setAllUsers(newAllusers) 
  //     }
  //   }
  //   if(String(currentUserIdref.current?.userId) === String(obj.userId)){
  //     //handling messages
  //     setBotChatHistory(prev => [...prev, obj]);
  //   }
  // };

  const updateReadStatus = async () => {
    const res = await APIServices.put("whatsAppApi/updateIsRead", {
      userId: oldUser.userId,
      isRead: 1
    }, headers);
    if (res && res.data.status === true) {
      let allnewusers = allUsers
      allnewusers = allnewusers.map(item => {
        if (item.userId === oldUser.userId) {
          return { ...item, isRead: 1 }
        }
        return item
      })
      setAllUsers(allnewusers)
    }
  }

  const handleClickOutside = (event) => {
    if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
      setIsContextMenuVisible(false)
    }
  };

  // useEffect(() => {
  //   if (adminUser && !socketRef.current) {
  //     socket.emit("joinRoom");
  //     socketRef.current = true;
  //   }
  //   return () => {
  //     socket.emit("leaveRoom");
  //   };
  // }, [adminUser]);

  const madeUnread = async () => {
    let user = allusersref.current.find(item => item._id === selectedLableUserId.current)
    const res = await APIServices.put("whatsAppApi/updateIsRead", {
      userId: user.userId,
      isRead: 2
    }, headers);
    if (res && res.data.status === true) {
      setIsContextMenuVisible(false)
    }
  }


  const handleContent = (content) => {
    const containsLink = content?.includes('http://') || content?.includes('https://');

    if (containsLink) {
      return (
        <>
          {
            content.split(' ').map((word, index) => {
              if (word.startsWith('http://') || word.startsWith('https://')) {
                return (
                  <a key={index} href={word} target="_blank" rel="noopener noreferrer">
                    {word}
                  </a>
                );
              } else {
                return word + ' ';
              }
            })
          }
        </>
      )
    }
    return (
      <pre className="adding-pre-wrap">
        {content}
      </pre>
    )
  }

  const [suser, setSuser] = useState([])
  var selectuser = []


  const handleSelectuser = (e, user) => {
    const isChecked = e.target.checked
    if (isChecked) {
      let value = user
      selectuser = [...suser, value]
      setSuser(selectuser)
    } else {
      let reqIndex = suser?.findIndex(x => x._id === user._id);
      let copyUsers = [...suser];
      copyUsers.splice(reqIndex, 1);
      setSuser([...copyUsers])
    }

  }

  {/*for multiple select assgin agent */ }
  const [showmaa, setShowMaa] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const handleCloseMaa = () => setShowMaa(false);
  const handleCloseMessage = () => setShowMessage(false);
  const handleShowMaa = () => {
    setShowMaa(true);
  }

  const handleMultipleMessage = () => {
    setShowMessage(true);
  }


  const [maauser, setMaauser] = useState([])
  var selectmaauser = []


  const handleSelectMaauser = (e, user) => {

    const isChecked = e.target.checked
    if (isChecked) {
      let value = user
      selectmaauser = [...maauser, value]
      setMaauser(selectmaauser)
    } else {
      let reqIndex = maauser?.findIndex(x => x._id === user._id);
      let copyUsers = [...maauser];
      copyUsers.splice(reqIndex, 1);
      setMaauser([...copyUsers])
    }

  }


  const handleMultipleAssign = async () => {
    let payload = {
      userIdArr: maauser,
      campaignId: selectcampaa,
      responderId: selectrespaa || ""
    };

    setLoader(true)
    const res = await APIServices.post(`whatsAppApi/updateUserResponderMulti`, payload, headers);
    if (res && res?.data?.status === true) {
      addToast(res?.data?.message, {
        appearance: 'success',
        autoDismiss: true
      })
      handleCloseMaa()
      setSelectcampaa()
      setSelectrespaa()
      setLoader(false)
      handleReload()
      setMaauser([])

    } else {
      addToast(res?.data?.message, {
        appearance: 'error',
        autoDismiss: true
      })
      setLoader(false)

    }
    setLoader(false)
  }


  {/*for multiple select assgin agent */ }


  const sendBulkMessageToWhatsup = async (e, suser) => {
    e.preventDefault();

    if (suser.length > 0) {
      // console.log(suser)
      suser.map(async (item) => {
        const formdata = new FormData()
        formdata.append('userId', item?.userId);
        formdata.append('currentChatter', item?.currentChatter || "admin");
        formdata.append('currentChatterId', item?._id || null);

        if (imgData !== "") {
          formdata.append('commonFile', imgData);
          formdata.append('message', "");
          setImgData("")
        } else {
          formdata.append('commonFile', "");
          formdata.append('message', texts);
        }
        if (texts === '') {
          toast.error('Please Enter Message .', {
            position: toast.POSITION.TOP_RIGHT
          });
        }

        const res = await APIServices.post("whatsAppApi/sendMessage", formdata, { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` });
        if (res && res.status === 200 && res.data?.status) {
          if (res.status) {
            toast.success(res.message);
          }

        }
      })
      setSuser([])
      setTexts("")
      handleClose()



      //Update Chatter to bot

    } else {

      toast.error('Please Select Person .', {
        position: toast.POSITION.TOP_RIGHT
      });

    }

  }
  const sendBulkMessageToWhatsupMulti = async (e, suser) => {
    e.preventDefault();
    if (maauser.length > 0) {
      setBtnTxt("Sending...")
      const formdata = new FormData()
      formdata.append('userId', maauser);
      formdata.append('currentChatter', "admin");
      formdata.append('currentChatterId', adminUser?._id || null);

      if (imgData !== "") {
        formdata.append('commonFile', imgData);
        formdata.append('message', "");
        setImgData("")
      } else {
        formdata.append('commonFile', "");
        formdata.append('message', texts);
      }
      if (texts === '') {
        toast.error('Please Enter Message .', {
          position: toast.POSITION.TOP_RIGHT
        });
      }

      const res = await APIServices.post("whatsAppApi/sendMessageMulti", formdata, { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` });
      if (res && res.status === 200 && res.data?.status) {
        if (res.status) {
          toast.success(res.message);
          alert("Message send successfully.")
        }
      }
      setSuser([])
      setBtnTxt("Send")
      setTexts("")
      handleReload()
      setMaauser([])
      handleCloseMulti()



      //Update Chatter to bot

    } else {

      toast.error('Please Select Person .', {
        position: toast.POSITION.TOP_RIGHT
      });

    }

  }

  // lead reply filter


  const handleInstantDateFilter = async (e) => {
    // console.log("e.target.value", e.target.value)
    InstantdateStored.current.time = e.target.value

  }


  const handleAaLeadCampChange = async (e) => {
    setSelectLeadcampaa(e.target.value)
  }

  const handleAaLeadRespChange = async (e) => {
    setSelectLeadResp(e.target.value)
    
  }


  const sendBulkInstantMessage = async (e) => {
    e.preventDefault();
    if (Instanttexts.length > 0) {
      setBtnTxt("Sending...")
   
      const headers = {
        "Authorization": `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
      const res = await APIServices.post("whatsAppApi/sendInstantReply",{
        currentChatter: "admin",
        currentChatterId: adminUser?._id,
        message: Instanttexts,
        time:InstantdateStored.current.time,
        campaignId:selectleadcampaa,
        responderId:selectleadResp,
        // leadImage : typeof imgLeadup === "object" ?imgLeadup:""
      },headers);

        if (res && res.status === 200 && res.data?.status) {
        if (res.status) {
          toast.success(res?.data?.message);
          setShowLeadMessage(false)
          setBtnTxt("Send")
          setInstantTexts("")
          setSelectLeadcampaa('')
          setSelectLeadResp('')
          // setImagLeadup('')
          InstantdateStored.current.time = 24
          let msg = "Total "+res?.data?.count+" message sent.";
          alert(msg)
        }else{
          toast.error(res?.data?.message);
          setBtnTxt("Send")

        }
      }else{        
        toast.error(res?.data?.message);
        setBtnTxt("Send")

      }

      
    } else {

      toast.error('Please enter Message.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  useEffect(() => {
    if (botChatHistory.length) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [botChatHistory])


  return (
    <div className="chat_window">
      {/* <Loader /> */}
      <Row className="gx-0">
        {isContextMenuVisible && (
          <div
            ref={contextMenuRef}
            className="chat-context-menu position-fixed"
            style={{ top: contextMenuPosition.top, left: contextMenuPosition.left }}
          >
            <div className="label-items-header mb-1">Status Change</div>
            <button className="text-capitalize fw-lighter fst-italic mb-1" onClick={() => madeUnread()}><FileExcel className="mx-1 mb-1" />Unread</button>
            <div className="label-items-header mb-1">Label Items</div>
            <button className="text-capitalize fw-lighter fst-italic" onClick={() => handleClickLable("")}><BookmarksFill className="mx-1 text-white" />Unlabelled</button>
            {
              labels.map(item => {
                return (
                  <button key={item._id} className="text-capitalize fw-lighter fst-italic" onClick={() => handleClickLable(item._id)}><BookmarksFill className="mx-1" style={{ color: item.colourCode }} />{item.name}</button>
                )
              })
            }
          </div>
        )}
        <Col md={4} lg={4} xl={3} className="chat-users-list"
          //  onScroll={() => onScroll()} 
          ref={listInnerRef}>
          <div class="user_list_header ">
            <div className="fw-bold me-1">Total : {totalChats}
              <span className=" me-1"><FiMessageSquare data-toggle="tooltip" onClick={needsReply} data-placement="right" title="Need Reply" /></span>
              {/* <span><FiBell data-toggle="tooltip" data-placement="right" title="BULK NOTIFICATION" onClick={handleShow}/></span> */}
              <span onClick={handleReload} className=" me-1"> <i class={reloadText ? "fa fa-refresh fa-spin" : "fa fa-refresh"} ></i>{loader ? "Processing.." : ""}</span>
              <span className=" ms-5" onClick={() => setShowLeadMessage(true)}> <FiTwitch data-toggle="tooltip" data-placement="right" title="Instant Reply" /> Instant Reply  </span>
              <span className=" " onClick={() => navigate('/callhistory')}> <FiPhone data-toggle="tooltip" data-placement="right" title="Call History" />  </span>
            </div>

            <div class="d-flex align-items-center justify-content-between">
              <Form.Select aria-label="label-selected-menu" className="fw-bold label-selected-menu me-1 text-capitalize w-50 mt-2" onChange={handleLabelSelectChange} value={selectedLableValue.current}>
                <option value="all" className="fw-bold">All Labels</option>
                {
                  labels.map(label => {
                    return (
                      <>
                        <option className="text-capitalize" value={label._id} style={{ color: label?.colourCode === "" ? "black" : label.colourCode }}>
                          {label.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select>
              <Form.Select aria-label="label-selected-menu" className="fw-bold label-selected-menu text-capitalize mt-2 w-50" onChange={handleDateFilter}  >
                <option className="fw-bold" value="Today">
                  Today
                </option>
                <option className="text-capitalize" value="Yesterday">
                  Yesterday
                </option>
                <option className="text-capitalize" value="Last24Hours">
                Last24Hours
                </option>
                <option className="text-capitalize" value="This Week" >
                  This Week
                </option>
                <option className="text-capitalize" value="Last Week" >
                  Last Week
                </option>
                <option className="text-capitalize" value="Last 7 days" >
                  Last 7 days
                </option>
                <option className="text-capitalize" value="This Month" >
                  This Month
                </option>
                <option className="text-capitalize" value="Last Month" >
                  Last Month
                </option>
              </Form.Select>
            </div>
            <div className="d-flex justify-content-between">
              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 me-1 text-capitalize mt-2" onChange={handleLabelCampaignChange} value={selectedCampaign.current}>
                <option value="all" className="fw-bold">Campaign</option>
                {
                  campaigns.map(campaign => {
                    return (
                      <>
                        <option className="text-capitalize" value={campaign._id} style={{ color: campaign?.colourCode === "" ? "black" : campaign.colourCode }}>
                          {campaign.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select>
              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 ms-1 text-capitalize mt-2" onChange={handleResponderChange} value={selectedResponder.current}>
                <option value="all" className="fw-bold">Responder</option>
                {
                  responder.map(respon => {
                    return (
                      <>
                        <option className="text-capitalize" value={respon._id}>
                          {respon.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select>
            </div>
            <div className="d-flex justify-content-between  ms-1 text-capitalize mt-2">

              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 ms-1 text-capitalize mt-2" onChange={handleUnreadMessage} value={selectedRead.current}>
                <option value="" className="fw-bold">Read/Unread </option>
                <option value="2" className="fw-bold">Unread</option>
                <option value="1" className="fw-bold">Read</option>
              </Form.Select>
            </div>



          </div>
          <div class="chat_search_box">
            <div className="chat-search-field">
              <span className="chat-bot-search-icon">
                {/* <i className="fa fa-search"></i> */}
                <FaSistrix />
                </span>
              <input type="text" className="form-control form-input" placeholder="Search anything..." onChange={e => handleSearch(e)} />
            </div>
          </div>
          <div className="Multiple_button">
            {/* <span><button className="as_btn btn btn-sm btn-primary m-0" onClick={()=>handleShowMaa()}>Multiple Assign</button></span><span><button   onClick={()=>handleMultipleMessage()} className="as_btn btn btn-sm btn-primary m-1">Multiple Message</button></span> */}
            {maauser.length > 0 && (
              <>
                <span><button className="as_btn btn btn-sm btn-primary m-0" onClick={() => handleShowMaa()}>Multiple Assign</button></span><span><button onClick={() => handleMultipleMessage()} className="as_btn btn btn-sm btn-primary m-1">Multiple Message</button></span>
              </>
            )}

          </div>
          {userloader ? <><Spinner className="spinneruser" /> </> : <div className="chat_all_users">
            {allUsers && allUsers.length >= 0 ?
              allUsers.map((user) => {
                return (
                  <div
                    onContextMenu={(e) => handleRightClick(e, user._id)}
                    key={user._id}
                    onClick={() => handleUserListClick(user)}
                    className={`${currentUser && currentUser.userId === user.userId ? "active chat-user" : "chat-user"} ${shortUser.includes(user._id) && !shortUser.includes(currentUser?._id) && ` text-danger`} ${user.isRead === 2 && `fw-bold fst-italic text-primary`} d-flex justify-content-between position-relative`}
                  >
                    <div className="user_info d-flex">
                    <div className="file-icon-wrapper">
                      <BookmarkFill className="me-2" style={{ color: user.filterColourCode === "" ? "grey" : user.filterColourCode }} />
                      <button className="btn btn-sm note-btn btn-info m-0" onClick={() => { handleShowNote(); setUserForNote(user) ;getUserNotes(user?.userId)  }}><i class="fa-solid fa-file-lines"></i></button>
                      </div>
                      <div className="user_name">
                        <h4><input type="checkbox" onChange={(e) => handleSelectMaauser(e, user?.userId)} /> {user.name ? user.name : user.contactNumber}
                          {user.campaignValue && user.campaignValue.length > 0 ? <span className="user_issue ">{user.campaignValue}</span> : ""}
                        </h4>
                        <p>{user.name && user.contactNumber}</p>
                      </div>
                      <div>
                        <button className="as_btn btn btn-sm btn-primary m-0 assign-btn" onClick={() => { handleShowaa(); setAAuser(user) }}><i class="fa-solid fa-user-plus"></i> </button>
                      </div>
                      <div>
                      
                      </div>
                    </div>
                    <div className="Time">
                      {user.astrologerName ?
                        <span className="text-danger">
                          {`Astro ${user.astrologerName}`} | {moment(user.updatedAt).format('MMMM Do, h:mm a')}
                        </span>
                        :
                        <span className="text-success">
                          {`Agent ${user.responderName}`}  | {moment(user.updatedAt).format('MMMM Do, h:mm a')}
                        </span>
                      }
                    </div>
                  </div>
                );
              })
              :
              <div className="d-flex align-items-center justify-content-center h-100">
                <Spinner animation="border" variant="dark" />
              </div>

            }

          </div>}

          {totalChats > 50 && (
            <div className="d-flex justify-content-center align-items-center w-100 p-0 m-0 custom-pagination">
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                disableInitialCallback={true}
                breakLabel="..."
                forcePage={forcePage}
                pageCount={totalChats < 50 ? 1 : Math.ceil(totalChats / 50)} // Total number of pages
                marginPagesDisplayed={2} // Number of pages to display at the start and end of the pagination
                pageRangeDisplayed={1} // Number of pages to display in the middle of the pagination
                onPageChange={handlePageChange} // Callback function when page is changed
                containerClassName="pagination"
                activeClassName="active"

              />
            </div>
          )}

        </Col>
        <Col md={8} lg={8} xl={9} className="chat-section">

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Bulk Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row >
                <Col md={6} lg={6} xl={6} className="chat-users-bulk mx-0.5 px-1">
                  <div class="d-flex align-items-center justify-content-between">
                    <Form.Select aria-label="label-selected-menu" className="fw-bold label-selected-menu-bulk me-1 text-capitalize w-50 mt-2" onChange={handleLabelSelectChange} value={selectedLableValue.current}>
                      <option value="all" className="fw-bold">All Labels</option>
                      {
                        labels.map(label => {
                          return (
                            <>
                              <option className="text-capitalize" value={label._id} style={{ color: label?.colourCode === "" ? "black" : label.colourCode }}>
                                {label.name}
                              </option>
                            </>
                          )
                        })
                      }
                    </Form.Select>
                    <Form.Select aria-label="label-selected-menu" className="fw-bold label-selected-menu-bulk text-capitalize mt-2 w-50" onChange={handleDateFilter} >
              // <option value="all" className="fw-bold">Date Filter</option>
                      <option className="fw-bold" value="Today" >
                        Today
                      </option>
                    </Form.Select>
                  </div>
                  <div className="d-flex justify-content-between">
                    <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu-bulk w-100 me-1 text-capitalize mt-2" onChange={handleLabelCampaignChange}>
                      <option value="all" className="fw-bold">Campaign</option>
                      {
                        campaigns.map(campaign => {
                          return (
                            <>
                              <option className="text-capitalize" value={campaign._id} style={{ color: campaign?.colourCode === "" ? "black" : campaign.colourCode }}>
                                {campaign.name}
                              </option>
                            </>
                          )
                        })
                      }
                    </Form.Select>
                    <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu-bulk w-100 ms-1 text-capitalize mt-2" onChange={handleResponderChange}>
                      <option value="all" className="fw-bold ">Responder</option>
                      {
                        responder.map(respon => {
                          return (
                            <>
                              <option className="text-capitalize" value={respon._id}>
                                {respon.name}
                              </option>
                            </>
                          )
                        })
                      }
                    </Form.Select>
                  </div>
                  <div className="chat_all_users">
                    {allUsers && allUsers.length > 0 ?
                      allUsers.map((user) => {
                        return (
                          <div
                            onContextMenu={(e) => handleRightClick(e, user._id)}
                            key={user._id}
                            onClick={() => handleUserListClick(user)}
                            className={`${currentUser && currentUser.userId === user.userId ? "active chat-user" : "chat-user"} ${shortUser.includes(user._id) && !shortUser.includes(currentUser?._id) && ` text-danger`} ${user.isRead === 2 && `fw-bold fst-italic text-primary`} d-flex justify-content-between position-relative`}
                          >
                            <div className="user_info d-flex">
                              <BookmarkFill className="me-2" style={{ color: user.filterColourCode === "" ? "grey" : user.filterColourCode }} />
                              <div className="user_name">

                                <h6> <input type="checkbox" onChange={(e) => handleSelectuser(e, user)} />{user.name ? user.name : user.contactNumber}
                                  <span className="user_issue ">{user.campaignValue}</span>
                                </h6>
                                <p >{user.name && user.contactNumber}</p>
                              </div>
                            </div>
                            <div className="Time ">
                              {user.astrologerName ?
                                <span className="text-danger">
                                  {`Astro ${user.astrologerName}`} | {moment(user.updatedAt).format('MMMM Do, h:mm a')}
                                </span>
                                :
                                <span className="text-success">
                                  {`Agent ${user.responderName}`}  | {moment(user.updatedAt).format('MMMM Do, h:mm a')}
                                </span>
                              }
                            </div>
                          </div>
                        );
                      })
                      :
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <Spinner animation="border" variant="dark" />
                      </div>
                    }



                  </div>
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <textarea type="text" rows={10} cols={30} value={texts}
                    onChange={(e) => setTexts(e.target.value)} />
                </Col>
              </Row>
              <div className="d-flex justify-content-center">
                <Button variant="primary" type="submit" className="as_btn" onClick={(e) => sendBulkMessageToWhatsup(e, suser)}>
                  Send
                </Button>
              </div>
            </Modal.Body>

          </Modal>
          {currentUser && (
            <div className="chat-window">
              <div className="chat-header">
                <span className="float-end me-2" onClick={closeChat}>
                  <XLg size={20} title="Close Chat" />
                </span>
                <div className="float-end d-flex">
                <button className="btn btn-sm note-btn-top btn-info m-0 me-2" onClick={() => { handleShowNote(); setUserForNote(currentUser);getUserNotes(currentUser?.userId) }}>Notes</button>
                <span className="float-end d-flex me-3" onClick={refreshChat}>{refreshchatloader ? "Processing..." : <ArrowClockwise size={20} title="Refresh Chat" onClick={refreshchatuser} />}

                  <Dropdown className="ms-3">
                    <Dropdown.Toggle id="dropdown-for-userby" as={CustomToggle}>
                      {currentUser?.currentChatter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {allChatterType.map(
                        (item, index) =>
                          item !== currentUser?.currentChatter && (
                            <Dropdown.Item key={index} as={"button"} onClick={() => updateChatter(item)} className="text-capitalize">
                              {item}
                            </Dropdown.Item>
                          )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="ms-3"
                    onToggle={handleDoshClose}
                    show={showDoshForm}
                  >
                    <Dropdown.Toggle variant="success" id="dropdown-for-userby" className="bg-transparent  border-0 p-0 m-0"
                      onClick={() => setShowDoshForm(true)}>
                      Dosh Calculator
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="p-3">
                      <MangalForms handleMangalDoshForm={handleMangalDoshForm} doshRespose={doshRespose} doshloader={doshloader} setDoshloader={setDoshloader} localData={localData} currentUser={currentUser} handleCloseDoshForm={handleCloseDoshForm} />
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
                </div>
                <div className="chat-user-info">
                  <PersonCircle className="me-1 " size={20} />
                  {currentUser.name} (+{currentUser.contactNumber})
                </div>
              </div>
              <div className="chat-history" style={{ backgroundImage: `url("${chatBack}")` }}>
                {!isloading && botChatHistory.length > 0 ?
                  <>
                    {botChatHistory &&
                      botChatHistory.length > 0 ?
                      botChatHistory.map((chat) => {
                        return (
                          <div className="py-2" key={chat._id}>
                            <div className={`bg-opacity-25 chat_single_message w-md-75 w-50  p-2 rounded-2 ${chat.by === "user" ? "bg-success user-left" : "bg-info bot-right ms-auto text-end"}`}>
                              {/* <p className="fw-bold mb-1 text-capitalize">{chat.by} </p> */}
                              <p className="fw-bold mb-1 text-capitalize">{chat.senderName ? chat.senderName : chat.senderEmail ? chat.senderEmail : chat.by} </p>
                              {chat?.referrals &&
                                <div>
                                  {chat?.referrals?.image_url && <img className="w-100 pb-2" src={chat?.referrals?.image_url} alt="test" />}
                                  {chat?.referrals?.headline && <h5 className="w-100 pb-2">{chat?.referrals?.headline}</h5>}
                                </div>
                              }
                              {chat.message && <p className="mb-1">{
                                (chat.message.includes("whats-app-chat-files") || chat.message.includes("https://d3i0p1mk3sd6q7.cloudfront.net")) ? chat.message.includes(".mp4") ? <video width="320" height="240" controls>
                                  <source src={chat.message} type="video/mp4" /></video> : <img src={chat.message} alt={`chat-img-${chat.by}`} className="img-fluid" />

                                  :
                                  <>
                                    {handleContent(chat.message)}
                                  </>
                              }</p>}
                              <p className="fst-italic text-black-50 text-decoration-underline mb-1">{moment(chat.date, 'MM-DD-YYYY HH:mm:ss Z').format('MMMM Do YYYY, h:mm:ss a')}</p>
                            </div>
                            <div ref={bottomRef} />
                            {/* {chat.by === "user" ?
                            <div className="bg-opacity-25 bg-success chat_single_message user-left w-75">
                              <p className="fw-bold mb-1">{chat.by} : </p>
                              <p>{chat.message}</p>
                            </div>
                            :
                            <div className="bg-opacity-25 bg-info chat_single_message bot-right w-75 ms-auto text-end">
                              <p className="fw-bold mb-1">{chat.by}</p>
                              <p>{chat.message}</p>
                            </div>} */}
                          </div>
                        )
                      }
                      ) :
                      <div className="text-center fs-4 fw-bold text-capitalize pt-5">No chat history available</div>}
                  </>
                  :
                  <div className="text-center fs-4 fw-bold text-capitalize pt-5">
                    {/* No chat history available */}
                    {/* {renderSpinner} */}
                    <Spinner animation="border" variant="dark" />
                  </div>
                }
              </div>
            </div>
          )}

          <form onSubmit={sendMessageToWhatsup} className={`chat-form d-flex ${currentUser ? "d-block" : "d-none"}`}>
            <div>
            <input
                type='button'
                className='Et-btn'
                value='Template'
                onClick={() =>handleShowWTemp()}
              />
            </div>
            <div className="download_icon">
              <label htmlFor="what-bot-image">
                {
                  imgData ? imgData.name.includes(".mp4") ? <video width="120" height="80" controls>
                    <source src={imgData && URL.createObjectURL(imgData)} type="video/mp4" /></video> :
                    <img src={imgData && URL.createObjectURL(imgData)} alt="image-data-uploaded-what-bot" style={{ width: "50px", height: "50px" }} />
                    :
                    <Upload className="h1 fw-bold" />
                }
                <input
                  id="what-bot-image"
                  type="file"
                  ref={imgDataRef}
                  name="what-bot-image"
                  accept="image/* video/*"
                  style={{ display: 'none' }}
                  onChange={e =>
                    setImgData(e.target.files[0])
                  }
                />
              </label>
              {
                imgData ?
                  <span className="cross" onClick={() => setImgData("")}><i class="fa fa-window-close" aria-hidden="true"></i></span>
                  :
                  ""
              }
            </div>
            <textarea
              className="border border-primary px-2 py-1"
              disabled={imgData !== "" ? true : false}
              rows="1" cols="100"
              value={texts}
              onChange={(e) => setTexts(e.target.value)}
              placeholder={imgData ? "" : "Type here.."}
            />
            {sendchatloader ? <><Spinner /></> : <><button className="border as_btn send" type="submit"  ><i class="fa fa-paper-plane ms-2" aria-hidden="true"></i></button> </>}

          </form>
        </Col>
      </Row >
      {/*     Assign agent modal                */}

      <Modal show={showaa} onHide={handleCloseaa}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Campaign/Agent </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa ms-3">
            <Row >
              Name :- {aauser?.name}
            </Row>
            <Row>
              Contact Number :- {aauser?.contactNumber}
            </Row>
          </div>

          <Row>
            <Col>

              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 me-1 text-capitalize mt-2" value={selectcampaa} onChange={(e) => handleAaCampChange(e)} >
                <option className="fw-bold">Campaign</option>
                {
                  campaignsaa.map(campaignaa => {
                    return (
                      <>
                        <option className="text-capitalize" value={campaignaa._id} style={{ color: campaignaa?.colourCode === "" ? "black" : campaignaa.colourCode }}>
                          {campaignaa.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select>
            </Col>
            <Col>

              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 ms-1 text-capitalize mt-2" value={selectrespaa} onChange={(e) => handleAaRespChange(e)} >
                <option className="fw-bold">Responder</option>
                {
                  responderaa.map(respon => {
                    return (
                      <>
                        <option className="text-capitalize" value={respon._id}>
                          {respon.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            loader ? <Button variant="primary" className="as_btn" >
              Processing...
            </Button> : <Button variant="primary" className="as_btn" onClick={handleAssign}>
              Assign
            </Button>
          }
          <Button variant="secondary" className="as_btn" onClick={handleCloseaa}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>



      {/*     Assign Multi agent modal                */}

      <Modal show={showmaa} onHide={handleCloseMaa}>
        <Modal.Header closeButton>
          <Modal.Title>Assign Campaign/Agent <small>(User Selected={maauser.length})</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>



          <Row>
            <Col>
              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 me-1 text-capitalize mt-2" value={selectcampaa} onChange={(e) => handleAaCampChange(e)} >
                <option className="fw-bold">Campaign</option>
                {
                  campaignsaa.map(campaignaa => {
                    return (
                      <>
                        <option className="text-capitalize" value={campaignaa._id} style={{ color: campaignaa?.colourCode === "" ? "black" : campaignaa.colourCode }}>
                          {campaignaa.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select>
            </Col>
            <Col>
              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 ms-1 text-capitalize mt-2" onChange={(e) => handleAaRespChange(e)} value={selectrespaa}>
                <option className="fw-bold">Responder</option>
                {
                  responderaa.map(respon => {
                    return (
                      <>
                        <option className="text-capitalize" value={respon._id}>
                          {respon.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loader ? <Button variant="primary" className="as_btn" >
            Processing...
          </Button> : <Button variant="primary" className="as_btn" onClick={handleMultipleAssign}>
            Assign
          </Button>}

          <Button variant="secondary" className="as_btn" onClick={handleCloseMaa}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/*    mutliple message modal                */}
      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Notification <small>(User Selected={maauser.length})</small></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} lg={6} xl={6}>
              <textarea type="text" rows={10} cols={75} value={texts}
                onChange={(e) => setTexts(e.target.value)} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit" className="as_btn" onClick={(e) => sendBulkMessageToWhatsupMulti(e, suser)}>
              {btnTxt}
            </Button>
          </div>
        </Modal.Body>

      </Modal>



      {/* Lead reply message */}
      <Modal show={showLeadMessage} onHide={handleCloseLeadMessage}>
        <Modal.Header closeButton>
          <Modal.Title>Bulk Instant Message </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Select aria-label="label-selected-menu" className="fw-bold label-selected-menu text-capitalize mt-2 w-50" onChange={handleInstantDateFilter}  >
                <option className="fw-bold" value="24">
                  24 hours
                </option>
                <option className="text-capitalize" value="48">
                  48 hours
                </option>
                <option className="text-capitalize" value="72" >
                  72 hours
                </option>

              </Form.Select>
            </Col>
            <Col>
              <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 me-1 text-capitalize mt-2" value={selectleadcampaa} onChange={(e) => handleAaLeadCampChange(e)} >
                <option className="fw-bold" value=''>All Campaign</option>
                {
                  showLeadMessagecampignid.map(campaignaa => {
                    return (
                      <>
                        <option className="text-capitalize" value={campaignaa._id} style={{ color: campaignaa?.colourCode === "" ? "black" : campaignaa.colourCode }}>
                          {campaignaa.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select>
            </Col>
            <Col>
            <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 ms-1 text-capitalize mt-2" value={selectleadResp} onChange={(e) => handleAaLeadRespChange(e)} >
                <option value="all" className="fw-bold">Responder</option>
                {
                  showLeadMessageResponderid.map(respon => {
                    return (
                      <>
                        <option className="text-capitalize" value={respon._id}>
                          {respon.name}
                        </option>
                      </>
                    )
                  })
                }
              </Form.Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col >
              <textarea type="text" rows={10} cols={75} value={Instanttexts}
                onChange={(e) => setInstantTexts(e.target.value)} />
            </Col>
            {/* <Col>
          
            {
                  imgLeadup ? 
                    <img src={ URL.createObjectURL(imgLeadup)} alt="image" style={{ width: "50px", height: "50px" }} />
                    :
                  <div>Upload Image
                    <label htmlFor='upload-photo'>
                      <img src="/images/imageUpload.jpg" alt="animage" width={25} className="pointerEvent rounded" />
                    </label>
                    <input id="upload-photo" name="file" 
                  accept="image/png, image/gif, image/jpeg"
                  className="d-none"  type="file" onChange={(e) =>setImagLeadup(e.target.files[0])}/>
              
                  </div>}

                  {
                imgLeadup ?
                  <span className="cross" onClick={() => setImagLeadup("")}><i class="fa fa-window-close" aria-hidden="true"></i></span>
                  :
                  ""
              }
                    
      
            </Col> */}
          </Row>
          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit" className="as_btn"
              onClick={(e) => sendBulkInstantMessage(e)}
            >
              {btnTxt}
            </Button>
          </div>
        </Modal.Body>

      </Modal>

    {/*    Notes modal   */}
    <Modal show={showNoteModel} id="my-modal"  onHide={handleCloseNote} 
     backdrop="static"
     scrollable={true} >
        <Modal.Header closeButton>
          <Modal.Title>Add Note </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="aa d-flex">
            <span className="me-3">  Name :- {userForNote?.name} </span>
           <span> Contact Number :- {userForNote?.contactNumber} </span> 
          
          </div>
         
          <Row>
            <Col>
        <Form.Group as={Row} className="my-3" controlId="formAartiInput">
        
          <Col sm="12">
            <Form.Control
              as="textarea"
              name="content"
              placeholder="Type here..."
              // value={reply}
              style={{ height: 100 }}
              onChange={(e) => {handleChange();setNote(e.target.value)}}
            />
            {error?
             <p className='text-danger'>Please enter your note</p>:''}
          </Col>

          </Form.Group>
              
              </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
          {
            loader ? <Button variant="primary" className="as_btn" >
              Processing...
            </Button> : <Button variant="primary" className="as_btn" onClick={handleNotesAdd}>
              Add
            </Button>
          }
          <Button variant="secondary" className="as_btn" onClick={handleCloseNote}>
            Close
          </Button>
        </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>
          {userNoteDetails?.notes && userNoteDetails?.notes?.length > 0 && userNoteDetails?.notes?.map(( item ,index) =><>
                 <Row>
                      { item?.source && item?.source != "" ?  
                          <Col xs={5}>
                              <span className="aa">Source :- </span>
                                <span className="item-msg">{item.source ? item.source : "" } </span>
                          </Col> : ""
                      }

                      {item?.sourcename && item?.sourcename != "" ? 
                        <Col xs={7}>
                            <span className="aa">Action :- </span>
                              <span className="item-msg">
                                {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                                </span>
                        </Col> : ""
                      }
                </Row>
        <Row className="notes-item">
              <Col xs={9}>
              <span className="item-name aa">
                              Note :- 
                  </span>
              <span className="item-msg">{item?.message}</span>
              </Col>
              <Col xs={3}>
                <div className="text-end d-flex flex-column">
                <span className="text-primary item-name aa">{item?.responderName || item?.adminName}</span> 
                <span className="item-text">{ item?.createdAt? moment(item?.createdAt).format('lll') :''}</span>
                </div>
              </Col>
            </Row>
          </>

          )}
        </Modal.Body>
     
      </Modal>


      {/* for WhatsApp Templete */}
      <Modal
      show={showWTempModel}
      id="my-modal"
      size="lg"
      onHide={handleCloseWTemp}
      backdrop="static"
      scrollable={true}
      dialogClassName="custom-modal-width whatsapp-modal"
    >
    <Form onSubmit={handleWTMessage}>

      <Modal.Header closeButton>
      <div className="d-flex justify-content-between w-100 align-items-center">
      <Modal.Title>WhatsApp Template Message</Modal.Title>
      {loaderForTemp?<Button variant="primary"className="as_btn ms-3" >
              Processing...
            </Button>: <Button variant="primary" className="as_btn mt-1 me-3" type="submit">
             Send
          </Button>}
             
    </div>
      </Modal.Header>
      <Modal.Body>
  <div className="row scrollable-div">
    {alltemplate?.map((item) => (
      <div
        className="col-md-6 col-lg-4"
        key={item?.id}
      >
        <div
          className="card mb-4"
          style={{
            height: '20rem',
            position: 'relative',
            border: selectedMessageIds.includes(item?.id) ? '2px solid #9d102a' : '1px solid #ddd',
          }}
        >
          <div className="card-body" style={{ overflow: 'auto' }}>
          <div  className="d-flex justify-content-start align-items-center mb-3">
          <input
              type="checkbox"
              checked={selectedMessageIds.includes(item?.id)}
              readOnly
              className="form-check-input"
              onClick={() => toggleSelection(item)}
            />
              <h6 className="ms-2 mt-1 m-0" 
              style={{textTransform: 'capitalize'}}
              >
                {item?.name.replace(/_/g, ' ')}</h6>
            </div>
            {item?.components?.some(component => component.type === "HEADER" && component.format === "IMAGE") && (
              <>
                <Form.Control
                  className="form-ch mb-1 required"
                  name="image_link"
                  type="url"
                  placeholder="Add image url"
                  value={inputValues.image_links[item.id] || ''}
                  onChange={(e) => handleUrlTextChange(e, item.id)}
                />

                <Button variant="secondary" className="btn-link mb-1"  onClick={() =>{handleOpenModal(item.id)}}>
                  Select Image Url
                </Button>
              </>
            )}

          <p>{item?.components?.find(component => component.type === "BODY")?.text}</p>

            {Array.from({ length: item?.components?.find(component => component.type === "BODY")?.example?.body_text[0]?.length || 0 }).map((_, index) => (
              <div key={index} className="mb-3">
                <Form.Control
                  className={`body-text-input form-ch mb-1 ${errors.body_text[item.id] && errors.body_text[item.id][index] && 'is-invalid'}`}
                  name={`body_text`}
                  type="text"
                  placeholder={`Enter value for body_text ${index + 1}`}
                  value={inputValues.body_text[item.id] && inputValues.body_text[item.id][index] || ''}
                  onChange={(e) => handleBodyTextChange(e, index, item.id)}
                />            
              </div>
            ))}
          </div>
        </div>
      </div>  
    ))}
  </div>

  {/* <Modal.Footer className="py-2 px-0">
    <Button variant="primary" className="as_btn" type="submit">
      Send
    </Button>
    <Button variant="secondary" className="as_btn" onClick={handleCloseWTemp}>
      Close
    </Button>
  </Modal.Footer> */}
    </Modal.Body>
</Form>
    </Modal>

    <ImgGalleyModel
        id="ImgGalleyModel"
        show={showImageGalleyModel}
        onHide={() => setShowImageGalleyModel(false)}
        modalHeading={"ImgGalley"}
        size="lg"
        handleSetImageUrl={handleSetImageUrl}
      />

      <ToastContainer />
    </div>
  );
};

export default Chatv2;
