import React, { useEffect, useState,useRef,useMemo, useCallback } from "react";
import { Row, Col, Dropdown, Form, ToggleButton, ButtonGroup, Spinner, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import APIServicenew from "../utils/APIGeneralService";
import { getCookies, setCookies } from "../utils/Helper";
import ReactPaginate from 'react-paginate';
import moment from "moment";
import DynamicDataTableWithCount from "./DynamicDataTableWithCount";
import { FaSistrix } from "react-icons/fa";


const BlockedUser = () => {
  document.title = 'Block Customers';
  const APIServices = new APIServicenew(process.env.REACT_APP_WHATSAPP_BOT_API_URL)
  const [users,setUsers] = useState([])
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(10)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [totalChats, setTotalChats] = useState('');
  const [flag, setFlag] = useState(false)
  const token = getCookies("accessToken")
  
  const headers = {
    "Authorization":`Bearer ${token}`
  }
  
  const handleBlock = async(userId,blockStatus)=>{
    const res = await APIServices.put(`whatsAppApi/blockUser/${userId}`, {
      is_block : blockStatus
    },headers);
    if (res.data && res.data.status) {
      setUsers(user=>{
        return user.map(item=>{
          if(item.userId === userId){
            return {...item,isBlock:blockStatus}
          }else{
            return item
          }
        })
      })
    }
    
  }
    
  const buttonBlockFormatter = (e) => {
    let blockStatus
    if(e.row.original.isBlock == 2){
      blockStatus = 1
    }else if(e.row.original.isBlock == 1){
      blockStatus = 2
    }
    return (
      <Button className={`blocked-user-btn ${e.row.original.isBlock == 2 && "unblocked-user-btn"}`} onClick={()=>handleBlock(e.row.original.userId,blockStatus)}>
        {e.row.original.isBlock === 2 ? "Unblock":"Block"}
      </Button>
    );
  };

  const [dateFilterValue, setDateFilterValue] = useState('all'); // Default value is 'all'

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    
   
    {
      Header: 'Customer Names (or Emails)',
      Cell: e => {
        return <span>{e.row.original?.name ?? '_'}</span>;
        
      },
    },
    {
      Header: 'Mobile No.',
      Cell: e => {
        return <span>{e.row.original?.contactNumber ?? '_'}</span>;
      },
    },
    {
      Header: 'Source',
      Cell: e => {
        return <span>{e.row.original.utmSource ?? '_'}</span>;
      },
    },
    {
      Header: 'Campign Name',
      Cell: e => {
        return  <span> {e.row.original.campaignName}</span>;
      },
    },
    {
      Header: 'Responder Name',
      Cell: e => {
        return  <span>
                     {e.row.original.responderName}
                </span>;
      },
    },
    {
      Header: 'Date',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('lll') : '_'}</span>
          </>
        );
      },
    },

    {
      Header: 'Latest Date',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.updatedAt).format('lll') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: 'Action',
      Cell: buttonBlockFormatter,
    },
   
  ]);

  const [ searchKey, setSearchKey ] = useState('')
  const handleSearch = (e)=>{
    setSearchKey(e.target.value)
  }


  // getResponder
  const selectedResponder = useRef(null)

  const [ selectedResponderId, setSelectedResponderId ] = useState("")
  const [responder,setResponder] = useState([])

  const fetchedResponder = async()=>{
    const res = await APIServices.get("responders/list",headers);
    if (res && res.status === 200) {
      setResponder(res?.data?.data)
    }
  }

  const handleResponderChange = (e) => {
    setSelectedResponderId(e.target.value)
  }


  // getDate

  const [dateStored, setDateStored] = useState({
    startDate: "",
    endDate: ""
  });

  const handleDateFilter = async (e) => {
    const value = e.target.value;
  setDateFilterValue(value);
    let startDate, endDate;
    switch (e.target.value) {
      case "all":
        startDate = "";
        endDate = "";
        break;
      case "Today":
        startDate = moment().format('MM-DD-YYYY');
        endDate = moment().format('MM-DD-YYYY');
        break;
      case "Yesterday":
        startDate = moment().subtract(1, 'day').format('MM-DD-YYYY');
        endDate = moment().subtract(1, 'day').format('MM-DD-YYYY');
        break;
      case "This Week":
        startDate = moment().startOf('week').format('MM-DD-YYYY');
        endDate = moment().endOf('week').format('MM-DD-YYYY');
        break;
      case "Last Week":
        startDate = moment().subtract(1, 'week').startOf('week').format('MM-DD-YYYY');
        endDate = moment().subtract(1, 'week').endOf('week').format('MM-DD-YYYY');
        break;
      case "Last 7 days":
        startDate = moment().subtract(7, 'day').startOf('day').format('MM-DD-YYYY');
        endDate = moment().format('MM-DD-YYYY');
        break;
      case "This Month":
        startDate = moment().startOf('month').format('MM-DD-YYYY');
        endDate = moment().endOf('month').format('MM-DD-YYYY');
        break;
      case "Last Month":
        startDate = moment().subtract(1, 'month').startOf('month').format('MM-DD-YYYY');
        endDate = moment().subtract(1, 'month').endOf('month').format('MM-DD-YYYY');
        break;
      default:
        startDate = "";
        endDate = "";
    }

    setDateStored({ startDate, endDate });

  };
 

  // block-unBlock user

  const userlistdata=useRef({
    userList:"block"
  })

  const [ userStatus, setUserStatus] = useState('')

  const handleBlockdisplay = (e) => {
    setUserStatus(e.target.value)
    }

    useEffect(()=>{
      fetchedAllUsers(skip, limit, pageIndex)
      fetchedResponder()
    },[flag])

    const fetchedAllUsers = useCallback(
      async(skip, limit, index)=>{
        try {
            const res = await APIServices.post("whatsAppApi/chatList", {
              searchKey: searchKey,
              limit: limit,
              // filterId,
              skip: skip,
              userList :userStatus,
              startDate: dateStored?.startDate,
              endDate : dateStored?.endDate,
              // campaignId,
              responderId: selectedResponderId
            },headers);
            if (res && res.status === 200) {
              let resData = res.data;
              if (resData && resData.status) {
                let newData = [...resData.data]
                setFlag(false)
                setUsers(newData);
                setTotalChats(resData.total_count);
                setPageCount(Math.ceil(resData?.total_count / limit))
              }
            }
          }
         catch (e) {
          throw new Error(`API error:${e?.message}`);
        }
        
        setSkip(skip);
        setLimit(limit);
        setPageIndex(index);
      },
      [pageIndex, skip, searchKey, selectedResponderId, dateStored?.startDate, dateStored?.endDate, userStatus]
    );


    const handleFilterClick = () => {
      if (searchKey || selectedResponderId || dateStored?.startDate || dateStored?.endDate || userStatus) {
        fetchedAllUsers(skip, limit, 0, searchKey , selectedResponderId, dateStored?.startDate, dateStored?.endDate, userStatus);
      }
    };

    const handleReset =()=>{
      setSearchKey('');
      setSelectedResponderId('');
      setDateStored({
        startDate:'',
        endDate:''
      });
      setDateFilterValue('all');
      setUserStatus('')
      setFlag(true)
    }
  
  return (<>
    <div className="gita_quote_heading">
    <div className="container d-flex align-items-center justify-content-between">
    <h4 className="hd-list-f left-q" >Block Customers <small>({totalChats})</small> </h4>
    </div>
 </div>
    
    <div className="chat_window">
      <Row className="mlist p-20 br-6">
      <div class="chat_search_box">
        <div className="chat-search-field">
          <span className="chat-bot-search-icon">
            {/* <i className="fa fa-search"></i> */}
            <FaSistrix />
            </span>
          <input type="text" className="form-control form-input w-50" placeholder="Search anything..." 
             value={searchKey}
             onChange={handleSearch} />
          <Row className="mt-2">
           <Col md={3} className='d-flex justify-content-end align-items-end'>
           
          <Form.Select aria-label="label-selected-menu" 
              className="fw-bold label-selected-menu text-capitalize mt-2 w-50" 
              value={dateFilterValue} 
              onChange={handleDateFilter}  >
          <option className="fw-bold" value="all">
                  All
                </option>      
          <option className="text-capitalize" value="Today">
                  Today
                </option>
                <option className="text-capitalize" value="Yesterday">
                  Yesterday
                </option>
                <option className="text-capitalize" value="This Week" >
                This Week
                </option>
                <option className="text-capitalize" value="Last Week" >
                Last Week
                </option>
                <option className="text-capitalize" value="Last 7 days" >
                Last 7 days
                </option>
                <option className="text-capitalize" value="This Month" >
                This Month
                </option>
                <option className="text-capitalize" value="Last Month" >
                Last Month
                </option>
            </Form.Select>
            </Col>
            <Col md={3}>
            <Form.Select aria-label="label-filter-menu" className="fw-bold label-selected-menu w-100 ms-1 text-capitalize mt-2"
              onChange={handleResponderChange}
              value={selectedResponderId}
            >
              <option value="all" className="fw-bold">Responder</option>
              {
                responder.map(respon=>{
                  return (
                    <>
                      <option className="text-capitalize" value={respon._id}>
                        {respon.name}
                      </option>
                    </>
                  )
                })
              }
            </Form.Select>
            </Col>
            <Col md={3}>
            <Form.Select aria-label="label-selected-menu" className="fw-bold label-selected-menu text-capitalize mt-2 w-50"
               value={userStatus}
               onChange={handleBlockdisplay}  >
                  <option className="text-capitalize" value="">
                    All
                  </option>
                  <option className="fw-bold" value="block">
                    Block
                  </option>      
                  <option className="text-capitalize" value="unblock">
                    Unblock
                  </option>
                  </Form.Select>
            </Col>
          {/*} <Col md={2}>
            <button className="as_btn" onClick={()=>handleReload()}>
            Reload</button></Col>*/}
                <Button className='as_btn ms-2 ' style={{marginTop:"8px"}} variant='primary' onClick={handleFilterClick}>
                    Submit
                </Button>

                <Button className='as_btn ms-2 reset-btn' style={{marginTop:"8px"}} variant='primary' onClick={handleReset}>
                  Reset
                </Button>
            </Row>
        </div>
      </div>
      </Row >
    </div>
      <div className='table-responsive admin_grid_table border-remove  blockeduser'>
        {/* <BootstrapTable keyField='index' data={ users } columns={ columns } /> */}
      </div>
      {users && users.length > 0 ? (
                  <DynamicDataTableWithCount
                    columns={columns}
                    data={users}
                    totalCount={pageCount}
                    initialState={{
                      pageIndex: pageIndex,
                      pageSize: limit,
                    }}
                    fetchData={fetchedAllUsers}
                    forcePage={skip / limit}
                  />
                ) : (
                  <h2 className='no_records'>Records not Available</h2>
                )}
    </>
  );
};

export default BlockedUser;
