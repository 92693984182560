import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Card, Container, Form, Button, Row, Col, Tab, Nav, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DynamicDataTableWithCount from '../DynamicDataTableWithCount';
import { UserContext } from '../../context/UserContextProvider';
import moment from 'moment';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { setCookies } from '../../utils/Helper';
import bimg from '../../imgs/default.jpg';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

const TempleList = () => {
  document.title = 'Temple List';
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };

  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  console.log("🚀 ~ TempleList ~ status:", status)
  const [sort, setSort] = useState('');
  const [location, setLocation] = useState('');

  const [showdt,setShowdt ] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  console.log("🚀 ~ TempleList ~ selectedId:", selectedId)

  const handleCloseDt = () => setShowdt(false);
  const handleShowDt = id => {
    setSelectedId(id?._id);
    setShowdt(true);
  };

  const logout = () => {
    refreshToken(null);
    setCookies('accessToken', null);
    navigate('/');
  };

  const getEditaData = async (data) => {
    navigate(`/templeAddEdit?editDataId=${data?._id}`);
  };

  const handleDelete = async () => {
    const response = await APIServices.delete(`templeList/findByIdAndremove/${selectedId}`, headers);
    if (response?.data?.status === true) {
      let newData = tableData.filter(item => item._id !== selectedId);
      setTableData(newData);
      toast.success(response?.data.message);
      setShowdt(false);
      handleCloseDt()
      fetchTempleList()
      
    } else {
      console.log("Something went Wrong")
    }
  };

  const handleReset = () => {
    setTitle('');
    setStatus('');
    setSort('');
    setLocation('');
    setFlag(true)
  };

  const handleStatusUpdate = async item => {
    console.log("🚀 ~ handleStatusUpdate ~ item:88888888888", item)
    const formData = new FormData();
    var datas = item?.status === 1 ? 2 : 1;
    let id = item?._id;
    formData.append('status', datas);

   const res = await APIServices.put(`templeList/updateStatus/${id}`, formData, headers)
   if(res?.data?.status === true){
     fetchTempleList();
   }
   else{
     console.log("error..")
   }
  };
 

  const columns = useMemo(() => [
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: 'Image English',
      Cell: e => {
        return (
          <img
            src={`${e.row.original.mobileAppImage ? e.row.original.mobileAppImage : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Image Hindi',
      Cell: e => {
        return (
          <img
            src={`${e.row.original.mobileAppImageHi ? e.row.original.mobileAppImageHi : bimg}`}
            alt='img'
            className='quotes_table_preview_image'
          />
        );
      },
    },
    {
      Header: 'Title English',
      Cell: e => {
        return <span>{e.row.original.title ?? '_'}</span>;
      },
    },
    {
      Header: 'Title Hindi',
      Cell: e => {
        return <span>{e.row.original.titleHi ?? '_'}</span>;
      },
    },
    {
      Header: 'Location',
      Cell: e => {
        return <span>{e.row.original.address ?? '_'}</span>;
      },
    },
    {
      Header: 'Location Hindi',
      Cell: e => {
        return <span>{e.row.original.addressHi ?? '_'}</span>;
      },
    },
    {
      Header: 'Sort Order',
      Cell: e => {
        return <span>{e.row.original.sortOrder ?? '_'}</span>;
      },
    },
  
    {
      Header: 'Status',
      Cell: e => {
        if (e.row.original.status === 1) {
          return (
            <h5>
              <input type='button' className='_active' value='Active' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        } else {
          return (
            <h5>
              <input type='button' className='_inactive' value='Inactive' onClick={() => handleStatusUpdate(e.row.original)} />
            </h5>
          );
        }
      },
    },
    {
      Header: 'Created At',
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('lll') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: 'Action',
      Cell: e => {
        if (e.row.original._id) {
          
          return (
            <>
            <div className='d-flex'>
                <Button type="button" className='Et-btn edit-btn1' onClick={()=>{getEditaData(e.row.original)}}>Edit</Button>
                <Button type="button" className='ms-2 Dt-btn' onClick={() => { handleShowDt(e.row.original)}}>Delete</Button>
            </div>
            </>
          );
        }
      },
    },
   
  ]);

  useEffect(() => {
    fetchTempleList(skip, limit, pageIndex);
  }, [flag]);

  const fetchTempleList = useCallback(
    async (skip, limit, index) => {
      try {
        const response = await APIServices.get(`templeList/getAll/${skip}/${limit}?status=${status}&title=${title}&sortDirection=${sort}&address=${location}`);
       
        console.log("🚀 ~ response:0000000000000", response)
        if (response?.data?.status === true) {
          console.log("🚀 ~ response:", response)
          setPageCount(Math.ceil(response.data?.totalCount / limit));
          setAllDataCount(response.data?.totalCount)
          setTableData(response.data?.Listdata);
          setFlag(false)
          window.scrollTo({ top: 0 });
        }else{
          if(response?.response?.data?.status == 4){
            logout()
           }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [pageIndex, skip, title, status, sort, location]
  );

  const handleFilterClick = () => {
    if (title || status || sort || location) {
      fetchTempleList(skip, limit, 0, title, status, sort, location);
    }
  };

  return (
    <div>
      <div className='App'>
        <div className='gita_quote_heading'>
          <div className='container'>
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q '>
                {' '}
                Temple List <small>({allDataCount})</small>
              </h4>
            </div>
          </div>
        </div>

        <Container className='' fluid>
          <div class='feed_area box-shadow my-3 p-20 br-6'>
            <div class='chat_search_box py-0'>
              <div className='chat-search-field'>
                <Row>
                    <Form>
                      <Form.Group as={Row} className='mb-2' controlId=''>
                        <Col md={3}>
                          <Form.Control
                            className='form-ch'
                            name='title'
                            type='search'
                            placeholder='Search By Title'
                            value={title}
                            onChange={(e) => setTitle(e.target.value.toLowerCase())}
                          />
                        </Col>
                        <Col md={3}>
                          <Form.Control
                            className='form-ch'
                            name='location'
                            type='search'
                            placeholder='Location'
                            value={location}
                            onChange={e => {
                              setLocation(e.target.value.toLowerCase());
                            }}
                          />
                        </Col>
                        <Col md={3}>
                          <Form.Select
                            aria-label='categories'
                            className='astroWatch-select-hover hd-cr form-ch'
                            value={status}
                            onChange={e => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option>Select Status</option>
                            <option value='1'>Active</option>
                            <option value='2'>Inactive</option>
                          </Form.Select>
                        </Col>

                        <Col md={3}>
                          <Form.Select
                            aria-label='categories'
                            className='  astroWatch-select-hover hd-cr form-ch'
                            value={sort}
                            onChange={e => {
                              setSort(e.target.value);
                            }}
                          >
                            <option value=''>Sort</option>
                            <option value='asc'>Order by Ascending</option>
                            <option value='desc'>Order by Descending</option>
                          </Form.Select>
                        </Col>

                        <Col md={3} className='mt-3'>
                          <Button
                            variant='secondary'
                            className='as_btn ms-1'
                            onClick={() => {
                              handleFilterClick();
                            }}
                          >
                            Filter
                          </Button>
                          <Button
                            variant='secondary'
                            className='as_btn'
                            onClick={() => {
                              handleReset();
                            }}
                          >
                            Reset
                          </Button>
                          <Button variant='secondary' className='as_btn' onClick={() => navigate('/templeAddEdit')}>
                            Add +
                          </Button>
                        </Col>
                      </Form.Group>
                    </Form>

                </Row>
              </div>
            </div>
          </div>
        {tableData && tableData.length > 0 ? (
          <DynamicDataTableWithCount
            columns={columns}
            data={tableData}
            totalCount={pageCount}
            initialState={{
              pageIndex: pageIndex,
              pageSize: limit,
            }}
            fetchData={fetchTempleList}
            forcePage={skip / limit}
          />
        ) : (
          <h2 className='no_records'>Records not Available</h2>
        )}
        </Container>

        <Modal
          show={showdt}
          size='sm'
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={handleCloseDt}
        >
          <Modal.Header className='modal-header-hd'>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this element?</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='cancle_btn' onClick={handleCloseDt}>
              Cancel
            </Button>
            <Button variant='primary' className='success_btn' onClick={handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
};

export default TempleList;
