import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Row, Col, Tab, Nav, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


const AddEditCategory = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDataId = searchParams.get('editDataId');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  const headers = {
    // 'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + accessToken,
  };
  const [inputCategoryFields, setInputCategoryFields] = useState({
    title: '',
    titleHi: '',
  });
const [loading, setLoading] = useState(false)
 const [selectedId,setSelectedId] = useState("")
  const [categoryErrors, setCategoryErrors] = useState({});

  const validateCategoryInputs = () => {
    let isValid = false;

    const newErrorsCategory = {};

    if (!inputCategoryFields.title || inputCategoryFields.title === '' || inputCategoryFields.title.trim() === '' || inputCategoryFields.title === null || inputCategoryFields.title === undefined) 
        newErrorsCategory.title = 'Please enter title.';

    if (!inputCategoryFields.titleHi || inputCategoryFields.titleHi === '' || inputCategoryFields.titleHi.trim() === '' || inputCategoryFields.titleHi === null || inputCategoryFields.titleHi === undefined)
      newErrorsCategory.titleHi = 'Please enter Title.';

    return newErrorsCategory;
  };

  const handleCategoryChange = e => {
    const { name, value } = e.target;
    setInputCategoryFields(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (categoryErrors[name]) {
      setCategoryErrors(prevErrors => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const handleCategoryReset = () => {
    setInputCategoryFields({
      title: '',
      titleHi: '',
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    
    const newErrors = validateCategoryInputs();
    if (Object.keys(newErrors).length > 0) {
      setCategoryErrors(newErrors);
      setLoading(false);
      return;
    }
    setLoading(true)

    const payload = {
      title: inputCategoryFields?.title,
      titleHi: inputCategoryFields?.titleHi
    };
    try{
      const response = await APIServices.post('blogCategory/add', payload, headers);
      if (response.data.status === true) {
        toast.success(response?.data?.message);
        // toast.success("succesfull");
        handleCategoryReset()
        navigate("/blogs")
        setLoading(false)
        
      } else {
        toast.error(response?.data?.message);
      }

    }
    catch (error) {
      toast.error('Something went wrong!');
    }
    finally {
      setLoading(false);
    }

  };

  // show and get edit data

  useEffect(()=>{
    if(editDataId){
      showEditData()
    }
  },[])

  const showEditData =(item) => {
   
    setSelectedId(item?._id)

    setInputCategoryFields({
    title: item?.title,
    titleHi: item?.titleHi,
    });
  }
  
  useEffect(()=>{
    getEditData()
  },[editDataId])

  const getEditData =async()=>{
    if(editDataId){

      let chadhawaDetailsRes = await APIServices.get(`blogCategory/getDetailById/${editDataId}`, headers);
      if (chadhawaDetailsRes?.status === 200) {
        let apiData = chadhawaDetailsRes?.data;
        if (apiData?.status) {
          showEditData(apiData?.data[0])
        }
      }
    }
  }

// update category

const handleUpdate = async(e) => {
  e.preventDefault()
  const formData = new FormData();
    formData.append('title', inputCategoryFields?.title);
    formData.append('titleHi', inputCategoryFields?.titleHi);

    const newErrors = validateCategoryInputs();

  setLoading(true)
  if (Object.keys(newErrors).length > 0) {
    setCategoryErrors(newErrors);
    setLoading(false)
  }
  else {
    const response = await APIServices.put(`blogCategory/editOne/${editDataId}`, formData);
    if (response.data.status === true) {
      toast.success(response?.data?.message);
      setLoading(false)
      navigate("/blogs")
      
    } else {
      toast.error(response?.data?.message);
    }
  } 
 
};

  return (
    <div className='blog_area '>
      <div className='gita_quote_heading'>
        <div className='container container d-flex justify-content-between'>
          <h4 className='font_20'>{editDataId ? "Edit " : "Add "}
             Category 
          </h4>
        </div>
      </div>
      <div className='container'>
        <div className=' my-5'>  
          <Form className='add_blog_form box-shadow' onSubmit={(e)=> `${selectedId ? handleUpdate(e) : handleSubmit(e)}`}>
            <Form.Group as={Row} className='mb-3'>
              <Col sm={2}>
                <Form.Label className='required'>Title</Form.Label>
              </Col>
              <Col sm={10}>
                <Form.Control
                  type='text'
                  placeholder='Blog Title...'
                  name='title'
                  isInvalid={!!categoryErrors?.title}
                  value={inputCategoryFields.title}
                  onChange={e => {
                    setInputCategoryFields(prev => ({ ...prev, title: e.target.value }));
                    handleCategoryChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{categoryErrors?.title}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formBasicEmailHi'>
              <Col sm={2}>
                <Form.Label className='required'>Title Hindi</Form.Label>
              </Col>
              <Col sm={10}>
                <Form.Control
                  type='text'
                  placeholder='Blog Title Hindi...'
                  name='titleHi'
                  isInvalid={!!categoryErrors?.titleHi}
                  value={inputCategoryFields.titleHi}
                  onChange={e => {
                    setInputCategoryFields(prev => ({ ...prev, titleHi: e.target.value }));
                    handleCategoryChange(e);
                  }}
                />
                <Form.Control.Feedback type='invalid'>{categoryErrors?.titleHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* <Button
              variant='primary'
              type='submit'
              className=' as_btn'
              onClick={e => {handleSubmit(e)}}
            >
                Add
            </Button> */}
            <div className='text-center'>
              <Button variant='primary' type='submit' className='as_btn'>
                {loading ? "Loading..." : `${ selectedId ? "Edit" : "Add"}`}
              </Button>
              <Button variant='primary' type='button' className='as_btn' onClick={() => navigate(-1)}>
                Back
              </Button>
            
            </div>
          </Form>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddEditCategory;
