import moment from "moment";
import { useState } from "react";
import { ThreeDots } from "react-bootstrap-icons";
import Replies from "./replies";

const Comment = ({ comment, handleDelete }) => {
    const [repShow, setRepShow] = useState(false)
    const [showoption, setShowOption] = useState(false)

    return (
        <li key={comment?._id} style={{ listStyle: 'none' }} className="py-2">
            <main className="d-flex justify-content-between user_comment">
                <article className="d-flex w-100">
                    <img src={comment?.astrologerId?.profileImage || comment?.userId?.profileImage} className="img-fluid rounded-circle" width={35} alt="usersimg" />
                    <div className="mx-2 d-flex justify-content-center align-items-center">
                        <p className="text-capitalize m-0 p-0 primaryColor responsiveSize name">
                            {comment?.astrologerId?.name || comment?.userId?.name || 'Unknown User'}
                        </p>
                        <p className="m-0 px-2 fw-light responsiveSize date" style={{ fontSize: '15px' }}>{moment(comment?.createdAt).format('MMM Do YYYY, h:mm a')}</p>
                    </div>
                </article>

                <div className="position-relative responsiveSize">
                    <ThreeDots className="pointerUtilityClass" onClick={() => setShowOption(!showoption)} />
                    <button className={`${showoption || 'd-none'} card p-1 position-absolute text-nowrap end-0 btnHover pointerUtilityClass z_Index`} onClick={() => handleDelete(comment?._id)}>
                        Delete
                    </button>
                </div>
            </main>

            {
                <article className="custom-margin border-top mt-2 pt-2 mb-2">
                    <p className="responsiveSize">{(comment?.description)}</p>
                    {
                        comment.reply.length ?
                            <button className="reply cursor-pointer border-0 px-2 rounded responsiveSize btnHover as_btn ms-0" onClick={() => setRepShow(!repShow)}>Replies</button> : null
                    }
                </article>
            }

            {
                repShow &&
                comment.reply.map(reply => {
                    return (
                        <div key={reply._id}>
                            <Replies commentpost={comment._id} postid={comment.postId} reply={reply} />
                        </div>
                    )
                })
            }
        </li>
    )
}

export default Comment;