import React, { createRef, useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Form,
} from "react-bootstrap";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { NutFill } from "react-bootstrap-icons";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useToasts } from "react-toast-notifications";
import APIServicenew from "../../utils/APIGeneralService"
import Cookies from "universal-cookie";
const cookies = new Cookies();
let token = cookies.get("accessToken");
const headers = { Authorization: `Bearer ${token}` };

export const AddUsers = () => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [place, setPlace] = useState();
  const { addToast } = useToasts();
  const [validated, setValidated] = useState(false);
  let nameRef = createRef();
  let emailRef = createRef();
  let mobileRef = createRef();
  let sourceRef = useRef();
  let problemRef = createRef();
  let dobRef = createRef();
  let locationRef = useRef();
  let hourRef = createRef();
  let minuteRef = createRef();
  let secondRef = createRef();
  let sourceURLRef = useRef();
  let problemDetailsRef = useRef();
  const[loader,setLoader]=useState(false)
  const [sourceList, setSourceList] = useState([]);
  const [problemList, setProblemList] = useState([]);
  const [showURLField, setShowURLField] = useState(false);
  const [showDetailsField, setShowDetailsField] = useState(false);

  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBY6JZhuRymDmtxBlKJuSHLMmYJxDwF_4Y";

  const source = [
    { value: "", label: "Select" },
    { value: "Facebook", label: "Facebook" },
    { value: "Instagram", label: "Instagram" },
    { value: "Linkedin", label: "Linkedin" },
    { value: "Call", label: "Call" },
    { value: "Whatsapp", label: "Whatsapp" },
    { value: "Youtube", label: "Youtube" },
    { value: "Other", label: "Other" },
  ];

  const problems = [
    { value: "", label: "Select" },
    { value: "Love Life", label: "Love Life" },
    { value: "Marriage Life", label: "Marriage Life" },
    { value: "Vastu", label: "Vastu" },
    { value: "Job", label: "Job" },
    { value: "Business", label: "Business" },
  ];

  useEffect(() => {
    try {
      setSourceList(source);
      setProblemList(problems);
    } catch (err) {
      console.log("err: ", err);
    }
  }, []);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  useEffect(()=>{
    setField("location",place)
  },[place])

  const findFormErrors = () => {

    const {
      name,
      mobile,
      email,
      location,
      hours,
      minutes,
      seconds,
      source,
      sourceURL,
      problemDetails,
      problem,
    } = form;

    const newErrors = {};
    
    if (!name || name === "") newErrors.name = "Please enter name!";
     else if (name.length > 15) newErrors.name = "Name is too long!";

    if (!mobile || mobile === "")
      newErrors.mobile = "Please enter moblie number!";
    else if (mobile.length > 10 || mobile.length < 10)
      newErrors.mobile ="Mobile number cannot be less then 10 digits and more then 10 digits!";
    
    if( !email || email == "") newErrors.email = "Please enter email id!"
    
    if (!location || location === undefined)
      newErrors.location = "Please enter location!";

      if ( !hours || hours === "")
      newErrors.hours = "Hours must be between 0 and 23!";

      if (!minutes || minutes === "")
      newErrors.minutes = "Minutes must be between 0 and 59!";
    
    if(!seconds || seconds === "" )
      newErrors.seconds ="Seconds must be between 1 and 59!"

    if (!source || source === "")
      newErrors.source = " Need to select source!";
    
    if (!sourceURL || sourceURL === "")
      newErrors.sourceURL = " Please add source URL!";

    if (!problem || problem === "")
      newErrors.problem = " Need to select problem!";

    if (!problemDetails || problemDetails === "")
       newErrors.problemDetails = " Please enter problem Details!";

      return newErrors;
  };

  const clearfield = () => {
    window.location.reload(false);
    nameRef.current.value = "";
    emailRef.current.value = "";
    mobileRef.current.value = "";
    problemRef.current.value = "";
    sourceRef.current.value = "";
    dobRef.current.value = "";
    hourRef.current.value = "";
    minuteRef.current.value = "";
    secondRef.current.value = "";
    locationRef.current.value = "";
    sourceURLRef.current.value = "";
    problemDetailsRef.current.value = "";
    setForm([""])
  };

    const addUsersData = async (event) => {
    let error = [];
    let userdata = {};
    event.preventDefault();
    const newErrors = findFormErrors();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      if (error.length === 0) {
        const current = new Date();
        const date = `${current.getFullYear()}-${
          current.getMonth() + 1
        }-${current.getDate()}`;

        userdata.created = date;
        userdata.name = nameRef.current.value;
        userdata.email = emailRef.current.value;
        userdata.contactNumber = mobileRef.current.value;
        userdata.password = "Test@123";
        userdata.problem = problemRef.current.value;
        userdata.utmSource = sourceRef.current.value;
        userdata.dob = dobRef.current.value;
        userdata.hour = hourRef.current.value;
        userdata.minutes = minuteRef.current.value;
        userdata.seconds = secondRef.current.value;
        userdata.location = locationRef.current.value;
        userdata.sourceURL = sourceURLRef.current.value;
        userdata.problemDetails = problemDetailsRef.current.value;
        userdata.deal_status = "Pending";

        let data = userdata;
        console.log(data);
        setLoader(true)
        let resp = await APIServices.post("users/signUp", data, headers);
        if (resp.data.status === true) {
          setTimeout(() => {
            toast.success("Created Successfully.", {
              position: toast.POSITION.TOP_RIGHT,
            });
            clearfield()
          }, 1000);
          setLoader(false)
         
        } else {
          setTimeout(() => {
            toast.error(resp.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1000);
          setLoader(false)
        }
        setLoader(false)
      } else {
        error.map((e) => {
          setTimeout(() => {
            toast.error(e.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }, 1000);
          setLoader(false)
        });
      }
    }
  };

  const renderName = () => {
    return (
      <>
        <Form.Group
          as={Col}
          size="md"
          className=""
          controlId="validationCustom01"
        >
          <Form.Label class="required">User Name</Form.Label>
          <Form.Control
            required
            name="name"
            type="text"
            placeholder="Name"
            ref={nameRef}
            isInvalid={!!errors.name}
            onChange={(e) => setField("name", e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <Form.Group
          as={Col}
          size="md"
          className=""
          controlId="validationCustom01"
        >
          <Form.Label class="required">Mobile Number</Form.Label>
          <Form.Control
            required
            name="mobile"
            type="number"
            placeholder="Mobile Number"
            ref={mobileRef}
            isInvalid={!!errors.mobile}
            onChange={(e) => setField("mobile", e.target.value)}  
          />
          <Form.Control.Feedback type="invalid">
            {errors.mobile}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderEmail = () => {
    return (
      <>
        <Form.Group
          as={Col}
          size="md"
          className=""
          controlId="validationCustom01"
        >
          <Form.Label class="required">Email Id</Form.Label>

          <Form.Control
            required
            name="email"
            type="email"
            placeholder="Email Id"
            ref={emailRef}
            isInvalid={!!errors.email}
            onChange={(e) => setField("email", e.target.value)}  
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderDOB = () => {
    return (
      <>
        <Form.Group as={Col} size="md" className="">
          <Form.Label class="">Date Of Birth</Form.Label>
          <Form.Control
            className="hd-cr"
            name="dob"
            placeholder="DOB"
            min="1900-01-1"
            max="2023-06-14"
            type="date"
            ref={dobRef}
          />
        </Form.Group>
      </>
    );
  };

  const renderHours = () => {
    return (
      <>
        <Form.Group as={Col} size="md" className="">
          <Form.Label className="required">Hours</Form.Label>
          <Form.Select
          aria-label="Default select example"
          required
          className="hd-cr"
          ref={hourRef}
            onChange={(e) => setField("hours", e.target.value)}
            isInvalid={!!errors.hours}
        >
        <option value="">Choose</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>

        </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.hours}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderMinutes = () => {
    return (
      <>
        <Form.Group as={Col} size="md" className="">
          <Form.Label class="required"> Minutes</Form.Label>
          <Form.Select
                  aria-label="Default select example"
                  required
                  className="hd-cr"
                  ref={minuteRef}
                    onChange={(e) => setField("minutes", e.target.value)}
                    isInvalid={!!errors.minutes}
                >
                <option value="">Choose</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                  <option value="40">40</option>
                  <option value="41">41</option>
                  <option value="42">42</option>
                  <option value="43">43</option>
                  <option value="43">43</option>
                  <option value="44">44</option>
                  <option value="45">45</option>
                  <option value="46">46</option>
                  <option value="47">47</option>
                  <option value="48">48</option>
                  <option value="49">49</option>
                  <option value="50">50</option>
                  <option value="51">51</option>
                  <option value="52">52</option>
                  <option value="53">53</option>
                  <option value="53">53</option>
                  <option value="54">54</option>
                  <option value="55">55</option>
                  <option value="56">56</option>
                  <option value="57">57</option>
                  <option value="58">58</option>
                  <option value="59">59</option>

                </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.minutes}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderSecond = () => {
    return (
      <>
        <Form.Group as={Col} size="md" className="">
          <Form.Label class="required">Seconds</Form.Label>
          <Form.Select
          aria-label="Default select example"
          required
          className="hd-cr"
          ref={secondRef}
            isInvalid={!!errors.seconds}
            onChange={(e) => setField("seconds", e.target.value)}
        >
        <option value="">Choose</option>
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
          <option value="23">23</option>
          <option value="24">24</option>
          <option value="25">25</option>
          <option value="26">26</option>
          <option value="27">27</option>
          <option value="28">28</option>
          <option value="29">29</option>
          <option value="30">30</option>
          <option value="31">31</option>
          <option value="32">32</option>
          <option value="33">33</option>
          <option value="34">34</option>
          <option value="35">35</option>
          <option value="36">36</option>
          <option value="37">37</option>
          <option value="38">38</option>
          <option value="39">39</option>
          <option value="40">40</option>
          <option value="41">41</option>
          <option value="42">42</option>
          <option value="43">43</option>
          <option value="43">43</option>
          <option value="44">44</option>
          <option value="45">45</option>
          <option value="46">46</option>
          <option value="47">47</option>
          <option value="48">48</option>
          <option value="49">49</option>
          <option value="50">50</option>
          <option value="51">51</option>
          <option value="52">52</option>
          <option value="53">53</option>
          <option value="53">53</option>
          <option value="54">54</option>
          <option value="55">55</option>
          <option value="56">56</option>
          <option value="57">57</option>
          <option value="58">58</option>
          <option value="59">59</option>

        </Form.Select>
            <Form.Control.Feedback type="invalid">
            {errors.seconds}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderLocation = () => {
    return (
      <>
        <Form.Group
          as={Col}
          size="md"
          className=""
          controlId="validationCustom01"
        >
          <Form.Label class="required">Location</Form.Label>

          <ReactGoogleAutocomplete
            required
            className={`form-control ${form.location === undefined ? errors.location !== undefined ? 'is-invalid' : "" : ""}`}
            name="location"
            type="text"
            placeholder="Location"
            apiKey={YOUR_GOOGLE_MAPS_API_KEY}
            isInvalid={!!errors.location}
            ref={locationRef}
            onPlaceSelected={(place) => setPlace(place)}
          />
          <Form.Control.Feedback type="invalid">
          {errors.location}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderSource = () => {
    return (
      <>
        <Form.Group
          as={Col}
          size="md"
          className=""
          controlId="validationCustom01"
        >
          <Form.Label class="required">Source</Form.Label>
          <Form.Select
            required
            className="hd-cr"
            placeholder="Source"
            name="source"
             onChange={(e) =>{ if (sourceRef.current.value !== "") {
              setShowURLField(true)
             setField("source", e.target.value)

             }else{
              setShowURLField(false)
             }}
            }
            isInvalid={!!errors.source}
            ref={sourceRef}
          >
            {sourceList?.map((e, key) => {
              return (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              );
            })}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
          {errors.source}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderProblem = () => {
    return (
      <>
        <Form.Group
          as={Col}
          size="md"
          className=""
          controlId="validationCustom01"
        >
          <Form.Label class="required">Problem</Form.Label>
          <Form.Select
            required
            name="problem"
            onChange={(e) =>{if(problemRef.current.value !== ""){
              setShowDetailsField(true)
             setField("problem" , e.target.value)

            }else{
              setShowDetailsField(false)
            }}
            }
            isInvalid={!!errors.problem}
            placeholder="Problem Area"
             ref={problemRef}
          >
            {problemList?.map((e, key) => {
              return (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {errors.problem}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderURLfield = () => {
    return (
      <>
        <Form.Group as={Col} size="md" className="" controlId="">
          <Form.Label class="required">Source URL</Form.Label>
          <Form.Control
            name="sourceURL"
            type="url"
            placeholder="Source URL"
            ref={sourceURLRef}
            onChange={(e) => setField("sourceURL", e.target.value)}
            isInvalid={!!errors.sourceURL}
          />
          <Form.Control.Feedback type="invalid">
            {errors.sourceURL}
          </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  const renderProblemDetails = () => {
    return (
      <>
        <Form.Group as={Col} size="md" className="" controlId="">
          <Form.Label class="required">Problem Details</Form.Label>
          <Form.Control
            name="problemDetails"
            placeholder="Problem Details"
            ref={problemDetailsRef}
            onChange={(e) => setField("problemDetails", e.target.value)}
            isInvalid={!!errors.problemDetails}
          />
            <Form.Control.Feedback type="invalid">
                {errors.problemDetails}
            </Form.Control.Feedback>
        </Form.Group>
      </>
    );
  };

  return (
    <>  <div className="notifiction_area" style={{
      backgroundImage: 'url("/images/why_choose_us.jpg")'
        }}>
         <div className="gita_quote_heading">
           <div className="container d-flex align-items-center justify-content-between">
           <h4 className="hd-list-f left-q" >Add User</h4>
           </div>
        </div>
            <div className="container">
            <div className="notifiction_form">
          <Form noValidate validated={validated} onSubmit={addUsersData}>
            <Row>
              <Col className="my-2 col-12 col-sm-4">{renderName()}</Col>
              <Col className="my-2 col-12 col-sm-4">{renderMobile()}</Col>
              <Col className="my-2 col-12 col-sm-4">{renderEmail()}</Col>
            </Row>
            <Row>
              <Col className="my-2 col-sm-6 col-md-3">
                {renderDOB()}
              </Col>
              <Col className="my-2 col-12 col-sm-6  col-md-3">{renderHours()}</Col>
              <Col className="my-2 col-12 col-sm-6  col-md-3">{renderMinutes()}</Col>
              <Col className="my-2 col-12 col-sm-6  col-md-3">{renderSecond()}</Col>
            </Row>
            <Row>
              <Col className="my-2 col-12 col-sm-4">
                {renderSource()}
                <br></br>
                {showURLField ? renderURLfield() : null}
              </Col>
              <Col className="my-2 col-12 col-sm-4">{renderLocation()}</Col>
              <Col className="my-2 col-12 col-sm-4">
                {renderProblem()}
                <br></br>
                {showDetailsField ? renderProblemDetails() : null}
              </Col>
            </Row>
            <Row>
              <Col className="text-center m-3 ">
              {loader?<Button variant="primary" className="as_btn" >
              Processing...
            </Button>:<Button variant="primary" className="as_btn" type="submit">
            Add User
          </Button>}
                
              </Col>
            </Row>
          </Form>
</div>
</div>
</div>

      <ToastContainer />
      {/* <AddInquiry /> */}
    </>
  );
};

// export const AddInquiry = () => {
//   const { addToast } = useToasts();

//   let nameRef = createRef();
//   let emailRef = createRef();
//   let mobileRef = createRef();
//   let sourceRef = createRef();
//   let problemRef = createRef();
//   let dobRef = createRef();
//   let locationRef = createRef();
//   let hourRef = createRef();
//   let minuteRef = createRef();
//   let secondRef = createRef();
//   let sourceURLRef = createRef();
//   let problemDetailsRef = createRef();

//   const [sourceList, setSourceList] = useState([]);
//   const [problemList, setProblemList] = useState([]);
//   const [showURLField, setShowURLField] = useState(false);
//   const [showDetailsField, setShowDetailsField] = useState(false);

//   const [validated1, setValidated1] = useState(false);

//   const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);

//   const source = [
//     { value: "" ,label:"Select"},
//     { value: "Facebook", label: "Facebook" },
//     { value: "Instagram", label: "Instagram" },
//     { value: "Linkedin", label: "Linkedin" },
//     { value: "Call", label: "Call" },
//     { value: "Whatsapp", label: "Whatsapp" },
//     { value: "Youtube", label: "Youtube" },
//     { value: "Other", label: "Other" },
//   ];

//   const problems = [
//     { value: "" , label:"Select"},
//     { value: "Love Life", label: "Love Life" },
//     { value: "Marriage Life", label: "Marriage Life" },
//     { value: "Vastu", label: "Vastu" },
//     { value: "Job", label: "Job" },
//     { value: "Business", label: "Business" },
//   ];

//   useEffect(() => {
//     try {
//       setSourceList(source);
//       setProblemList(problems);
//     } catch (err) {
//       console.log("err: ", err);
//     }
//   }, []);

//   const saveInquiryDATA = async (data) => {
//     let response = await APIServices("users/addInquiry", data, "POST");
//     return response;
//   };
//   const AddInquiryData = async (event) => {
//     let error = [];
//     let userdata = {};
//     const form = event.currentTarget;
//     event.preventDefault();
//     if (form.checkValidity() === false) {
//       event.stopPropagation();
//       setValidated1(true);
//     } else {
//       if (error.length === 0) {
//         const current = new Date();
//         const date = `${current.getFullYear()}-${
//           current.getMonth() + 1
//         }-${current.getDate()}`;
//         userdata.created = date;

//         userdata.name = nameRef.current.value;
//         // nameRef.current.value = "";

//         userdata.email = emailRef.current.value;
//         // emailRef.current.value = "";

//         userdata.contactNumber = mobileRef.current.value;
//         // mobileRef.current.value = "";

//         // let problem = problemRef.current.state.selectValue.map(
//         //   (ct) => ct.label
//         // );
//         userdata.problem = problemRef.current.value;
//         // problemRef.current.state.selectValue.length = "";

//         //let source = sourceRef.current.state.selectValue.map((ct) => ct.label);
//         userdata.utmSource = sourceRef.current.value;
//        // sourceRef.current.state.selectValue.length = "";
//         userdata.sourceURL = sourceURLRef.current?.value;
//         // if (userdata.sourceURL) {
//         //   sourceURLRef.current.value = "";
//         // }
//         userdata.problemDetails = problemDetailsRef.current?.value;
//         // if (userdata.problemDetails) {
//         //   problemDetailsRef.current.value = "";
//         // }
//         userdata.deal_status = "Pending";
//         let data = userdata;
//         console.log(data);
//         let resp = await APIServices.post("users/addInquiry", data, headers);
//         let success_msg = "Data Added Successfully";
//         if (resp.data.status == true) {
//           addToast(success_msg, {
//             appearance: "success",
//             autoDismiss: true,
//           });
//         } else {
//           addToast(resp.data.message, {
//             appearance: "error",
//             autoDismiss: true,
//           });
//         }
//       } else {
//         error.map((e) => {
//           addToast(e.message, {
//             appearance: "error",
//             autoDismiss: true,
//           });
//         });
//       }
//     }
//   };

//   const renderName = () => {
//     return (
//       <>
//         <Form.Group as={Col} size="md" controlId="validationCustom01">
//           <Form.Label class="required">User Name</Form.Label>
//           <Form.Control
//             required
//             name="name"
//             type="text"
//             placeholder="Name"
//             ref={nameRef}
//           />
//           <Form.Control.Feedback type="invalid">
//             Please enter user name.
//           </Form.Control.Feedback>
//         </Form.Group>
//       </>
//     );
//   };

//   const renderMobile = () => {
//     return (
//       <>
//         <Form.Group as={Col} size="md" controlId="validationCustom01">
//           <Form.Label class="required">Contect Number</Form.Label>
//           <Form.Control
//             required
//             name="mobile"
//             type="number"
//             placeholder="Mobile"
//             ref={mobileRef}
//           />
//            <Form.Control.Feedback type="invalid">
//             Please enter user Contect Number.
//           </Form.Control.Feedback>
//         </Form.Group>
//       </>
//     );
//   };

//   const renderEmail = () => {
//     return (
//       <>
//         <Form.Group as={Col} size="md" controlId="validationCustom01">
//           <Form.Label class="required">Email</Form.Label>
//           <Form.Control
//             required
//             name="email"
//             type="email"
//             placeholder="Email Id"
//             ref={emailRef}
//           />
//            <Form.Control.Feedback type="invalid">
//             Please enter email Id.
//           </Form.Control.Feedback>
//         </Form.Group>
//       </>
//     );
//   };

//   const renderDOB = () => {
//     return (
//       <>
//         <InputGroup size="md" className="">
//           <FormControl name="dob" placeholder="DOB" type="date" ref={dobRef} />
//         </InputGroup>
//       </>
//     );
//   };

//   const renderHours = () => {
//     return (
//       <>
//         <InputGroup size="md" className="">
//           <FormControl
//             name="hour"
//             placeholder="Hour"
//             type="number"
//             ref={hourRef}
//           />
//         </InputGroup>
//       </>
//     );
//   };

//   const renderMinutes = () => {
//     return (
//       <>
//         <InputGroup size="md" className="">
//           <FormControl
//             name="minutes"
//             placeholder="Minutes"
//             type="number"
//             ref={minuteRef}
//           />
//         </InputGroup>
//       </>
//     );
//   };

//   const renderSecond = () => {
//     return (
//       <>
//         <InputGroup size="md" className="">
//           <FormControl
//             name="second"
//             placeholder="Second"
//             type="number"
//             ref={secondRef}
//           />
//         </InputGroup>
//       </>
//     );
//   };

//   const renderLocation = () => {
//     return (
//       <>
//         <InputGroup size="md" className="">
//           <FormControl
//             name="location"
//             placeholder="Location"
//             ref={locationRef}
//           />
//         </InputGroup>
//       </>
//     );
//   };

//   const renderSource = () => {
//     return (
//       <>
//         <Form.Group as={Col} size="md" controlId="validationCustom01">
//           <Form.Label class="required">Source</Form.Label>
//           <Form.Select
//             required
//             placeholder="Source"
//             onChange={() =>sourceRef.current.value !== ""? setShowURLField(true):setShowURLField(false)}
//             name="source"
//           // options={sourceList}
//             ref={sourceRef}
//           >
//             {sourceList?.map((e, key) => {
//               return (
//                 <option key={key} value={e.value}>
//                   {e.label}
//                 </option>
//               );
//             })}
//             </Form.Select>
//             <Form.Control.Feedback type="invalid">
//             Please enter Source.
//           </Form.Control.Feedback>
//         </Form.Group>
//       </>
//     );
//   };

//   const renderProblem = () => {
//     return (
//       <>
//         <Form.Group as={Col} size="md" controlId="validationCustom01">
//           <Form.Label class="required">Problem</Form.Label>
//           <Form.Select
//             required
//             name="problem"
//             onChange={() =>problemRef.current.value !== "" ? setShowDetailsField(true):setShowDetailsField(false)}
//             placeholder="Problem Area"
//             // options={problemList}
//             ref={problemRef}
//           >
//              {problemList?.map((e, key) => {
//               return (
//                 <option key={key} value={e.value}>
//                   {e.label}
//                 </option>
//               );
//             })}
//         </Form.Select>
//         <Form.Control.Feedback type="invalid">
//             Please enter problem.
//           </Form.Control.Feedback>
//         </Form.Group>
//       </>
//     );
//   };

//   const renderURLfield = () => {
//     return (
//       <>
//         <Form.Group as={Col} size="md" controlId="validationCustom01">
//           <Form.Label class="required">SourceURL</Form.Label>
//           <Form.Control
//             required
//             name="sourceURL"
//             type="url"
//             placeholder="Source URL"
//             ref={sourceURLRef}
//           />
//         </Form.Group>
//       </>
//     );
//   };

//   const renderProblemDetails = () => {
//     return (
//       <>
//         <Form.Group as={Col} size="md" controlId="validationCustom01">
//           <Form.Label class="required">Problem Details</Form.Label>
//           <Form.Control
//             required
//             name="problemDetails"
//             placeholder="Problem Details"
//             ref={problemDetailsRef}
//           />
//         </Form.Group>
//       </>
//     );
//   };

//   return (
//     <Container fluid className="mt-3 mb-5">
//       <div style={{ margin: "20px 100px 100px 100px" }}>
//         <Form noValidate validated={validated1} onSubmit={AddInquiryData}>
//           <Row>
//             <Col>
//               <h5>Add Inquiry</h5>
//             </Col>
//           </Row>
//           <Row>
//             <Col className="my-2">{renderName()}</Col>
//             <Col className="my-2">{renderMobile()}</Col>
//             <Col className="my-2">{renderEmail()}</Col>
//           </Row>

//           <Row>
//             <Col className="my-2">
//               {renderSource()}
//               <br></br>
//               {showURLField ? renderURLfield() : null}
//             </Col>

//             <Col className="my-2">
//               {renderProblem()}
//               <br></br>
//               {showDetailsField ? renderProblemDetails() : null}
//             </Col>
//           </Row>

//           <Row>
//             <Col className="text-center m-3 ">
//               <Button variant="primary" type="submit">
//                 Submit
//               </Button>
//             </Col>
//           </Row>
//         </Form>
//       </div>
//     </Container>
//   );
// };

//export default AddUsers;
