export const PujaBookingStatus = Object.freeze({
  SCHEDULED: 0,
  PUJA_START: 1,
  PUJA_END: 2,
  VIDEO_SHARE: 3,
  PRASAD_DELIVERY: 4,
  PUJA_COMPLETE: 5,
});


export const HindiToEnglishDayMaping = Object.freeze({
  सोमवार: "monday",
  मंगलवार: "tuesday",
  बुधवार: "wednesday",
  गुरुवार: "thursday",
  शुक्रवार: "friday",
  शनिवार: "saturday",
  रविवार: "sunday",
});
