import { useState } from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";


function Pujacrop({setpujaCropImg,setFileCpujaimage,setShowCroppuja,showcroppuja,cpujafileimage}) {
  
  const [img, setImg] = useState("");
  const [crop, setCrop] = useState();
  const [output, setOutput] = useState("");
  const [imageRef, setImageRef] = useState(null);
  const min = -180;
  const max = 180;
  const [errors, setErrors] = useState({});
  const TO_RADIANS = Math.PI / 180;
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const TakeImage = (e) => {
    if(output){
        setOutput()
    }
    if (e.target.files && e.target.files.length > 0) {
      const data = e.target.files[0];
      setImg(data || "");
    }
    
  };

  // Create canvas to blob with promise
  const toBlob = (canvas) => {
    return new Promise((resolve) => {
      canvas.toBlob(resolve);
    });
  };

  const cropImageNow = async(e) => {
    // console.log("image.naturalWidth", image?.naturalWidth)
    const canvas = document.createElement("canvas");
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
    ctx.scale(pixelRatio, pixelRatio);

    ctx.imageSmoothingQuality = "high";

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    const rotateRads = rotate * TO_RADIANS;
    const centerX = imageRef.naturalWidth / 2;
    const centerY = imageRef.naturalHeight / 2;

    ctx.save();

    // 5) Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY);
    // 4) Move the origin to the center of the original position
    ctx.translate(centerX, centerY);
    // 3) Rotate around the origin
    ctx.rotate(rotateRads);
    // 2) Scale the image
    ctx.scale(scale, scale);
    // 1) Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY);

    ctx.drawImage(
      imageRef,
      0,
      0,
      imageRef.naturalWidth,
      imageRef.naturalHeight,
      0,
      0,
      imageRef.naturalWidth,
      imageRef.naturalHeight
    );

    ctx.restore();

    
    const blob = await toBlob(canvas);
    // setCimage(blob)
    setpujaCropImg(URL.createObjectURL(blob));
    setOutput(URL.createObjectURL(blob));

    const fileName = img.name
  const file = new File([blob], fileName, { type: blob.type });
  setFileCpujaimage(file)
  
  
};

const validateInputs = () => {
  let isValid = false;
 
  const newErrors = {};
  

    if ( !img) 
    newErrors.img = "Please select image .";

    if (img && !crop) 
    newErrors.crop = "Please crop image .";

    return newErrors;
}; 

  

  const handleCrop = () => {
    const newErrors = validateInputs();
    
    if(Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
     
      }else{
    if(crop){
      cropImageNow();
      setShowCroppuja(false);
       setOutput();
       setRotate();
       setScale(1);
       setCrop();
       setImg();
    }
  }
  };

  function handleChange(e) { 
    let key, value;
    // key=e
    key = e?.target?.name;
    if (!!errors[key]){
      setErrors({
        ...errors,
        [key]: null,
        });
    }
  }

  

  return (
    <>
      <Modal show={showcroppuja} onHide={()=>`${setShowCroppuja(false)}  ${setImg()}`}>
        <Modal.Header closeButton>
          <Modal.Title>Crop Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label className="required" column sm="3">
                Image
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="file"
                  style={{border:"1px solid #b3b3b3"}}
                  accept="image/*"
                  name='img'
                  isInvalid={!!errors?.img}
                  onChange={(e) => {TakeImage(e); handleChange(e)}}
                />
                <Form.Control.Feedback type="invalid">
                {errors?.img}
           </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label  column sm="3">
                Size
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  id="scale-input"
                  type="range"
                  style={{border:"1px solid #b3b3b3"}}
                  step="0.1"
                  value={scale}
                  disabled={!img}
                  onChange={(e) => setScale(Number(e.target.value))}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label  column sm="3">
                Rotation
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  id="rotate-input"
                  type="range"
                  min={min}
                  max={max}
                  style={{border:"1px solid #b3b3b3"}}
                  value={rotate}
                  disabled={!img}
                  onChange={(e) =>
                    setRotate(
                      Math.min(180, Math.max(-180, Number(e.target.value)))
                    )
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Col sm="9">
                
                  <ReactCrop crop={crop} minWidth={150} minHeight={150}  name="crop"  onChange={(c) => {setCrop(c); handleChange(c)}}>
                    {img && (
                      <img
                        ref={imageRef}
                        src={URL.createObjectURL(img)}
                        onLoad={(e) => {
                          setImageRef(e.target);
                        }}
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg)`,
                         
                        }}
                      />
                    )}
                  </ReactCrop>
                  {img && !crop?<p style={{color:"#dc3545", fontSize:"0.875em"}}>{errors.crop}</p>:""} 
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>`${setShowCroppuja(false)}  ${setImg()}`}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCrop}>
            Crop
          </Button>
        </Modal.Footer>
      </Modal>
    
    </>
  );
}

export default Pujacrop;
